import classnames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Portal } from 'react-portal';

import { useTheme } from '../../contexts/Theme';
import { useAbsolutePosition } from '../../hooks/useAbsolutePosition';
import { useClosable } from '../../hooks/useClosable';
import { useResize } from '../../hooks/useResize';
import { Card, CardList } from '../Card';
import { FocusTrap } from '../FocusTrap';
import { MenuDivider } from './MenuDivider';
import { MenuItem } from './MenuItem';

interface MenuDivider {
  divider: boolean;
}

type Item = React.ComponentProps<typeof MenuItem> | MenuDivider;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  align?: 'left' | 'center' | 'right';
  'aria-labelledby': string;
  cardListProps?: React.ComponentProps<typeof CardList>;
  cardProps?: React.ComponentProps<typeof Card>;
  customPosition?: React.CSSProperties;
  edgeSpacing?: number;
  id: string;
  items: Item[];
  offsetLeft?: number;
  offsetTop?: number;
  onClose?(): void;
  placement?: 'bottom' | 'top';
  position?: 'absolute' | 'fixed';
}

const MIN_HEIGHT = 80;
const transitionMs = 200;

export const Menu: React.FC<Props> = ({
  align = 'left',
  cardListProps = {},
  cardProps = {},
  className,
  customPosition = {},
  edgeSpacing = 0,
  items,
  offsetLeft = 0,
  offsetTop = 8,
  onClose = null,
  placement = 'bottom',
  position: stylePosition = 'absolute',
  ...props
}) => {
  const { zIndex } = useTheme();
  const [visible, setVisible] = useState(false);
  const [activeFocusTrap, setActiveFocusTrap] = useState(false);
  const triggerRef = useRef<HTMLElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const { style: position, reposition } = useAbsolutePosition({
    align,
    containerRef: menuRef,
    offsetLeft,
    offsetTop,
    placement,
    position: stylePosition,
    ref: triggerRef,
  });

  useEffect(() => {
    reposition();
  }, [items, reposition]);

  /**
   * maxHeight to avoid Menu overlapping window edges
   */
  const [maxHeight, setMaxHeight] =
    useState<React.CSSProperties['maxHeight']>('100vh');

  const close = useCallback(() => {
    setVisible(false);
    setTimeout(() => {
      onClose?.();
    }, transitionMs);
  }, [onClose, transitionMs]);

  useClosable(
    {
      ref: menuRef,
      onClose: close,
    },
    [visible]
  );

  useEffect(() => {
    triggerRef.current = document.getElementById(props['aria-labelledby']);
    // Avoid displaying Menu while trigger is clicked, to prevent closing it immediately
    const visibleTimeout = setTimeout(() => {
      setVisible(true);
    }, 50);
    return () => {
      clearTimeout(visibleTimeout);
      onClose?.();
    };
  }, []);

  useEffect(() => {
    const styleTop = parseFloat(menuRef.current?.style.top);
    const top = position?.top;
    const isActive = Math.abs(top - styleTop) < 0.1;
    setActiveFocusTrap(isActive);
  }, [menuRef.current?.style, position]);

  /**
   * Re-calculating maxHeight on resize prevents the Menu from overflowing.
   */
  useResize(() => {
    if (!triggerRef.current) return;
    const { bottom, top } = triggerRef.current.getBoundingClientRect();
    switch (placement) {
      case 'bottom':
        setMaxHeight(
          Math.max(
            MIN_HEIGHT,
            window.innerHeight - bottom - offsetTop - edgeSpacing
          )
        );
        break;
      case 'top':
        setMaxHeight(Math.max(MIN_HEIGHT, top - offsetTop - edgeSpacing));
        break;
    }
  }, [offsetTop, placement, triggerRef]);

  return (
    <Portal>
      <div
        className={classnames('menu', {
          visible,
        })}
        ref={menuRef}
        style={{ ...position, ...customPosition }}
      >
        <FocusTrap
          active={activeFocusTrap}
          arrowKeys="updown"
          searchByCharacter
        >
          <Card noBorder shadow="strong" {...cardProps}>
            <div
              className={classnames('menuList', className, {
                visible,
              })}
              role="menu"
              {...props}
            >
              <CardList
                {...cardListProps}
                style={{
                  ...(cardListProps?.style ?? {}),
                  maxHeight,
                }}
              >
                {items.map((item, i) =>
                  'divider' in item ? (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuDivider key={`menuitem-${i}`} />
                  ) : (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem key={`menuitem-${i}`} {...item} />
                  )
                )}
              </CardList>
            </div>
          </Card>
        </FocusTrap>
        <style jsx>{`
          .menu {
            left: -99em;
            opacity: 0;
            position: absolute;
            top: -99em;
            transition: opacity ${transitionMs}ms ease;
            z-index: ${zIndex.menu};
          }
          .menu.visible {
            opacity: 1;
          }
          .menuList {
            background: var(--menu-bg);
            min-width: 176px;
          }
          .menuList :global(.menuItem) {
            display: block;
            width: 100%;
          }
          .menuList :global(.menuItem) {
            align-items: center;
            display: flex;
            justify-content: space-between;
            width: 100%;
          }
          .revert-row {
            min-width: 100px;
            padding: 1px;
          }
          .revert-row :global(.menuItem) {
            flex-direction: row-reverse;
            justify-content: flex-end;
            border-radius: 6px;
            padding: 5px 10px;
          }
          .revert-row :global(.menuItem) :global(span) {
            padding: 0 3px;
            margin: 0;
          }
          .revert-row :global(.menuItem):hover {
            background: #3a3737 !important;
          }
          :global(.with-keyboard) .menuList :global(.menuItem:focus) {
            box-shadow: 0 0 0 2px var(--focus-shadow);
            outline: none;
          }
          :global(.with-keyboard)
            .menuList
            :global(.menuItem:not(.static):focus),
          .menuList :global(.menuItem:not(.static):hover) {
            background-color: var(--menu-item-hover-bg);
          }
          .menuList :global(.menuItem[disabled]:hover) {
            background: var(--menu-bg);
            cursor: not-allowed;
          }

          :global(.tile-show) {
            opacity: 1;
          }

          :global(.card-list) {
            padding: 3px !important;
          }
        `}</style>
      </div>
    </Portal>
  );
};
