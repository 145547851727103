import { useTheme } from '@daily/shared/contexts/Theme';

export const UploadIcon: React.FC = () => {
  const { colors } = useTheme();

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.39422 4.82291L4.82279 7.39393C4.48764 7.72905 4.48764 8.27071 4.82279 8.60582C5.15793 8.94093 5.69964 8.94093 6.03479 8.60582L7 7.6474L7 13.9171C4.16229 13.441 2 10.973 2 8.00001C2 4.6863 4.68629 2.00001 8 2.00001C11.3137 2.00001 14 4.6863 14 8.00001C14 10.973 11.8377 13.441 9 13.9171L9 7.64719L9.96564 8.60582C10.3008 8.94093 10.8425 8.94093 11.1776 8.60582C11.3448 8.43869 11.4288 8.21929 11.4288 7.99988C11.4288 7.78047 11.3448 7.56106 11.1776 7.39393L8.60622 4.82291C8.27107 4.4878 7.72936 4.4878 7.39422 4.82291Z"
        fill={colors.baseText}
      />
      <style jsx>{`
        svg {
          display: block;
        }
      `}</style>
    </svg>
  );
};
