import { Text } from '@daily/shared/components/Text';
import { useTheme } from '@daily/shared/contexts/Theme';
import { pxToRem } from '@daily/shared/lib/pxToRem';
import { useMediaTrack, useParticipant } from '@daily-co/daily-react-hooks';
import classNames from 'classnames';
import { CALL_CUSTOM_EVENTS } from 'components/App/IframeListener';
import { UserImage } from 'components/Gemstone/UserImage';
import { useIframeDriver } from 'contexts/IframeDriverProvider';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCallState } from '../../contexts/CallProvider';
import { useDisplayName } from '../../hooks/useDisplayName';
import { Tile } from '../Tile';
import { TileInfo } from '../Tile/TileInfo';
import { TileVideo } from '../Tile/TileVideo';
import { DEFAULT_PORTRAIT_AR } from './constants';

type Orientation = 'portrait' | 'landscape';

interface Props extends React.ComponentProps<typeof Tile> {
  orientation: Orientation;
}

export const MobileTile: React.FC<Props> = ({
  isScreen = false,
  isSpeaking,
  network,
  orientation,
  sessionId,
  videoFit,
  ...props
}) => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const isPortrait = useMemo(() => orientation === 'portrait', [orientation]);
  const [cropVideo, setCropVideo] = useState<Orientation>(null);
  const { showNames } = useCallState();

  const participant = useParticipant(sessionId);

  const hasPoorConnection = network === 'very-low' && participant?.local;
  const hasWeakConnection = network === 'low' && participant?.local;

  const displayName = useDisplayName(participant, showNames, network);

  const { sendMessageToDriver } = useIframeDriver();
  const handleClick = useCallback(
    (event) => {
      console.log({ event });
      sendMessageToDriver({
        action: CALL_CUSTOM_EVENTS.ST_SHOW_PARTICIPANT_DETAILS,
        payload: {
          from: 'mobile-tile',
          userID: participant?.user_id,
          x: event.clientX,
          y: event.clientY,
        },
      });
    },
    [participant?.user_id, sendMessageToDriver]
  );

  const noVideoText = useMemo(() => {
    let color: React.ComponentProps<typeof Text>['color'] = 'inherit';
    if (hasWeakConnection) {
      color = 'warning';
    }
    if (hasPoorConnection) {
      color = 'error';
    }
    const style = {
      fontSize: pxToRem(16),
      lineHeight: pxToRem(20),
    };
    return (
      <Text
        color={color}
        style={{ cursor: 'pointer', color: participant?.userData?.['color'] }}
        truncate
        variant="strong"
        onClick={handleClick}
      >
        {displayName}
      </Text>
    );
  }, [
    displayName,
    handleClick,
    hasPoorConnection,
    hasWeakConnection,
    participant?.userData,
  ]);

  const videoRef = useRef<HTMLVideoElement>(null);
  const videoState = useMediaTrack(
    sessionId,
    isScreen ? 'screenVideo' : 'video'
  );

  /**
   * Handle video resize to determine if video should be cropped based on orientation.
   */
  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;
    const handleVideoResize = () => {
      if (orientation === 'landscape') {
        setCropVideo(null);
        return;
      }
      if (!video) return;
      const { videoHeight, videoWidth } = video;
      if (videoWidth && videoHeight) {
        const aspectRatio = videoWidth / videoHeight;
        if (aspectRatio >= 4 / 3) {
          setCropVideo('landscape');
        } else if (aspectRatio <= 3 / 4) {
          setCropVideo('portrait');
        } else {
          setCropVideo(null);
        }
      }
    };
    handleVideoResize();
    video.addEventListener('resize', handleVideoResize);
    return () => {
      video?.removeEventListener('resize', handleVideoResize);
    };
  }, [isPortrait, orientation]);

  return (
    <div
      className={classNames('mobile-tile', {
        cropLandscape: cropVideo === 'landscape',
        cropPortrait: cropVideo === 'portrait',
        portrait: isPortrait,
        landscape: !isPortrait,
      })}
      id={sessionId}
      {...props}
    >
      <TileVideo
        ref={videoRef}
        fit={videoFit}
        isLocal={participant?.local}
        sessionId={sessionId}
        isScreen={isScreen}
      />
      {videoState.isOff && !isScreen && (
        <div className="noVideo">
          {participant?.userData?.['avatar'] ? (
            <div onClick={handleClick} style={{ cursor: 'pointer' }}>
              <UserImage
                size="large"
                avatar={participant?.userData?.['avatar']}
                gemstone={participant?.userData?.['gemstone']}
              />
            </div>
          ) : (
            noVideoText
          )}
        </div>
      )}
      {!isScreen && (
        <TileInfo
          activeIndicator="static"
          name={displayName}
          sessionId={sessionId}
        />
      )}
      <style jsx>{`
        .mobile-tile {
          background: ${colors.custom.mainAreaBgAccent};
          border-radius: 4px;
          height: 100%;
          overflow: hidden;
          position: relative;
          width: 100%;
        }
        .mobile-tile.portrait.cropLandscape :global(video) {
          border-radius: 0;
          // Enforce 4:3 aspect ratio within 3:4 container
          height: 56.25%;
          margin-top: calc(
            calc(
                calc(100% / ${DEFAULT_PORTRAIT_AR}) - 100% *
                  ${DEFAULT_PORTRAIT_AR}
              ) / 2
          );
          object-fit: cover;
          width: 100%;
        }
        .mobile-tile.portrait.cropPortrait :global(video) {
          object-fit: cover;
        }
        .noVideo {
          cursor: pointer;
          align-items: center;
          background: ${colors.custom.mainAreaBgAccent};
          color: ${colors.custom.mainAreaText};
          display: flex;
          justify-content: center;
          left: 0;
          padding: 8px;
          height: 100%;
          position: absolute;
          top: 0;
          width: 100%;
        }
        .mobile-tile :global(.info),
        .mobile-tile :global(.info .mic) {
          border-bottom-left-radius: 4px;
        }
      `}</style>
    </div>
  );
};
