import { Button } from '@daily/shared/components/Button';
import { LoadingSpinner } from '@daily/shared/components/LoadingSpinner';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { AnimatePresence, motion } from 'framer-motion';
import getConfig from 'next/config';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  animationPlacement?: 'left' | 'right';
}

export const TestSound: React.FC<Props> = ({ animationPlacement = 'left' }) => {
  const { t } = useTranslation();
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const { assetPrefix } = getConfig().publicRuntimeConfig;

  const handleTestSoundClick = () => {
    audioRef.current.play();
  };

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const handlePlaying = () => {
      setIsPlaying(true);
    };
    const handleEnded = () => {
      setIsPlaying(false);
    };

    audio.addEventListener('playing', handlePlaying);
    audio.addEventListener('ended', handleEnded);
    return () => {
      audio.removeEventListener('playing', handlePlaying);
      audio.removeEventListener('ended', handleEnded);
    };
  }, [audioRef]);

  return (
    <div>
      <Stack
        align="center"
        gap={4}
        horizontal
        style={{
          flexDirection: animationPlacement === 'right' ? 'row-reverse' : 'row',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: animationPlacement === 'right' && '100%',
            right: animationPlacement === 'left' && '100%',
          }}
        >
          <AnimatePresence>
            {isPlaying && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <LoadingSpinner color="var(--text-muted)" size={16} />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <Button
          disabled={isPlaying}
          onClick={handleTestSoundClick}
          variant="ghost"
        >
          <Text color="muted" style={{ margin: '-1px 0 1px' }} underline>
            {t('settings.modal.playTestSound')}
          </Text>
        </Button>
      </Stack>
      <audio ref={audioRef} src={`${assetPrefix}/test-sound.mp3`} />
    </div>
  );
};
