import { useCallback, useEffect, useState } from 'react';

import { useResize } from './useResize';

interface HookArgs {
  align?: 'left' | 'center' | 'right';
  containerRef: React.MutableRefObject<HTMLElement>;
  offsetLeft?: number;
  offsetTop?: number;
  placement?: 'bottom' | 'top';
  position?: 'absolute' | 'fixed';
  ref: React.MutableRefObject<HTMLElement>;
}

export const useAbsolutePosition = ({
  align = 'left',
  containerRef,
  offsetLeft = 0,
  offsetTop = 8,
  placement = 'bottom',
  position: stylePosition = 'absolute',
  ref,
}: HookArgs) => {
  const [position, setPosition] = useState(null);

  const calcPosition = useCallback(() => {
    if (!ref.current || !containerRef.current) return;
    const trigger = ref.current;
    const triggerRect = trigger.getBoundingClientRect();
    let left = null;
    let top = null;
    switch (align) {
      case 'left':
        left = Math.min(
          triggerRect.left,
          window.innerWidth - containerRef.current.clientWidth
        );
        break;
      case 'right':
        left = Math.max(
          0,
          triggerRect.right - containerRef.current?.clientWidth
        );
        break;
      case 'center':
        left =
          triggerRect.left +
          triggerRect.width / 2 -
          containerRef.current?.clientWidth / 2;
        break;
    }
    switch (placement) {
      case 'bottom':
        top =
          (stylePosition === 'absolute' ? window.scrollY : 0) +
          triggerRect.top +
          triggerRect.height +
          offsetTop;
        break;
      case 'top':
        top =
          (stylePosition === 'absolute' ? window.scrollY : 0) +
          triggerRect.top -
          containerRef.current?.clientHeight -
          offsetTop;
        break;
    }

    setPosition({
      left: left + offsetLeft,
      top,
    });
  }, []);

  useResize(calcPosition);

  useEffect(() => {
    if (!containerRef.current || !ref.current) return;
    calcPosition();
  }, [containerRef.current, ref.current]);

  return {
    reposition: calcPosition,
    style: {
      position: stylePosition,
      ...position,
    },
  };
};
