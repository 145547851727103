import { Button } from '@daily/shared/components/Button';
import { Modal } from '@daily/shared/components/Modal';
import { Text } from '@daily/shared/components/Text';
import { useTranslation } from 'react-i18next';

interface Props extends React.ComponentProps<typeof Modal> {}

export const AutoplayFailedModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const handleModalClose = () => {
    document
      .querySelectorAll('.audioTracks audio')
      .forEach((audio: HTMLAudioElement) => {
        if (audio.paused) audio.play();
      });
    props.onClose();
  };

  return (
    <Modal
      actions={[
        <Button key="join" variant="primary">
          {t('general.joinMeeting')}
        </Button>,
      ]}
      onClose={handleModalClose}
      title={t('lobby.audioPrompt.title')}
    >
      <Text El="p" variant="large">
        {t('lobby.audioPrompt.desc')}
      </Text>
    </Modal>
  );
};
