import { Text } from '@daily/shared/components/Text';
import { useTheme } from '@daily/shared/contexts/Theme';
import { DailyNetworkStats } from '@daily-co/daily-js';
import { useParticipant } from '@daily-co/daily-react-hooks';
import { CALL_CUSTOM_EVENTS } from 'components/App/IframeListener';
import { UserImage } from 'components/Gemstone/UserImage';
import { useIframeDriver } from 'contexts/IframeDriverProvider';
import { memo, useCallback, useMemo } from 'react';

interface Props {
  hasPoorConnection: boolean;
  isScreen: boolean;
  name: string;
  network?: DailyNetworkStats['threshold'];
  sessionId: string;
}

export const NoVideo: React.FC<Props> = memo(
  ({ hasPoorConnection, isScreen, name, network, sessionId }) => {
    const { colors } = useTheme();

    const participant = useParticipant(sessionId);
    const { sendMessageToDriver } = useIframeDriver();

    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        sendMessageToDriver({
          action: CALL_CUSTOM_EVENTS.ST_SHOW_PARTICIPANT_DETAILS,
          payload: {
            from: 'tile',
            userID: participant?.user_id,
            x: event.clientX,
            y: event.clientY,
          },
        });
      },
      [participant?.user_id, sendMessageToDriver]
    );

    const noVideoText = useMemo(() => {
      let color: React.ComponentProps<typeof Text>['color'] = 'inherit';
      if (network === 'low') {
        color = 'warning';
      }
      if (hasPoorConnection) {
        color = 'error';
      }
      return (
        <Text
          onClick={handleClick}
          color={color}
          truncate
          variant="strong"
          style={{ cursor: 'pointer', color: participant?.userData?.['color'] }}
        >
          {name}
        </Text>
      );
    }, [handleClick, hasPoorConnection, name, participant, network]);

    return (
      <div className="noVideo">
        {isScreen ? null : (
          <>
            {participant?.userData?.['avatar'] ? (
              <div onClick={handleClick} style={{ cursor: 'pointer' }}>
                <UserImage
                  size="large"
                  avatar={participant?.userData?.['avatar']}
                  gemstone={participant?.userData?.['gemstone']}
                />
              </div>
            ) : (
              noVideoText
            )}
          </>
        )}
        <style jsx>{`
          .noVideo {
            background-color: ${colors.custom.mainAreaBgAccent};
            color: ${colors.custom.mainAreaText};
            left: 50%;
            max-width: 100%;
            padding: 0 16px;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        `}</style>
      </div>
    );
  }
);
NoVideo.displayName = 'NoVideo';
