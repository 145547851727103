import { useEffect, useState } from 'react';

type ResizeObserverCallback = (width: number, height: number) => void;

/**
 * Sets up a ResizeObserver for the given ref element.
 * Whenever the element's dimension change, callback is called
 * with the element's current width and height.
 */
export const useResizeObserver = (
  ref: React.MutableRefObject<HTMLElement>,
  callback: ResizeObserverCallback
) => {
  const [isPageVisible, setIsPageVisible] = useState(true);

  /**
   * Setup page visibility change listener.
   * When a page is backgrounded, ResizeObservers don't seem to catch any events.
   * We'll fallback to an interval in that case, since intervals are only throttled,
   * when the page is backgrounded.
   */
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsPageVisible(document.visibilityState === 'visible');
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (!ref.current) return;

    if (typeof ResizeObserver !== 'undefined' && isPageVisible) {
      let frame: ReturnType<typeof requestAnimationFrame>;
      const resizeObserver = new ResizeObserver(() => {
        if (!ref.current) return;
        const { height, width } = ref.current.getBoundingClientRect();
        if (frame) cancelAnimationFrame(frame);
        frame = requestAnimationFrame(() => {
          callback(width, height);
        });
      });
      resizeObserver.observe(ref.current);
      return () => {
        resizeObserver.disconnect();
      };
    }

    let lastWidth: number;
    let lastHeight: number;
    const interval = setInterval(() => {
      if (!ref.current) return;
      const { height, width } = ref.current.getBoundingClientRect();
      if (width !== lastWidth || height !== lastHeight) {
        callback(width, height);
        lastWidth = width;
        lastHeight = height;
      }
    }, 100);
    return () => {
      clearInterval(interval);
    };
  }, [callback, isPageVisible, ref]);
};
