import { DailyEventObjectNonFatalError } from '@daily-co/daily-js';
import { useDailyEvent } from '@daily-co/daily-react-hooks';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCallState } from '../../contexts/CallProvider';
import { useParticipants } from '../../contexts/ParticipantsProvider';
import {
  useAutoplayFailedModal,
  useDeviceInUseModal,
  useDeviceNotFoundModal,
  useHiddenParticipantModal,
  useLeaveWhileRecordingModal,
  useMuteAllModal,
  useNameModal,
  useRecordingErrorModal,
  useScreenshareErrorModal,
  useStartRecordingModal,
  useStartScreenshareModal,
  useUnblockPermissionsModal,
} from '../../contexts/UIState';
import { useCallConfig } from '../../hooks/useCallConfig';
import { isFirefox, isSafari } from '../../lib/browserConfig';
import { BroadcastJoiningModal } from '../BroadcastJoiningModal';
import { DeviceInUseModal } from '../Haircheck/DeviceInUseModal';
import { DeviceNotFoundModal } from '../Haircheck/DeviceNotFoundModal';
import { UnblockPermissionsModal } from '../Haircheck/UnblockPermissionsModal';
import { NameModal } from '../NameModal';
import { ParticipantHiddenModal } from '../ParticipantHiddenModal';
import { RemoveParticipantModal } from '../People';
import {
  LeaveWhileRecordingModal,
  RecordingErrorModal,
  StartRecordingModal,
} from '../Recording';
import { ScreenShareErrorModal } from '../ScreenShareErrorModal';
import { MuteAllModal } from '../Sidebar/MuteAllModal';
import { StartScreenShareModal } from '../StartScreenShareModal';
import { AutoplayFailedModal } from './AutoplayFailedModal';

export const Modals: React.FC = () => {
  const { t } = useTranslation();
  const closeText = t('general.close');

  const [showHiddenParticipantModal] = useHiddenParticipantModal();
  const [showAutoplayFailedModal, setShowAutoplayFailedModal] =
    useAutoplayFailedModal();
  const [showDeviceInUseModal, setShowDeviceInUseModal] = useDeviceInUseModal();
  const [showDeviceNotFoundModal, setShowDeviceNotFoundModal] =
    useDeviceNotFoundModal();
  const [showNameModal, setShowNameModal] = useNameModal();
  const [showRecordingErrorModal, setShowRecordingErrorModal] =
    useRecordingErrorModal();
  const [showStartRecordingModal, setShowStartRecordingModal] =
    useStartRecordingModal();
  const [showStartScreenshareModal, setShowStartScreenshareModal] =
    useStartScreenshareModal();
  const [screenShareError, setScreenShareError] = useScreenshareErrorModal();
  const [showUnblockPermissionsModal, setShowUnblockPermissionsModal] =
    useUnblockPermissionsModal();
  const [showLeaveWhileRecordingModal, setShowLeaveWhileRecordingModal] =
    useLeaveWhileRecordingModal();
  const [showMuteAllModal, setShowMuteAllModal] = useMuteAllModal();

  const { attendeeUIInteraction } = useCallState();
  const { broadcastRole } = useCallConfig();
  const { participantMarkedForRemoval, setParticipantMarkedForRemoval } =
    useParticipants();
  const { setAttendeeUIInteraction } = useCallState();

  const handleRemoveParticipantModalClose = () =>
    setParticipantMarkedForRemoval(null);
  const handleNameModalClose = () => setShowNameModal(false);
  const handleAutoplayFailedModalClose = () =>
    setShowAutoplayFailedModal(false);
  const handleBroadcastJoiningModalClose = () => setAttendeeUIInteraction(true);
  const handleDeviceInUseModalClose = () => setShowDeviceInUseModal(false);
  const handleDeviceNotFoundModalClose = () =>
    setShowDeviceNotFoundModal(false);
  const handleRecordingErrorModalClose = () =>
    setShowRecordingErrorModal(false);
  const handleStartRecordingModalClose = () =>
    setShowStartRecordingModal(false);
  const handleStartScreenShareModalClose = () =>
    setShowStartScreenshareModal(false);
  const handleScreenShareErrorModal = () => setScreenShareError(null);
  const handleUnblockPermissionsModalClose = () =>
    setShowUnblockPermissionsModal(false);
  const handleLeaveWhileRecordingModalClose = () =>
    setShowLeaveWhileRecordingModal(false);
  const handleMuteAllModalClose = () => setShowMuteAllModal(false);

  /** In public+no prejoin UI rooms, attendees won't see the haircheck
   * and won't have a UI interaction before joining the call. We show
   * a modal to prompt a user interaction.
   */
  const isAttendee = useMemo(
    () => broadcastRole === 'attendee',
    [broadcastRole]
  );

  useDailyEvent(
    'nonfatal-error',
    useCallback(
      (ev: DailyEventObjectNonFatalError) => {
        if (ev.type === 'screen-share-error') {
          if (
            ev.errorMsg.includes(
              'getDisplayMedia requires transient activation from a user gesture'
            )
          ) {
            setShowStartScreenshareModal(true);
          }
          if (ev.errorMsg.includes('blocked-by-os')) {
            setScreenShareError('blocked-by-os');
          }
          if (
            ev.errorMsg.includes('blocked-by-browser') &&
            (isFirefox() || isSafari())
          ) {
            setScreenShareError('blocked-by-browser');
          }
        }
      },
      [setScreenShareError, setShowStartScreenshareModal]
    )
  );

  return (
    <>
      {showHiddenParticipantModal && <ParticipantHiddenModal />}
      {participantMarkedForRemoval && (
        <RemoveParticipantModal onClose={handleRemoveParticipantModalClose} />
      )}
      {showAutoplayFailedModal && !isAttendee && (
        <AutoplayFailedModal onClose={handleAutoplayFailedModalClose} />
      )}
      {(!attendeeUIInteraction || showAutoplayFailedModal) && isAttendee && (
        <BroadcastJoiningModal onClose={handleBroadcastJoiningModalClose} />
      )}
      {showDeviceInUseModal && (
        <DeviceInUseModal
          onClose={handleDeviceInUseModalClose}
          closeText={closeText}
        />
      )}
      {showDeviceNotFoundModal && (
        <DeviceNotFoundModal
          onClose={handleDeviceNotFoundModalClose}
          closeText={closeText}
        />
      )}
      {showLeaveWhileRecordingModal && (
        <LeaveWhileRecordingModal
          onClose={handleLeaveWhileRecordingModalClose}
          closeText={closeText}
        />
      )}
      {showMuteAllModal && (
        <MuteAllModal onClose={handleMuteAllModalClose} closeText={closeText} />
      )}
      {showNameModal && <NameModal onClose={handleNameModalClose} />}
      {showRecordingErrorModal && (
        <RecordingErrorModal
          onClose={handleRecordingErrorModalClose}
          closeText={closeText}
        />
      )}
      {showStartRecordingModal && (
        <StartRecordingModal
          onClose={handleStartRecordingModalClose}
          closeText={closeText}
        />
      )}
      {showStartScreenshareModal && (
        <StartScreenShareModal onClose={handleStartScreenShareModalClose} />
      )}
      {screenShareError && (
        <ScreenShareErrorModal
          error={screenShareError}
          closeText={t('general.dismiss')}
          onClose={handleScreenShareErrorModal}
        />
      )}
      {showUnblockPermissionsModal && (
        <UnblockPermissionsModal
          onClose={handleUnblockPermissionsModalClose}
          closeText={closeText}
        />
      )}
    </>
  );
};
