import classNames from 'classnames';
import React, { useContext, useState } from 'react';

import { useTheme } from '../../contexts/Theme';
import { Button } from '../Button';
import { Text } from '../Text';
import { TabsContext } from './Tabs';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  // index is automatically set by TabList component
  index?: number;
  textProps?: React.ComponentProps<typeof Text>;
}

export const Tab: React.FC<Props> = ({
  children,
  className,
  index,
  onClick,
  textProps = {},
  ...props
}) => {
  const { activeTab, id, setTab, vertical } = useContext(TabsContext);
  const { colors, isDarkMode } = useTheme();
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(ev);
    setTab(index);
  };

  const isActive = index === activeTab;

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div className="tab-wrapper">
      <Button
        variant="ghost"
        role="tab"
        aria-selected={isActive}
        aria-controls={`tabpanel-${id}-${index}`}
        className={classNames('tab', className, {
          'tab-active': isActive,
          'tab-vertical': vertical,
        })}
        id={`tab-${id}-${index}`}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        tabIndex={isActive ? 0 : -1}
        {...props}
      >
        <Text
          color={
            isActive && vertical
              ? 'inherit'
              : isActive || isHovered || vertical
              ? 'default'
              : 'muted'
          }
          variant="strong"
          {...textProps}
        >
          {children}
        </Text>
      </Button>
      <style jsx>{`
        .tab-wrapper :global(.tab-vertical) {
          padding: 8px 16px;
          width: 100%;
        }
        .tab-wrapper :global(.tab-vertical:focus),
        .tab-wrapper :global(.tab-vertical:hover) {
          background: ${isDarkMode
            ? colors.system.blue
            : colors.system.greyLight};
        }
        :global(.with-mouse) .tab-wrapper :global(.tab-vertical:hover) {
          box-shadow: none;
        }
        .tab-wrapper :global(.tab-vertical.tab-active) {
          background-color: ${colors.accent};
          color: ${colors.accentText};
        }
      `}</style>
    </div>
  );
};
