import { SVGProps } from 'react';

export function DiscordSupportIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" {...props}>
      <path
        fill="#DBB2FF"
        d="M14.849 24.368h2.302V14.327H14.85v10.041ZM16 11.748c.358 0 .665-.13.92-.39a1.29 1.29 0 0 0 .385-.938 1.29 1.29 0 0 0-.384-.938 1.245 1.245 0 0 0-.921-.39c-.358 0-.665.13-.92.39a1.29 1.29 0 0 0-.385.938c0 .364.128.677.384.938.256.26.563.39.921.39ZM16 32.3c-3.582-.911-6.523-3.028-8.826-6.349-2.302-3.32-3.453-6.96-3.453-10.92V5.731L16 1.043l12.28 4.688v9.3c0 3.958-1.152 7.598-3.454 10.92-2.303 3.32-5.245 5.437-8.826 6.348Z"
      />
    </svg>
  );
}
