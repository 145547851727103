import React from 'react';

import { CheckIcon, WarningIcon } from '../Icons';

interface Props {
  icon?: JSX.Element;
  type: string;
}

export const SnackbarIcon: React.FC<Props> = ({ icon, type }) => {
  if (icon)
    return React.cloneElement(icon, {
      color: `var(--snackbar-${type}-color)`,
    });

  if (type === 'error') {
    return <WarningIcon color="var(--snackbar-error-color)" size={16} />;
  }
  if (type === 'success') {
    return <CheckIcon color="var(--snackbar-success-color)" size={16} />;
  }
};
