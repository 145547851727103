import { sortByKey } from '@daily/shared/lib/sortByKey';
import { DailyEventObjectParticipant } from '@daily-co/daily-js';
import {
  useAppMessage,
  useDaily,
  useThrottledDailyEvent,
} from '@daily-co/daily-react-hooks';
import deepEqual from 'fast-deep-equal';
import { useRouter } from 'next/router';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { atom, selector, useRecoilCallback, useRecoilValue } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

import { useCallConfig } from '../hooks/useCallConfig';
import { useSidebarView } from './UIState';

export type Reaction =
  | '👏'
  | '👍'
  | '👎'
  | '❤️'
  | '😂'
  | '🤯'
  | '🎉'
  | '👋'
  | '➕'
  | '🔥';

export interface ChatMessage {
  date: Date;
  fromId: string;
  id: string;
  local: boolean;
  message: string;
  name?: string;
  reactions?: Record<
    Reaction,
    {
      count: number;
      hasReacted: boolean;
    }
  >;
  received?: Date;
  seen?: boolean;
}

interface ChatMessageData {
  event: 'chat-msg';
  date: Date;
  message: string;
  name: string;
}
interface RequestHistoryData {
  event: 'request-chat-history';
}
interface SyncChatMessageData {
  event: 'sync-chat-msg';
  message: ChatMessage;
}
interface SyncChatCompleteData {
  event: 'sync-chat-complete';
}
interface MessageReactionData {
  event: 'message-reaction';
  message: ChatMessage;
  reaction: Reaction;
  delta: 1 | -1;
}
type AppMessageData =
  | ChatMessageData
  | RequestHistoryData
  | SyncChatMessageData
  | SyncChatCompleteData
  | MessageReactionData;

type SendMessageOptions = {
  name?: string; // Override sender name. IE. "Study Together"
  showAsLocal?: boolean; // Show message as local message.
};
interface ContextValue {
  loading: boolean;
  reactToMessage(message: ChatMessage, reaction: Reaction): void;
  sendMessage(m: string, options?: SendMessageOptions): void;
}

const SYNC_CAP = 1000;
const SYNC_MAX_WAITING_TIME = 60 * 1000;
const SYNC_MAX_WAITING_TIME_OPEN = 5 * 1000;

const initialReactions: ChatMessage['reactions'] = {
  '❤️': {
    count: 0,
    hasReacted: false,
  },
  '🎉': {
    count: 0,
    hasReacted: false,
  },
  '👍': {
    count: 0,
    hasReacted: false,
  },
  '👎': {
    count: 0,
    hasReacted: false,
  },
  '👏': {
    count: 0,
    hasReacted: false,
  },
  '👋': {
    count: 0,
    hasReacted: false,
  },
  '😂': {
    count: 0,
    hasReacted: false,
  },
  '🤯': {
    count: 0,
    hasReacted: false,
  },
  '➕': {
    count: 0,
    hasReacted: false,
  },
  '🔥': {
    count: 0,
    hasReacted: false,
  },
};

export const ChatContext = createContext<ContextValue>(null);

const isEqualMessage = (m1: ChatMessage, m2: ChatMessage) =>
  m1.fromId === m2.fromId &&
  m1.message === m2.message &&
  Math.abs(m1.date.getTime() - m2.date.getTime()) < 300;

export const chatMessagesState = atom<ChatMessage[]>({
  key: 'chat-messages',
  default: [],
});

export const chatUnreadState = selector({
  key: 'chat-unread',
  get: ({ get }) => {
    const messages = get(chatMessagesState);
    return messages.some((m) => !m.seen);
  },
});

export const ChatProvider: React.FC = ({ children }) => {
  const daily = useDaily();
  const { enableAdvancedChat } = useCallConfig();
  const router = useRouter();
  const enableChat = router.query['chat'] !== 'false';
  const [sidebarView] = useSidebarView();

  const [waitingForSyncToComplete, setWaitingForSyncToComplete] =
    useState(true);
  const waitingForSync = useRef<boolean>(true);
  const requestedPeers = useRef<Record<string, number>>({});

  useThrottledDailyEvent(
    'participant-updated',
    useRecoilCallback(
      ({ set }) =>
        (evts: DailyEventObjectParticipant[]) => {
          set(chatMessagesState, (n) => {
            let newMessages = n.slice();
            evts.forEach(({ participant }) => {
              newMessages = newMessages.map((msg) => {
                if (msg.fromId === participant.session_id) {
                  return {
                    ...msg,
                    name: participant.user_name,
                  };
                }
                return msg;
              });
            });
            if (deepEqual(n, newMessages)) return n;
            return newMessages;
          });
        },
      []
    )
  );

  const addMessage = useRecoilCallback(
    ({ set }) =>
      (m: ChatMessage) => {
        set(chatMessagesState, (old) => {
          if (old.some((oldM) => isEqualMessage(oldM, m))) {
            return old;
          }
          return [...old, m].sort(sortByKey('received'));
        });
      },
    []
  );

  const sendAppMessage = useAppMessage<AppMessageData>({
    onAppMessage: useRecoilCallback(
      ({ set, snapshot }) =>
        async (ev, sendAppMessage) => {
          if (!enableChat) return;
          switch (ev?.data?.event) {
            case 'chat-msg':
              addMessage({
                date: new Date(ev.data?.date),
                fromId: ev.fromId,
                id: uuidv4(),
                local: false,
                message: ev.data?.message,
                name: ev.data?.name,
                received: new Date(),
                reactions: enableAdvancedChat
                  ? {
                      ...initialReactions,
                    }
                  : null,
                seen: sidebarView === 'chat',
              });
              break;
            case 'request-chat-history':
              if (waitingForSyncToComplete) {
                /**
                 * This peer is awaiting chat history sync themselves, so nothing to respond with.
                 */
                break;
              }
              const messages = await snapshot.getPromise(chatMessagesState);
              [...messages]
                // Send SYNC_CAP messages at max. Older messages won't be synced to avoid potential network bottlenecks.
                .slice(-SYNC_CAP)
                .forEach((message) => {
                  sendAppMessage(
                    {
                      event: 'sync-chat-msg',
                      message,
                    },
                    ev.fromId
                  );
                });
              sendAppMessage(
                {
                  event: 'sync-chat-complete',
                },
                ev.fromId
              );
              break;
            case 'sync-chat-msg': {
              const newMessage: ChatMessage = {
                ...ev.data?.message,
                date: new Date(ev.data?.message?.date),
                id: uuidv4(),
                local: false,
                received: new Date(ev.data?.message?.received),
                seen: sidebarView === 'chat',
              };
              for (let r of Object.keys(newMessage.reactions ?? {})) {
                newMessage.reactions[r].hasReacted = false;
              }
              addMessage(newMessage);
              break;
            }
            case 'sync-chat-complete':
              setWaitingForSyncToComplete(false);
              // Map not needed anymore at this point
              requestedPeers.current = {};
              break;
            case 'message-reaction':
              if (!enableAdvancedChat) return;
              const { delta, message, reaction } = ev.data;
              const messageWithDates: ChatMessage = {
                ...message,
                date: new Date(message.date),
                received: new Date(message.received),
              };
              set(chatMessagesState, (msgs) => {
                return msgs.map((m) => {
                  if (isEqualMessage(messageWithDates, m))
                    return {
                      ...m,
                      reactions: {
                        ...m.reactions,
                        [reaction]: {
                          ...(m.reactions?.[reaction] ?? {}),
                          count: (m.reactions?.[reaction]?.count ?? 0) + delta,
                        },
                      },
                    };
                  return m;
                });
              });
              break;
          }
        },
      [
        addMessage,
        enableAdvancedChat,
        enableChat,
        sidebarView,
        waitingForSyncToComplete,
      ]
    ),
  });

  /**
   * Sends a chat message to all other participants via sendAppMessage.
   */
  const sendMessage = useCallback(
    (message: string, options?: SendMessageOptions) => {
      if (!enableChat) return;
      const date = new Date();
      const local = daily.participants().local;
      addMessage({
        date,
        fromId: local.session_id,
        id: uuidv4(),
        local: options?.showAsLocal ?? false,
        message,
        name: options?.name ?? local?.user_name,
        reactions: enableAdvancedChat ? { ...initialReactions } : null,
        received: date,
        seen: true,
      });
      sendAppMessage({
        event: 'chat-msg',
        date,
        message,
        name: options?.name ?? local?.user_name,
      });
    },
    [addMessage, daily, enableAdvancedChat, enableChat, sendAppMessage]
  );

  /**
   * Adds or removes reaction to a message.
   */
  const reactToMessage = useRecoilCallback(
    ({ set }) =>
      (msg: ChatMessage, reaction: Reaction) => {
        if (!enableAdvancedChat) return;
        set(chatMessagesState, (msgs) =>
          msgs.map((m) => {
            if (isEqualMessage(m, msg)) {
              const hasReactedBefore = m.reactions?.[reaction]?.hasReacted;
              const prevCount = m.reactions?.[reaction]?.count ?? 0;
              const newCount = hasReactedBefore
                ? Math.max(0, prevCount - 1)
                : prevCount + 1;
              const newMessage: ChatMessage = {
                ...m,
                reactions: {
                  ...m.reactions,
                  [reaction]: {
                    count: newCount,
                    hasReacted: !hasReactedBefore,
                  },
                },
              };
              sendAppMessage({
                event: 'message-reaction',
                message: m,
                reaction,
                delta: hasReactedBefore ? -1 : 1,
              });
              return newMessage;
            }
            return m;
          })
        );
      },
    [enableAdvancedChat, sendAppMessage]
  );

  /**
   * Requests chat history (via sendAppMessage) from a random remote peer with an older joined_at date.
   * Does not request if no other remote peers of interest are available.
   */
  const requestChatHistory = useCallback(() => {
    const participants = daily.participants();
    if (!participants?.local) return;
    const joinedDate = participants.local.joined_at;
    // Previous in the sense of "those who were in the meeting earlier"
    const previousRemotePeerIds = Object.entries(participants)
      .filter(
        ([id, participant]) =>
          id !== 'local' &&
          participant.joined_at < joinedDate &&
          // don't bother requesting history from anyone we already pinged twice
          (requestedPeers.current[id] ?? 0) < 2
      )
      .map(([id]) => id);
    // Nothing to sync if there are no older remote peers
    if (previousRemotePeerIds.length === 0) {
      setWaitingForSyncToComplete(false);
      return;
    }
    // Pick a random remote peer id and request history
    const randomIdx = Math.floor(previousRemotePeerIds.length * Math.random());
    const randomId = previousRemotePeerIds[randomIdx];
    sendAppMessage(
      {
        event: 'request-chat-history',
      },
      randomId
    );
    requestedPeers.current[randomId] =
      randomId in requestedPeers.current
        ? requestedPeers.current[randomId] + 1
        : 1;
  }, [daily, sendAppMessage]);

  /**
   * Starts requesting chat history when joined-meeting.
   */
  useEffect(() => {
    if (!daily || !enableChat || !waitingForSyncToComplete) return;
    let interval: NodeJS.Timeout;
    let timeout: NodeJS.Timeout;

    // Randomize delay to increase the chance of lowering overall network traffic
    const requestDelay = 1000 + Math.ceil(2000 * Math.random());

    const handleJoinedMeeting = () => {
      timeout = setTimeout(() => {
        // No chat history within ${SYNC_MAX_WAITING_TIME}. Abort!
        waitingForSync.current = false;
        setWaitingForSyncToComplete(false);
      }, SYNC_MAX_WAITING_TIME);
      interval = setInterval(() => {
        if (!waitingForSync.current) {
          clearInterval(interval);
          return;
        }
        requestChatHistory();
      }, requestDelay);
    };
    daily.on('joined-meeting', handleJoinedMeeting);
    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
      daily.off('joined-meeting', handleJoinedMeeting);
    };
  }, [daily, enableChat, requestChatHistory, waitingForSyncToComplete]);

  /**
   * When opening chat while syncing, wait at most 5 additional seconds.
   */
  useEffect(() => {
    if (sidebarView !== 'chat') return;
    const timeout = setTimeout(() => {
      if (waitingForSync.current) {
        waitingForSync.current = false;
        setWaitingForSyncToComplete(false);
      }
    }, SYNC_MAX_WAITING_TIME_OPEN);
    return () => {
      clearTimeout(timeout);
    };
  }, [sidebarView, waitingForSyncToComplete]);

  return (
    <ChatContext.Provider
      value={{
        loading: waitingForSyncToComplete,
        reactToMessage,
        sendMessage,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChatState = () => useContext(ChatContext);

export const useChatMessages = () => {
  const messages = useRecoilValue(chatMessagesState);
  const markMessagesAsRead = useRecoilCallback(
    ({ set }) =>
      () => {
        set(chatMessagesState, (msgs) =>
          msgs.some((m) => !m.seen)
            ? msgs.map((m) => ({ ...m, seen: true }))
            : msgs
        );
      },
    []
  );
  return {
    messages,
    markMessagesAsRead,
  };
};
export const useChatUnread = () => useRecoilValue(chatUnreadState);
