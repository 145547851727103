import { Text } from '@daily/shared/components/Text';
import { useTheme } from '@daily/shared/contexts/Theme';
import { useMediaQuery } from '@daily/shared/hooks/useMediaQuery';
import {
  useLocalParticipant,
  useParticipant,
  useParticipantIds,
  useScreenShare,
} from '@daily-co/daily-react-hooks';
import classNames from 'classnames';
import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCallState } from '../../contexts/CallProvider';
import { useParticipants } from '../../contexts/ParticipantsProvider';
import { useShowLocalVideo } from '../../contexts/UIState';
import { useCallConfig } from '../../hooks/useCallConfig';
import { useResizeObserver } from '../../hooks/useResizeObserver';
import { useStyleVariants } from '../../hooks/useStyleVariants';
import {
  DEFAULT_LANDSCAPE_AR,
  DEFAULT_PORTRAIT_AR,
  PAGINATION_HEIGHT,
  TILE_GAP,
} from './constants';
import { MobileTile } from './MobileTile';
import { Screen } from './Screen';
import { Dimensions } from './types';

const FIXED_PORTRAIT: Dimensions = {
  height: 114,
  width: 85,
};
const FIXED_PORTRAIT_TABLET: Dimensions = {
  height: 128,
  width: 96,
};
const FIXED_LANDSCAPE: Dimensions = {
  height: 90,
  width: 160,
};

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  active: boolean;
}

export const Home: React.FC<Props> = memo(({ active, className, ...props }) => {
  const { mediaQueries } = useTheme();
  const { t } = useTranslation();
  const [showLocalVideo] = useShowLocalVideo();
  const { broadcastRole } = useCallConfig();
  const { showNames } = useCallState();
  const { currentSpeakerId } = useParticipants();
  const { mobilePortrait: isPortrait } = useStyleVariants();
  const tilesRef = useRef<HTMLDivElement>(null);
  const [tilesDimensions, setTilesDimensions] = useState<Dimensions>({
    height: 1,
    width: 1,
  });
  const currentSpeaker = useParticipant(currentSpeakerId);
  const localParticipant = useLocalParticipant();
  const participantIds = useParticipantIds();
  const ownerIds = useParticipantIds({ filter: 'owner' });
  const { screens } = useScreenShare();

  const isTablet = useMediaQuery(mediaQueries.large);

  const showFixedBar = useMemo(() => {
    switch (broadcastRole) {
      case 'attendee':
        return screens.length > 0;
      case 'owner':
        return screens.length > 0 || ownerIds.length > 1;
      default:
        return screens.length > 0 || participantIds.length > 1;
    }
  }, [broadcastRole, ownerIds.length, participantIds.length, screens.length]);

  useResizeObserver(
    tilesRef,
    useCallback((width, height) => {
      const zoom = document.documentElement.clientWidth / window.innerWidth;
      // Avoid UI updates, when user pinch-zoomed into page
      if (zoom !== 1) return;
      setTilesDimensions({
        height,
        width,
      });
    }, [])
  );

  const speakerStyle = useMemo(() => {
    const { height: maxHeight, width: maxWidth } = tilesDimensions;
    if (isPortrait) {
      return {
        height: Math.min(maxHeight, maxWidth / DEFAULT_PORTRAIT_AR),
        width: Math.min(maxWidth, maxHeight * DEFAULT_PORTRAIT_AR),
      };
    }
    return {
      height: Math.min(maxHeight, maxWidth / DEFAULT_LANDSCAPE_AR),
      width: Math.min(maxWidth, maxHeight * DEFAULT_LANDSCAPE_AR),
    };
  }, [isPortrait, tilesDimensions]);

  const mainArea = useMemo(() => {
    if (screens.length > 0) {
      return (
        <div className="screens">
          {screens.map((screen) => (
            <Screen key={screen.screenId} screen={screen} />
          ))}
        </div>
      );
    }
    if (currentSpeakerId) {
      return (
        <MobileTile
          isSpeaking={currentSpeaker?.audio}
          orientation={isPortrait ? 'portrait' : 'landscape'}
          sessionId={currentSpeakerId}
          showNames={showNames}
          style={speakerStyle}
        />
      );
    }
    if (broadcastRole === 'attendee') {
      return (
        <Text variant="largestrong" color="white" style={{ margin: 'auto' }}>
          {t('broadcast.hostLeft')}
        </Text>
      );
    }
    return null;
  }, [
    broadcastRole,
    currentSpeaker?.audio,
    currentSpeakerId,
    isPortrait,
    screens,
    showNames,
    speakerStyle,
    t,
  ]);

  const fixedStyle = useMemo(() => {
    if (isPortrait) return isTablet ? FIXED_PORTRAIT_TABLET : FIXED_PORTRAIT;
    return FIXED_LANDSCAPE;
  }, [isPortrait, isTablet]);

  return (
    <div
      className={classNames('home', className, {
        isPortrait,
        hasFixed: showFixedBar,
      })}
      style={{
        // CSSProperties type doesn't know about custom properties
        ['--screens' as any]: screens.length,
      }}
      {...props}
    >
      {showFixedBar && (
        <div className="fixed">
          {showLocalVideo && broadcastRole !== 'attendee' && (
            <MobileTile
              orientation={isPortrait ? 'portrait' : 'landscape'}
              sessionId={localParticipant?.session_id}
              showNames={showNames}
              style={fixedStyle}
            />
          )}
          {screens.length > 0 && (
            <MobileTile
              isSpeaking={currentSpeaker?.audio}
              orientation={isPortrait ? 'portrait' : 'landscape'}
              sessionId={currentSpeakerId}
              showNames={showNames}
              style={fixedStyle}
            />
          )}
        </div>
      )}
      <div ref={tilesRef} className="tiles">
        {mainArea}
      </div>
      <style jsx>{`
        /* Home layout */
        .home {
          display: grid;
          flex: none;
          gap: 0px ${TILE_GAP}px;
          grid-template-areas: 'fixed tiles';
          grid-template-columns: ${FIXED_LANDSCAPE.width}px minmax(0, 1fr);
          margin: 0 auto;
          overflow: hidden;
          padding: ${TILE_GAP}px ${TILE_GAP}px 0;
        }
        .home.isPortrait {
          gap: ${TILE_GAP}px 0px;
          grid-auto-flow: row;
          grid-auto-rows: minmax(min-content, max-content);
          grid-template-columns: 1fr;
          grid-template-rows: ${FIXED_PORTRAIT.height}px minmax(0, 1fr);
          grid-template-areas:
            'fixed'
            'tiles';
          height: auto !important;
          max-height: 100%;
          margin: auto 0;
          justify-content: stretch;
        }
        .home:not(.isPortrait) {
          max-width: 100%;
          width: auto !important;
        }
        @media ${mediaQueries.large} {
          .home.isPortrait {
            grid-template-rows: ${FIXED_PORTRAIT_TABLET.height}px minmax(0, 1fr);
          }
        }
        /* Fixed participant bar */
        .fixed {
          align-items: center;
          display: flex;
          flex-direction: column;
          gap: ${TILE_GAP}px;
          grid-area: fixed;
          justify-content: center;
        }
        .home.isPortrait .fixed {
          flex-direction: row;
        }
        /* Main area (speaker & screens) */
        .tiles {
          align-items: center;
          display: flex;
          grid-area: tiles;
          justify-content: center;
          overflow: hidden;
          position: relative;
        }
        .home.isPortrait .tiles {
          height: calc(
            calc(100vw - ${2 * TILE_GAP}px) / ${DEFAULT_PORTRAIT_AR}
          );
          max-height: 100%;
        }
        .home:not(.isPortrait) .tiles {
          height: 100%;
          max-width: 100%;
          width: calc(
            calc(100vh - ${TILE_GAP}px - ${PAGINATION_HEIGHT}px) *
              ${DEFAULT_LANDSCAPE_AR}
          );
        }
        .home:not(.hasFixed) .tiles {
          grid-area: fixed / fixed / tiles / tiles;
        }
        /* Screenshares (using :global, because they're rendered from mainAray memo) */
        .home .tiles > :global(.screens) {
          align-items: center;
          display: flex;
          gap: ${TILE_GAP}px;
          height: 100%;
          justify-content: center;
          width: 100%;
        }
        .home.isPortrait .tiles > :global(.screens) {
          flex-direction: column;
        }
        .home:not(.isPortrait) .tiles > :global(.screens) {
          flex-direction: row;
        }
        .home.isPortrait .tiles > :global(.screens .tile) {
          height: calc(100% / var(--screens));
          width: 100%;
        }
        .home:not(.isPortrait) .tiles > :global(.screens .tile) {
          height: 100%;
          width: calc(100% / var(--screens));
        }
      `}</style>
    </div>
  );
});

Home.displayName = 'Home';
