import { WarningIcon } from '@daily/shared/components/Icons';
import { useSnackbar } from '@daily/shared/contexts/Snackbar';
import {
  DailyEventObjectAppMessage,
  DailyStreamingOptions,
} from '@daily-co/daily-js';
import {
  useAppMessage,
  useDaily,
  useLocalParticipant,
  useRecording as useDailyRecording,
} from '@daily-co/daily-react-hooks';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useCallConfig } from '../hooks/useCallConfig';
import { useCallState } from './CallProvider';
import {
  useIsMobile,
  useRecordingErrorModal,
  useRecordingUI,
  useViewMode,
} from './UIState';

interface RecordingAppMessageData {
  event: 'recording-starting';
}

interface ContextValue {
  startRecording(): void;
  stopRecording(): void;
}

const RecordingContext = createContext<ContextValue>({
  startRecording: null,
  stopRecording: null,
});

export function isCloudRecordingType(recType: string): boolean {
  return recType === 'cloud' || recType === 'cloud-beta';
}

export const RecordingProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const { state } = useCallState();
  const { enableRecording } = useCallConfig();
  const daily = useDaily();
  const localParticipant = useLocalParticipant();
  const viewMode = useViewMode();
  const [isMobile] = useIsMobile();
  const [, setShowRecordingErrorModal] = useRecordingErrorModal();
  const { addMessage } = useSnackbar();
  const [recordingUIState, setRecordingUIState] = useRecordingUI();
  const [isInitiator, setIsInitiator] = useState(false);

  /**
   * Updates config for startRecording based on viewMode.
   */
  const cloudRecordingConfig: DailyStreamingOptions = useMemo(
    () => ({
      layout: {
        preset: viewMode === 'speaker' ? 'active-participant' : 'default',
      },
    }),
    [viewMode]
  );

  const {
    error,
    isRecording,
    layout,
    local,
    startedBy,
    startRecording: startDailyRecording,
    stopRecording: stopDailyRecording,
  } = useDailyRecording({
    onRecordingStarted: useCallback(() => {
      setRecordingUIState(null);
    }, [setRecordingUIState]),
  });

  /**
   * Prevent users from accidentally leaving the meeting without stopping
   * their running recording.
   */
  const handleOnUnload = useCallback(() => t('recording.confirmLeave'), [t]);
  useEffect(() => {
    if (
      !enableRecording ||
      isCloudRecordingType(enableRecording) ||
      !isRecording ||
      state === 'redirecting'
    )
      return;
    const prev = window.onbeforeunload;
    window.onbeforeunload = handleOnUnload;
    return () => {
      window.onbeforeunload = prev;
    };
  }, [enableRecording, handleOnUnload, isRecording, state]);

  /**
   * Actually start the recording via API, after countdown.
   */
  const startRecordingForRealz = useCallback(() => {
    if (isRecording) return;
    if (isCloudRecordingType(enableRecording)) {
      startDailyRecording(cloudRecordingConfig);
    } else {
      startDailyRecording();
    }
  }, [cloudRecordingConfig, enableRecording, isRecording, startDailyRecording]);

  /**
   * Displays an error message in the following cases:
   * - the local participant is the one who initiated the cloud recording (not single-participant)
   * - the local participant initiated the local or cloud (old) recording
   * - the local participant is a meeting owner
   *
   * In case a single-participant cloud recording errored out,
   * only the recorded participant sees the error message.
   */
  useEffect(() => {
    if (!enableRecording || !error) return;
    const isRecordedSingleParticipant =
      isCloudRecordingType(enableRecording) &&
      layout?.preset === 'single-participant' &&
      layout?.session_id === localParticipant?.session_id;
    const isInitiatorOrOwner =
      localParticipant?.owner ||
      local ||
      (isCloudRecordingType(enableRecording) &&
        layout?.preset !== 'single-participant' &&
        startedBy === localParticipant?.session_id);

    if (isRecordedSingleParticipant || isInitiatorOrOwner) {
      if (isMobile) {
        addMessage({
          content: t('recording.status.stopped'),
          icon: <WarningIcon size={16} />,
          type: 'error',
        });
      } else {
        setShowRecordingErrorModal(true);
      }
    }
  }, [
    addMessage,
    enableRecording,
    error,
    isMobile,
    layout,
    local,
    localParticipant?.owner,
    localParticipant?.session_id,
    setShowRecordingErrorModal,
    startedBy,
    t,
  ]);

  /**
   * Progress recording UI state.
   */
  useEffect(() => {
    let timeout: NodeJS.Timeout;

    switch (recordingUIState) {
      case 'starting3':
        timeout = setTimeout(() => {
          setRecordingUIState('starting2');
        }, 1000);
        break;
      case 'starting2':
        timeout = setTimeout(() => {
          setRecordingUIState('starting1');
        }, 1000);
        break;
      case 'starting1':
        if (isInitiator) startRecordingForRealz();
        break;
      case 'saved':
        timeout = setTimeout(() => {
          setRecordingUIState(null);
        }, 5000);
        break;
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [
    isInitiator,
    recordingUIState,
    setRecordingUIState,
    startRecordingForRealz,
  ]);

  const sendAppMessage = useAppMessage({
    onAppMessage: useCallback(
      (ev: DailyEventObjectAppMessage<RecordingAppMessageData>) => {
        switch (ev?.data?.event) {
          /**
           * Initialize recording countdown for remote participants.
           */
          case 'recording-starting':
            setRecordingUIState('starting3');
            break;
        }
      },
      [setRecordingUIState]
    ),
  });

  /**
   * Triggered via UI. Initializes the countdown.
   */
  const startRecording = useCallback(() => {
    if (!daily || !enableRecording || isRecording) return;
    setIsInitiator(true);
    setRecordingUIState('starting3');
    sendAppMessage({
      event: 'recording-starting',
    });
  }, [
    daily,
    enableRecording,
    isRecording,
    sendAppMessage,
    setRecordingUIState,
  ]);

  /**
   * Triggered via UI.
   */
  const stopRecording = useCallback(() => {
    if (!enableRecording || !isRecording) return;
    setRecordingUIState('saved');
    stopDailyRecording();
  }, [enableRecording, isRecording, setRecordingUIState, stopDailyRecording]);

  return (
    <RecordingContext.Provider
      value={{
        startRecording,
        stopRecording,
      }}
    >
      {children}
    </RecordingContext.Provider>
  );
};

export const useRecording = () => useContext(RecordingContext);
