import { ChatIcon } from '@daily/shared/components/Icons';
// import { Text } from '@daily/shared/components/Text';
import { Tooltip } from '@daily/shared/components/Tooltip';
import { useTheme } from '@daily/shared/contexts/Theme';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useChatUnread } from '../../contexts/ChatProvider';
import { useIsMobile, useSidebarView } from '../../contexts/UIState';
import { robotsClassName } from '../../lib/robots';
import { TrayButton } from './TrayButton';

interface Props extends React.ComponentProps<typeof TrayButton> {}

export const ChatControls: React.FC<Props> = ({
  children,
  className,
  onClick,
  ...props
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const [isMobile] = useIsMobile();
  const [sidebarView, setSidebarView] = useSidebarView();
  const chatUnread = useChatUnread();
  // const { enableChat } = useCallConfig();
  const router = useRouter();
  const enableChat = router.query['chat'] !== 'false';

  const [hasOpenedChatOnce, setHasOpenedChatOnce] = useState(false);

  const handleChatClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    setSidebarView(sidebarView === 'chat' ? null : 'chat');
    setHasOpenedChatOnce(true);
    onClick?.(ev);
  };

  useEffect(() => {
    if (sidebarView !== 'chat') return;
    setHasOpenedChatOnce(true);
  }, [sidebarView]);

  if (!enableChat) return null;

  return (
    <div className="chat-controls">
      <Tooltip
        // disabled={!chatUnread || hasOpenedChatOnce || isMobile}
        // disableListeners
        id="tt-chat-notification"
        // open={chatUnread && !hasOpenedChatOnce}
        // placement="top"
        tabIndex={-1}
        title="Chat"
        // title={t('chat.started')}
      >
        <TrayButton
          badge={{
            color: colors.system.red,
            visible: sidebarView !== 'chat' && chatUnread,
          }}
          className={classnames(className, {
            [robotsClassName('btn-chat-show')]: sidebarView !== 'chat',
            [robotsClassName('btn-chat-hide')]: sidebarView === 'chat',
          })}
          onClick={handleChatClick}
          {...props}
        >
          <ChatIcon />
          {/* <Text>{t('general.chat')}</Text> */}
        </TrayButton>
      </Tooltip>
    </div>
  );
};
