import { Modal } from '@daily/shared/components/Modal';
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@daily/shared/components/Tabs';
import { useTheme } from '@daily/shared/contexts/Theme';
import { useMediaQuery } from '@daily/shared/hooks/useMediaQuery';
import DailyIframe from '@daily-co/daily-js';
import { useDevices, useLocalParticipant } from '@daily-co/daily-react-hooks';
import classNames from 'classnames';
import { prebuiltDebugger } from 'components/Tile/Debugger';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { atom, useRecoilCallback, useRecoilValue } from 'recoil';

import {
  SettingsView,
  useIsMobile,
  useSettingsView,
} from '../../contexts/UIState';
import { useCallConfig } from '../../hooks/useCallConfig';
import { robotsClassName } from '../../lib/robots';
import { MobileTile } from '../MobileView/MobileTile';
import { Tile } from '../Tile';
import { AudioVideoSettings } from './AudioVideoSettings';
import { BackgroundEffects } from './BackgroundEffects';
import { LanguageSettings } from './LanguageSettings';
import { VersionInfo } from './VersionInfo';
import { VideoQualitySettings } from './VideoQualitySettings';

interface Props {
  onClose(): void;
}

const versionState = atom<boolean>({
  key: 'show-version-info',
  default: false,
});

export const SettingsModal: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const [isMobile] = useIsMobile();
  const [settingsView, setSettingsView] = useSettingsView();
  const { colors } = useTheme();
  const localParticipant = useLocalParticipant();
  const { broadcastRole, enableVideoProcessingUI } = useCallConfig();
  const { cameras, camState } = useDevices();
  const isNarrowViewport = useMediaQuery('(max-width: 500px)');

  const showVersionInfo = useRecoilValue(versionState);
  const enableVersionInfo = useRecoilCallback(({ set }) => () => {
    set(versionState, true);
  });
  const disableVersionInfo = useRecoilCallback(({ set }) => () => {
    set(versionState, false);
  });
  useEffect(() => {
    window['dailyEnableVersionInfo'] = enableVersionInfo;
    const params = new URLSearchParams(location.search);
    if (params.has('debug')) {
      enableVersionInfo();
    }
    prebuiltDebugger.enabled ? enableVersionInfo() : disableVersionInfo();
  }, [enableVersionInfo, prebuiltDebugger]);

  const disableBackgroundEffects =
    camState === 'pending' || cameras.length === 0;

  const index: Record<SettingsView, number> = {
    devices: 0,
    quality: 1,
    language: 2,
    effects: 3,
    version: 4,
  };

  const shouldRenderVideo = useMemo(
    () =>
      (settingsView === 'devices' || settingsView === 'effects') &&
      broadcastRole !== 'attendee',
    [broadcastRole, settingsView]
  );

  const supportsBackgroundEffects = useMemo(
    () =>
      DailyIframe.supportedBrowser().supportsVideoProcessing &&
      !isMobile &&
      broadcastRole !== 'attendee' &&
      enableVideoProcessingUI,
    [broadcastRole, enableVideoProcessingUI, isMobile]
  );

  return (
    <Modal
      closeText={t('general.close')}
      onClose={onClose}
      position="top"
      title={t('settings.modal.title')}
      variant="closable"
    >
      <div
        className={classNames('settings-modal', { narrow: isNarrowViewport })}
      >
        <Tabs
          id="settings-modal"
          initialIndex={index[settingsView]}
          vertical={!isNarrowViewport}
        >
          <div className="tablist">
            <TabList gap={isNarrowViewport ? undefined : 0}>
              <Tab
                className={classNames(
                  'tab',
                  robotsClassName('tab-audio-video')
                )}
                onClick={() => setSettingsView('devices')}
              >
                {t('settings.modal.audioVideo')}
              </Tab>
              <Tab
                className={classNames(
                  'tab',
                  robotsClassName('tab-video-quality')
                )}
                onClick={() => setSettingsView('quality')}
              >
                {t('settings.modal.videoQuality')}
              </Tab>
              <Tab
                className={classNames('tab', robotsClassName('tab-language'))}
                onClick={() => setSettingsView('language')}
              >
                {t('settings.modal.language')}
              </Tab>
              {supportsBackgroundEffects && (
                <Tab
                  className={classNames(
                    'tab',
                    {
                      disabled: disableBackgroundEffects,
                    },
                    robotsClassName('tab-effects')
                  )}
                  disabled={disableBackgroundEffects}
                  onClick={() => setSettingsView('effects')}
                >
                  {t('settings.modal.backgroundEffects')}
                </Tab>
              )}
              {showVersionInfo && (
                <Tab className="tab" onClick={() => setSettingsView('version')}>
                  Version Info
                </Tab>
              )}
            </TabList>
          </div>
          <div className="panels">
            {shouldRenderVideo ? (
              isMobile ? (
                <MobileTile
                  hideActions
                  orientation="landscape"
                  sessionId={localParticipant?.session_id}
                  showNames
                />
              ) : (
                <Tile
                  activeIndicator="meter"
                  hideActions
                  sessionId={localParticipant?.session_id}
                  showNames
                />
              )
            ) : null}
            <TabPanels>
              <TabPanel>
                <AudioVideoSettings shouldShowMicVolume={isMobile} />
              </TabPanel>
              <TabPanel>
                <VideoQualitySettings />
              </TabPanel>
              <TabPanel>
                <LanguageSettings />
              </TabPanel>
              {supportsBackgroundEffects && (
                <TabPanel>
                  <BackgroundEffects />
                </TabPanel>
              )}
              {showVersionInfo && (
                <TabPanel>
                  <VersionInfo />
                </TabPanel>
              )}
            </TabPanels>
          </div>
        </Tabs>
      </div>
      <style jsx>{`
        .settings-modal :global(.mobile-tile),
        .settings-modal :global(.tile) {
          border-radius: 0;
          height: auto;
          margin-bottom: var(--card-spacing);
        }
        .settings-modal :global(.mobile-tile video) {
          border-radius: 0;
        }

        /* Style adjustments for narrow viewports */
        .settings-modal.narrow .tablist :global(.tab) {
          padding: 4px 0;
        }

        /* Style adjustments when Tabs are rendered vertical */
        .settings-modal:not(.narrow) {
          display: flex;
          margin: calc(-1 * var(--card-spacing));
          min-height: 164px;
        }
        .settings-modal:not(.narrow) .tablist {
          background: ${colors.backgroundAccent};
          border-right: 1px solid var(--card-border);
          border-top: 1px solid var(--card-border);
          flex: 0 0 160px;
        }
        .settings-modal:not(.narrow) :global(.tile) {
          margin: calc(-1 * var(--card-spacing));
          margin: calc(-1 * var(--card-spacing)) calc(-1 * var(--card-spacing))
            var(--card-spacing);
          width: auto;
        }
        .settings-modal:not(.narrow) .panels {
          border-top: 1px solid var(--card-border);
          flex: 1 1 100%;
          padding: var(--card-spacing);
        }
        .settings-modal :global(.tab.disabled) {
          opacity: 0.4;
        }
      `}</style>
    </Modal>
  );
};
