import { useEffect } from 'react';

interface HookArgs {
  onClose(): void;
  ref: React.MutableRefObject<HTMLElement>;
}

/**
 * Sets up document click and escape key event listeners.
 * Calls onClose, when clicked outside of passed ref and all deps are truthy.
 */
export const useClosable = (
  { onClose, ref }: HookArgs,
  deps: ReadonlyArray<any>
) => {
  const handleClick = (ev: MouseEvent) => {
    // close menu when user clicked outside
    const target = ev.target as HTMLElement;
    if (!ref.current?.contains(target)) {
      onClose?.();
    }
  };

  const handleKeydown = (ev: KeyboardEvent) => {
    switch (ev.key) {
      case 'Escape':
        ev.stopImmediatePropagation();
        onClose?.();
        break;
    }
  };

  useEffect(() => {
    if (!deps.every(Boolean)) return;
    // This timeout is needed to assure that browsers have time to process clicks
    // before React updates the hook.
    // Otherwise components using useClosable could run into a race condition
    // that would prevent users from triggering the ref correctly.
    const timeout = setTimeout(() => {
      document.addEventListener('keydown', handleKeydown);
      document.addEventListener('click', handleClick);
      document.addEventListener('mouseleave', handleClick);
    }, 300);
    return () => {
      clearTimeout(timeout);
      document.removeEventListener('keydown', handleKeydown);
      document.removeEventListener('click', handleClick);
      document.removeEventListener('mouseleave', handleClick);
    };
  }, deps);
};
