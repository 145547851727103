import { useTheme } from '@daily/shared/contexts/Theme';
import { throttle } from '@daily/shared/lib/throttle';
import {
  useLocalParticipant,
  useNetwork,
  useScreenShare,
} from '@daily-co/daily-react-hooks';
import classnames from 'classnames';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useVirtual, VirtualItem } from 'react-virtual';

import { useCallState } from '../../contexts/CallProvider';
import { useParticipants } from '../../contexts/ParticipantsProvider';
import { useTracks } from '../../contexts/TracksProvider';
import { usePinnedId, useSidebarView } from '../../contexts/UIState';
import { useActiveSpeaker } from '../../hooks/useActiveSpeaker';
import { useCamSubscriptions } from '../../hooks/useCamSubscriptions';
import { useScrollbarWidth } from '../../hooks/useScrollbarWidth';
import { DEFAULT_ASPECT_RATIO } from '../../lib/constants';
import { Tile } from '../Tile';
import { useBlockScrolling } from './useBlockScrolling';

interface Props {
  aspectRatio?: number;
  fixed: string[];
  others: string[];
  visible: boolean;
}

interface CamSubscriptions {
  subscribedIds: string[];
  stagedIds: string[];
}

/**
 * Gap between tiles in pixels.
 */
const GAP = 8;

const SIDEBAR_WIDTH = 192;

export const ParticipantBar: React.FC<Props> = ({
  aspectRatio = DEFAULT_ASPECT_RATIO,
  fixed,
  others,
  visible,
}) => {
  const { colors, mediaQueries } = useTheme();
  const { showNames, showParticipantsBar } = useCallState();
  const { currentSpeakerId, swapParticipantPosition } = useParticipants();
  const { maxCamSubscriptions } = useTracks();
  const [pinnedId] = usePinnedId();
  const [sidebarView] = useSidebarView();
  const scrollTop = useRef<number>(0);
  const scrollRef = useRef<HTMLDivElement>(null);
  const othersRef = useRef<HTMLDivElement>(null);
  const blockScrolling = useBlockScrolling(scrollRef);
  const scrollbarWidth = useScrollbarWidth();
  const activeSpeakerId = useActiveSpeaker();
  const { screens } = useScreenShare();

  const itemHeight = useMemo(
    () => SIDEBAR_WIDTH / aspectRatio + GAP,
    [aspectRatio]
  );

  const hasPinnedOrScreens = useMemo(
    () => screens.length > 0 || pinnedId,
    [pinnedId, screens]
  );
  const othersCount = useMemo(() => others.length, [others]);
  const localParticipant = useLocalParticipant();

  const { threshold } = useNetwork();

  const virtualizer = useVirtual({
    size: othersCount,
    parentRef: scrollRef,
    estimateSize: useCallback(() => itemHeight, [itemHeight]),
    overscan: 4,
  });
  const range = useMemo<[number, number]>(() => {
    const start = virtualizer.virtualItems?.[0]?.index ?? 0;
    return [start, start + virtualizer.virtualItems.length];
  }, [virtualizer.virtualItems]);
  const visibleOthers = useMemo(
    () => others.slice(range[0], range[1]),
    [others, range]
  );

  /**
   * Determines wether or not to render the active speaker border.
   * Border should be omitted in 1-to-1 scenarios.
   */
  const shouldRenderSpeakerBorder = useMemo(
    () =>
      // Non-floating bar with at least 3 rendered participants
      (showParticipantsBar && fixed.length + others.length > 2) ||
      // Floating bar with more than 1 participant on shread screen
      (!showParticipantsBar && fixed.length > 1 && hasPinnedOrScreens),
    [fixed.length, hasPinnedOrScreens, others.length, showParticipantsBar]
  );

  const [camSubscriptions, setCamSubscriptions] = useState<CamSubscriptions>({
    subscribedIds: [],
    stagedIds: [],
  });
  useCamSubscriptions(
    camSubscriptions?.subscribedIds,
    camSubscriptions?.stagedIds
  );

  /**
   * Determines subscribed and staged participant ids,
   * based on rendered range, scroll position and viewport.
   */
  const updateCamSubscriptions = useCallback(
    (virtualItems: VirtualItem[]) => {
      const fixedRemote = fixed.filter(
        (id) => id !== localParticipant?.session_id
      );
      const scrollEl = scrollRef.current;

      // No participant bar = Subscribe to speaker & pinned only
      if (!showParticipantsBar || !scrollEl) {
        setCamSubscriptions({
          subscribedIds: [currentSpeakerId, pinnedId, ...fixedRemote],
          stagedIds: [],
        });
        return;
      }

      const visibleIds = virtualItems
        .filter((v) => {
          return (
            v.end > scrollEl.scrollTop ||
            v.start < scrollEl.scrollTop + scrollEl.clientHeight
          );
        })
        .slice(0, maxCamSubscriptions ?? virtualItems.length);
      const bufferedIds = virtualItems.filter((v) => {
        return (
          v.end <= scrollEl.scrollTop ||
          v.start >= scrollEl.scrollTop + scrollEl.clientHeight
        );
      });

      setCamSubscriptions({
        subscribedIds: [
          currentSpeakerId,
          pinnedId,
          ...fixedRemote,
          ...visibleIds.map((v) => others[v.index]),
        ],
        stagedIds: bufferedIds.map((v) => others[v.index]),
      });
    },
    [
      currentSpeakerId,
      fixed,
      localParticipant?.session_id,
      maxCamSubscriptions,
      others,
      pinnedId,
      showParticipantsBar,
    ]
  );

  /**
   * Keep scroll position when toggling sidebar.
   */
  useEffect(() => {
    const scrollable = scrollRef.current;
    if (!scrollable) return;
    const handleScroll = () => {
      scrollTop.current = scrollable.scrollTop;
    };
    scrollable.addEventListener('scroll', handleScroll);
    return () => {
      scrollable.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    if (!scrollRef.current) return;
    scrollRef.current.scrollTop = scrollTop.current;
  }, [sidebarView]);

  /**
   * Move out-of-view active speakers to position right after presenters.
   */
  useEffect(() => {
    const scrollEl = scrollRef.current;
    // Ignore promoting, when no screens are being shared
    // because the active participant will be shown in the SpeakerTile anyway
    if (!hasPinnedOrScreens || !scrollEl) return;

    const maybePromoteActiveSpeaker = () => {
      const fixedOtherId = fixed.find(
        (id) => id !== localParticipant?.session_id
      );

      // Promote speaker when participant bar isn't rendered & screen is shared
      if (!showParticipantsBar && hasPinnedOrScreens && fixedOtherId) {
        swapParticipantPosition(fixedOtherId, activeSpeakerId);
        return;
      }

      // Ignore when speaker is already at first position or component unmounted
      if (!fixedOtherId || fixedOtherId === activeSpeakerId || !scrollEl)
        return;

      // Active speaker not rendered at all, promote immediately
      if (
        visibleOthers.every((id) => id !== activeSpeakerId) &&
        activeSpeakerId !== localParticipant?.session_id
      ) {
        swapParticipantPosition(fixedOtherId, activeSpeakerId);
        return;
      }

      const activeTile: HTMLDivElement = othersRef.current?.querySelector(
        `[id="${activeSpeakerId}"]`
      );
      // Ignore when active speaker is not within "others"
      if (!activeTile) return;

      // Ignore when active speaker is already pinned
      if (activeSpeakerId === pinnedId) return;

      const { height: tileHeight } = activeTile.getBoundingClientRect();
      const othersVisibleHeight =
        scrollEl?.clientHeight - othersRef.current?.offsetTop;

      const scrolledOffsetTop = activeTile.offsetTop - scrollEl?.scrollTop;

      // Ignore when speaker is already visible (< 50% cut off)
      if (
        fixedOtherId === activeSpeakerId &&
        scrolledOffsetTop + tileHeight / 2 < othersVisibleHeight &&
        scrolledOffsetTop > -tileHeight / 2
      )
        return;

      swapParticipantPosition(fixedOtherId, activeSpeakerId);
    };
    maybePromoteActiveSpeaker();
    const throttledHandler = throttle(maybePromoteActiveSpeaker, 100);
    scrollEl.addEventListener('scroll', throttledHandler);
    return () => {
      scrollEl?.removeEventListener('scroll', throttledHandler);
    };
  }, [
    activeSpeakerId,
    fixed,
    hasPinnedOrScreens,
    localParticipant?.session_id,
    pinnedId,
    showParticipantsBar,
    swapParticipantPosition,
    visibleOthers,
  ]);

  /**
   * Update cam subscriptions based on virtualized viewframe.
   */
  useEffect(() => {
    const timeout = setTimeout(() => {
      updateCamSubscriptions(virtualizer.virtualItems);
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [updateCamSubscriptions, virtualizer.virtualItems]);

  const allLength = fixed.length + others.length;

  if (allLength === 0) return null;

  return (
    <div
      ref={scrollRef}
      className={classnames('sidebar', {
        blockScrolling,
        floating: !showParticipantsBar,
        scrollbarOutside: scrollbarWidth > 0,
        visible,
      })}
    >
      <div>
        {fixed.map((id, i) => {
          // reduce setting up & tearing down tiles as much as possible
          const key = i;
          return (
            <Tile
              alignActionsMenu={showParticipantsBar ? 'right' : 'left'}
              aspectRatio={aspectRatio}
              key={key}
              isSpeaking={shouldRenderSpeakerBorder && id === activeSpeakerId}
              network={id === localParticipant?.session_id ? threshold : null}
              sessionId={id}
              showNames={showNames}
            />
          );
        })}
      </div>
      {showParticipantsBar && (
        <div
          ref={othersRef}
          className="participants"
          style={{ height: `${virtualizer.totalSize}px` }}
        >
          {virtualizer.virtualItems.map((row) => {
            const id = others[row.index];
            return (
              <div
                key={row.index}
                className="row"
                style={{
                  transform: `translateY(${row.start}px)`,
                }}
              >
                <Tile
                  alignActionsMenu={showParticipantsBar ? 'right' : 'left'}
                  aspectRatio={aspectRatio}
                  isSpeaking={
                    shouldRenderSpeakerBorder && id === activeSpeakerId
                  }
                  network={
                    id === localParticipant?.session_id ? threshold : null
                  }
                  sessionId={id}
                  showNames={showNames}
                />
              </div>
            );
          })}
        </div>
      )}
      <style jsx>{`
        .sidebar :global(.user-image) {
          transform: scale(0.5);
        }
        .sidebar {
          // border-left: 1px solid ${colors.custom.mainAreaBg};
          margin-left: ${GAP}px;
          flex: none;
          min-width: ${SIDEBAR_WIDTH}px;
          overflow-x: hidden;
          overflow-y: auto;
        }
        .sidebar:not(.visible) {
          display: none;
        }
        .sidebar.blockScrolling {
          overflow-y: hidden;
        }
        .sidebar.floating {
          border: none;
          border-radius: 4px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04),
            0px 0px 4px rgba(0, 0, 0, 0.08);
          left: 8px;
          position: fixed;
          top: calc(var(--header-height) + 8px);
          width: 138px;
          z-index: var(--zindex-sidebar);
        }
        .sidebar.floating :global(.tile) {
          border: none;
        }
        .sidebar.blockScrolling.scrollbarOutside {
          padding-right: 12px;
        }
        /**
         * The ParticipantBar is technically rendered with dark mode colors (ThemeScope),
         * but the scrollbar is rendered using the main color mode (dark or light).
         * This ::after element prevents a color switch for the scrollbar
         * background color, by applying the main color mode background color.
         */
        .sidebar.blockScrolling.scrollbarOutside::after {
          background: ${colors.background};
          content: '';
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          width: 12px;
        }
        .sidebar:not(.scrollbarOutside) :global(.tile-actions) {
          right: 20px;
        }
        .sidebar .fixed {
          // background: ${colors.background};
          position: sticky;
          top: 0;
          z-index: 3;
        }
        .sidebar .participants {
          position: relative;
        }
        .sidebar :global(.tile) {
          // border-top: ${GAP}px solid ${colors.custom.mainAreaBg};
          margin-top: ${GAP}px;
          width: ${SIDEBAR_WIDTH}px;
        }
        .sidebar .fixed :global(.tile:first-child) {
          // border: none;
          margin-top: 0;
        }
        .sidebar .participants .row {
          position: absolute;
          width: 100%;
        }
        @media ${mediaQueries.large} {
          .sidebar.floating {
            width: ${SIDEBAR_WIDTH}px;
          }
        }
      `}</style>
    </div>
  );
};
