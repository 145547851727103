import {
  DailyEventObjectParticipant,
  DailyParticipantUpdateOptions,
  DailyReceiveSettings,
} from '@daily-co/daily-js';
import {
  ExtendedDailyParticipant,
  useActiveParticipant,
  useAppMessage,
  useDaily,
  useLocalParticipant,
  useNetwork,
  useParticipantIds,
  useScreenShare,
  useThrottledDailyEvent,
} from '@daily-co/daily-react-hooks';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  atom,
  selector,
  useRecoilCallback,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import { RequestTopLayerAppMessage } from '../components/App/useBandwidthControls';
import { useCallConfig } from '../hooks/useCallConfig';
import { usePreviousValue } from '../hooks/usePreviousValue';
import { useCallState } from './CallProvider';
import {
  hiddenIdsState,
  useHiddenIds,
  usePinnedId,
  useViewMode,
} from './UIState';

export interface ParticipantMetaData {
  last_active_date?: Date;
}

interface ContextValue {
  currentSpeakerId: string;
  muteAll(muteFutureParticipants?: boolean): void;
  muteNewParticipants: boolean;
  participantMarkedForRemoval: ExtendedDailyParticipant;
  setParticipantMarkedForRemoval(p: ExtendedDailyParticipant): void;
  swapParticipantPosition(id1: string, id2: string): void;
}

export const ParticipantsContext = createContext<ContextValue>(null);

const participantMetaDataState = atom<Record<string, ParticipantMetaData>>({
  key: 'participant-meta-data',
  default: {},
});
export const useParticipantMetaData = () =>
  useRecoilValue(participantMetaDataState);

type ParticipantIdsFilterFn = Extract<
  Parameters<typeof useParticipantIds>[0]['filter'],
  Function
>;

const orderedParticipantIdsState = atom<string[]>({
  key: 'ordered-participant-ids',
  default: [],
});

const orderedAndFilteredParticipantIdsState = selector({
  key: 'ordered-and-filtered-participant-ids',
  get: ({ get }) =>
    get(orderedParticipantIdsState).filter(
      (id) => !get(hiddenIdsState).includes(id)
    ),
});

export const useOrderedParticipantIds = () =>
  useRecoilValue(orderedAndFilteredParticipantIdsState);

// const orderedParticipantIds = useOrderedParticipantIds();

export const ParticipantsProvider: React.FC = ({ children }) => {
  const daily = useDaily();
  const { state, videoQuality } = useCallState();
  const { broadcast, broadcastRole, optimizeLargeCalls } = useCallConfig();
  const viewMode = useViewMode();
  const [pinnedId] = usePinnedId();
  const [participantMarkedForRemoval, setParticipantMarkedForRemoval] =
    useState<ExtendedDailyParticipant>(null);

  const { threshold } = useNetwork();

  /**
   * Unmuted participant ids.
   */
  const unmutedParticipantIds = useParticipantIds({
    filter: useCallback<ParticipantIdsFilterFn>(
      (p) => ['playable', 'sendable'].includes(p.tracks.audio.state),
      []
    ),
  });
  const prevUnmutedParticipantIds = usePreviousValue(unmutedParticipantIds);
  const setMetaData = useSetRecoilState(participantMetaDataState);
  /**
   * Update last_active_date whenever a participant unmutes.
   */
  useEffect(() => {
    const newlyUnmutedIds = unmutedParticipantIds.filter(
      (id) => !prevUnmutedParticipantIds.includes(id)
    );
    if (!newlyUnmutedIds.length) return;
    setMetaData((m) => {
      const newMetaData = { ...m };
      newlyUnmutedIds.forEach((id) => {
        newMetaData[id] = {
          ...newMetaData[id],
          last_active_date: new Date(),
        };
      });
      return newMetaData;
    });
  }, [prevUnmutedParticipantIds, setMetaData, unmutedParticipantIds]);

  const { screens } = useScreenShare();
  const [hiddenIds] = useHiddenIds();

  /**
   * Only return participants that should be visible in the call
   */
  const participantIds = useParticipantIds({
    filter: useCallback(
      (p: ExtendedDailyParticipant) => {
        if (hiddenIds.includes(p.session_id)) {
          return false;
        } else if (broadcast) return p.owner;
        else return true;
      },
      [broadcast, hiddenIds]
    ),
  });

  /**
   * The participant who most recently got mentioned via a `active-speaker-change` event
   */
  const active = useActiveParticipant({ ignoreLocal: true });

  const localParticipant = useLocalParticipant();

  const participantMetaData = useParticipantMetaData();
  /**
   * The participant who should be rendered prominently right now
   */
  const currentSpeakerId = useMemo(() => {
    if (!daily) return null;
    /**
     * Ensure activeParticipant is still present in the call.
     * The activeParticipant only updates to a new active participant so
     * if everyone else is muted when AP leaves, the value will be stale.
     */
    const isPresent = participantIds.includes(active?.session_id);
    const isPinnedPresent = participantIds.includes(pinnedId);

    if (isPinnedPresent) return pinnedId;

    const displayableIds = participantIds.filter(
      (id) => id !== localParticipant?.session_id
    );
    const participants = Object.values(daily.participants());

    if (
      !isPresent &&
      displayableIds.length > 0 &&
      displayableIds.every((id) => {
        const p = participants.find((p) => p.session_id === id);
        return !p?.audio && !participantMetaData[id]?.last_active_date;
      })
    ) {
      // Return first cam on participant in case everybody is muted and nobody ever talked
      // or first remote participant, in case everybody's cam is muted, too.
      return (
        displayableIds.find((id) => {
          const p = participants.find((p) => p.session_id === id);
          return p?.video;
        }) ?? displayableIds?.[0]
      );
    }

    const sorted = displayableIds
      .sort((a, b) => {
        const lastActiveA = participantMetaData[a]?.last_active_date;
        const lastActiveB = participantMetaData[b]?.last_active_date;
        if (lastActiveA > lastActiveB) return 1;
        if (lastActiveA < lastActiveB) return -1;
        return 0;
      })
      .reverse();

    const fallback =
      broadcastRole === 'attendee' ? null : localParticipant?.session_id;

    return isPresent ? active?.session_id : sorted?.[0] ?? fallback;
  }, [
    active,
    broadcastRole,
    daily,
    localParticipant,
    participantIds,
    participantMetaData,
    pinnedId,
  ]);

  /**
   * Swaps the position of 2 participants identified by their session_id.
   */
  const swapParticipantPosition = useRecoilCallback(
    ({ set }) =>
      (id1: string, id2: string) => {
        /**
         * Ignore in the following cases:
         * - id1 and id2 are equal
         * - one of both ids is not set
         */
        if (id1 === id2 || !id1 || !id2) return;
        set(orderedParticipantIdsState, (prevIds) => {
          const newIds = prevIds.slice();
          const idx1 = prevIds.indexOf(id1);
          const idx2 = prevIds.indexOf(id2);
          /**
           * Could not find one of both ids in array.
           * This can be due to a race condition when a participant leaves,
           * while a swap of positions is triggered.
           */
          if (idx1 === -1 || idx2 === -1) return prevIds;
          newIds[idx1] = id2;
          newIds[idx2] = id1;
          return newIds;
        });
      },
    []
  );

  const [muteNewParticipants, setMuteNewParticipants] = useState(false);

  const unmutedIds = useParticipantIds({
    filter: useCallback((p) => !p.local && p.audio, []),
  });
  const muteAll = useCallback(
    (muteFutureParticipants: boolean = false) => {
      if (!localParticipant?.owner) return;
      setMuteNewParticipants(muteFutureParticipants);
      if (!unmutedIds.length) return;
      daily.updateParticipants(
        unmutedIds.reduce<Record<string, DailyParticipantUpdateOptions>>(
          (o, id) => {
            o[id] = {
              setAudio: false,
            };
            return o;
          },
          {}
        )
      );
    },
    [daily, localParticipant, unmutedIds]
  );

  /**
   * Maintain positions for each participant in Speaker & Grid view.
   */
  useThrottledDailyEvent(
    'participant-joined',
    useRecoilCallback(
      ({ set }) =>
        (evts: DailyEventObjectParticipant[]) => {
          const participants = daily?.participants?.() ?? {};
          const remoteIds = Object.keys(participants).filter(
            (id) => id !== 'local'
          );

          const camOnParticipants = remoteIds.filter((id) =>
            ['loading', 'playable', 'sendable'].includes(
              participants[id]?.tracks?.video?.state
            )
          );

          const camOffParticipants = remoteIds.filter((id) =>
            ['off', 'blocked'].includes(participants[id]?.tracks?.video?.state)
          );

          set(orderedParticipantIdsState, () => {
            console.log({
              camOnParticipants,
              camOffParticipants,
            });

            return [...camOnParticipants, ...camOffParticipants];
          });

          if (muteNewParticipants && daily) {
            const updates: Record<string, DailyParticipantUpdateOptions> =
              evts.reduce((updates, { participant }) => {
                updates[participant.session_id] = {
                  setAudio: false,
                };
                return updates;
              }, {});
            daily.updateParticipants(updates);
          }
        },
      [daily, muteNewParticipants]
    ),
    250
  );
  useThrottledDailyEvent(
    'participant-left',
    useRecoilCallback(
      ({ transact_UNSTABLE }) =>
        (evts: DailyEventObjectParticipant[]) => {
          const participants = daily?.participants?.() ?? {};
          const remoteIds = Object.keys(participants).filter(
            (id) => id !== 'local'
          );

          const camOnParticipants = remoteIds.filter((id) =>
            ['loading', 'playable', 'sendable'].includes(
              participants[id]?.tracks?.video?.state
            )
          );

          const camOffParticipants = remoteIds.filter((id) =>
            ['off', 'blocked'].includes(participants[id]?.tracks?.video?.state)
          );

          transact_UNSTABLE(({ set }) => {
            set(orderedParticipantIdsState, () => {
              return [...camOnParticipants, ...camOffParticipants];
            });
            set(participantMetaDataState, (md) => {
              const newMetaData = { ...md };
              evts.forEach((ev) => {
                delete newMetaData[ev.participant.session_id];
              });
              return newMetaData;
            });
          });
        },
      [daily]
    ),
    250
  );

  useEffect(() => {
    if (!(daily && daily.meetingState() === 'joined-meeting')) return;

    const receiveSettings: DailyReceiveSettings = {};

    participantIds.forEach((id) => {
      if (id === localParticipant?.session_id) return;

      if (
        // weak or bad network
        (['low', 'very-low'].includes(threshold) && videoQuality === 'auto') ||
        // Low quality or Bandwidth saver mode enabled
        ['bandwidth-saver', 'low'].includes(videoQuality)
      ) {
        receiveSettings[id] = { video: { layer: 0 } };
        return;
      }

      // Speaker view settings based on speaker status or pinned user
      if (viewMode === 'speaker') {
        if (
          (currentSpeakerId === id && !screens.length && !pinnedId) ||
          pinnedId === id
        ) {
          receiveSettings[id] = { video: { layer: 2 } };
        } else {
          receiveSettings[id] = { video: { layer: 0 } };
        }
      }

      // Grid view settings are handled separately in GridView
      // Mobile view settings are handled separately in MobileCall
    });

    if (Object.keys(receiveSettings).length === 0) return;

    daily.updateReceiveSettings(receiveSettings);
  }, [
    currentSpeakerId,
    daily,
    localParticipant?.session_id,
    participantIds,
    pinnedId,
    screens.length,
    threshold,
    videoQuality,
    viewMode,
  ]);

  const sendAppMessage = useAppMessage<RequestTopLayerAppMessage>();
  /**
   * Request top layer from either
   * - remote pinned participant
   * - remote non active speaker
   */
  useEffect(() => {
    const isRemotePinned =
      Boolean(pinnedId) && pinnedId !== localParticipant?.session_id;
    const hasNonActiveSpeaker =
      currentSpeakerId !== localParticipant?.session_id;
    if (
      viewMode !== 'speaker' ||
      state !== 'joined' ||
      !optimizeLargeCalls ||
      (!isRemotePinned && !hasNonActiveSpeaker)
    )
      return;
    const remoteId = isRemotePinned ? pinnedId : currentSpeakerId;
    const requestTopLayer = () => {
      sendAppMessage({ event: 'request-top-layer' }, remoteId);
    };
    const interval = setInterval(requestTopLayer, 10000);
    requestTopLayer();
    return () => {
      clearInterval(interval);
    };
  }, [
    currentSpeakerId,
    localParticipant?.session_id,
    optimizeLargeCalls,
    pinnedId,
    sendAppMessage,
    state,
    viewMode,
  ]);

  return (
    <ParticipantsContext.Provider
      value={{
        currentSpeakerId,
        muteAll,
        muteNewParticipants,
        participantMarkedForRemoval,
        setParticipantMarkedForRemoval,
        swapParticipantPosition,
      }}
    >
      {children}
    </ParticipantsContext.Provider>
  );
};

export const useParticipants = () => useContext(ParticipantsContext);
