import { Button } from '@daily/shared/components/Button';
import { Card, CardList } from '@daily/shared/components/Card';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import tinykeys from 'tinykeys';

interface Props {
  message: string;
  onPick(value: string): void;
  textAreaRef: React.MutableRefObject<HTMLTextAreaElement>;
}

export const ChatCommandMenu: React.FC<Props> = ({
  message,
  onPick,
  textAreaRef,
}) => {
  const { t } = useTranslation();

  const commands = useMemo(
    () => [
      {
        cmd: '/giphy [text]',
        label: (
          <Stack align="start" gap={2} width="100%">
            <Text variant="strong">/giphy [text]</Text>
            <Text color="muted" variant="small">
              {t('giphy.description')}
            </Text>
          </Stack>
        ),
        value: '/giphy ',
      },
    ],
    [t]
  );

  const suitableCommands = commands.filter(({ cmd }) =>
    cmd.startsWith(message)
  );

  /**
   * Autocomplete first command, when user hits Enter.
   */
  useEffect(() => {
    const unsubscribe = tinykeys(textAreaRef.current, {
      Enter: (ev) => {
        if (!suitableCommands.length) return;
        ev.preventDefault();
        ev.stopImmediatePropagation();
        onPick(suitableCommands[0].value);
      },
    });
    return () => {
      unsubscribe();
    };
  }, [onPick, suitableCommands, textAreaRef]);

  if (
    !suitableCommands.length ||
    message.includes(' ') ||
    !message.startsWith('/')
  )
    return null;

  return (
    <Card spacing={12}>
      <CardList>
        {suitableCommands.map(({ cmd, label, value }) => (
          <Button
            key={cmd}
            variant="ghost"
            onClick={() => onPick(value)}
            fullWidth
          >
            {label}
          </Button>
        ))}
      </CardList>
    </Card>
  );
};
