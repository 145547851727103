import { useUniqueId } from 'hooks/useUniqueId';
import { SVGProps } from 'react';

export function Ruby(props: SVGProps<SVGSVGElement>) {
  // Gradient ID's
  const a = useUniqueId();
  const b = useUniqueId();
  const c = useUniqueId();
  const d = useUniqueId();
  const e = useUniqueId();
  const f = useUniqueId();
  const g = useUniqueId();
  const h = useUniqueId();
  const i = useUniqueId();
  const j = useUniqueId();
  const k = useUniqueId();
  const l = useUniqueId();
  const m = useUniqueId();
  const n = useUniqueId();
  const o = useUniqueId();
  return (
    <svg width={120} height={120} viewBox="0 0 120 120" fill="none" {...props}>
      <g clipPath={`url(#${a})`}>
        <path
          d="m16.391 53.658 6.172-16.095L9.31 28.395.506 51.723l15.885 1.935Z"
          fill={`url(#${b})`}
        />
        <path
          d="M24.79 34.201 37.64 22.69 30.254 8.428 11.637 25.033 24.79 34.2Z"
          fill={`url(#${c})`}
        />
        <path
          d="m62.023 16.68 16.695 4.075 7.488-14.262L62.023.483V16.68Z"
          fill={`url(#${d})`}
        />
        <path
          d="m41.282 20.755 16.695-4.075V.483l-24.182 6.01 7.487 14.262Z"
          fill={`url(#${e})`}
        />
        <path
          d="M41.748 24.932c-.303.102-.606.203-.808.407L27.109 37.642a2.217 2.217 0 0 0-.505.814l-6.461 17.286c-.101.305-.202.61-.101 1.017l2.22 18.303c0 .305.203.61.304.915l10.398 15.151c.202.305.404.509.707.61l16.254 8.542c.303.101.606.203.909.203h18.374c.303 0 .606-.102.909-.203l16.254-8.542c.303-.203.505-.406.707-.61l10.398-15.15c.202-.306.303-.61.303-.916L100 56.76c0-.305 0-.61-.101-1.017l-6.461-17.286a2.217 2.217 0 0 0-.505-.814L79.203 25.34c-.202-.204-.505-.407-.808-.407l-17.768-4.373c-.303-.101-.606-.101-1.01 0l-17.87 4.373Z"
          fill={`url(#${f})`}
        />
        <path
          d="m82.26 22.69 12.95 11.511 13.154-9.168L89.747 8.327 82.26 22.69Z"
          fill={`url(#${g})`}
        />
        <path
          d="m119.495 51.723-8.803-23.328-13.254 9.168 6.172 16.197 15.885-2.037Z"
          fill={`url(#${h})`}
        />
        <path
          d="m100.572 78.82-9.814 14.16 10.725 12.122 14.064-20.577-14.975-5.705Z"
          fill={`url(#${i})`}
        />
        <path
          d="m87.722 95.73-15.177 8.047 3.845 15.687 22.057-11.612-10.725-12.123Z"
          fill={`url(#${j})`}
        />
        <path
          d="M104.014 57.733 101.99 74.95l14.975 5.806L120 55.798l-15.986 1.935Z"
          fill={`url(#${k})`}
        />
        <path
          d="M68.6 104.694H51.4l-3.845 15.79h24.89l-3.845-15.79Z"
          fill={`url(#${l})`}
        />
        <path
          d="m18.01 74.949-2.125-17.216L0 55.798l2.934 24.855L18.01 74.95Z"
          fill={`url(#${m})`}
        />
        <path
          d="m47.455 103.777-15.177-8.048-10.725 12.123 22.057 11.612 3.845-15.687Z"
          fill={`url(#${n})`}
        />
        <path
          d="m29.242 92.979-9.814-14.261-14.975 5.806 14.064 20.577 10.725-12.122Z"
          fill={`url(#${o})`}
        />
      </g>
      <defs>
        <linearGradient
          id={b}
          x1={30.661}
          y1={41.027}
          x2={-1.167}
          y2={53.658}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2C78" />
          <stop offset={0.521} stopColor="#FF5469" />
          <stop offset={1} stopColor="#C2145D" />
        </linearGradient>
        <linearGradient
          id={c}
          x1={24.638}
          y1={8.428}
          x2={6.717}
          y2={56.625}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2C78" />
          <stop offset={0.521} stopColor="#FF5469" />
          <stop offset={1} stopColor="#C2145D" />
        </linearGradient>
        <linearGradient
          id={d}
          x1={74.115}
          y1={0.483}
          x2={80.3}
          y2={38.521}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2C78" />
          <stop offset={0.521} stopColor="#FF5469" />
          <stop offset={1} stopColor="#C2145D" />
        </linearGradient>
        <linearGradient
          id={e}
          x1={45.886}
          y1={0.483}
          x2={25.697}
          y2={40.857}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2C78" />
          <stop offset={0.521} stopColor="#FF5469" />
          <stop offset={1} stopColor="#C2145D" />
        </linearGradient>
        <linearGradient
          id={f}
          x1={60}
          y1={20.483}
          x2={60}
          y2={100.483}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2C78" />
          <stop offset={0.521} stopColor="#FF5469" />
          <stop offset={1} stopColor="#C2145D" />
        </linearGradient>
        <linearGradient
          id={g}
          x1={95.312}
          y1={8.327}
          x2={97.528}
          y2={42.317}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2C78" />
          <stop offset={0.521} stopColor="#FF5469" />
          <stop offset={1} stopColor="#C2145D" />
        </linearGradient>
        <linearGradient
          id={h}
          x1={108.466}
          y1={28.395}
          x2={104.244}
          y2={60.421}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2C78" />
          <stop offset={0.521} stopColor="#FF5469" />
          <stop offset={1} stopColor="#C2145D" />
        </linearGradient>
        <linearGradient
          id={i}
          x1={107.164}
          y1={58.961}
          x2={103.152}
          y2={105.102}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2C78" />
          <stop offset={0.521} stopColor="#FF5469" />
          <stop offset={1} stopColor="#C2145D" />
        </linearGradient>
        <linearGradient
          id={j}
          x1={98.447}
          y1={76.188}
          x2={85.496}
          y2={119.464}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2C78" />
          <stop offset={0} stopColor="#FF2C78" />
          <stop offset={0.521} stopColor="#FF5469" />
          <stop offset={1} stopColor="#C2145D" />
        </linearGradient>
        <linearGradient
          id={k}
          x1={109.208}
          y1={48.157}
          x2={110.995}
          y2={80.755}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2C78" />
          <stop offset={0.521} stopColor="#FF5469" />
          <stop offset={1} stopColor="#C2145D" />
        </linearGradient>
        <linearGradient
          id={l}
          x1={77.672}
          y1={141.304}
          x2={55.772}
          y2={98.088}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2C78" />
          <stop offset={0.521} stopColor="#FF5469" />
          <stop offset={1} stopColor="#C2145D" />
        </linearGradient>
        <linearGradient
          id={m}
          x1={32.997}
          y1={45.237}
          x2={9.005}
          y2={80.653}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2C78" />
          <stop offset={0.521} stopColor="#FF5469" />
          <stop offset={1} stopColor="#C2145D" />
        </linearGradient>
        <linearGradient
          id={n}
          x1={10.805}
          y1={113.272}
          x2={41.465}
          y2={103.928}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2C78" />
          <stop offset={0.521} stopColor="#FF5469" />
          <stop offset={1} stopColor="#C2145D" />
        </linearGradient>
        <linearGradient
          id={o}
          x1={27.449}
          y1={50.785}
          x2={16.848}
          y2={105.101}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2C78" />
          <stop offset={0.521} stopColor="#FF5469" />
          <stop offset={1} stopColor="#C2145D" />
        </linearGradient>
        <clipPath id={a}>
          <path fill="#fff" transform="translate(0 .483)" d="M0 0h120v120H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
