import { Card, CardContent } from '@daily/shared/components/Card';
import { WarningIcon } from '@daily/shared/components/Icons';
import { Stack, StackItem } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { useTheme } from '@daily/shared/contexts/Theme';
import { useNetwork } from '@daily-co/daily-react-hooks';
import classnames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStyleVariants } from '../../hooks/useStyleVariants';
import { DownloadIcon } from './DownloadIcon';
import { UploadIcon } from './UploadIcon';

export const Network: React.FC = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { textVariant, textVariantSmall } = useStyleVariants();

  const { getStats, threshold } = useNetwork();
  const [stats, setStats] = useState(null);

  useEffect(() => {
    const updateStats = async () => {
      setStats(await getStats());
    };
    updateStats();
    const interval = setInterval(updateStats, 2000);
    return () => {
      clearInterval(interval);
    };
  }, [getStats]);

  const downloadKbs = useMemo(
    () => Math.round((stats?.latest?.videoRecvBitsPerSecond ?? 0) / 1000),
    [stats?.latest?.videoRecvBitsPerSecond]
  );
  const uploadKbs = useMemo(
    () => Math.round((stats?.latest?.videoSendBitsPerSecond ?? 0) / 1000),
    [stats?.latest?.videoSendBitsPerSecond]
  );

  const statusTitleText = useMemo(() => {
    switch (threshold) {
      case 'low':
        return t('network.weakNetwork');
      case 'very-low':
        return t('network.badPacketLoss');
      default:
      case 'good':
        return t('network.packetLoss');
    }
  }, [t, threshold]);
  const statusSubtitleText = useMemo(() => {
    switch (threshold) {
      case 'low':
        return t('network.lowBandwidthMode');
      case 'very-low':
        return t('network.cameraOff');
      default:
      case 'good':
        return t('network.statusGood');
    }
  }, [t, threshold]);
  const statusIcon = useMemo(() => {
    switch (threshold) {
      case 'low':
        return <WarningIcon color={colors.system.orange} />;
      case 'very-low':
        return <WarningIcon color={colors.system.red} />;
      default:
      case 'good':
        return (
          <span>
            <Text color="inherit" variant="strong">
              {t('network.good')}
            </Text>
            <style jsx>{`
              span {
                background: ${colors.accent};
                border-radius: 4px;
                color: ${colors.accentText};
                display: block;
                padding: 8px;
                text-transform: uppercase;
              }
            `}</style>
          </span>
        );
    }
  }, [
    colors.accent,
    colors.accentText,
    colors.system.orange,
    colors.system.red,
    t,
    threshold,
  ]);

  return (
    <div className="network">
      <Stack gap={10}>
        <Text color="muted" variant={textVariantSmall}>
          {t('network.refreshInfo')}
        </Text>
        <Card
          className={classnames('status', threshold)}
          noBorder
          style={{ background: colors.backgroundAccent }}
        >
          <CardContent>
            <Stack align="center" justify="space-between" horizontal>
              <Stack gap={0}>
                <Text variant="strong">{statusTitleText}</Text>
                <Text variant={textVariant}>{statusSubtitleText}</Text>
              </Stack>
              <StackItem style={{ flex: 'none' }}>{statusIcon}</StackItem>
            </Stack>
          </CardContent>
        </Card>
        <Card noBorder style={{ background: colors.backgroundAccent }}>
          <CardContent>
            <Stack gap={0}>
              <Stack align="center" gap={4} horizontal>
                <DownloadIcon />
                <Text variant="strong">{t('network.downloadRate')}</Text>
              </Stack>
              <Text El="span" variant="largestrong">
                {t('network.kbs', { kbs: downloadKbs })}
              </Text>
            </Stack>
          </CardContent>
        </Card>
        <Card noBorder style={{ background: colors.backgroundAccent }}>
          <CardContent>
            <Stack gap={0}>
              <Stack align="center" gap={4} horizontal>
                <UploadIcon />
                <Text variant="strong">{t('network.uploadRate')}</Text>
              </Stack>
              <Text El="span" variant="largestrong">
                {t('network.kbs', { kbs: uploadKbs })}
              </Text>
            </Stack>
          </CardContent>
        </Card>
        <Text color="muted" variant={textVariantSmall}>
          {t('network.bandwidthInfo')}
        </Text>
      </Stack>
      <style jsx>{`
        .network {
          padding: var(--card-spacing);
        }
        .network :global(.card) {
          box-shadow: none;
        }
        .network :global(.status.very-low) {
          border: 1px solid ${colors.system.red};
        }
      `}</style>
    </div>
  );
};
