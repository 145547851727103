import { LockIcon } from '@daily/shared/components/Icons';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { useTheme } from '@daily/shared/contexts/Theme';
import { useTranslation } from 'react-i18next';

export const LockedMessage: React.FC = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  return (
    <Stack align="center">
      <Stack align="center" gap={4} horizontal>
        <LockIcon color={colors.supportiveText} size={16} />
        <Text color="muted" textAlign="center">
          {t('haircheck.setup.lockedByOwner')}
        </Text>
      </Stack>
    </Stack>
  );
};
