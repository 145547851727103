import {
  CamIcon,
  HiddenIcon,
  MicrophoneIcon,
  PinIcon,
  UnpinIcon,
} from '@daily/shared/components/Icons';
import { Menu } from '@daily/shared/components/Menu';
import { Text } from '@daily/shared/components/Text';
import { useTheme } from '@daily/shared/contexts/Theme';
import {
  useDaily,
  useLocalParticipant,
  useParticipant,
  useParticipantIds,
  useScreenShare,
} from '@daily-co/daily-react-hooks';
import { CALL_CUSTOM_EVENTS } from 'components/App/IframeListener';
import { useIframeDriver } from 'contexts/IframeDriverProvider';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCallState } from '../../contexts/CallProvider';
import { useParticipants } from '../../contexts/ParticipantsProvider';
import {
  useAdminsList,
  useHiddenIds,
  useHiddenParticipantModal,
  useIsMobile,
  useNameModal,
  usePinnedId,
  useSettingsView,
  useShowLocalVideo,
} from '../../contexts/UIState';
import { useCallConfig } from '../../hooks/useCallConfig';
import { useStyleVariants } from '../../hooks/useStyleVariants';

type MenuPropsWithoutItems = Omit<React.ComponentProps<typeof Menu>, 'items'>;

interface Props extends MenuPropsWithoutItems {
  isScreen?: boolean;
  sessionId: string;
}

export const ParticipantMenu: React.FC<Props> = ({
  isScreen = false,
  onClose,
  sessionId,
  ...menuProps
}) => {
  const [, setAdminsList] = useAdminsList();
  const { sendMessageToDriver } = useIframeDriver();
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { disableAudio } = useCallState();
  const [showLocalVideo, setShowLocalVideo] = useShowLocalVideo();
  const { broadcast, broadcastRole, enableVideoProcessingUI } = useCallConfig();
  const daily = useDaily();
  const { setParticipantMarkedForRemoval } = useParticipants();
  const localParticipant = useLocalParticipant();
  const { screens } = useScreenShare();
  const screen = useMemo(
    () => screens.find((s) => s.session_id === sessionId),
    [screens, sessionId]
  );
  const [isMobile] = useIsMobile();
  const [, setShowNameModal] = useNameModal();
  const [pinnedId, setPinnedId] = usePinnedId();
  const [, setSettingsView] = useSettingsView();
  const { iconVariant, textVariant } = useStyleVariants();

  const participantIds = useParticipantIds();
  const participant = useParticipant(
    sessionId === 'local' ? localParticipant?.session_id : sessionId
  );

  const [hiddenIds, setHiddenIds] = useHiddenIds();
  const [, setHiddenParticipantModal] = useHiddenParticipantModal();

  const pinLabel = useMemo(() => {
    const isPinned = pinnedId === sessionId || pinnedId === screen?.screenId;
    if (isScreen) {
      return isPinned ? t('screenShare.unpin') : t('screenShare.pin');
    }
    if (participant?.local) {
      return isPinned ? t('people.unpinYourself') : t('people.pinYourself');
    }
    return isPinned ? t('people.unpinParticipant') : t('people.pinParticipant');
  }, [isScreen, participant?.local, screen?.screenId, sessionId, pinnedId, t]);

  const handlePinClick = useCallback(() => {
    if (isScreen) {
      setPinnedId(screen?.screenId);
    } else {
      setPinnedId(sessionId);
    }
    onClose?.();
  }, [isScreen, onClose, screen?.screenId, sessionId, setPinnedId]);
  const handleUnpinClick = () => {
    setPinnedId(null);
    onClose?.();
  };

  const handleRenameClick = () => {
    setShowNameModal(true);
    onClose?.();
  };

  const handleBackgroundEffectsClick = () => {
    setSettingsView('effects');
    onClose?.();
  };

  const handleToggleSelfViewClick = () => {
    setShowLocalVideo(!showLocalVideo);
    onClose?.();
  };

  const handleMicMuteClick = () => {
    if (participant.local) {
      daily.setLocalAudio(!participant.audio);
    } else {
      daily.updateParticipant(sessionId, { setAudio: false });
    }
    onClose?.();
  };

  const handleCamMuteClick = () => {
    if (participant.local) {
      daily.setLocalVideo(!participant.video);
    } else {
      daily.updateParticipant(sessionId, { setVideo: false });
    }
    onClose?.();
  };

  const handleStopScreenShareClick = () => {
    daily.sendAppMessage('stop-screen-share', sessionId);
    onClose?.();
  };

  const handleRemoveClick = () => {
    setParticipantMarkedForRemoval(participant);
    onClose?.();
  };

  const handleHideClick = () => {
    if (!hiddenIds.includes(sessionId)) {
      setHiddenParticipantModal(true);
    }

    setHiddenIds((ids) => {
      if (ids.includes(sessionId)) {
        return ids.filter((id) => id !== sessionId);
      }
      return [...ids, sessionId];
    });

    onClose?.();
  };

  const menuItems: React.ComponentProps<typeof Menu>['items'] = [];

  const handleShowParticipantDetails = useCallback(
    (event) => {
      sendMessageToDriver({
        action: CALL_CUSTOM_EVENTS.ST_SHOW_PARTICIPANT_DETAILS,
        payload: {
          from: 'participant-row',
          userID: participant.user_id,
          x: event.clientX,
          y: event.clientY,
        },
      });

      onClose?.();
    },
    [participant.user_id, sendMessageToDriver, onClose]
  );

  menuItems.push({
    label: participant.user_name,
    // @ts-ignore
    onClick: handleShowParticipantDetails,
  });

  // Hide participant
  if (!participant?.local) {
    menuItems.push({
      label: (
        <Text variant={textVariant}>
          {hiddenIds.includes(sessionId)
            ? t('people.showParticipant')
            : t('people.hideParticipant')}
        </Text>
      ),
      icon: <HiddenIcon size={iconVariant} />,
      onClick: handleHideClick,
    });
  }

  // Rename
  if (participant?.local && !isScreen) {
    menuItems.push(
      {
        label: <Text variant={textVariant}>{t('people.rename')}</Text>,
        onClick: handleRenameClick,
      },
      ...(enableVideoProcessingUI && !isMobile && broadcastRole !== 'attendee'
        ? [
            {
              disabled: !localParticipant?.video,
              label: (
                <Text
                  color={!localParticipant?.video ? 'muted' : 'default'}
                  variant={textVariant}
                >
                  {t('video.backgroundEffects')}
                </Text>
              ),
              onClick: handleBackgroundEffectsClick,
            },
          ]
        : []),
      ...(broadcastRole !== 'attendee'
        ? [
            {
              disabled: participantIds.length === 1,
              icon: <HiddenIcon size={iconVariant} />,
              label: (
                <Text
                  color={participantIds.length === 1 ? 'muted' : 'default'}
                  variant={textVariant}
                >
                  {showLocalVideo
                    ? t('people.hideSelfView')
                    : t('people.showSelfView')}
                </Text>
              ),
              onClick: handleToggleSelfViewClick,
            },
          ]
        : [])
    );
  }

  const isBroadcastOwner =
    broadcast && localParticipant?.owner && participant?.owner;
  const hasMutePermission =
    !isScreen && localParticipant?.owner && !participant?.local;
  /**
   * Mute/Unmute microphone and camera
   * In OOB calls, attendees can't turn on their cam/mic so muting
   * doesn't need to be offered here.
   */
  if (isBroadcastOwner || (!broadcast && hasMutePermission)) {
    if (!disableAudio) {
      menuItems.push({
        disabled: !participant?.audio,
        label: (
          <Text
            color={!participant?.audio ? 'muted' : 'default'}
            variant={textVariant}
          >
            {t('audio.muteMicrophone')}
          </Text>
        ),
        icon: (
          <MicrophoneIcon
            id={`mute-mic-${sessionId}`}
            color={!participant?.audio ? colors.supportiveText : 'currentColor'}
            muted
            size={iconVariant}
            transition={false}
          />
        ),
        onClick: handleMicMuteClick,
      });
    }
    menuItems.push({
      disabled: !participant?.video,
      label: (
        <Text
          color={!participant?.video ? 'muted' : 'default'}
          variant={textVariant}
        >
          {t('video.muteCamera')}
        </Text>
      ),
      icon: (
        <CamIcon
          id={`mute-cam-${sessionId}`}
          color={!participant?.video ? colors.supportiveText : 'currentColor'}
          muted
          size={iconVariant}
          transition={false}
        />
      ),
      onClick: handleCamMuteClick,
    });
  }

  // Stop screen share
  if (localParticipant?.owner && isScreen && !participant?.local) {
    menuItems.push({
      label: (
        <Text color="error" variant={textVariant}>
          {t('screenShare.stopShare')}
        </Text>
      ),
      onClick: handleStopScreenShareClick,
    });
  }

  const isBroadcastNonAttendee = broadcast && (participant?.owner || isScreen);
  // In OOB calls, pinning is only available for owner tiles
  // Pinning is never available on mobile
  if (!isMobile && (!broadcast || isBroadcastNonAttendee)) {
    const disabled = participant?.local && !showLocalVideo;
    // Pin / Unpin
    menuItems.push({
      disabled,
      label: (
        <Text color={disabled ? 'muted' : 'default'} variant={textVariant}>
          {pinLabel}
        </Text>
      ),
      icon:
        pinnedId === sessionId ? (
          <UnpinIcon size={iconVariant} />
        ) : (
          <PinIcon size={iconVariant} />
        ),
      onClick:
        pinnedId === sessionId || pinnedId === screen?.screenId
          ? handleUnpinClick
          : handlePinClick,
    });
  }

  // Handle user kick, including ban and timeout for 30 minutes.
  // I don't like the `to` prop, but atm keep consistent with the API
  const handleKick = (to: 'kick' | 'temp' | 'perm') => {
    sendMessageToDriver({
      action: CALL_CUSTOM_EVENTS.ST_KICK_PARTICIPANT,
      receiverID: localParticipant.user_id,
      payload: {
        userID: participant.user_id,
        sessionID: participant.session_id,
        to: to,
      },
    });

    // Kick user visually from room.
    if (!participant?.local) {
      daily.updateParticipant(participant.session_id, { eject: true });
    }
  };

  // Remove from call…
  if (localParticipant?.owner && !participant?.local && !isScreen) {
    // Kick user
    menuItems.push({
      label: (
        <Text color="error" variant={textVariant}>
          Kick User
        </Text>
      ),
      onClick: () => handleKick('kick'),
    });
    // Timeout user
    menuItems.push({
      label: (
        <Text color="error" variant={textVariant}>
          Kick User (30 Minutes)
        </Text>
      ),
      onClick: () => handleKick('temp'),
    });
    // Ban user
    menuItems.push({
      label: (
        <Text color="error" variant={textVariant}>
          Ban User
        </Text>
      ),
      onClick: () => handleKick('perm'),
    });

    // menuItems.push({
    //   label: (
    //     <Text color="error" variant={textVariant}>
    //       {t('people.removeFromCall')}
    //     </Text>
    //   ),
    //   onClick: handleRemoveClick,
    // });
  }

  const handleAddAsAdmin = () => {
    // daily.addParticipantAsAdmin(sessionId);
    console.log('handleAddAsAdmin', participant);

    // Add to the "Admins" tab, so the room owner does not have to refresh the page
    setAdminsList((oldAdmins) => {
      // @ts-ignore
      if (oldAdmins.find((admin) => admin.userID === participant.user_id)) {
        return oldAdmins;
      }

      return [
        ...oldAdmins,
        {
          userID: participant.user_id,
          username: participant.user_name,
        },
      ];
    });

    sendMessageToDriver({
      action: CALL_CUSTOM_EVENTS.ST_ADMIN_ADD_PARTICIPANT,
      receiverID: localParticipant.user_id,
      payload: {
        userID: participant.user_id,
        sessionID: participant.session_id,
      },
    });

    onClose?.();
  };

  if (localParticipant?.owner && !participant?.local && !participant?.owner) {
    menuItems.push({
      label: (
        <Text color="default" variant={textVariant}>
          Add as Admin
        </Text>
      ),
      onClick: handleAddAsAdmin,
    });
  }

  if (menuItems.length === 0) return null;

  return <Menu items={menuItems} onClose={onClose} {...menuProps} />;
};
