import { Button } from '@daily/shared/components/Button';
import { ReportUserIcon } from '@daily/shared/components/Icons';
import { Tooltip } from '@daily/shared/components/Tooltip';
import { useParticipant } from '@daily-co/daily-react-hooks';
import { CALL_CUSTOM_EVENTS } from 'components/App/IframeListener';
import { useIframeDriver } from 'contexts/IframeDriverProvider';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  activeIndicator?: 'meter' | 'static';
  name?: string;
  sessionId: string;
  videoEl: any;
}

export const TileRaportUser: React.FC<Props> = ({ sessionId, videoEl }) => {
  const { sendMessageToDriver } = useIframeDriver();
  const { t } = useTranslation();
  const participant = useParticipant(sessionId);

  const userID = participant?.user_id;

  const handleClick = useCallback(
    (event) => {
      const canvas = document.createElement('canvas');
      if (videoEl.current) {
        canvas.width = videoEl.current.videoWidth;
        canvas.height = videoEl.current.videoHeight;
        const context = canvas.getContext('2d');
        context?.drawImage(videoEl.current, 0, 0, canvas.width, canvas.height);
      }
      const screenshot = canvas.toDataURL();
      sendMessageToDriver({
        action: CALL_CUSTOM_EVENTS.ST_REPORT_PARTICIPANT,

        payload: {
          from: 'tile-report-user',
          userID: userID,
          videoEl: videoEl.current ? screenshot : '',
          x: event.clientX,
          y: event.clientY,
        },
      });
    },
    [userID, sendMessageToDriver]
  );

  return (
    <Tooltip
      id="tt-raport-participant"
      placement="top"
      title={t('people.reportParticipant')}
    >
      <Button onClick={handleClick} variant="ghost">
        <ReportUserIcon color="#FF3939" size={20} />
      </Button>
    </Tooltip>
  );
};
TileRaportUser.displayName = 'TileRaportUser';
