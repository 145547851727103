import classnames from 'classnames';
import React from 'react';

import { Anchor } from '../Anchor';
import { Button } from '../Button';
import { CheckIcon } from '../Icons';
import { Text } from '../Text';

interface Props {
  active?: boolean;
  className?: string;
  disabled?: boolean;
  download?: boolean;
  href?: string;
  HrefComponent?: React.FC<any>;
  icon?: JSX.Element;
  label?: JSX.Element | string;
  onClick?(): void;
  static?: boolean;
}

export const MenuItem: React.FC<Props> = ({
  active,
  className,
  disabled,
  download,
  href,
  static: _static,
  HrefComponent = href ? Anchor : _static ? 'div' : Button,
  icon,
  label,
  onClick,
}) => {
  const itemProps = {
    disabled: _static ? null : disabled,
    download: _static ? null : download,
    href: _static ? null : href,
    focusable: false,
    onClick,
    variant: href || _static ? null : 'ghost',
  };
  return (
    <HrefComponent
      role="menuitem"
      {...itemProps}
      className={classnames('menuItem', className, {
        active,
        static: _static,
      })}
    >
      <Text
        El="span"
        color={disabled ? 'muted' : 'default'}
        variant={active ? 'strong' : 'base'}
      >
        {label}
      </Text>
      <span className={classnames('icon', { active })}>
        {active ? <CheckIcon size={16} /> : icon}
      </span>
      <style jsx>{`
        .icon {
          margin-left: 16px;
        }
      `}</style>
    </HrefComponent>
  );
};
