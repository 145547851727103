import React from 'react';

import { useTheme } from '../../contexts/Theme';
import { CaretDownIcon } from '../Icons';

interface Props {
  disabled?: boolean;
}

export const Caret: React.FC<Props> = ({ disabled }) => {
  const { colors } = useTheme();

  return (
    <span>
      <CaretDownIcon
        color={disabled ? colors.supportiveText : 'currentColor'}
        size={16}
      />
      <style jsx>{`
        span {
          display: inline-flex;
          pointer-events: none;
          position: absolute;
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
        }
      `}</style>
    </span>
  );
};
