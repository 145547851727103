import { useSnackbar } from '@daily/shared/contexts/Snackbar';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface GiphyGif {
  q: string;
  size: number;
  title: string;
  url: string;
}

const MAX_GIFS_PER_SEARCH = 25;

export const useGiphySearch = (q: string) => {
  const { i18n, t } = useTranslation();
  const { addMessage } = useSnackbar();
  const [gif, setGif] = useState<GiphyGif>(null);
  const [availableGifs, setAvailableGifs] = useState<GiphyGif[]>([]);
  const [searchOffset, setSearchOffset] = useState(0);
  const [fetchRandom, setFetchRandom] = useState(false);

  /**
   * Sanitize lang param for GIPHY API
   */
  const giphyLang = useMemo(() => {
    // Fix Japanese ISO key
    if (i18n.language === 'jp') return 'ja';
    // Fallback to en
    if (['ka'].includes(i18n.language)) return 'en';
    return i18n.language;
  }, [i18n.language]);

  const displayError = useCallback(() => {
    addMessage({
      content: t('giphy.fetchFailed'),
      type: 'error',
    });
  }, [addMessage, t]);

  const fetchRandomGif = useCallback(
    async (q: string) => {
      try {
        const response = await fetch(
          `https://api.giphy.com/v1/gifs/random?api_key=${process.env.NEXT_PUBLIC_GIPHY_API_KEY}&rating=pg&lang=${giphyLang}`
        );
        if (response.ok) {
          const parsedResponse = await response.json();
          setGif({
            q,
            size: parseInt(parsedResponse.data.images.fixed_height.size, 10),
            title: parsedResponse.data.title,
            url: parsedResponse.data.images.fixed_height.url,
          });
        }
      } catch (e) {
        displayError();
      }
    },
    [displayError, giphyLang]
  );

  const searchGifs = useCallback(
    async (q: string) => {
      try {
        const response = await fetch(
          `https://api.giphy.com/v1/gifs/search?api_key=${process.env.NEXT_PUBLIC_GIPHY_API_KEY}&q=${q}&limit=${MAX_GIFS_PER_SEARCH}&offset=0&rating=pg&lang=${giphyLang}`
        );
        if (response.ok) {
          const parsedResponse = await response.json();
          if (!parsedResponse.data.length) {
            setFetchRandom(true);
            return;
          }
          setAvailableGifs(
            parsedResponse.data.map((result) => ({
              q,
              size: parseInt(result.images.fixed_height.size, 10),
              title: result.title,
              url: result.images.fixed_height.url,
            }))
          );
          setSearchOffset(0);
        }
      } catch (e) {
        displayError();
      }
    },
    [displayError, giphyLang]
  );

  useEffect(() => {
    if (!q) {
      setAvailableGifs([]);
      setFetchRandom(false);
      setGif(null);
      setSearchOffset(0);
      return;
    }
    fetchRandom ? fetchRandomGif(q) : searchGifs(q);
  }, [fetchRandom, fetchRandomGif, q, searchGifs]);

  const refresh = useCallback(() => {
    if (!q) return;
    fetchRandom
      ? fetchRandomGif(q)
      : setSearchOffset(
          (prevOffset) => (prevOffset + 1) % availableGifs.length
        );
  }, [availableGifs.length, fetchRandom, fetchRandomGif, q]);

  useEffect(() => {
    if (!availableGifs.length) return;
    setGif(availableGifs[searchOffset]);
  }, [availableGifs, searchOffset]);

  return {
    gif,
    refresh,
  };
};
