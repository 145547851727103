import { useUniqueId } from 'hooks/useUniqueId';
import { SVGProps } from 'react';

export function Topaz(props: SVGProps<SVGSVGElement>) {
  // Gradient ID's
  const a = useUniqueId();
  const b = useUniqueId();
  const c = useUniqueId();
  const d = useUniqueId();
  const e = useUniqueId();
  const f = useUniqueId();
  const g = useUniqueId();
  const h = useUniqueId();
  const i = useUniqueId();

  return (
    <svg width={120} height={120} viewBox="0 0 120 120" fill="none" {...props}>
      <g clipPath={`url(#${a})`}>
        <path
          d="M62.123 0v16.566c.104 0 .104.107.207.107l31.475 15.504c.104 0 .104.106.208.106l12.631-10.3L62.123 0Z"
          fill={`url(#${b})`}
        />
        <path
          d="M26.194 32.177c.104 0 .104-.106.207-.106l31.476-15.505c.103 0 .103-.106.207-.106V0L13.562 21.876l12.632 10.301Z"
          fill={`url(#${c})`}
        />
        <path
          d="M15.738 70.832v-.212l7.765-34.938v-.213l-12.632-10.3L0 74.549l15.738-3.717Z"
          fill={`url(#${d})`}
        />
        <path
          d="M27.755 36.21 20.04 70.9a.834.834 0 0 0 .206.843l21.6 27.835c.206.211.514.422.823.422H77.33c.309 0 .617-.105.823-.422l21.6-27.835c.206-.21.308-.527.206-.843l-7.715-34.69c-.102-.315-.308-.526-.514-.737L60.463 20.079a1.394 1.394 0 0 0-.926 0l-31.165 15.5a.939.939 0 0 0-.617.632Z"
          fill={`url(#${e})`}
        />
        <path
          d="M103.432 74.973c0 .106-.103.106-.103.213L81.586 103.22l-.104.106 7.041 14.867 30.751-39.504-15.842-3.717Z"
          fill={`url(#${f})`}
        />
        <path
          d="M96.496 35.576v.212l7.765 34.938v.212l15.738 3.717-10.975-49.274-12.528 10.195Z"
          fill={`url(#${g})`}
        />
        <path
          d="m38.62 103.327-.104-.106-21.743-28.035s-.104-.107-.104-.213L.932 78.69l30.75 39.504 6.937-14.867Z"
          fill={`url(#${h})`}
        />
        <path
          d="M77.757 105.133H42.347L35.307 120H84.59l-6.834-14.867Z"
          fill={`url(#${i})`}
        />
      </g>
      <defs>
        <linearGradient
          id={b}
          x1={84.384}
          y1={0}
          x2={95.611}
          y2={25.814}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B3E4FF" />
          <stop offset={0.388} stopColor="#00A4FF" />
          <stop offset={1} stopColor="#015B8D" />
        </linearGradient>
        <linearGradient
          id={c}
          x1={35.823}
          y1={0}
          x2={48.526}
          y2={63.556}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B3E4FF" />
          <stop offset={0.388} stopColor="#00A4FF" />
          <stop offset={1} stopColor="#015B8D" />
        </linearGradient>
        <linearGradient
          id={d}
          x1={11.752}
          y1={25.169}
          x2={39.299}
          y2={47.204}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B3E4FF" />
          <stop offset={0.477} stopColor="#00A4FF" />
          <stop offset={1} stopColor="#015B8D" />
        </linearGradient>
        <linearGradient
          id={e}
          x1={60}
          y1={20}
          x2={93.555}
          y2={75.942}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B3E4FF" />
          <stop offset={0.388} stopColor="#00A4FF" />
          <stop offset={1} stopColor="#015B8D" />
        </linearGradient>
        <linearGradient
          id={f}
          x1={84.564}
          y1={44.71}
          x2={122.875}
          y2={100.645}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B3E4FF" />
          <stop offset={0.493} stopColor="#00A4FF" />
          <stop offset={0.87} stopColor="#015B8D" />
        </linearGradient>
        <linearGradient
          id={g}
          x1={108.248}
          y1={25.381}
          x2={131.072}
          y2={43.531}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B3E4FF" />
          <stop offset={0.388} stopColor="#00A4FF" />
          <stop offset={1} stopColor="#015B8D" />
        </linearGradient>
        <linearGradient
          id={h}
          x1={19.776}
          y1={74.973}
          x2={38.966}
          y2={102.87}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B3E4FF" />
          <stop offset={0.388} stopColor="#00A4FF" />
          <stop offset={1} stopColor="#015B8D" />
        </linearGradient>
        <linearGradient
          id={i}
          x1={50.019}
          y1={93.924}
          x2={50.7}
          y2={116.434}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B3E4FF" />
          <stop offset={0.388} stopColor="#00A4FF" />
          <stop offset={1} stopColor="#015B8D" />
        </linearGradient>
        <clipPath id={a}>
          <path fill="#fff" d="M0 0h120v120H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
