import { GaugeIcon } from '@daily/shared/components/Icons';
import { Text } from '@daily/shared/components/Text';
import { useTheme } from '@daily/shared/contexts/Theme';
import { useNetwork } from '@daily-co/daily-react-hooks';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsMobile, useSidebarView } from '../../contexts/UIState';
import { useCallConfig } from '../../hooks/useCallConfig';
import { robotsClassName } from '../../lib/robots';
import { TrayButton } from './TrayButton';

interface Props extends React.ComponentProps<typeof TrayButton> {}

export const NetworkControls: React.FC<Props> = ({
  children,
  className,
  onClick,
  ...props
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const [isMobile] = useIsMobile();
  const [sidebarView, setSidebarView] = useSidebarView();
  const { enableNetworkUI } = useCallConfig();

  const { threshold } = useNetwork();

  const [latestNetwork, setLatestNetwork] = useState<typeof threshold>('good');
  const [hasNetworkChanged, setHasNetworkChanged] = useState(false);

  useEffect(() => {
    if (threshold === latestNetwork || sidebarView === 'network') return;
    setLatestNetwork(threshold);
    setHasNetworkChanged(threshold !== 'good');
  }, [latestNetwork, sidebarView, threshold]);

  const handleNetworkClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    if (isMobile) return; // todo: handle mobile
    setSidebarView((sbv) => (sbv === 'network' ? null : 'network'));
    setHasNetworkChanged(false);
    onClick?.(ev);
  };

  const badgeColorMap: Record<typeof threshold, string> = {
    good: 'transparent',
    low: colors.system.orange,
    'very-low': colors.system.red,
  };

  if (!enableNetworkUI) return null;

  return (
    <div className="network-controls">
      <TrayButton
        badge={{
          color: badgeColorMap[latestNetwork],
          visible: hasNetworkChanged,
        }}
        className={classnames(className, {
          [robotsClassName('btn-network-show')]: sidebarView !== 'network',
          [robotsClassName('btn-network-hide')]: sidebarView === 'network',
        })}
        onClick={handleNetworkClick}
        {...props}
      >
        <GaugeIcon />
        <Text>{t('general.network')}</Text>
      </TrayButton>
    </div>
  );
};
