import classnames from 'classnames';
import React from 'react';

import { Text } from '../Text';

export const DD = ({
  children,
  className,
  variant,
  ...props
}: React.ComponentProps<typeof Text>) => (
  <Text
    El="dd"
    variant={variant || 'mono'}
    {...props}
    className={classnames(className, {
      largestrong: variant === 'largestrong',
    })}
  >
    {children}
    <style jsx>{`
      :global(dd.largestrong) {
        grid-column-start: 1;
        grid-column-end: -1;
        padding-bottom: 12px;
      }
    `}</style>
  </Text>
);
