import { useEffect } from 'react';

type OnUnlock = () => void;

export const useScrollLock = (locked: boolean, onUnlock: OnUnlock = null) => {
  const handleKeyDown = (ev: KeyboardEvent) => {
    if (ev.key === 'Escape') {
      onUnlock?.();
    }
  };

  useEffect(() => {
    const lock = () => {
      document.documentElement.classList.add('scroll-lock');
    };

    const unlock = () => {
      document.documentElement.classList.remove('scroll-lock');
    };

    if (locked) {
      lock();
    } else {
      unlock();
      onUnlock?.();
    }
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      unlock();
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [locked]);
};
