import classnames from 'classnames';
import React from 'react';

import { useTheme } from '../../contexts/Theme';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: string | JSX.Element;
  name: string;
}

export const Radio: React.FC<Props> = ({
  checked,
  id,
  label = null,
  ...props
}) => {
  const { fontFamilies } = useTheme();
  const isControlled = typeof checked === 'boolean';

  return (
    <div>
      <input
        checked={checked}
        className={classnames({
          isControlled,
          checked,
        })}
        id={id}
        type="radio"
        {...props}
      />
      <label htmlFor={id}>{label}</label>
      <style jsx>{`
        div {
          position: relative;
        }
        input {
          margin: 0;
          opacity: 0;
          position: absolute;
        }
        label {
          cursor: pointer;
          display: inline-block;
          padding-left: ${label ? '24px' : '16px'};
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
        label::before {
          border: 1px solid var(--radio-border);
          border-radius: 100%;
          box-shadow: 0 0 0 0 var(--focus-shadow);
          content: '';
          height: 16px;
          left: 0;
          position: absolute;
          top: 1px;
          transition: border 200ms ease, box-shadow 200ms ease;
          width: 16px;
        }
        label::after {
          background-color: var(--radio-checked);
          border-radius: 100%;
          content: '';
          height: 8px;
          left: 4px;
          opacity: 0;
          position: absolute;
          top: 5px;
          transition: opacity 200ms ease;
          width: 8px;
        }
        .isControlled.checked + label,
        input:not(.isControlled):checked + label {
          font-family: ${fontFamilies.medium};
          font-weight: normal;
        }
        .isControlled.checked + label::after,
        input:not(.isControlled):checked + label::after {
          opacity: 1;
        }
        input:not([disabled]) + label:hover::before,
        div:focus-within label::before {
          border-color: var(--focus-border);
          box-shadow: 0 0 0 2px var(--focus-shadow);
        }
        input[disabled] + label {
          color: var(--text-muted);
          cursor: default;
        }
        input[disabled] + label::before {
          opacity: 0.6;
        }
        .isControlled.checked[disabled] + label::after,
        input:not(.isControlled):checked[disabled] + label::after {
          opacity: 0.6;
        }
      `}</style>
    </div>
  );
};
