import { useUniqueId } from 'hooks/useUniqueId';
import { SVGProps } from 'react';

export function Jasper(props: SVGProps<SVGSVGElement>) {
  // Gradient ID's
  const a = useUniqueId();
  const b = useUniqueId();
  const c = useUniqueId();
  const d = useUniqueId();
  const e = useUniqueId();
  const f = useUniqueId();
  const g = useUniqueId();
  const h = useUniqueId();
  const i = useUniqueId();
  const j = useUniqueId();
  const k = useUniqueId();
  const l = useUniqueId();
  const m = useUniqueId();

  return (
    <svg width={120} height={120} viewBox="0 0 120 120" fill="none" {...props}>
      <g clipPath={`url(#${a})`}>
        <path
          d="m20.61 40.513 13.807-16.102-8.731-13.642L5.99 33.745l14.62 6.77Z"
          fill={`url(#${b})`}
        />
        <path
          d="m37.766 22.256 20.203-6.05V0L29.137 8.513l8.63 13.743Z"
          fill={`url(#${c})`}
        />
        <path
          d="m62.03 16.205 20.202 5.949 8.63-13.641L62.029 0v16.205Z"
          fill={`url(#${d})`}
        />
        <path
          d="m15.94 65.23 2.943-20.923-14.619-6.769L0 67.59l15.94-2.359Z"
          fill={`url(#${e})`}
        />
        <path
          d="m94.315 10.77-8.731 13.64 13.807 16.103 14.619-6.77L94.315 10.77Z"
          fill={`url(#${f})`}
        />
        <path
          d="M37.708 26.83 23.573 43.308c-.303.307-.404.614-.505 1.023L20.04 65.923c-.101.409 0 .818.201 1.125l8.986 19.853c.202.307.404.614.707.921l18.172 11.871c.303.205.707.307 1.111.307h21.606c.403 0 .807-.102 1.11-.307l18.173-11.87c.303-.205.606-.512.707-.922l8.985-19.853c.202-.307.202-.716.202-1.125L96.971 44.33c-.1-.41-.202-.716-.505-1.023L82.13 26.83c-.303-.307-.606-.512-.909-.614l-20.696-6.14c-.404-.103-.808-.103-1.111 0l-20.697 6.14c-.404.102-.807.307-1.01.614Z"
          fill={`url(#${g})`}
        />
        <path
          d="m92.083 91.692-17.665 11.59 4.467 15.59 25.381-16.513-12.183-10.667Z"
          fill={`url(#${h})`}
        />
        <path
          d="m103.452 69.333-8.63 19.282 12.081 10.667 12.488-27.59-15.939-2.359Z"
          fill={`url(#${i})`}
        />
        <path
          d="m25.28 88.615-8.833-19.282L.609 71.589l12.488 27.693 12.182-10.667Z"
          fill={`url(#${j})`}
        />
        <path
          d="m101.117 44.205 2.944 21.025L120 67.59l-4.264-30.052-14.619 6.667Z"
          fill={`url(#${k})`}
        />
        <path
          d="M70.457 104.41H49.543L44.975 120h30.05l-4.568-15.59Z"
          fill={`url(#${l})`}
        />
        <path
          d="M45.584 103.282 27.919 91.795l-12.183 10.564 25.381 16.513 4.467-15.59Z"
          fill={`url(#${m})`}
        />
      </g>
      <defs>
        <linearGradient
          id={b}
          x1={5.99}
          y1={10.77}
          x2={44.308}
          y2={34.223}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E66057" />
          <stop offset={0.596} stopColor="#FF8E3C" />
          <stop offset={1} stopColor="#FFE03C" />
        </linearGradient>
        <linearGradient
          id={c}
          x1={29.137}
          y1={0}
          x2={58.372}
          y2={26.607}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E66057" />
          <stop offset={0.583} stopColor="#FF8E3C" />
          <stop offset={1} stopColor="#FFE03C" />
        </linearGradient>
        <linearGradient
          id={d}
          x1={62.029}
          y1={0}
          x2={88.305}
          y2={23.828}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E66057" />
          <stop offset={0.484} stopColor="#FF8E3C" />
          <stop offset={1} stopColor="#FFE03C" />
        </linearGradient>
        <linearGradient
          id={e}
          x1={0}
          y1={37.538}
          x2={35.324}
          y2={45.444}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E66057" />
          <stop offset={0.484} stopColor="#FF8E3C" />
          <stop offset={1} stopColor="#FFE03C" />
        </linearGradient>
        <linearGradient
          id={f}
          x1={80.113}
          y1={-2.776}
          x2={118.854}
          y2={32.292}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E66057" />
          <stop offset={0.484} stopColor="#FF8E3C" />
          <stop offset={1} stopColor="#FFE03C" />
        </linearGradient>
        <linearGradient
          id={g}
          x1={20}
          y1={20}
          x2={109.437}
          y2={82.318}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E66057" />
          <stop offset={0.484} stopColor="#FF8E3C" />
          <stop offset={1} stopColor="#FFE03C" />
        </linearGradient>
        <linearGradient
          id={h}
          x1={28.407}
          y1={78.433}
          x2={104.171}
          y2={119.53}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E66057" />
          <stop offset={0.484} stopColor="#FF8E3C" />
          <stop offset={1} stopColor="#FFE03C" />
        </linearGradient>
        <linearGradient
          id={i}
          x1={74.334}
          y1={45.28}
          x2={129.229}
          y2={82.398}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E66057" />
          <stop offset={0.484} stopColor="#FF8E3C" />
          <stop offset={1} stopColor="#FFE03C" />
        </linearGradient>
        <linearGradient
          id={j}
          x1={0.609}
          y1={69.333}
          x2={31.927}
          y2={93.549}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.081} stopColor="#E66057" />
          <stop offset={0.579} stopColor="#FF8E3C" />
          <stop offset={1} stopColor="#FFE03C" />
        </linearGradient>
        <linearGradient
          id={k}
          x1={72.205}
          y1={13.04}
          x2={122.695}
          y2={72.654}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E66057" />
          <stop offset={0.556} stopColor="#FF8E3C" />
          <stop offset={1} stopColor="#FFE03C" />
        </linearGradient>
        <linearGradient
          id={l}
          x1={18.37}
          y1={104.41}
          x2={54.687}
          y2={140.553}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E66057" />
          <stop offset={0.484} stopColor="#FF8E3C" />
          <stop offset={1} stopColor="#FFE03C" />
        </linearGradient>
        <linearGradient
          id={m}
          x1={15.736}
          y1={91.795}
          x2={46.913}
          y2={115.741}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E66057" />
          <stop offset={0.484} stopColor="#FF8E3C" />
          <stop offset={1} stopColor="#FFE03C" />
        </linearGradient>
        <clipPath id={a}>
          <path fill="#fff" d="M0 0h120v120H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
