import { Button } from '@daily/shared/components/Button';
import { Text } from '@daily/shared/components/Text';
import { useMemo } from 'react';

import { useIsMobile } from '../../contexts/UIState';
import { useStyleVariants } from '../../hooks/useStyleVariants';

type Props = React.ComponentProps<typeof Button>;

export const CTAButton: React.FC<Props> = ({ children, ...props }) => {
  const [isMobile] = useIsMobile();

  const { textVariantStrong } = useStyleVariants();

  const mobileButtonStyles = useMemo(
    () => (isMobile ? { padding: '10px 20px 12px' } : {}),
    [isMobile]
  );

  return (
    <Button {...props} style={mobileButtonStyles}>
      <Text variant={textVariantStrong} El="span" color="inherit">
        {children}
      </Text>
    </Button>
  );
};
