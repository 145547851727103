import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

import { useCallState } from '../../contexts/CallProvider';

/**
 * Helper hook to identify wether this call is running in an Intercom integration.
 * Automatically hides participant bar, names and leave button.
 */
export const useIntercomIntegration = () => {
  const { query } = useRouter();
  const {
    setShowFullscreenButton,
    setShowLeaveButton,
    setShowNames,
    setShowParticipantsBar,
    state,
  } = useCallState();

  const configureForIntercom = useCallback(() => {
    setShowParticipantsBar(false);
    setShowNames(false);
    setShowLeaveButton(false);
    setShowFullscreenButton(true);
  }, [
    setShowFullscreenButton,
    setShowLeaveButton,
    setShowNames,
    setShowParticipantsBar,
  ]);

  useEffect(() => {
    if (
      query['autojoin'] === 't' &&
      query['intercomAppLink'] === 't' &&
      state === 'joining'
    ) {
      configureForIntercom();
    }
  }, [configureForIntercom, query, state]);
};
