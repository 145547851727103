import { useUniqueId } from 'hooks/useUniqueId';
import { SVGProps } from 'react';

export function GameChangerIcon(props: SVGProps<SVGSVGElement>) {
  const idA = useUniqueId();
  const idB = useUniqueId();
  return (
    <svg width="76" height="74" fill="none" viewBox="0 0 76 74" {...props}>
      <g clipPath={`url(#${idA})`}>
        <path
          fill={`url(#${idB})`}
          d="M75.25 37.105a4.26 4.26 0 0 1-.51 2.65 11.23 11.23 0 0 1-4.11 3.89c-.77.43-2 1.18-2.78 1.66-1.17.74-1.36.92-1.26 1.25.1.33.58 1.59 1.16 3.07 1.25 3.32 1.63 4.73 1.53 6.22a5.65 5.65 0 0 1-1.63 3.69c-1.66 1.77-3.88 2.29-8.37 2-2.39-.17-4-.13-4 .13 0 0-.36 1.49-.79 3.22-.93 3.64-1.68 5.37-2.82 6.47-2.8 2.73-6.95 2.11-11.17-1.66-.88-.78-1.9-1.63-2.26-1.88l-.67-.46-.57.49c-.36.26-1.49 1.19-2.51 2.09-2.34 2-3.6 2.86-5.05 3.26a5.85 5.85 0 0 1-6.74-2.66 16.56 16.56 0 0 1-1.94-5.33c-.34-1.37-.69-2.71-.8-3l-.18-.49-3.91.13c-4.21.14-5 0-6.66-.75a5.52 5.52 0 0 1-3-3.7c-.53-1.83-.17-3.71 1.52-8 1.27-3.17 1.27-2.75-.51-3.9-3.32-2.12-4.07-2.65-5.12-3.65a10.732 10.732 0 0 1-1.59-2 4.26 4.26 0 0 1-.51-2.74 4.27 4.27 0 0 1 .47-2.57c1-1.82 2.36-3.06 5.67-5 1-.6 2-1.23 2.23-1.4l.4-.31-.33-1c-.19-.53-.7-1.88-1.15-3-1.87-4.68-1.79-7.11.31-9.36 1.66-1.77 3.94-2.31 8.36-2 1.1.105 2.206.135 3.31.09l.62-.12.48-2c1.1-4.49 1.88-6.37 3.24-7.72a5.7 5.7 0 0 1 3.75-1.69c2.14-.18 4.1.82 7.57 3.73 2.7 2.27 2.56 2.21 3.36 1.61.36-.27 1.1-.91 1.64-1.41a30.605 30.605 0 0 1 4.15-3.22c.3-.15.94-.43 1.42-.61a6.83 6.83 0 0 1 4.35.16 7.38 7.38 0 0 1 3.7 4.39c.22.58.67 2.15 1 3.48.33 1.33.68 2.63.78 2.89l.18.48 3.94-.13c3.4-.1 4.09-.08 5.09.18a5.79 5.79 0 0 1 4.46 4.17c.51 1.74.26 3.46-1 6.65-.41 1.06-.94 2.45-1.17 3.08l-.44 1.15.38.3c.2.17 1.38.93 2.6 1.69 2.59 1.6 4.55 3.39 5.3 4.83.476.801.68 1.734.58 2.66Z"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m37.068 29.512-.263-.075-4.798-1.363-3.022 1.862-1.198 5.443 4.64 4.347 4.954 5.264 9.292-6.566 1.258-5.908-.758-3.777-3.923-.371h-3.14L38 30.73l-.932-1.22Z"
          clipRule="evenodd"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M32.75 26.134c-3.937 0-6.75 2.642-6.75 7.142 0 3.076 1.933 6.215 6.6 10.394l.519.481 2.879 2.632a2.943 2.943 0 0 0 3.976.001l1.756-1.598 1.17-1.074c5.029-4.401 7.1-7.652 7.1-10.836 0-4.5-2.813-7.142-6.75-7.142-1.89 0-3.645.826-5.25 2.403-1.605-1.577-3.36-2.403-5.25-2.403Zm6.318 5.762c.042-.044.081-.09.118-.138 1.378-1.745 2.723-2.546 4.064-2.546 2.337 0 3.75 1.327 3.75 4.064 0 1.628-1.274 3.819-3.887 6.469l-.006-.006-.097.097-.537.518-1.25 1.167-3.236 2.958-3.004-2.747-1.373-1.28-.328-.313C30.402 37.31 29 34.984 29 33.276c0-2.737 1.413-4.064 3.75-4.064 1.341 0 2.686.801 4.064 2.546a1.526 1.526 0 0 0 .554.453 1.462 1.462 0 0 0 1.264 0c.16-.076.307-.18.436-.315Z"
          clipRule="evenodd"
        />
        <path
          fill="#fff"
          d="M38 61.846a25 25 0 1 1 0-50 25 25 0 0 1 0 50Zm0-47a22 22 0 1 0 0 43.998 22 22 0 0 0 0-43.998Z"
        />
      </g>
      <defs>
        <linearGradient
          id={idB}
          x1="30.675"
          x2="-42.003"
          y1="82.518"
          y2="45.224"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9656A1" />
          <stop offset="1" stopColor="#FF3939" />
        </linearGradient>
        <clipPath id={idA}>
          <path fill="#fff" d="M0 .845h75.25v72.54H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
