import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Portal } from 'react-portal';

import { useTheme } from '../../contexts/Theme';
import { useAbsolutePosition } from '../../hooks/useAbsolutePosition';
import { useClosable } from '../../hooks/useClosable';
import { Button } from '../Button';
import { Card, CardContent, CardList } from '../Card';
import { Checkbox } from '../Checkbox';
import { FocusTrap } from '../FocusTrap';
import { Radio } from '../Radio';
import { Stack } from '../Stack';
import { Text } from '../Text';
import { Caret } from './Caret';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { BaseProps, Option } from './types';

interface Props
  extends BaseProps,
    Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  id: string;
  onChange?(value: string | string[]): void;
  options: Option<string | JSX.Element>[];
}

export const CustomSelect: React.FC<Props> = ({
  alignMenu = 'left',
  disabled = false,
  id,
  label,
  multiple,
  name,
  onChange,
  options,
  ...props
}) => {
  const { zIndex } = useTheme();
  const ref = useRef(null);
  const optionsRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [changedOptions, setChangedOptions] = useState(options);
  const { reposition, style: position } = useAbsolutePosition({
    align: alignMenu,
    containerRef: optionsRef,
    offsetTop: 4,
    ref,
  });

  const availableOptions = options.filter((o) => !o.disabled);
  const selectedOptions = options.filter((o) => o.selected);
  const allSelected =
    multiple &&
    selectedOptions.length === options.length &&
    availableOptions.length > 0;
  const noneSelected = selectedOptions.length === 0;

  const close = () => {
    setIsOpen(false);
    setHasChanged(false);
  };

  useEffect(() => {
    if (!isOpen) {
      setChangedOptions(options);
    }
    if (isOpen) {
      reposition();
    }
  }, [options, isOpen]);

  const handleSelectionClick = () => {
    if (disabled) return;
    setIsOpen(true);
  };

  const handleOptionChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = ev.target;
    setHasChanged(true);
    setChangedOptions((co) =>
      co.map((o) =>
        o.value === value
          ? {
              ...o,
              selected: checked,
            }
          : multiple
          ? o
          : {
              ...o,
              selected: false,
            }
      )
    );
  };

  const handleClearClick = () => {
    onChange?.([]);
    close();
  };

  const handleApplyClick = () => {
    onChange?.(
      multiple
        ? changedOptions
            .filter((co) => co.selected && !co.disabled)
            .map((co) => co.value)
        : changedOptions.find((co) => co.selected).value
    );
    close();
  };

  useClosable(
    {
      onClose: close,
      ref: optionsRef,
    },
    [isOpen]
  );

  if (!multiple && options.filter((o) => o.selected).length > 1) {
    console.warn(
      `Setting more than 1 selected value on a non-multipe Select is not supported. Check <Select name="${name}" />`
    );
  }

  return (
    <div
      className={classNames({
        disabled,
        isOpen,
      })}
      {...props}
    >
      {selectedOptions.map((s) => (
        <input
          key={`${name}-val-${s.value}`}
          type="hidden"
          name={name}
          value={s.value}
        />
      ))}
      <Stack gap={4}>
        {label && (
          <Text El="label" id={`label-${id}`} variant="strong">
            {label}
          </Text>
        )}
        <div className="select">
          <button
            ref={ref}
            aria-controls={`list-${id}`}
            aria-expanded={isOpen}
            aria-haspopup="listbox"
            className="selection"
            id={id}
            onClick={handleSelectionClick}
            type="button"
          >
            <Stack align="center" justify="start" horizontal wrap>
              {allSelected || noneSelected ? (
                <Text color={disabled ? 'muted' : 'default'}>
                  {allSelected && 'All'}
                  {noneSelected && 'Select…'}
                </Text>
              ) : (
                selectedOptions.map((s) => (
                  <span key={s.value} className="option">
                    <Text color={disabled ? 'muted' : 'default'}>
                      {s?.selectedLabel || s.label}
                    </Text>
                  </span>
                ))
              )}
            </Stack>
          </button>
          <Caret disabled={disabled} />
        </div>
      </Stack>
      {isOpen && (
        <Portal>
          <div
            ref={optionsRef}
            aria-labelledby={`label-${id}`}
            aria-multiselectable={multiple}
            className={classNames('options', { isOpen })}
            id={`list-${id}`}
            role="listbox"
            style={position}
          >
            <FocusTrap
              arrowKeys="updown"
              searchByCharacter
              // only focus first element, when options element is positioned
              // to avoid undesired scrolling
              active={optionsRef.current?.style.top === `${position?.top}px`}
            >
              <Card
                style={{ background: 'var(--menu-bg)' }}
                noBorder
                shadow="strong"
              >
                <CardList>
                  {options.map((o, i) => (
                    <label
                      aria-disabled={o.disabled}
                      key={name + o.value}
                      htmlFor={name + o.value}
                    >
                      <Stack align="center" justify="space-between" horizontal>
                        <Text
                          color={o.disabled ? 'muted' : 'default'}
                          El="span"
                        >
                          {o.label}
                        </Text>
                        {multiple ? (
                          <Checkbox
                            aria-disabled={o.disabled}
                            aria-selected={changedOptions[i].selected}
                            checked={changedOptions[i].selected}
                            disabled={o.disabled}
                            id={name + o.value}
                            label=""
                            name={name}
                            onChange={handleOptionChange}
                            role="option"
                            value={o.value}
                          />
                        ) : (
                          <Radio
                            aria-disabled={o.disabled}
                            aria-selected={changedOptions[i].selected}
                            checked={changedOptions[i].selected}
                            disabled={o.disabled}
                            id={name + o.value}
                            label=""
                            name={name}
                            onChange={handleOptionChange}
                            role="option"
                            value={o.value}
                          />
                        )}
                      </Stack>
                    </label>
                  ))}
                </CardList>
                <CardContent style={{ padding: '8px 16px' }}>
                  <Stack
                    align="center"
                    justify={multiple ? 'space-between' : 'end'}
                    horizontal
                  >
                    {multiple && (
                      <Button
                        disabled={changedOptions.every((co) => !co.selected)}
                        onClick={handleClearClick}
                        variant="ghost"
                      >
                        <Text
                          El="span"
                          style={{
                            opacity: changedOptions.every((co) => !co.selected)
                              ? 0
                              : 1,
                          }}
                          underline
                        >
                          Clear
                        </Text>
                      </Button>
                    )}
                    <Button
                      disabled={!hasChanged}
                      onClick={handleApplyClick}
                      size="small"
                    >
                      Apply
                    </Button>
                  </Stack>
                </CardContent>
              </Card>
            </FocusTrap>
          </div>
        </Portal>
      )}
      <style jsx>{`
        .selection {
          background: var(--input-bg);
          border: 1px solid var(--input-border);
          border-radius: 8px;
          box-shadow: 0 0 0 0 var(--focus-shadow);
          cursor: pointer;
          display: block;
          padding: 6px 7px 7px;
          position: relative;
          transition: border 200ms ease, box-shadow 200ms ease;
          width: 100%;
        }
        .select {
          position: relative;
        }
        .disabled .selection {
          background: var(--input-disabled-bg);
          box-shadow: none !important;
          color: var(--input-disabled-color);
          cursor: default;
        }
        .selection:focus {
          outline: none;
        }
        .isOpen .selection,
        .selection:focus,
        .selection:hover {
          border-color: var(--focus-border);
          box-shadow: 0 0 0 2px var(--focus-shadow);
        }
        .selection .option {
          display: inline-block;
          white-space: nowrap;
        }
        .options {
          min-width: 176px;
          opacity: 0;
          transition: opacity 200ms ease;
        }
        .options {
          left: -99em;
          top: -99em;
        }
        .options.isOpen {
          opacity: 1;
          z-index: ${zIndex.menu};
        }
        .options label {
          display: block;
        }
        .options label:not([aria-disabled='true']) {
          cursor: pointer;
        }
        .options label:focus {
          outline: none;
        }
        :global(.with-mouse) .options label:not([aria-disabled='true']):hover,
        :global(.with-keyboard) .options label:focus-within {
          background-color: var(--menu-item-hover-bg);
        }
        .options label[aria-disabled='true'] {
          opacity: 0.4;
        }
      `}</style>
    </div>
  );
};
