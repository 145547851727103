import { EllipsisIcon } from '@daily/shared/components/Icons';
import { Menu, MenuButton } from '@daily/shared/components/Menu';
import { Text } from '@daily/shared/components/Text';
import {
  useDaily,
  useLocalParticipant,
  useParticipantIds,
} from '@daily-co/daily-react-hooks';
import { CALL_CUSTOM_EVENTS } from 'components/App/IframeListener';
import { useIframeDriver } from 'contexts/IframeDriverProvider';
import { RoomAdmin, useAdminsList } from 'contexts/UIState';
import { useState } from 'react';

export function AdminItem(admin: RoomAdmin) {
  const [, setAdminsList] = useAdminsList();
  const [menuOpened, setMenuOpened] = useState(false);
  const handleMenuClose = () => setMenuOpened(false);
  const { sendMessageToDriver } = useIframeDriver();
  const daily = useDaily();
  const localParticipant = useLocalParticipant();
  const removableSessionIds = useParticipantIds({
    filter: (p) => p.user_id === admin.userID,
  });

  const handleDemoteFromAdmin = () => {
    sendMessageToDriver({
      action: CALL_CUSTOM_EVENTS.ST_ADMIN_REMOVE_PARTICIPANT,
      receiverID: localParticipant.user_id,
      payload: {
        userID: admin.userID,
      },
    });

    // Remove locally, from "Admins" tab (visible only for Room creator)
    setAdminsList((admins) => admins.filter((a) => a.userID !== admin.userID));

    // Kick just removed admins, because they can bypass the "Demote admin modal".
    // We need setTimeout here, because when we kick users from daily, they won't be able
    // to listen to broadcasted events. So "Demoted from admin" modal will not appear.
    window.setTimeout(() => {
      removableSessionIds.forEach((id) =>
        daily.updateParticipant(id, { eject: true })
      );
    }, 250);

    handleMenuClose?.();
  };

  const menuItems: React.ComponentProps<typeof Menu>['items'] = [
    {
      label: admin.username,
    },
  ];

  // only show menu item, if it's not current user
  if (localParticipant.user_id !== admin.userID) {
    menuItems.push({
      label: <Text color="error">Demote from admin</Text>,
      onClick: handleDemoteFromAdmin,
    });
  }

  const admId = admin?.username?.toLowerCase().replace(/\s/g, '-');
  const btnId = `admin-btn-${admId}`;
  const menuId = `admin-menu-${admId}`;

  return (
    <>
      <div className="admin-item">
        <div className="admin-name">{admin.username}</div>
        <MenuButton
          aria-controls={menuId}
          focusable
          id={btnId}
          onClick={() => setMenuOpened(!menuOpened)}
          open={!menuOpened}
          variant="ghost"
        >
          <EllipsisIcon size={16} />
        </MenuButton>
        {menuOpened && (
          <Menu
            items={menuItems}
            align="right"
            aria-labelledby={btnId}
            id={menuId}
            onClose={handleMenuClose}
          />
        )}
      </div>
      <style jsx>
        {`
          .admin-item {
            margin-top: 6px;
            display: flex;
            align-items: center;
          }
          .admin-name {
            flex: 1;
          }
        `}
      </style>
    </>
  );
}

export function Admins() {
  const [admins] = useAdminsList();
  return (
    <>
      <div className="admin-list">
        {admins.map((admin) => (
          <AdminItem key={admin.username} {...admin} />
        ))}
      </div>
      <style jsx>
        {`
          .admin-list {
            padding: 16px;
          }
        `}
      </style>
    </>
  );
}
