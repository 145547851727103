import React from 'react';

export const MenuDivider: React.FC = () => (
  <div>
    <style jsx>{`
      div {
        border-top: 1px solid var(--card-border);
        margin: 8px 0;
      }
    `}</style>
  </div>
);
