import { Button } from '@daily/shared/components/Button';
import { Modal } from '@daily/shared/components/Modal';
import { Text } from '@daily/shared/components/Text';
import { useScreenShare } from '@daily-co/daily-react-hooks';
import { useTranslation } from 'react-i18next';

interface Props extends React.ComponentProps<typeof Modal> {}

export const StartScreenShareModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const { startScreenShare } = useScreenShare();

  const handleStartClick = (close) => {
    startScreenShare();
    close();
  };

  return (
    <Modal
      actions={[
        <Button key="start" onClick={handleStartClick} variant="primary">
          {t('screenShare.startSharing')}
        </Button>,
        <Button key="cancel" variant="secondary">
          {t('general.cancel')}
        </Button>,
      ]}
      title={t('screenShare.startScreenSharing')}
      {...props}
    >
      <Text variant="large">{t('screenShare.startModalDesc')}</Text>
    </Modal>
  );
};
