import { ScreenShare } from '@daily-co/daily-react-hooks';

import { MobileTile } from './MobileTile';

interface Props {
  screen: ScreenShare;
}

export const Screen: React.FC<Props> = ({ screen }) => (
  <MobileTile
    isScreen
    orientation="landscape"
    sessionId={screen.session_id}
    showNames={false}
  />
);
