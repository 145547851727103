import {
  CamIcon,
  ChevronDownIcon,
  WarningIcon,
} from '@daily/shared/components/Icons';
import { Menu, MenuButton } from '@daily/shared/components/Menu';
import { Radio } from '@daily/shared/components/Radio';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { Tooltip } from '@daily/shared/components/Tooltip';
import { VisuallyHidden } from '@daily/shared/components/VisuallyHidden';
import { useTheme } from '@daily/shared/contexts/Theme';
import { useMediaQuery } from '@daily/shared/hooks/useMediaQuery';
import {
  StatefulDevice,
  useDaily,
  useDevices,
  useLocalParticipant,
  useParticipantIds,
} from '@daily-co/daily-react-hooks';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCallState } from '../../contexts/CallProvider';
import { useMediaDevices } from '../../contexts/MediaDeviceProvider';
import {
  useDeviceInUseModal,
  useDeviceNotFoundModal,
  useIsMobile,
  useMobileTray,
  useSettingsView,
  useShowLocalVideo,
} from '../../contexts/UIState';
import { useCallConfig } from '../../hooks/useCallConfig';
import { robotsClassName } from '../../lib/robots';
import { TOOLTIP_DELAY } from './constant';
import { ToggleText } from './ToggleText';
import { TrayButton } from './TrayButton';

interface Props {
  shouldEnableVideoMenu?: boolean;
}

export const VideoControls: React.FC<Props> = memo(
  ({ shouldEnableVideoMenu = true }) => {
    const { colors, mediaQueries } = useTheme();
    const { t } = useTranslation();
    const daily = useDaily();
    const { state } = useCallState();
    const { enableVideoProcessingUI } = useCallConfig();
    const { promptForAccess } = useMediaDevices();
    const { cameras, camState, hasCamError, refreshDevices, setCamera } =
      useDevices();
    const localParticipant = useLocalParticipant();
    const remoteParticipants = useParticipantIds({ filter: 'remote' });

    const isDesktop = useMediaQuery(mediaQueries.large);
    const [, setShowDeviceInUseModal] = useDeviceInUseModal();
    const [, setShowDeviceNotFoundModal] = useDeviceNotFoundModal();
    const [isMobile] = useIsMobile();
    const [showMobileTray] = useMobileTray();
    const [, setSettingsView] = useSettingsView();
    const [showLocalVideo, setShowLocalVideo] = useShowLocalVideo();
    const [showCamsMenu, setShowCamsMenu] = useState(false);

    /**
     * Local muted state for optimistic updates when toggling the camera.
     * Enabling camera can take ~1 second until the participant state is updated.
     * Adding an optimistic local state update adds the expected UI feedback.
     */
    const [muted, setMuted] = useState(!localParticipant?.video);
    useEffect(() => {
      /**
       * Add a small delay before actually updating the local state.
       * When toggling the camera fast, participant-update events might be emitted subsequently.
       */
      const timeout = setTimeout(() => {
        setMuted(!localParticipant?.video);
      }, 100);
      return () => {
        clearTimeout(timeout);
      };
    }, [localParticipant?.video]);

    /**
     * Setup media session event handler and map muted state.
     */
    useEffect(() => {
      // @ts-ignore
      if (
        !daily ||
        typeof navigator.mediaSession?.setCameraActive !== 'function'
      )
        return;
      // @ts-ignore
      navigator.mediaSession.setCameraActive(!muted);
      const handleToggleCam = () => {
        if (hasCamError) return;
        daily.setLocalVideo(muted);
      };
      try {
        navigator.mediaSession.setActionHandler(
          'togglecamera',
          handleToggleCam
        );
      } catch (e) {}
    }, [daily, hasCamError, muted]);

    const toggleCamera = useCallback(() => {
      switch (camState) {
        case 'blocked':
          promptForAccess();
          break;
        case 'in-use':
          setShowDeviceInUseModal(true);
          break;
        case 'not-found':
          setShowDeviceNotFoundModal(true);
          break;
        default:
          daily.setLocalVideo(muted);
          setMuted(!muted);
          break;
      }
    }, [
      camState,
      daily,
      muted,
      promptForAccess,
      setShowDeviceInUseModal,
      setShowDeviceNotFoundModal,
    ]);

    const handleCamClick = async (cam: StatefulDevice) => {
      setShowCamsMenu(false);
      if (cam.selected) return;
      const lastSelected = cameras.find((c) => c.selected);
      try {
        // optimistic UI update
        setCamera(cam.device.deviceId);
      } catch {
        // reset to last known device in case switching failed
        setCamera(lastSelected.device.deviceId);
      }
    };

    const handleMenuOpen = () => {
      refreshDevices();
      setShowCamsMenu(true);
    };
    const handleMenuClose = () => {
      setShowCamsMenu(false);
    };

    const handleBackgroundEffectsClick = () => {
      setSettingsView('effects');
      setShowCamsMenu(false);
    };
    const handleSettingsClick = () => {
      setSettingsView('devices');
      setShowCamsMenu(false);
    };
    const handleToggleSelfView = () => {
      setShowLocalVideo((show) => !show);
      setShowCamsMenu(false);
    };

    const isSupported = camState !== 'not-supported';
    const mobileDisabled = isMobile && !showMobileTray;
    const isOnlyCamInUse =
      cameras.length === 1 && cameras[0].state === 'in-use';
    const enableVideoMenu =
      shouldEnableVideoMenu &&
      !isMobile &&
      isSupported &&
      camState !== 'pending';

    const renderAsMuted = muted || hasCamError;

    const btnText = useMemo(() => {
      switch (camState) {
        case 'pending':
          return <Text>{t('video.camera')}</Text>;
        case 'blocked':
          return <Text>{t('general.allow')}</Text>;
        case 'in-use':
          return <Text>{t('video.inUse')}</Text>;
        case 'not-found':
          return <Text>{t('video.noCamera')}</Text>;
        default:
          return (
            <ToggleText
              on={!renderAsMuted}
              onText={t('video.mute')}
              offText={t('video.unmute')}
            />
          );
      }
    }, [camState, renderAsMuted, t]);

    const isTooltipDisabled =
      // Dedicated mobile view
      isMobile ||
      // Responsive view on mobile-ish viewport size
      !isDesktop ||
      // Unsupported browser
      !isSupported ||
      // Pending device access
      camState === 'pending' ||
      // Error state
      hasCamError;
    const isButtonDisabled =
      // Unsupported browser
      !isSupported ||
      // Disabled on mobile (hidden tray)
      mobileDisabled ||
      // User's only cam is in use (toggling cam doesn't do anything)
      isOnlyCamInUse ||
      // Pending device access
      camState === 'pending';

    // When camera is mandatory for the rooms, we should look for the query params in url.
    // For instance, if ?video=forced, that it means that camera is required for the room.
    const router = useRouter();
    const isCameraRequired = router.query['video'] === 'forced';
    if (isCameraRequired) return null;

    return (
      <div
        className={classnames('video-controls', {
          muted: renderAsMuted,
          videoMenu: enableVideoMenu,
        })}
      >
        <Tooltip
          // delay={TOOLTIP_DELAY}
          // disabled={isTooltipDisabled}
          id="tt-video-controls"
          title="Camera"
          // title={`${ctrlKey} + E`}
          tabIndex={-1}
        >
          <TrayButton
            className={classnames('btn', {
              [robotsClassName('btn-cam-mute')]: !muted,
              [robotsClassName('btn-cam-unmute')]: muted,
            })}
            disabled={isButtonDisabled}
            onClick={toggleCamera}
          >
            <CamIcon
              className={classnames('icon', { disabled: isButtonDisabled })}
              color={renderAsMuted ? '#7B7170' : 'currentColor'}
              id="VideoControls"
              muted={renderAsMuted}
            />
            {/* {btnText} */}
          </TrayButton>
        </Tooltip>
        {enableVideoMenu && (
          <MenuButton
            aria-controls="camera-menu"
            className={robotsClassName('btn-cam-devices')}
            focusable={false}
            id="camera-menu-btn"
            onClick={handleMenuOpen}
            open={showCamsMenu}
            variant="ghost"
          >
            <ChevronDownIcon size={16} />
            <VisuallyHidden>{t('video.selectVideoDevices')}</VisuallyHidden>
          </MenuButton>
        )}
        {showCamsMenu && (
          <Menu
            align="left"
            aria-labelledby="camera-menu-btn"
            className={robotsClassName('cam-devices-menu')}
            edgeSpacing={16}
            id="camera-menu"
            items={[
              {
                label: (
                  <Stack align="center" gap={4} horizontal>
                    <CamIcon
                      id="video-menu-label"
                      size={16}
                      transition={false}
                    />
                    <Text
                      variant="strong"
                      style={{ display: 'block', margin: '-1px 0 1px' }}
                    >
                      {t('video.camera')}
                    </Text>
                  </Stack>
                ),
                static: true,
              },
              ...(!cameras.length
                ? [
                    {
                      label: t('video.unmuteToAllowAccess'),
                      static: true,
                    },
                  ]
                : []),
              ...cameras.map((cam, index) => ({
                className: robotsClassName('cam-device'),
                disabled: cam.state === 'in-use',
                icon: (
                  <Radio
                    id={cam.device.deviceId}
                    name={cam.device.deviceId}
                    checked={cam.selected}
                    readOnly
                    tabIndex={-1}
                  />
                ),
                label: (
                  <Stack horizontal>
                    {cam.state === 'in-use' && (
                      <WarningIcon color={colors.system.red} size={16} />
                    )}
                    {cam.device.label ||
                      `${t('video.camera_{number}', {
                        number: index + 1,
                      })}`}
                  </Stack>
                ),
                onClick: () => handleCamClick(cam),
              })),
              {
                divider: true,
              },
              ...(enableVideoProcessingUI && !isMobile
                ? [
                    {
                      disabled: !localParticipant?.video,
                      label: t('video.backgroundEffects'),
                      onClick: handleBackgroundEffectsClick,
                    },
                  ]
                : []),
              {
                label: t('video.settings'),
                onClick: handleSettingsClick,
              },
              ...(state === 'joined'
                ? [
                    {
                      disabled: remoteParticipants.length === 0,
                      label: showLocalVideo
                        ? t('people.hideSelfView')
                        : t('people.showSelfView'),
                      onClick: handleToggleSelfView,
                    },
                  ]
                : []),
            ]}
            offsetTop={4}
            onClose={handleMenuClose}
            placement="top"
            cardListProps={{
              style: {
                marginRight: 16,
                overflow: 'auto',
              },
            }}
          />
        )}
        <style jsx>{`
          .video-controls {
            position: relative;
            white-space: nowrap;
          }
          .video-controls :global(.btn[disabled]) {
            opacity: 0.4;
          }
          .video-controls :global(.icon.disabled) {
            opacity: calc(0.3 / 0.4);
          }
          .video-controls :global(.icon) {
            border-radius: 4px;
          }
          .video-controls :global(#camera-menu-btn svg path) {
            fill: #7b7170;
          }
          :global(.with-keyboard)
            .video-controls
            :global(#camera-menu-btn:focus svg path),
          .video-controls :global(#camera-menu-btn:hover svg path) {
            fill: white;
          }
          .video-controls :global(#camera-menu-btn) {
            position: absolute;
            padding: 1px;
            left: 0;
            top: -4px;
          }
        `}</style>
      </div>
    );
  }
);

VideoControls.displayName = 'VideoControls';
