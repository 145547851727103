import { useTheme } from '@daily/shared/contexts/Theme';
import { useDaily, useDevices } from '@daily-co/daily-react-hooks';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

import { useAudioLevel } from '../../hooks/useAudioLevel';

interface ImperativeHandle {
  stop(): void;
}

export const MicVolume = forwardRef<ImperativeHandle>(({}, ref) => {
  const { colors } = useTheme();
  const daily = useDaily();
  const { microphones } = useDevices();
  const [stream, setStream] = useState<MediaStream>(null);

  /**
   * Allow parent component to stop tracks, before switching to new microphone.
   * MicVolume depends on currentMic, which only changes after a successful call
   * to setInputDevicesAsync.
   */
  useImperativeHandle(
    ref,
    () => ({
      stop: () => {
        if (!stream) return;
        stream.getTracks().forEach((t) => t.stop());
      },
    }),
    [stream]
  );

  const currentMic = useMemo(
    () => microphones.find((m) => m.selected),
    [microphones]
  );

  useEffect(() => {
    if (!daily) return;
    const audioTrack: MediaStreamTrack =
      daily.participants().local.tracks.audio?.persistentTrack;
    if (!audioTrack) return;
    // Cloned track is not sent to other participants
    const track = audioTrack.clone();

    if (!track.enabled) {
      track.enabled = true;
    }

    setStream(new MediaStream([track]));

    return () => {
      track.stop();
    };
  }, [currentMic?.device?.deviceId, daily]);

  const volume = useAudioLevel(stream);

  const dotHeights = useMemo(
    () => [5, 11, 7].map((b) => 3 + b * 2 * (volume >= 0.1 ? volume : 0)),
    [volume]
  );

  return (
    <div>
      {dotHeights.map((height, i) => {
        const key = `dot-${i}`;
        return <span key={key} className="dot" style={{ height }} />;
      })}
      <style jsx>{`
        div {
          align-items: center;
          display: flex;
          height: 16px;
          justify-content: center;
          width: 16px;
        }
        div :global(.dot) {
          background: ${colors.accent};
          border-radius: 3px;
          margin: 0 1px;
          transition: height 0.15s ease;
          width: 3px;
        }
      `}</style>
    </div>
  );
});
MicVolume.displayName = 'MicVolume';
