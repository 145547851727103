import { DailyCallOptions } from '@daily-co/daily-js';
import IframeDriverMessageChannel from 'contexts/IframeDriverMessageChannel';
import { useCallback } from 'react';

import { useCallState } from '../../contexts/CallProvider';
import {
  useCustomTrayButtons,
  useMaxGridTilesPerPage,
  useMinGridTilesPerPage,
  usePreferredViewMode,
  useShowLocalVideo,
} from '../../contexts/UIState';
import { cloneParticipantsWithoutTracks } from '../../lib/participants';

export const useIframeMeetingLifecycleHandlers = (
  messageChannel: IframeDriverMessageChannel
) => {
  const {
    initializeCallArgs,
    setShowFullscreenButton,
    setShowLeaveButton,
    setShowParticipantsBar,
  } = useCallState();
  const [, setShowLocalVideo] = useShowLocalVideo();
  const [, setMaxGridTilesPerPage] = useMaxGridTilesPerPage();
  const [, setMinGridTilesPerPage] = useMinGridTilesPerPage();
  const [, setPreferredViewMode] = usePreferredViewMode();
  const [, setCustomTrayButtons] = useCustomTrayButtons();

  const handleDriverJoinMeetingMsg = useCallback(
    (msg: any) => {
      if (!msg.properties) return;

      const callArgs: DailyCallOptions = { ...msg.properties };
      if (callArgs.subscribeToTracksAutomatically !== undefined) {
        console.warn(
          'Prebuilt UI does not support subscribeToTracksAutomatically option; ignoring'
        );
        delete callArgs.subscribeToTracksAutomatically;
      }
      if (callArgs.layoutConfig) {
        if (callArgs.layoutConfig.grid) {
          if (Number.isInteger(callArgs.layoutConfig.grid.maxTilesPerPage)) {
            setMaxGridTilesPerPage(callArgs.layoutConfig.grid.maxTilesPerPage);
            delete callArgs.layoutConfig.grid.maxTilesPerPage;
          }
          if (Number.isInteger(callArgs.layoutConfig.grid.minTilesPerPage)) {
            setMinGridTilesPerPage(callArgs.layoutConfig.grid.minTilesPerPage);
            delete callArgs.layoutConfig.grid.minTilesPerPage;
          }
        }
      }
      if (callArgs.activeSpeakerMode !== undefined) {
        setPreferredViewMode(callArgs.activeSpeakerMode ? 'speaker' : 'grid');
        delete callArgs.activeSpeakerMode;
      }
      if (callArgs.customTrayButtons !== undefined) {
        setCustomTrayButtons(callArgs.customTrayButtons);
        delete callArgs.customTrayButtons;
      }
      if (callArgs.showParticipantsBar !== undefined) {
        setShowParticipantsBar(callArgs.showParticipantsBar);
        delete callArgs.showParticipantsBar;
      }
      if (callArgs.showLocalVideo !== undefined) {
        setShowLocalVideo(callArgs.showLocalVideo);
        delete callArgs.showLocalVideo;
      }
      if (callArgs.showLeaveButton !== undefined) {
        setShowLeaveButton(callArgs.showLeaveButton);
        delete callArgs.showLeaveButton;
      }
      if (callArgs.showFullscreenButton !== undefined) {
        setShowFullscreenButton(callArgs.showFullscreenButton);
        delete callArgs.showFullscreenButton;
      }
      initializeCallArgs(callArgs);

      // Swallow msg (don't forward to call machine): prebuilt UI knows to invoke
      // join() when appropriate. This avoids a multiple-join bug, and accounts
      // for actual join() occurring later in the prebuilt UI flow than when
      // the driver invokes it (i.e. when enable_prejoin_ui = true).
    },
    [
      initializeCallArgs,
      setCustomTrayButtons,
      setMaxGridTilesPerPage,
      setMinGridTilesPerPage,
      setPreferredViewMode,
      setShowFullscreenButton,
      setShowLeaveButton,
      setShowLocalVideo,
      setShowParticipantsBar,
    ]
  );

  const handleCallMachineJoinedMeetingMsg = useCallback(
    (msg: any) => {
      // Remove tracks, which can't be serialized and sent through the
      // message channel.
      const newMsg = { ...msg };
      if (msg.participants) {
        newMsg.participants = cloneParticipantsWithoutTracks(
          newMsg.participants
        );
      }
      messageChannel?.forwardPackagedMessageToDriver(newMsg);
    },
    [messageChannel]
  );

  return {
    handleDriverJoinMeetingMsg,
    handleCallMachineJoinedMeetingMsg,
  };
};
