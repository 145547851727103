import React from 'react';

import { useTheme } from '../../contexts/Theme';

export const DataList: React.FC = ({ children }) => {
  const { mediaQueries } = useTheme();
  return (
    <dl>
      {children}
      <style jsx>{`
        dl {
          display: grid;
          gap: 4px 8px;
          grid-template-columns: 1fr;
          grid-template-rows: 1fr;
          grid-template-areas: '.';
          margin: 0;
        }
        dl :global(dd) {
          margin: 0;
          overflow: hidden;
        }
        @media ${mediaQueries.medium} {
          dl {
            grid-template-columns: 150px 1fr;
            grid-template-areas: '. .';
          }
        }
        @media ${mediaQueries.large} {
          dl {
            grid-template-columns: 1fr;
            grid-template-areas: '.';
          }
        }
        @media ${mediaQueries.xlarge} {
          dl {
            grid-template-columns: 150px 1fr;
            grid-template-areas: '. .';
          }
        }
      `}</style>
    </dl>
  );
};
