import { useDaily, useDevices, useRoom } from '@daily-co/daily-react-hooks';
import Bowser from 'bowser';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import { isIOSMobile, isSafari } from '../lib/browserConfig';
import { useCallState } from './CallProvider';
import {
  useDeviceInUseModal,
  useDeviceNotFoundModal,
  useUnblockPermissionsModal,
} from './UIState';

interface ContextValue {
  promptForAccess(): void;
}

const noop = () => {};

const REREQUEST_INTERVAL = 2000;

const MediaDeviceContext = createContext<ContextValue>({
  promptForAccess: noop,
});

export const MediaDeviceProvider: React.FC = ({ children }) => {
  const daily = useDaily();
  const { disableAudio, mode, state } = useCallState();
  const room = useRoom();
  const [, setShowDeviceInUseModal] = useDeviceInUseModal();
  const [, setShowDeviceNotFoundModal] = useDeviceNotFoundModal();
  const [showUnblockPermissionsModal, setShowUnblockPermissionsModal] =
    useUnblockPermissionsModal();

  const { camState, micState, refreshDevices } = useDevices();

  /**
   * Clean up modals, when errors are resolved.
   */
  useEffect(() => {
    if (camState !== 'blocked' && micState !== 'blocked') {
      setShowUnblockPermissionsModal(false);
    }
    if (camState !== 'in-use' && micState !== 'in-use') {
      setShowDeviceInUseModal(false);
    }
    if (camState !== 'not-found' && micState !== 'not-found') {
      setShowDeviceNotFoundModal(false);
    }
  }, [
    camState,
    micState,
    setShowDeviceInUseModal,
    setShowDeviceNotFoundModal,
    setShowUnblockPermissionsModal,
  ]);

  /**
   * Automatically show modal when selected device is in use.
   */
  useEffect(() => {
    if (state !== 'joined' || camState !== 'in-use') return;
    setShowDeviceInUseModal(true);
  }, [camState, setShowDeviceInUseModal, state]);

  const promptForAccess = useCallback(() => {
    if (isSafari() || isIOSMobile()) {
      if (mode === 'direct-link') {
        if (showUnblockPermissionsModal) {
          window.location.reload();
        } else {
          setShowUnblockPermissionsModal(true);
        }
      } else {
        if (!isIOSMobile() || isSafari(14)) {
          let openModal = true;
          const openTimeout = setTimeout(() => {
            openModal = false;
          }, 1500);
          daily.once('camera-error', () => {
            if (openModal) {
              setShowUnblockPermissionsModal(true);
              clearTimeout(openTimeout);
            }
          });
        }
        daily.setLocalVideo(true);
        if (!disableAudio) daily.setLocalAudio(true);
      }
    } else {
      setShowUnblockPermissionsModal(true);
    }
  }, [
    daily,
    disableAudio,
    mode,
    setShowUnblockPermissionsModal,
    showUnblockPermissionsModal,
  ]);

  const browser = useMemo(() => Bowser.parse(navigator.userAgent), []);

  /**
   * Automatically re-request cam access.
   */
  useEffect(() => {
    if (!daily || !room) return;

    let interval;
    switch (camState) {
      case 'in-use':
        if (browser?.browser?.name === 'Firefox') return;
      case 'blocked':
        if (isSafari() || isIOSMobile()) return;
        interval = setInterval(() => {
          try {
            daily.setLocalVideo(true);
            if (!disableAudio) {
              daily.setLocalAudio(true);
              if (room?.config?.start_audio_off) {
                daily.setLocalAudio(false);
              }
            }
          } catch {}
        }, REREQUEST_INTERVAL);
        break;
      default:
        refreshDevices();
        break;
    }
    return () => {
      clearInterval(interval);
    };
  }, [browser, camState, daily, disableAudio, refreshDevices, room]);

  return (
    <MediaDeviceContext.Provider
      value={{
        promptForAccess,
      }}
    >
      {children}
    </MediaDeviceContext.Provider>
  );
};

export const useMediaDevices = () => useContext(MediaDeviceContext);
