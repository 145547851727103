import React, { useCallback, useEffect, useRef } from 'react';

interface Props {
  onChangePage(p: number): void;
  onSwipe(deltaX: number): void;
  page: number;
  pages: number;
}

export const SwipeContainer: React.FC<Props> = ({
  children,
  onChangePage,
  onSwipe,
  page,
  pages,
}) => {
  const swipeRef = useRef<HTMLDivElement>(null);
  const dragStartTime = useRef<Date>(null);

  const dragStart = useRef<number>(0);
  const dragDelta = useRef<number>(0);

  /**
   * Automatically switch to last page, e.g. when only participant on last page leaves.
   */
  useEffect(() => {
    if (page > pages) {
      onChangePage(pages);
    }
  }, [onChangePage, page, pages]);

  const preparePagesX = () => {
    const pages = Array.from(swipeRef.current?.childNodes).filter(
      (el: HTMLElement) => !el.classList.contains('hidden')
    );

    pages.forEach((el: HTMLElement) => {
      el.style.transitionDuration = '0ms';
    });
  };

  const updatePagesX = useCallback(
    (delta: number) => {
      const pages = Array.from(swipeRef.current?.childNodes).filter(
        (el: HTMLElement) => !el.classList.contains('hidden')
      );

      pages.forEach((el: HTMLElement) => {
        const elPage = parseInt(el.dataset['page'], 10);
        switch (elPage - page) {
          case 0:
            // same page
            el.style.transform = `translate3d(${delta}px, 0, 0)`;
            break;
          case 1:
            // next page
            el.style.transform = `translate3d(calc(100vw + ${delta}px), 0, 0)`;
            break;
          case -1:
            // prev page
            el.style.transform = `translate3d(calc(-100vw + ${delta}px), 0, 0)`;
            break;
        }
      });
    },
    [page]
  );

  const resetPagesX = useCallback(
    (delta: number) => {
      const pages = Array.from(swipeRef.current?.childNodes).filter(
        (el: HTMLElement) => !el.classList.contains('hidden')
      );

      pages.forEach((el: HTMLElement) => {
        el.style.transitionDuration = '';
      });
      updatePagesX(delta);
    },
    [updatePagesX]
  );

  const handleTouchStart = useCallback((ev: TouchEvent) => {
    if (ev.touches.length > 1) return;
    dragStartTime.current = new Date();
    dragStart.current = ev.touches[0].clientX;
    dragDelta.current = 0;
    preparePagesX();
  }, []);

  /**
   * Setup touchmove handler
   */
  const moveFrame = useRef(null);
  const handleTouchMove = useCallback(
    (ev: TouchEvent) => {
      if (pages === 1 || ev.touches.length > 1) return;
      if (moveFrame.current) cancelAnimationFrame(moveFrame.current);
      const pageWidth = swipeRef.current.clientWidth;
      const newDelta = ev.touches[0].clientX - dragStart.current;
      let newDragDelta;

      if (newDelta < 0) {
        const maxDrag = page === pages ? pageWidth / 6 : pageWidth;
        newDragDelta = Math.max(-maxDrag, newDelta);
      } else {
        const maxDrag = page === 1 ? pageWidth / 6 : pageWidth;
        newDragDelta = Math.min(maxDrag, newDelta);
      }
      if (Math.abs(newDragDelta) > 3) {
        ev.stopPropagation();
      }
      dragDelta.current = newDragDelta;

      moveFrame.current = requestAnimationFrame(() => {
        onSwipe?.(newDragDelta / pageWidth);
        updatePagesX(newDragDelta);
      });
    },
    [
      dragDelta,
      dragStart,
      moveFrame,
      onSwipe,
      page,
      pages,
      swipeRef,
      updatePagesX,
    ]
  );
  useEffect(() => {
    const swipe = swipeRef.current;
    if (!swipe) return;
    swipe.addEventListener('touchmove', handleTouchMove, { passive: true });
    return () => {
      if (moveFrame.current) cancelAnimationFrame(moveFrame.current);
      swipe.removeEventListener('touchmove', handleTouchMove);
    };
  }, [handleTouchMove]);

  useEffect(() => {
    const swipe = swipeRef.current;
    if (!swipe) return;

    const handleTouchEnd = (ev: TouchEvent) => {
      if (!dragStartTime.current) return;
      if (moveFrame.current) cancelAnimationFrame(moveFrame.current);

      const pageWidth = swipeRef.current.clientWidth;
      const time = new Date().getTime() - dragStartTime.current?.getTime();
      const speed = dragDelta.current / time;
      dragStartTime.current = null;
      dragStart.current = 0;

      const next =
        (dragDelta.current < -pageWidth / 2 || speed < -0.25) && page < pages;
      const prev =
        (dragDelta.current > pageWidth / 2 || speed > 0.25) && page > 1;

      if (next) {
        resetPagesX(-pageWidth);
        onChangePage(Math.min(pages, page + 1));
      } else if (prev) {
        resetPagesX(pageWidth);
        onChangePage(Math.max(1, page - 1));
      } else {
        resetPagesX(0);
        onSwipe?.(0);
      }
      if (Math.abs(dragDelta.current) > 3) {
        ev.stopPropagation();
      }
    };

    swipe.addEventListener('touchstart', handleTouchStart, { passive: true });
    swipe.addEventListener('touchend', handleTouchEnd, { passive: true });

    return () => {
      swipe.removeEventListener('touchstart', handleTouchStart);
      swipe.removeEventListener('touchend', handleTouchEnd);
    };
  }, [
    handleTouchStart,
    onChangePage,
    onSwipe,
    page,
    pages,
    resetPagesX,
    swipeRef,
    updatePagesX,
  ]);

  return (
    <div className="swipe" ref={swipeRef}>
      {children}
      <style jsx>{`
        .swipe {
          align-items: center;
          display: flex;
          flex-direction: row;
          height: 100%;
          justify-content: center;
          overflow: hidden;
          position: relative;
        }
      `}</style>
    </div>
  );
};
