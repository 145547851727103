import { Text } from '@daily/shared/components/Text';
import { useParticipantIds } from '@daily-co/daily-react-hooks';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCallConfig } from '../../hooks/useCallConfig';
import { useParticipantCounts } from '../../hooks/useParticipantCounts';

export const PeopleInCall: React.FC = () => {
  const { t } = useTranslation();
  const { broadcast, enableImplicitPassiveParticipant } = useCallConfig();

  const { hidden: passiveCount, present } = useParticipantCounts();
  const fullCount = present + passiveCount;
  const ownerIds = useParticipantIds({ filter: 'owner' });

  const router = useRouter();
  const roomType =
    router.query['roomType'] === 'official' ? 'official' : 'custom';
  const maxParticipants = router?.query['maxParticipants'] || 0;

  /**
   * The displayed count depends on
   * - wether or not the call is an owner_only_broadcast
   * - the room is configured to have passive participants
   * In broadcast calls we'll show the amount of viewers, which is either
   * - the hidden count, when passive participants are counted
   * - the amount of non owners (fullCount minus amount of owners)
   * In regular calls we'll display the full amount participants.
   */
  const count = useMemo(() => {
    // In Broadcast calls, we're only interested in the amount of watchers.
    if (broadcast) {
      return enableImplicitPassiveParticipant
        ? passiveCount
        : fullCount - ownerIds.length;
    }
    return fullCount;
  }, [
    broadcast,
    enableImplicitPassiveParticipant,
    fullCount,
    ownerIds.length,
    passiveCount,
  ]);

  const text = useMemo(() => {
    if (broadcast) return t('people.watchingWithAmount', { amount: count });
    if (count <= 1) return t('people.waitingForOtherParticipants');

    if (maxParticipants && roomType !== 'official') {
      return t('people.withCountCustomRoom', { count, max: maxParticipants });
    }

    return t('people.withCount', { count });
  }, [broadcast, count, maxParticipants, roomType, t]);

  return <Text>🚀 {text}</Text>;
};
