import classnames from 'classnames';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import tinykeys from 'tinykeys';

import { useTheme } from '../../contexts/Theme';
import { Stack } from '../Stack';
import { TabsContext } from './Tabs';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  gap?: number;
}

export const TabList: React.FC<Props> = ({
  children,
  className,
  gap = 16,
  ...props
}) => {
  const { colors } = useTheme();
  const { activeTab, id, vertical } = useContext(TabsContext);

  const tablistRef = useRef<HTMLDivElement>(null);
  const indicatorRef = useRef<HTMLSpanElement>(null);

  const getTabs = useCallback(
    () =>
      Array.from(
        document.querySelectorAll(`[id^="tab-${id}-"]`)
      ) as HTMLElement[],
    [id]
  );

  /**
   * Setup keyboard controls:
   * - ArrowLeft moves focus to previous tab
   * - ArrowRight moves focus to next tab
   * - Home moves focus to first tab
   * - End moves focus to last tab
   * https://www.w3.org/TR/wai-aria-practices/examples/tabs/tabs-2/tabs.html
   */
  useEffect(() => {
    if (!tablistRef.current) return;

    const focusPrev = () => {
      const tabs = getTabs();
      const currentTabIdx = tabs.findIndex((tab) =>
        tab.isEqualNode(document.activeElement)
      );
      const prevTab =
        currentTabIdx - 1 < 0 ? tabs.length - 1 : currentTabIdx - 1;
      tabs[prevTab].focus();
    };

    const focusNext = () => {
      const tabs = getTabs();
      const currentTabIdx = tabs.findIndex((tab) =>
        tab.isEqualNode(document.activeElement)
      );
      const nextTab = currentTabIdx + 1 >= tabs.length ? 0 : currentTabIdx + 1;
      tabs[nextTab].focus();
    };

    const unsubscribe = tinykeys(tablistRef.current, {
      ArrowLeft: () => {
        if (!tablistRef.current.contains(document.activeElement) || vertical)
          return;
        focusPrev();
      },
      ArrowRight: () => {
        if (!tablistRef.current.contains(document.activeElement) || vertical)
          return;
        focusNext();
      },
      ArrowUp: (ev) => {
        if (!tablistRef.current.contains(document.activeElement) || !vertical)
          return;
        ev.preventDefault();
        focusPrev();
      },
      ArrowDown: (ev) => {
        if (!tablistRef.current.contains(document.activeElement) || !vertical)
          return;
        ev.preventDefault();
        focusNext();
      },
      End: (ev) => {
        if (!tablistRef.current.contains(document.activeElement)) return;
        ev.preventDefault();
        const tabs = getTabs();
        tabs[tabs.length - 1].focus();
      },
      Home: (ev) => {
        if (!tablistRef.current.contains(document.activeElement)) return;
        ev.preventDefault();
        const tabs = getTabs();
        tabs[0].focus();
      },
    });
    return () => {
      unsubscribe();
    };
  }, [vertical]);

  const repositionIndicator = useCallback(() => {
    if (vertical) return;
    const tabEl = document.getElementById(`tab-${id}-${activeTab}`);
    if (!tabEl || !indicatorRef.current) return;
    const left = tabEl.offsetLeft;

    indicatorRef.current.style.transform = `translateX(${left}px)`;
    indicatorRef.current.style.width = `${tabEl.clientWidth}px`;
  }, [activeTab, vertical]);

  useEffect(() => {
    if (vertical) return;
    let timeout: NodeJS.Timeout;
    const tabEl = document.getElementById(`tab-${id}-${activeTab}`);
    if (!tabEl) return;
    window.addEventListener('resize', repositionIndicator);
    if ('fonts' in document) {
      // wait for fonts to be loaded, otherwise position is wrong
      (document as any).fonts.ready.then(() => {
        repositionIndicator();
      });
    } else {
      // fallback to simply timeout if fonts API is not supported
      timeout = setTimeout(repositionIndicator, 300);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
      window.removeEventListener('resize', repositionIndicator);
    };
  }, [activeTab, children, vertical]);

  return (
    <div
      ref={tablistRef}
      className={classnames('tablist', className, {
        'tablist-vertical': vertical,
      })}
      role="tablist"
      aria-orientation={vertical ? 'vertical' : 'horizontal'}
      {...props}
    >
      <Stack
        align={vertical ? 'stretch' : 'center'}
        gap={gap}
        horizontal={!vertical}
      >
        {React.Children.map(children, (tab: JSX.Element, i) =>
          tab
            ? React.cloneElement(tab, {
                index: i,
              })
            : null
        )}
      </Stack>
      {!vertical && (
        <span ref={indicatorRef} role="presentation" className="indicator" />
      )}
      <style jsx>
        {`
          .tablist {
            padding-bottom: 8px;
            position: relative;
          }
          .tablist-vertical {
            padding: 0;
          }
          .indicator {
            background: ${colors.accent};
            border-radius: 4px;
            bottom: 0;
            height: 2px;
            opacity: 0;
            position: absolute;
          }
          .indicator[style] {
            opacity: 1;
            transition: opacity 200ms ease 200ms, width 200ms ease,
              transform 200ms ease;
          }
        `}
      </style>
    </div>
  );
};
