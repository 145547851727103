import {
  AudioIcon,
  CaretDownIcon,
  ChevronDownIcon,
  MicrophoneIcon,
  WarningIcon,
} from '@daily/shared/components/Icons';
import { Menu, MenuButton } from '@daily/shared/components/Menu';
import { Radio } from '@daily/shared/components/Radio';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { Tooltip } from '@daily/shared/components/Tooltip';
import { VisuallyHidden } from '@daily/shared/components/VisuallyHidden';
import { useTheme } from '@daily/shared/contexts/Theme';
import { useMediaQuery } from '@daily/shared/hooks/useMediaQuery';
import {
  StatefulDevice,
  useDaily,
  useDevices,
  useLocalParticipant,
} from '@daily-co/daily-react-hooks';
import classnames from 'classnames';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tinykeys from 'tinykeys';

import { useMediaDevices } from '../../contexts/MediaDeviceProvider';
import {
  useDeviceInUseModal,
  useDeviceNotFoundModal,
  useIsMobile,
  useMobileTray,
  useSettingsView,
} from '../../contexts/UIState';
import { robotsClassName } from '../../lib/robots';
import { TOOLTIP_DELAY } from './constant';
import { ToggleText } from './ToggleText';
import { TrayButton } from './TrayButton';

interface Props {
  shouldEnableAudioMenu?: boolean;
}

export const AudioControls: React.FC<Props> = memo(
  ({ shouldEnableAudioMenu = true }) => {
    const { colors, mediaQueries } = useTheme();
    const { t } = useTranslation();
    const [, setShowDeviceInUseModal] = useDeviceInUseModal();
    const [, setShowDeviceNotFoundModal] = useDeviceNotFoundModal();
    const [isMobile] = useIsMobile();
    const [showMobileTray] = useMobileTray();
    const [, setSettingsView] = useSettingsView();
    const { promptForAccess } = useMediaDevices();
    const {
      hasMicError,
      microphones,
      micState,
      refreshDevices,
      setMicrophone,
      setSpeaker,
      speakers,
    } = useDevices();
    const daily = useDaily();
    const localParticipant = useLocalParticipant();
    const muted = useMemo(
      () => !localParticipant?.audio || hasMicError,
      [hasMicError, localParticipant?.audio]
    );

    const isDesktop = useMediaQuery(mediaQueries.large);

    const [showAudioMenu, setShowAudioMenu] = useState(false);

    /**
     * Setup media session event handler and map muted state.
     */
    useEffect(() => {
      if (
        !daily ||
        // @ts-ignore
        typeof navigator.mediaSession?.setMicrophoneActive !== 'function'
      )
        return;
      // @ts-ignore
      navigator.mediaSession.setMicrophoneActive(!muted);
      const handleToggleMic = () => {
        if (hasMicError) return;
        daily.setLocalAudio(muted);
      };
      try {
        navigator.mediaSession.setActionHandler(
          'togglemicrophone',
          handleToggleMic
        );
      } catch (e) {}
    }, [daily, hasMicError, muted]);

    const toggleMic = useCallback(() => {
      switch (micState) {
        case 'blocked':
          promptForAccess();
          break;
        case 'in-use':
          setShowDeviceInUseModal(true);
          break;
        case 'not-found':
          setShowDeviceNotFoundModal(true);
          break;
        default:
          daily.setLocalAudio(muted);
          break;
      }
    }, [
      daily,
      micState,
      muted,
      promptForAccess,
      setShowDeviceInUseModal,
      setShowDeviceNotFoundModal,
    ]);

    const ctrlKey =
      process.browser && navigator.platform.startsWith('Mac') ? 'Cmd' : 'Ctrl';

    useEffect(() => {
      const unsubscribe = tinykeys(window, {
        '$mod+KeyD': (ev) => {
          toggleMic();
          ev.preventDefault();
        },
      });
      return () => {
        unsubscribe();
      };
    }, [toggleMic]);

    const handleMicClick = useCallback(
      async (mic: StatefulDevice) => {
        setShowAudioMenu(false);
        if (mic.selected) return;
        const lastMic = microphones.find((m) => m.selected);
        try {
          // optimistic UI update
          setMicrophone?.(mic.device.deviceId);
        } catch {
          setMicrophone?.(lastMic.device.deviceId);
          // reset to last known device in case switching failed
        }
      },
      [microphones, setMicrophone]
    );

    const handleSpeakerClick = useCallback(
      async (speaker: StatefulDevice) => {
        setShowAudioMenu(false);
        if (speaker.selected) return;
        setSpeaker?.(speaker.device.deviceId);
      },
      [setSpeaker]
    );

    const handleMenuOpen = () => {
      refreshDevices();
      setShowAudioMenu(true);
    };
    const handleMenuClose = () => {
      setShowAudioMenu(false);
    };

    const handleSettingsClick = useCallback(() => {
      setSettingsView('devices');
      setShowAudioMenu(false);
    }, [setSettingsView]);

    const isSupported = micState !== 'not-supported';
    const mobileDisabled = isMobile && !showMobileTray;
    const enableAudioMenu =
      shouldEnableAudioMenu &&
      !isMobile &&
      isSupported &&
      micState !== 'pending';

    const btnText = useMemo(() => {
      switch (micState) {
        case 'pending':
          return <Text>{t('audio.microphone')}</Text>;
        case 'blocked':
          return <Text>{t('general.allow')}</Text>;
        case 'in-use':
          return <Text>{t('audio.inUse')}</Text>;
        case 'not-found':
          return <Text>{t('audio.noMic')}</Text>;
        default:
          return (
            <ToggleText
              on={!muted}
              onText={t('audio.mute')}
              offText={t('audio.unmute')}
            />
          );
      }
    }, [micState, muted, t]);

    const menuItems = useMemo(() => {
      const items: React.ComponentProps<typeof Menu>['items'] = [
        {
          label: (
            <Stack align="center" gap={4} horizontal>
              <MicrophoneIcon
                id="audio-menu-label"
                size={16}
                transition={false}
              />
              <Text
                variant="strong"
                style={{ display: 'block', margin: '-1px 0 1px' }}
              >
                {t('audio.microphone')}
              </Text>
            </Stack>
          ),
          static: true,
        },
      ];

      if (micState === 'not-found') {
        items.push({
          icon: <WarningIcon color={colors.system.red} size={16} />,
          label: t('audio.noMicrophone'),
          onClick: () => {
            toggleMic();
            setShowAudioMenu(false);
          },
        });
      } else {
        microphones.length
          ? items.push(
              ...microphones.map((mic, index) => ({
                className: robotsClassName('mic-device'),
                disabled: mic.state === 'in-use',
                icon: (
                  <Radio
                    id={mic.device.deviceId}
                    name={mic.device.deviceId}
                    checked={mic.selected}
                    readOnly
                    tabIndex={-1}
                  />
                ),
                label: (
                  <Stack horizontal>
                    {mic.state === 'in-use' && (
                      <WarningIcon color={colors.system.red} size={16} />
                    )}
                    {mic.device.label ||
                      `${t('audio.microphone_{number}', {
                        number: index + 1,
                      })}`}
                  </Stack>
                ),
                onClick: () => handleMicClick(mic),
              }))
            )
          : items.push({
              label: t('audio.unmuteToAllowAccess'),
              static: true,
            });
      }

      items.push(
        { divider: true },
        {
          label: (
            <Stack align="center" gap={4} horizontal>
              <AudioIcon size={16} />
              <Text
                variant="strong"
                style={{ display: 'block', margin: '-1px 0 1px' }}
              >
                {t('audio.speakers')}
              </Text>
            </Stack>
          ),
          static: true,
        }
      );

      if (speakers.length > 0) {
        items.push(
          ...speakers.map((speaker, index) => ({
            className: robotsClassName('speaker-device'),
            icon: (
              <Radio
                id={speaker.device.deviceId}
                name={speaker.device.deviceId}
                checked={speaker.selected}
                readOnly
                tabIndex={-1}
              />
            ),
            label:
              speaker.device.label ||
              `${t('audio.speakers_{number}', {
                number: index + 1,
              })}`,
            onClick: () => handleSpeakerClick(speaker),
          }))
        );
      } else {
        items.push({
          className: robotsClassName('speaker-device'),
          icon: (
            <Radio
              id="system-default"
              name="system-default"
              checked
              readOnly
              tabIndex={-1}
            />
          ),
          label: t('audio.systemDefault'),
          onClick: () => setShowAudioMenu(false),
        });
      }

      items.push(
        {
          divider: true,
        },
        {
          label: t('audio.settings'),
          onClick: handleSettingsClick,
        }
      );

      return items;
    }, [
      colors.system.red,
      handleMicClick,
      handleSettingsClick,
      handleSpeakerClick,
      micState,
      microphones,
      speakers,
      t,
      toggleMic,
    ]);

    const isTooltipDisabled =
      // Dedicated mobile view
      isMobile ||
      // Responsive view on mobile-ish viewport size
      !isDesktop ||
      // Unsupported browser
      !isSupported ||
      // Pending device access
      micState === 'pending' ||
      // Error state
      hasMicError;
    const isButtonDisabled =
      // Unsupported browser
      !isSupported ||
      // Disabled on mobile (hidden tray)
      mobileDisabled ||
      // Pending device access
      micState === 'pending';

    return (
      <div
        className={classnames('audio-controls', {
          muted,
          audioMenu: shouldEnableAudioMenu,
        })}
      >
        <Tooltip
          // delay={TOOLTIP_DELAY}
          // disabled={isTooltipDisabled}
          id="tt-audio-controls"
          title="Microphone"
          // title={`${ctrlKey} + D`}
          tabIndex={-1}
        >
          <TrayButton
            className={classnames('btn', {
              [robotsClassName('btn-mic-mute')]: !muted,
              [robotsClassName('btn-mic-unmute')]: muted,
            })}
            disabled={isButtonDisabled}
            onClick={toggleMic}
          >
            <MicrophoneIcon
              className={classnames('icon', { disabled: isButtonDisabled })}
              color={muted ? '#7B7170' : 'currentColor'}
              id="AudioControls"
              muted={muted}
            />
            {/* {btnText} */}
          </TrayButton>
        </Tooltip>
        {enableAudioMenu && (
          <MenuButton
            aria-controls="audio-menu"
            className={robotsClassName('btn-audio-devices')}
            id="audio-menu-btn"
            onClick={handleMenuOpen}
            open={showAudioMenu}
            variant="ghost"
          >
            <ChevronDownIcon size={16} />
            <VisuallyHidden>{t('audio.selectAudioDevices')}</VisuallyHidden>
          </MenuButton>
        )}
        {showAudioMenu && (
          <Menu
            align="left"
            aria-labelledby="audio-menu-btn"
            className={robotsClassName('audio-devices-menu')}
            edgeSpacing={16}
            id="audio-menu"
            items={menuItems}
            offsetTop={4}
            onClose={handleMenuClose}
            placement="top"
            cardListProps={{
              style: {
                marginRight: 16,
                overflow: 'auto',
              },
            }}
          />
        )}
        <style jsx>{`
          .audio-controls {
            position: relative;
            white-space: nowrap;
          }
          .audio-controls :global(.btn[disabled]) {
            opacity: 0.4;
          }
          .audio-controls :global(.icon.disabled) {
            opacity: calc(0.3 / 0.4);
          }
          .audio-controls :global(.icon) {
            border-radius: 4px;
          }
          .audio-controls :global(#audio-menu-btn svg path) {
            fill: #7b7170;
          }
          :global(.with-keyboard)
            .audio-controls
            :global(#audio-menu-btn:focus svg path),
          .audio-controls :global(#audio-menu-btn:hover svg path) {
            fill: white;
          }
          .audio-controls :global(#audio-menu-btn) {
            position: absolute;
            padding: 1px;
            left: 0;
            top: -4px;
          }
        `}</style>
      </div>
    );
  }
);

AudioControls.displayName = 'AudioControls';
