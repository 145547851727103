import { Button } from '@daily/shared/components/Button';
import {
  EllipsisIcon,
  HiddenIcon,
  MicrophoneIcon,
} from '@daily/shared/components/Icons';
import { Menu, MenuButton } from '@daily/shared/components/Menu';
import { useTheme } from '@daily/shared/contexts/Theme';
import { hexToRgba } from '@daily/shared/lib/colors';
import { useLocalParticipant } from '@daily-co/daily-react-hooks';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useIsMobile,
  useMuteAllModal,
  useShowLocalVideo,
} from '../../contexts/UIState';

export const PeopleActions: React.FC = () => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const [showLocalVideo, setShowLocalVideo] = useShowLocalVideo();
  const localParticipant = useLocalParticipant();
  const [isMobile] = useIsMobile();
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const [, setShowMuteAllModal] = useMuteAllModal();

  const handleMoreOptionsClick = () => {
    setShowMoreMenu(true);
  };
  const handleMoreOptionsClose = () => {
    setShowMoreMenu(false);
  };
  const handleMuteAllClick = useCallback(() => {
    setShowMoreMenu(false);
    setShowMuteAllModal(true);
  }, [setShowMuteAllModal]);

  const handleShowSelfViewClick = useCallback(() => {
    setShowMoreMenu(false);
    setShowLocalVideo(true);
  }, [setShowLocalVideo]);

  const menuItems = useMemo(() => {
    const items: React.ComponentProps<typeof Menu>['items'] = [];
    if (!showLocalVideo) {
      items.push({
        label: t('people.showSelfView'),
        icon: <HiddenIcon size={16} />,
        onClick: handleShowSelfViewClick,
      });
    }
    if (localParticipant?.owner) {
      items.push({
        label: t('people.muteAll'),
        icon: <MicrophoneIcon id="mute-all" size={16} transition={false} />,
        onClick: handleMuteAllClick,
      });
    }
    return items;
  }, [
    handleMuteAllClick,
    handleShowSelfViewClick,
    localParticipant?.owner,
    showLocalVideo,
    t,
  ]);

  if (!localParticipant?.owner && showLocalVideo) return null;

  return (
    <div className="people-actions">
      {menuItems.length < 2 ? (
        // At most 1 action possible: render it directly.
        <>
          {!showLocalVideo && (
            <Button
              onClick={handleShowSelfViewClick}
              size="small"
              variant="secondary"
            >
              {t('people.showSelfView')}
            </Button>
          )}
          {localParticipant?.owner && (
            <Button
              onClick={handleMuteAllClick}
              size="small"
              variant="secondary"
            >
              {t('people.muteAll')}
            </Button>
          )}
        </>
      ) : (
        // Multiple actions possible. Wrap them in a More… menu.
        <>
          <MenuButton
            aria-controls="more-options-menu"
            iconAfter={<EllipsisIcon size={16} />}
            id="more-options-btn"
            open={showMoreMenu}
            onClick={handleMoreOptionsClick}
            size={isMobile ? 'medium' : 'small'}
            variant="secondary"
          >
            {t('general.more')}
          </MenuButton>
          {showMoreMenu && (
            <Menu
              align="right"
              aria-labelledby="more-options-btn"
              id="more-options-menu"
              items={menuItems}
              onClose={handleMoreOptionsClose}
              placement="top"
            />
          )}
        </>
      )}
      <style jsx>{`
        .people-actions {
          bottom: 0;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          left: 0;
          margin-top: auto;
          padding: 0;
          position: sticky;
          width: 100%;
        }
        .people-actions::before {
          background: linear-gradient(
            to top,
            ${colors.background},
            ${hexToRgba(colors.background, 0)}
          );
          bottom: -16px;
          content: '';
          left: -16px;
          pointer-events: none;
          position: absolute;
          right: -16px;
          top: -16px;
        }
      `}</style>
    </div>
  );
};
