import {
  AudioIcon,
  CamIcon,
  MicrophoneIcon,
} from '@daily/shared/components/Icons';
import { Select } from '@daily/shared/components/Select';
import { Stack, StackItem } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { useDevices } from '@daily-co/daily-react-hooks';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useCallState } from '../../contexts/CallProvider';
import { useCallConfig } from '../../hooks/useCallConfig';
import { robotsClassName } from '../../lib/robots';
import { MicVolume } from './MicVolume';
import { TestSound } from './TestSound';

interface Props {
  shouldShowMicVolume: boolean;
}

export const AudioVideoSettings: React.FC<Props> = ({
  shouldShowMicVolume,
}) => {
  const { t } = useTranslation();
  const { disableAudio } = useCallState();
  const { broadcastRole } = useCallConfig();
  const {
    cameras,
    camState,
    microphones,
    micState,
    setCamera,
    setMicrophone,
    setSpeaker,
    speakers,
  } = useDevices();

  /**
   * Ref for imperative handle inside MicVolume component.
   */
  const micVolumeRef = useRef(null);

  const isAttendee = useMemo(
    () => broadcastRole === 'attendee',
    [broadcastRole]
  );

  const handleCamChange = (id: string) => {
    setCamera(id);
  };
  const handleMicChange = (id: string) => {
    // Stop cloned track for MicVolume detection before requesting new device.
    // Without stopping there'll be concurrent mic processes (crashing in Firefox).
    micVolumeRef.current?.stop();
    setMicrophone(id);
  };
  const handleSpeakerChange = (id: string) => {
    setSpeaker(id);
  };

  const camOptions: React.ComponentProps<typeof Select>['options'] =
    useMemo(() => {
      switch (camState) {
        case 'blocked':
          return [
            {
              label: t('video.cameraBlocked'),
              value: null,
            },
          ];
        case 'not-found':
          return [
            {
              label: t('video.noCamera'),
              value: null,
            },
          ];
        default:
          if (!cameras.length) {
            return [
              {
                label: t('video.unmuteToAllowAccess'),
                value: null,
              },
            ];
          }
          return cameras.map((cam, index) => ({
            label:
              cam.state === 'in-use'
                ? `⚠️ ${
                    cam.device.label ||
                    `${t('video.camera_{number}', {
                      number: index + 1,
                    })}`
                  }`
                : cam.device.label ||
                  `${t('video.camera_{number}', {
                    number: index + 1,
                  })}`,
            selected: cam.selected,
            value: cam.device.deviceId,
          }));
      }
    }, [camState, cameras, t]);

  const micOptions: React.ComponentProps<typeof Select>['options'] =
    useMemo(() => {
      switch (micState) {
        case 'blocked':
          return [
            {
              label: t('audio.microphoneBlocked'),
              value: null,
            },
          ];
        case 'not-found':
          return [
            {
              label: t('audio.noMicrophone'),
              value: null,
            },
          ];
        default:
          if (!microphones.length) {
            return [
              {
                label: t('audio.unmuteToAllowAccess'),
                value: null,
              },
            ];
          }
          return microphones.map((mic, index) => ({
            label:
              mic.state === 'in-use'
                ? `⚠️ ${
                    mic.device.label ||
                    `${t('audio.microphone_{number}', {
                      number: index + 1,
                    })}`
                  }`
                : mic.device.label ||
                  `${t('audio.microphone_{number}', {
                    number: index + 1,
                  })}`,
            selected: mic.selected,
            value: mic.device.deviceId,
          }));
      }
    }, [micState, microphones, t]);

  return (
    <div className="audio-video-settings">
      <Stack gap={16}>
        {!isAttendee && (
          <Stack gap={4}>
            <Stack align="center" gap={4} horizontal>
              <CamIcon id="cam-settings-label" size={16} transition={false} />
              <Text
                El="label"
                className="label"
                htmlFor="settings-camera"
                variant="strong"
              >
                {t('video.camera')}
              </Text>
            </Stack>
            <Select
              className={robotsClassName('select-video-devices')}
              disabled={!cameras.length}
              id="settings-camera"
              name="settings-camera"
              native
              onChange={handleCamChange}
              options={camOptions}
            />
          </Stack>
        )}
        {!disableAudio && !isAttendee && (
          <Stack gap={4}>
            <Stack align="center" gap={4} horizontal>
              <MicrophoneIcon
                id="mic-settings-label"
                size={16}
                transition={false}
              />
              <Text
                El="label"
                className="label"
                htmlFor="settings-microphone"
                variant="strong"
              >
                {t('audio.microphone')}
              </Text>
              {micState === 'granted' &&
                microphones.length > 0 &&
                shouldShowMicVolume && <MicVolume ref={micVolumeRef} />}
            </Stack>
            <Select
              className={robotsClassName('select-audio-devices')}
              disabled={!microphones.length}
              id="settings-microphone"
              name="settings-microphone"
              native
              onChange={handleMicChange}
              options={micOptions}
            />
          </Stack>
        )}
        {!disableAudio && (
          <Stack gap={4}>
            <Stack gap={4} horizontal>
              <AudioIcon id="speaker-settings-label" size={16} />
              <Text
                El="label"
                className="label"
                htmlFor="settings-speakers"
                variant="strong"
              >
                {t('audio.speakers')}
              </Text>
              <StackItem style={{ marginLeft: 'auto' }}>
                <TestSound />
              </StackItem>
            </Stack>
            <Select
              id="settings-speakers"
              name="settings-speakers"
              native
              onChange={handleSpeakerChange}
              options={
                speakers.length > 0
                  ? speakers.map((speaker, index) => ({
                      label:
                        speaker.device.label ||
                        `${t('audio.speakers_{number}', {
                          number: index + 1,
                        })}`,
                      selected: speaker.selected,
                      value: speaker.device.deviceId,
                    }))
                  : [
                      {
                        label: t('audio.systemDefault'),
                        selected: true,
                        value: 'default',
                      },
                    ]
              }
            />
          </Stack>
        )}
      </Stack>
      <style jsx>{`
        .audio-video-settings :global(.label) {
          display: block;
          margin-bottom: 1px;
          margin-top: -1px;
        }
      `}</style>
    </div>
  );
};
