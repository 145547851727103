import { Card, CardContent } from '@daily/shared/components/Card';
import { AudioIcon } from '@daily/shared/components/Icons';
import { Stack, StackItem } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { useSnackbar } from '@daily/shared/contexts/Snackbar';
import { useTheme } from '@daily/shared/contexts/Theme';
import { useMediaQuery } from '@daily/shared/hooks/useMediaQuery';
import {
  useDaily,
  useDevices,
  useLocalParticipant,
} from '@daily-co/daily-react-hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCallState } from '../../contexts/CallProvider';
import { useIsMobile } from '../../contexts/UIState';
import { useCallConfig } from '../../hooks/useCallConfig';
import { useSoundLoader } from '../../hooks/useSoundLoader';
import { useStyleVariants } from '../../hooks/useStyleVariants';
import { DEFAULT_ASPECT_RATIO } from '../../lib/constants';
import { DEFAULT_LANDSCAPE_AR, DEFAULT_PORTRAIT_AR } from '../MobileView';
import { AudioVideoSettings } from '../SettingsModal/AudioVideoSettings';
import { TestSound } from '../SettingsModal/TestSound';
import { Tile } from '../Tile';
import { CTAContent } from './CTAContent';
import { DeviceErrorHandling } from './DeviceErrorHandling';
import { LockedMessage } from './LockedMessage';
import { NewSetupTray } from './NewSetupTray';
import { NotSupportedMessage } from './NotSupportedMessage';
import { VideoContainer } from './VideoContainer';

export type AccessState = 'none' | 'lobby' | 'full';
export type RequestState = 'idle' | 'waiting';

export type GeneralState = ReturnType<typeof useDevices>['camState'];

interface Props {
  accessState: AccessState;
  onCancelJoinRequest(): void;
}

export const NewSetup: React.FC<Props> = ({
  accessState,
  onCancelJoinRequest,
}) => {
  const { t } = useTranslation();
  const { mediaQueries } = useTheme();
  const { addMessage } = useSnackbar();

  const [requestState, setRequestState] = useState<RequestState>('idle');

  const [isMobile] = useIsMobile();
  const { cameras, camState, hasCamError, hasMicError, micState } =
    useDevices();
  const daily = useDaily();
  const { setRedirectOnLeaveCall } = useCallState();
  const { broadcastRole, optimizeLargeCalls, poweredByDaily } = useCallConfig();
  const localParticipant = useLocalParticipant();

  const { isPortrait, mobileLandscape, mobilePortrait } = useStyleVariants();

  const { joinSound, load: loadSounds } = useSoundLoader();

  const isSmallScreen = useMediaQuery(mediaQueries.small);
  const isLargeScreen = useMediaQuery(mediaQueries.large);

  const shouldUseLandscapeLayout =
    (!isPortrait && !isLargeScreen) || mobileLandscape;
  const showExtraContent =
    camState !== 'pending' &&
    !isSmallScreen &&
    !shouldUseLandscapeLayout &&
    !isMobile;

  useEffect(() => {
    if (!daily) return;
    daily.startCamera();
  }, [daily]);

  const handleComplete = useCallback(async () => {
    if (!daily) return;

    loadSounds();

    // In case participant unmuted during prejoin, force-mute again.
    if (optimizeLargeCalls && daily.participants().local.audio) {
      daily.setLocalAudio(false);
      addMessage({
        content: t('notification.micMutedAutomatically'),
      });
    }

    await daily.join();

    if (accessState === 'lobby') {
      setRequestState('waiting');
      const { granted } = await daily?.requestAccess({
        name: localParticipant?.user_name,
        access: {
          level: 'full',
        },
      });
      if (granted) {
        joinSound.play();
      } else {
        setRedirectOnLeaveCall(false);
      }
    }
  }, [
    accessState,
    addMessage,
    daily,
    joinSound,
    loadSounds,
    localParticipant,
    optimizeLargeCalls,
    setRedirectOnLeaveCall,
    t,
  ]);

  const tileAspectRatio = useMemo(
    () =>
      isMobile
        ? isPortrait
          ? DEFAULT_PORTRAIT_AR
          : DEFAULT_LANDSCAPE_AR
        : DEFAULT_ASPECT_RATIO,
    [isPortrait, isMobile]
  );
  const getAvailableHeight = useMemo(() => {
    /**
     * To avoid having to scroll in mobile portrait mode or anything
     * getting cut off, we need to set the tile height to whatever
     * is available after accommodating all the other elements on
     * the screen
     */

    if (!mobilePortrait) return;

    const bannerIsShowing = poweredByDaily ? 30 : 0;
    const titleCard = 96;
    const ctaButton = isSmallScreen && 40;
    const lockedByHost = accessState === 'lobby' && isSmallScreen ? 24 : 0;
    const totalPadding = 80;
    const trayHeight = 59;
    const testSoundCard = !showExtraContent && 48;
    const ownerMsg = broadcastRole === 'owner' ? 32 : 0;
    const audioVideoSettings = !showExtraContent
      ? 0
      : accessState === 'lobby'
      ? 273
      : 233;

    const maxTileHeight =
      window.innerHeight -
      (titleCard +
        ctaButton +
        totalPadding +
        lockedByHost +
        testSoundCard +
        trayHeight +
        bannerIsShowing +
        ownerMsg +
        audioVideoSettings);

    return maxTileHeight;
  }, [
    accessState,
    broadcastRole,
    poweredByDaily,
    mobilePortrait,
    isSmallScreen,
  ]);

  const noDeviceErrors = !hasCamError && !hasMicError;

  if (camState === 'not-supported' || micState === 'not-supported') {
    return <NotSupportedMessage />;
  }

  return (
    <>
      <div>
        {noDeviceErrors ? (
          <Card
            spacing={8}
            style={{
              backgroundColor: 'var(--body-bg)',
              padding: '20px',
              flexDirection: shouldUseLandscapeLayout
                ? 'row-reverse'
                : 'column',
            }}
            columns={shouldUseLandscapeLayout && [1, 2, 2, 2]}
          >
            {camState !== 'pending' && (
              <CardContent
                style={
                  shouldUseLandscapeLayout
                    ? {
                        flex: '1 0 40%',
                        minWidth: '30%',
                        padding: '46px 8px 16px',
                      }
                    : { padding: '0 0 16px 0' }
                }
              >
                <Stack
                  align="center"
                  justify="center"
                  style={{ paddingTop: '48px', height: '100%' }}
                >
                  <Text
                    variant="largestrong"
                    textAlign={
                      shouldUseLandscapeLayout || isSmallScreen
                        ? 'center'
                        : 'left'
                    }
                    style={{ fontSize: '20px' }}
                  >
                    {t('haircheck.setup.hi')}{' '}
                    <strong>{localParticipant?.user_name}</strong>{' '}
                    {t('haircheck.setup.title')}
                  </Text>
                </Stack>
              </CardContent>
            )}
            <CardContent
              noPadding
              style={
                shouldUseLandscapeLayout
                  ? {
                      flex: '1 0 60%',
                      boxShadow:
                        '1px 0 0 var(--card-border), 0 1px 0 var(--card-border)',
                    }
                  : {}
              }
            >
              {camState === 'pending' ? (
                <VideoContainer hasVideo={false}>
                  <Stack
                    style={{ textAlign: isSmallScreen ? 'left' : 'center' }}
                  >
                    <Text variant="largestrong">
                      {t('haircheck.setup.allowAccessTitle')}
                    </Text>
                    <Text>{t('haircheck.setup.allowAccessSubtitle')}</Text>
                  </Stack>
                </VideoContainer>
              ) : (
                <VideoContainer>
                  <Tile
                    aspectRatio={tileAspectRatio}
                    activeIndicator="meter"
                    hideActions
                    name={localParticipant?.user_name}
                    sessionId={localParticipant?.session_id}
                    showNames
                    style={
                      mobilePortrait
                        ? { border: 'none', maxHeight: getAvailableHeight }
                        : { border: 'none' }
                    }
                    videoFit="cover"
                  />
                </VideoContainer>
              )}
              <NewSetupTray
                micState={micState}
                camState={camState}
                hasMicError={hasMicError}
                cameras={cameras}
              />

              {camState !== 'pending' && (
                <div>
                  <Stack
                    align="end"
                    justify="center"
                    style={{
                      height: '100%',
                      float: !showExtraContent ? 'none' : 'right',
                      marginTop: !showExtraContent ? '0' : '-60px',
                    }}
                  >
                    <StackItem style={{ width: '100%' }}>
                      <CTAContent
                        shouldUseLandscapeLayout={shouldUseLandscapeLayout}
                        accessState={accessState}
                        requestState={requestState}
                        onCancelClick={onCancelJoinRequest}
                        onComplete={handleComplete}
                        camState={camState}
                        micState={micState}
                        hasCamError={hasCamError}
                        hasMicError={hasMicError}
                      />
                    </StackItem>
                    {/* {shouldUseLandscapeLayout && (
                      <StackItem style={{ marginTop: 'auto' }}>
                        <Stack justify="center" horizontal gap={4}>
                          <AudioIcon id="speaker-settings-label" size={16} />
                          <TestSound animationPlacement="right" />
                        </Stack>
                      </StackItem>
                    )} */}
                  </Stack>
                </div>
              )}
            </CardContent>
            {/* {camState !== 'pending' &&
              !showExtraContent &&
              !shouldUseLandscapeLayout && (
                <CardContent>
                  <Stack horizontal justify="center" padding={'8'}>
                    <AudioIcon
                      id="speaker-settings-label"
                      size={16}
                      color="var(--text-muted)"
                    />
                    <TestSound animationPlacement="right" />
                  </Stack>
                </CardContent>
              )} */}

            <CardContent noPadding>
              <Stack
                gap={24}
                style={{
                  padding: '8px 0',
                }}
              >
                <AudioVideoSettings shouldShowMicVolume={false} />

                {accessState === 'lobby' && <LockedMessage />}
                {broadcastRole === 'owner' && (
                  <Stack>
                    <Text textAlign="center" color="muted">
                      {t('haircheck.setup.joinAsPresenter')}
                    </Text>
                  </Stack>
                )}
              </Stack>
            </CardContent>
          </Card>
        ) : (
          <DeviceErrorHandling
            accessState={accessState}
            requestState={requestState}
            camState={camState}
            micState={micState}
            hasMicError={hasMicError}
            cameras={cameras}
            hasCamError={hasCamError}
            onComplete={handleComplete}
            onCancelClick={onCancelJoinRequest}
            shouldUseLandscapeLayout={shouldUseLandscapeLayout}
          />
        )}
      </div>
    </>
  );
};
