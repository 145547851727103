import { ParticipantsIcon } from '@daily/shared/components/Icons';
import { Tooltip } from '@daily/shared/components/Tooltip';
// import { Text } from '@daily/shared/components/Text';
import { useTheme } from '@daily/shared/contexts/Theme';
import { useWaitingParticipants } from '@daily-co/daily-react-hooks';
import classnames from 'classnames';
import React from 'react';

// import { useTranslation } from 'react-i18next';
import { useSidebarView } from '../../contexts/UIState';
import { robotsClassName } from '../../lib/robots';
import { TrayButton } from './TrayButton';

interface Props extends React.ComponentProps<typeof TrayButton> {}

export const PeopleControls: React.FC<Props> = ({
  children,
  className,
  onClick,
  ...props
}) => {
  const { colors } = useTheme();
  // const { t } = useTranslation();
  const [sidebarView, setSidebarView] = useSidebarView();
  const { waitingParticipants } = useWaitingParticipants();

  const handlePeopleClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    setSidebarView(sidebarView === 'people' ? null : 'people');
    onClick?.(ev);
  };

  return (
    <div className="people-controls">
      <Tooltip id={'tt-people-controls'} tabIndex={-1} title={'People'}>
        <TrayButton
          badge={{
            color: colors.system.red,
            visible: waitingParticipants.length > 0,
          }}
          className={classnames(className, {
            [robotsClassName('btn-people-show')]: sidebarView !== 'people',
            [robotsClassName('btn-people-hide')]: sidebarView === 'people',
          })}
          onClick={handlePeopleClick}
          {...props}
        >
          <ParticipantsIcon />
          {/* <Text>{t('general.people')}</Text> */}
        </TrayButton>
      </Tooltip>
    </div>
  );
};
