import React, { useEffect, useMemo, useRef } from 'react';

import { useTheme } from '../../contexts/Theme';
import { pxToRem } from '../../lib/pxToRem';
import { Stack } from '../Stack';
import { Text } from '../Text';
import { Caret } from './Caret';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { BaseProps, Option } from './types';

interface NativeProps
  extends BaseProps,
    Omit<React.HTMLAttributes<HTMLSelectElement>, 'onChange'> {
  id: string;
  label?: string;
  native: boolean;
  onChange?(value: string | string[]): void;
  options: Option[];
}

export const NativeSelect: React.FC<NativeProps> = ({
  disabled = false,
  id,
  label,
  multiple = false,
  onChange,
  options,
  ...props
}) => {
  const { fontFamilies, fontSizes, lineHeights } = useTheme();

  const selectRef = useRef<HTMLSelectElement>(null);
  const handleChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const values = Array.from(ev.currentTarget.selectedOptions).map(
      (o) => o.value
    );
    onChange?.(multiple ? values : values[0]);
  };

  const selectedValue = useMemo(
    () => options.find((o) => o.selected)?.value || null,
    [options]
  );

  // Update DOM when selected option changes.
  useEffect(() => {
    const selectEl = selectRef.current;
    if (!selectEl || !selectedValue) return;
    selectEl.value = selectedValue;
  }, [selectedValue]);

  return (
    <Stack gap={4}>
      {label && (
        <Text El="label" htmlFor={id} id={`label-${id}`} variant="strong">
          {label}
        </Text>
      )}
      <div className="select">
        <select
          ref={selectRef}
          defaultValue={selectedValue}
          disabled={disabled}
          id={id}
          multiple={multiple}
          onChange={handleChange}
          {...props}
        >
          {options.map((o) => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </select>
        {!multiple && <Caret disabled={disabled} />}
      </div>
      <style jsx>{`
        select {
          appearance: none;
          background: var(--input-bg);
          border: 1px solid var(--input-border);
          border-radius: 8px;
          box-shadow: 0 0 0 0 var(--focus-shadow);
          color: var(--input-color);
          display: block;
          font-family: ${fontFamilies.regular};
          font-size: ${fontSizes.base};
          line-height: ${lineHeights.base};
          padding: 6px 28px 7px 7px;
          position: relative;
          transition: border 200ms ease, box-shadow 200ms ease;
          width: 100%;
        }
        select:focus,
        select:hover {
          border-color: var(--focus-border);
          box-shadow: 0 0 0 2px var(--focus-shadow);
          outline: none;
        }
        select[disabled] {
          background: var(--input-disabled-bg);
          box-shadow: none !important;
          color: var(--input-disabled-color);
          cursor: default;
        }
        option {
          font-size: ${fontSizes.base};
        }
        .select {
          position: relative;
        }
        @supports (-webkit-touch-callout: none) {
          /* iOS specific style adjustments */
          select {
            /* Font size of 16px to disable zoom on focus */
            font-size: ${pxToRem(16)};
          }
        }
      `}</style>
    </Stack>
  );
};
