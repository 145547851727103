import { Button } from '@daily/shared/components/Button';
import { Modal } from '@daily/shared/components/Modal';
import { Text } from '@daily/shared/components/Text';
import { useTranslation } from 'react-i18next';

interface Props extends React.ComponentProps<typeof Modal> {}

export const RecordingErrorModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <Modal
      actions={[<Button key="close">{props.closeText}</Button>]}
      title={t('recording.errorModal.title')}
      {...props}
    >
      <Text variant="large">{t('recording.errorModal.content')}</Text>
    </Modal>
  );
};
