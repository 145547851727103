import { Text } from '@daily/shared/components/Text';
import { Tooltip } from '@daily/shared/components/Tooltip';
import { useTheme } from '@daily/shared/contexts/Theme';
import { DailyCustomTrayButton } from '@daily-co/daily-js';
import { useCallback } from 'react';

import { useIframeDriver } from '../../contexts/IframeDriverProvider';
import { robotsClassName } from '../../lib/robots';
import { ACTION_CUSTOM_BUTTON_CLICK, TOOLTIP_DELAY } from './constant';
import { CustomIcon } from './CustomIcon';
import { TrayButton } from './TrayButton';

interface Props extends React.ComponentProps<typeof TrayButton> {
  btn: DailyCustomTrayButton;
  id: string;
}

const ICON_SIZE = 24;

export const CustomButton: React.FC<Props> = ({ btn, id, ...props }) => {
  const { isDarkMode } = useTheme();
  const { sendMessageToDriver } = useIframeDriver();

  const handleClick = useCallback(() => {
    sendMessageToDriver({
      action: ACTION_CUSTOM_BUTTON_CLICK,
      button_id: id,
    });
  }, [id, sendMessageToDriver]);

  return (
    <Tooltip
      delay={TOOLTIP_DELAY}
      disabled={!btn.tooltip}
      id={`tt-custom-button_${id}`}
      tabIndex={-1}
      title={btn.tooltip}
    >
      <TrayButton
        className={robotsClassName('btn-custom')}
        onClick={handleClick}
        {...props}
        id={`${id}-controls`}
      >
        <CustomIcon
          alt={btn.label}
          src={
            isDarkMode && btn.iconPathDarkMode
              ? btn.iconPathDarkMode
              : btn.iconPath
          }
          padding={4}
          size={ICON_SIZE}
          style={{ display: 'block' }}
        />
        <Text>{btn.label}</Text>
      </TrayButton>
    </Tooltip>
  );
};
