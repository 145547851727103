import { useTheme } from '@daily/shared/contexts/Theme';
import { DailyThemeConfig } from '@daily-co/daily-js';
import { useCallback, useEffect, useState } from 'react';

import { useCallState } from '../../contexts/CallProvider';
import IframeDriverMessageChannel from '../../contexts/IframeDriverMessageChannel';
import {
  useCustomTrayButtons,
  usePreferredViewMode,
  useShowLocalVideo,
} from '../../contexts/UIState';
import { convertDailyThemeColors } from '../../lib/theme';

export const useIframeUIControlHandlers = (
  messageChannel: IframeDriverMessageChannel
) => {
  const {
    setEnableJoinSound,
    setIsFullscreen,
    setShowNames,
    setShowParticipantsBar,
  } = useCallState();
  const [, setCustomTrayButtons] = useCustomTrayButtons();
  const [showLocalVideo, setShowLocalVideo] = useShowLocalVideo();
  const { setDarkColors, setLightColors } = useTheme();
  const [preferredViewMode, setPreferredViewMode] = usePreferredViewMode();
  const [activeSpeakerMode, setActiveSpeakerMode] = useState(false);

  const handleDriverSetActiveSpeakerModeMsg = useCallback(
    (msg: any) => {
      // Map activeSpeakerMode to preferredViewMode
      setPreferredViewMode(msg.enabled ? 'speaker' : 'grid');
      // Swallow msg (don't forward to call machine)
    },
    [setPreferredViewMode]
  );

  const handleDriverShowLocalVideoMsg = useCallback(
    (msg: any) => {
      // Map showLocalVideo (property) to showLocalVideo (state)
      setShowLocalVideo(msg?.show);
      // Swallow msg (don't forward to call machine)
    },
    [setShowLocalVideo]
  );

  const handleDriverShowParticipantsBarMsg = useCallback(
    (msg: any) => {
      // Map showParticipantsBar (property) to showParticipantsBar (state)
      setShowParticipantsBar(msg?.show);
      // Swallow msg (don't forward to call machine)
    },
    [setShowParticipantsBar]
  );

  const handleUpdateCustomTrayButtonsMsg = useCallback(
    (msg: any) => {
      setCustomTrayButtons(msg?.btns);
    },
    [setCustomTrayButtons]
  );

  const handleSetThemeMsg = useCallback(
    (msg: any) => {
      const theme: DailyThemeConfig = msg?.theme;
      if (!theme) return;
      const [light, dark] = convertDailyThemeColors(theme);
      setLightColors(light);
      setDarkColors(dark);
    },
    [setDarkColors, setLightColors]
  );

  const handleDriverShowNames = useCallback(
    (msg: any) => {
      // Map showNamesMode to showNames
      setShowNames(msg?.mode !== 'never');
      // Swallow msg (don't forward to call machine)
    },
    [setShowNames]
  );

  const handleDriverEnterFullscreen = useCallback(
    (_msg: any) => {
      // Map fullscreen to isFullscreen
      setIsFullscreen(true);
      // Swallow msg (don't forward to call machine)
    },
    [setIsFullscreen]
  );

  const handleDriverExitFullscreen = useCallback(
    (_msg: any) => {
      // Map exited-fullscreen to isFullscreen
      setIsFullscreen(false);
      // Swallow msg (don't forward to call machine)
    },
    [setIsFullscreen]
  );

  const handleDriverSetPlayNewParticipantSound = useCallback(
    (msg: any) => {
      setEnableJoinSound(msg.arg);
    },
    [setEnableJoinSound]
  );

  /**
   * Map preferredViewMode to activeSpeakerMode (used by API).
   */
  useEffect(() => {
    setActiveSpeakerMode(preferredViewMode === 'speaker');
  }, [preferredViewMode]);

  /**
   * Fire show-local-video-changed event when showLocalVideo changes.
   */
  useEffect(() => {
    messageChannel?.sendMessageToDriver({
      action: 'show-local-video-changed',
      show: showLocalVideo,
    });
  }, [messageChannel, showLocalVideo]);

  /**
   * Fire set-active-speaker-mode event when activeSpeakerMode changes.
   */
  useEffect(() => {
    messageChannel?.sendMessageToDriver({
      action: 'active-speaker-mode-change',
      enabled: activeSpeakerMode,
    });
  }, [activeSpeakerMode, messageChannel]);

  return {
    handleDriverSetActiveSpeakerModeMsg,
    handleDriverShowLocalVideoMsg,
    handleDriverShowNames,
    handleDriverShowParticipantsBarMsg,
    handleDriverEnterFullscreen,
    handleDriverExitFullscreen,
    handleDriverSetPlayNewParticipantSound,
    handleUpdateCustomTrayButtonsMsg,
    handleSetThemeMsg,
  };
};
