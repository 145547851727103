import { useTheme } from '@daily/shared/contexts/Theme';
import classnames from 'classnames';

import { useStyleVariants } from '../../hooks/useStyleVariants';
import { isAndroid } from '../../lib/browserConfig';

interface Props {
  hasVideo?: boolean;
}

export const VideoContainer: React.FC<Props> = ({
  children,
  hasVideo = true,
  ...props
}) => {
  const { colors, mediaQueries } = useTheme();
  const { mobileLandscape } = useStyleVariants();

  return (
    <div
      className={classnames('videoContainer', {
        hasVideo,
      })}
    >
      <div
        className={classnames('videoArea', {
          androidLandscape: isAndroid() && mobileLandscape,
        })}
        {...props}
      >
        {hasVideo ? (
          <>{children}</>
        ) : (
          <div className="message-container">{children}</div>
        )}
      </div>
      <style jsx>{`
        .videoContainer {
          background-color: var(--bg-color);
          display: flex;
          justify-content: center;
          box-shadow: 1px 0 0 var(--card-border), 0 1px 0 var(--card-border);
        }
        .videoContainer.hasVideo {
          background-color: ${colors.custom.mainAreaBgAccent};
          box-shadow: none;
        }
        .videoArea {
          padding-bottom: 56.25%;
          position: relative;
          max-width: 60vw;
          width: 464px;
        }
        .videoArea.androidLandscape {
          width: 328px; // reduce the width due to the address bar is taking up space
        }
        .videoContainer.hasVideo .videoArea {
          padding-bottom: 0;
        }
        .message-container {
          align-items: center;
          display: flex;
          height: 100%;
          justify-content: center;
          left: 0;
          padding: 16px;
          top: 0;
          width: 100%;
          position: absolute;
        }

        @media ${mediaQueries.small} {
          .videoArea {
            max-width: 90vw;
          }

          .message-container {
            padding: 16px;
          }
        }
        @media ${mediaQueries.medium} {
          .videoArea {
            max-width: 84vw;
          }

          .message-container {
            padding: 32px;
          }
        }
        @media ${mediaQueries.large} {
          .videoArea {
            width: 494px;
          }
        }
      `}</style>
    </div>
  );
};
