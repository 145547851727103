import { Button } from '@daily/shared/components/Button';
import { LandscapeIcon, UserIcon } from '@daily/shared/components/Icons';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { Tooltip } from '@daily/shared/components/Tooltip';
import { VisuallyHidden } from '@daily/shared/components/VisuallyHidden';
import { useTheme } from '@daily/shared/contexts/Theme';
import {
  DailyInputSettings,
  DailyInputVideoProcessorSettings,
} from '@daily-co/daily-js';
import {
  useInputSettings,
  useLocalParticipant,
  useVideoTrack,
} from '@daily-co/daily-react-hooks';
import classNames from 'classnames';
import deepEqual from 'fast-deep-equal';
import getConfig from 'next/config';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { atom, useRecoilCallback, useRecoilValue } from 'recoil';

import { useCallConfig } from '../../hooks/useCallConfig';

type Effect =
  | 'none'
  | 'soft-blur'
  | 'strong-blur'
  | 'vb-coffeeshop'
  | 'vb-forest'
  | 'vb-hills'
  | 'vb-library'
  | 'vb-lounge'
  | 'vb-ocean'
  | 'vb-office'
  | 'vb-palms'
  | 'vb-rollercoaster';

const sortedEffects: Effect[] = [
  'none',
  'soft-blur',
  'strong-blur',
  'vb-coffeeshop',
  'vb-forest',
  'vb-hills',
  'vb-library',
  'vb-lounge',
  'vb-ocean',
  'vb-office',
  'vb-palms',
  'vb-rollercoaster',
];

const selectedBackgroundEffectState = atom<Effect>({
  key: 'selected-background-effect',
  default: 'none',
});

export const BackgroundEffects: React.FC = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { enableVideoProcessingUI } = useCallConfig();
  const selectedEffect = useRecoilValue(selectedBackgroundEffectState);
  const { assetPrefix } = getConfig().publicRuntimeConfig;

  const localParticipant = useLocalParticipant();
  const videoTrack = useVideoTrack(localParticipant?.session_id);

  const isUserFacingVideo = useMemo(() => {
    if (!videoTrack?.track) return true;
    const videoTrackSettings = videoTrack.track.getSettings();
    return 'facingMode' in videoTrackSettings
      ? videoTrackSettings.facingMode === 'user'
      : true;
  }, [videoTrack?.track]);

  const { inputSettings, updateInputSettings } = useInputSettings({
    onInputSettingsUpdated: useRecoilCallback(
      ({ set }) =>
        (ev) => {
          if (!enableVideoProcessingUI) return;
          let newEffect: Effect = 'none';
          const isBlurEnabled =
            ev.inputSettings?.video?.processor?.type === 'background-blur';
          const config = ev.inputSettings?.video?.processor?.config as any;
          if (isBlurEnabled) {
            const level = config?.strength ?? 0;
            if (level === 0) {
              newEffect = 'none';
            } else if (level < 0.5) {
              newEffect = 'soft-blur';
            } else {
              newEffect = 'strong-blur';
            }
          } else {
            if (config?.type === 'url') {
              const url = new URL(config.source);
              const match = url.pathname.match(
                new RegExp(
                  sortedEffects.filter((fx) => fx.startsWith('vb-')).join('|')
                )
              );
              if (match) {
                newEffect = match[0] as Effect;
              }
            }
          }
          set(selectedBackgroundEffectState, (prevEffect) =>
            prevEffect === newEffect ? prevEffect : newEffect
          );
        },
      [enableVideoProcessingUI]
    ),
  });

  const handleSelectEffect = useCallback(
    (effect: Effect) => {
      try {
        let type: DailyInputVideoProcessorSettings['type'] = 'none';
        let blurLevel = 0;
        if (effect.endsWith('-blur')) {
          type = 'background-blur';
          switch (effect) {
            case 'strong-blur':
              blurLevel = 1;
              break;
            case 'soft-blur':
              blurLevel = 0.2;
              break;
          }
        } else if (effect.startsWith('vb-')) {
          type = 'background-image';
        }

        const settings: DailyInputSettings = {
          video: {
            processor: {
              type,
            },
          },
        };
        switch (type) {
          case 'background-blur':
            settings.video.processor.config = {
              strength: blurLevel,
            };
            break;
          case 'background-image':
            const { hostname, port, protocol } = window.location;
            // Check if assetPrefix already contains FQDN
            const prefix = assetPrefix.match(/^https?\:\/\//)
              ? assetPrefix
              : `${protocol}//${hostname}:${port}${assetPrefix}`;
            settings.video.processor.config = {
              source: `${prefix}/backgrounds/${effect}.jpg`,
              type: 'url',
            };
            break;
        }

        if (
          deepEqual(inputSettings, settings) ||
          (deepEqual(inputSettings, {}) && effect === 'none')
        )
          return;

        updateInputSettings(settings);
      } catch {}
    },
    [assetPrefix, inputSettings, updateInputSettings]
  );

  return (
    <div
      className={classNames('effects', {
        isUserFacingVideo,
      })}
    >
      <Stack align="center" justify="space-between" horizontal wrap>
        <Text variant="strong">{t('settings.modal.selectEffect')}</Text>
        {selectedEffect && (
          <Text>
            {t('settings.modal.appliedEffect', {
              effect: selectedEffect,
            })}
          </Text>
        )}
      </Stack>
      <div className="selection">
        {sortedEffects.map((effect) => (
          <Tooltip
            key={effect}
            id={`tt-eff-${effect}`}
            placement="top"
            // i18next-extract-mark-context-next-line ["none", "strong-blur", "soft-blur", "vb-coffeeshop", "vb-forest", "vb-hills", "vb-library", "vb-lounge", "vb-ocean", "vb-office", "vb-palms", "vb-rollercoaster"]
            title={t(`settings.modal.effects`, { context: effect })}
            tabIndex={-1}
          >
            <Button
              className={classNames('btn-effect', effect, {
                vb: effect.startsWith('vb-'),
                selected: selectedEffect === effect,
              })}
              data-testId={`btn-${effect}`}
              onClick={() => handleSelectEffect(effect)}
              variant="secondary"
            >
              {effect === 'none' ? (
                <>
                  <UserIcon
                    className={`icon-${effect}`}
                    color={
                      selectedEffect === effect ? colors.accent : undefined
                    }
                    size={24}
                  />
                  <VisuallyHidden>
                    {t(`settings.modal.effects_none`)}
                  </VisuallyHidden>
                </>
              ) : effect.endsWith('-blur') ? (
                <>
                  <LandscapeIcon
                    className={`icon-${effect}`}
                    color={
                      selectedEffect === effect ? colors.accent : undefined
                    }
                    size={24}
                  />
                  <VisuallyHidden>
                    {
                      // i18next-extract-mark-context-next-line ["strong-blur", "soft-blur"]
                      t(`settings.modal.effects`, { context: effect })
                    }
                  </VisuallyHidden>
                </>
              ) : effect.startsWith('vb-') ? (
                <img
                  // i18next-extract-mark-context-next-line ["vb-coffeeshop", "vb-forest", "vb-hills", "vb-library", "vb-lounge", "vb-ocean", "vb-office", "vb-palms", "vb-rollercoaster"]
                  alt={t('settings.modal.effects', {
                    context: effect,
                  })}
                  src={`${assetPrefix}/backgrounds/${effect}-thumb.jpg`}
                  height={56}
                  width={56}
                />
              ) : null}
            </Button>
          </Tooltip>
        ))}
      </div>
      <style jsx>{`
        .effects {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
        .selection {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 8px;
        }
        .selection :global(.btn-effect) {
          height: 56px;
          padding: 15px;
          width: 56px;
        }
        .selection :global(.btn-effect.vb) {
          padding: 0;
          overflow: hidden;
        }
        .isUserFacingVideo .selection :global(.btn-effect.vb) {
          transform: scaleX(-1);
        }
        .selection :global(.btn-effect.selected) {
          border-color: ${colors.accent};
        }
        .selection :global(.icon-soft-blur) {
          filter: blur(1px);
        }
        .selection :global(.icon-strong-blur) {
          filter: blur(3px);
        }
      `}</style>
    </div>
  );
};
