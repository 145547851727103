import { Tooltip } from '@daily/shared/components/Tooltip';

import { BakerIcon } from './RoleIcons/BakerIcon';
import { ChampionIcon } from './RoleIcons/ChampionIcon';
import { DiscordSupportIcon } from './RoleIcons/DiscordSupportIcon';
import { GameChangerIcon } from './RoleIcons/GameChangerIcon';
import { ModeratorIcon } from './RoleIcons/ModeratorIcon';
import { StaffIcon } from './RoleIcons/StaffIcon';
import { SubscriberIcon } from './RoleIcons/SubscriberIcon';
import { TutorIcon } from './RoleIcons/TutorIcon';

type RoleBadges = {
  donator3?: boolean;
  donator2?: boolean;
  donator1?: boolean;
  // member?: boolean;
  staff?: boolean;
  moderator?: boolean;
  discordsupport?: boolean;
  tutor?: boolean;
  subscriber?: boolean;
};

type RolesProps = {
  badges?: RoleBadges;
};

export function Roles({ badges }: RolesProps) {
  if (!badges) return null;

  return (
    <div className="roles">
      {/* Donator 3 */}
      {badges?.donator3 && (
        <div className="roles-item">
          <Tooltip
            id="role_1"
            title="Game Changer 💥"
            toggleStyle={{ display: 'flex', alignItems: 'center' }}
          >
            <GameChangerIcon height={16} width={16} />
          </Tooltip>
        </div>
      )}
      {/* Donator 2 */}
      {badges?.donator2 && !badges?.donator3 && (
        <div className="roles-item">
          <Tooltip
            id="role_2"
            title="Legend️ ⚡"
            toggleStyle={{ display: 'flex', alignItems: 'center' }}
          >
            <ChampionIcon height={16} width={16} />
          </Tooltip>
        </div>
      )}
      {/* Donator 1 */}
      {badges?.donator1 && !badges?.donator2 && !badges?.donator3 && (
        <div className="roles-item">
          <Tooltip
            id="role_3"
            title="Superhero 🤝"
            toggleStyle={{ display: 'flex', alignItems: 'center' }}
          >
            <BakerIcon height={16} width={16} />
          </Tooltip>
        </div>
      )}
      {/* Staff */}
      {badges?.staff && (
        <div className="roles-item">
          <Tooltip
            id="role_4"
            title="Staff"
            toggleStyle={{ display: 'flex', alignItems: 'center' }}
          >
            <StaffIcon height={16} width={16} />
          </Tooltip>
        </div>
      )}
      {/* Moderator */}
      {badges?.moderator && (
        <div className="roles-item">
          <Tooltip
            id="role_5"
            title="Moderator"
            toggleStyle={{ display: 'flex', alignItems: 'center' }}
          >
            <ModeratorIcon height={16} width={16} />
          </Tooltip>
        </div>
      )}
      {/* Discord Support */}
      {badges?.discordsupport && (
        <div className="roles-item">
          <Tooltip
            id="role_6"
            title="Discord Support "
            toggleStyle={{ display: 'flex', alignItems: 'center' }}
          >
            <DiscordSupportIcon height={16} width={16} />
          </Tooltip>
        </div>
      )}
      {/* Tutor */}
      {badges?.tutor && (
        <div className="roles-item">
          <Tooltip
            id="role_6"
            title="Tutor"
            toggleStyle={{ display: 'flex', alignItems: 'center' }}
          >
            <TutorIcon height={16} width={16} />
          </Tooltip>
        </div>
      )}
      {/* Subscriber */}
      {badges?.subscriber && (
        <div className="roles-item">
          <Tooltip
            id="role_6"
            title="Premium"
            toggleStyle={{ display: 'flex', alignItems: 'center' }}
          >
            <SubscriberIcon height={16} width={16} />
          </Tooltip>
        </div>
      )}
      <style jsx>{`
        .roles {
          display: flex;
          align-items: center;
        }
        .roles-item {
          display: flex;
          align-items: center;
          margin-left: 4px;
        }
      `}</style>
    </div>
  );
}
