import { Button } from '@daily/shared/components/Button';
import { Form } from '@daily/shared/components/Form';
import { Input } from '@daily/shared/components/Input';
import { Modal } from '@daily/shared/components/Modal';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { useDaily, useLocalParticipant } from '@daily-co/daily-react-hooks';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { robotsClassName } from '../../lib/robots';

interface Props extends React.ComponentProps<typeof Modal> {}

export const NameModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const localParticipant = useLocalParticipant();
  const [name, setName] = useState(localParticipant?.user_name ?? '');
  const daily = useDaily();

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setName(ev.target.value);
  };

  const handleSubmit = () => {
    daily.setUserName(name);
  };

  return (
    <Modal
      actions={[
        <Button key="submit" form="name-form" type="submit">
          {t('nameModal.changeName')}
        </Button>,
        <Button key="cancel" variant="secondary">
          {t('general.cancel')}
        </Button>,
      ]}
      title={t('nameModal.title')}
      {...props}
    >
      <Form id="name-form" onSubmit={handleSubmit}>
        <Stack gap={12}>
          <Text El="label" variant="large">
            {t('nameModal.description')}
          </Text>
          <Input
            className={robotsClassName('input-name')}
            maxLength={80}
            name="username"
            onChange={handleChange}
            placeholder={t('nameModal.inputPlaceholder')}
            value={name}
          />
        </Stack>
      </Form>
    </Modal>
  );
};
