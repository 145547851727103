import { useTheme } from '@daily/shared/contexts/Theme';
import { pxToRem } from '@daily/shared/lib/pxToRem';
import { DailyNetworkStats } from '@daily-co/daily-js';
import { useMediaTrack, useParticipant } from '@daily-co/daily-react-hooks';
import classnames from 'classnames';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import { useActiveSpeaker } from '../../hooks/useActiveSpeaker';
import { useDisplayName } from '../../hooks/useDisplayName';
import { useResizeObserver } from '../../hooks/useResizeObserver';
import { DEFAULT_ASPECT_RATIO } from '../../lib/constants';
import { Debugger } from './Debugger';
import { NoVideo } from './NoVideo';
import { TileActions } from './TileActions';
import { TileButtons } from './TileButtons';
import { TileInfo } from './TileInfo';
import { TileVideo } from './TileVideo';

const SM_TILE_MAX_WIDTH = 300;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  activeIndicator?: 'meter' | 'static';
  alignActionsMenu?: React.ComponentProps<typeof TileActions>['alignMenu'];
  aspectRatio?: number;
  hideActions?: boolean;
  isMobile?: boolean;
  isScreen?: boolean;
  isSpeaking?: boolean;
  name?: string;
  network?: DailyNetworkStats['threshold'];
  onVideoResize?: (aspectRatio: number) => void;
  sessionId: string;
  showNames: boolean;
  videoFit?: React.ComponentProps<typeof TileVideo>['fit'];
}

export const Tile: React.FC<Props> = memo(
  ({
    activeIndicator = 'static',
    alignActionsMenu,
    aspectRatio = DEFAULT_ASPECT_RATIO,
    hideActions = false,
    isMobile = false,
    isScreen = false,
    isSpeaking = false,
    name,
    network,
    sessionId,
    showNames,
    onVideoResize,
    videoFit = 'contain',
    ...props
  }) => {
    const { colors } = useTheme();
    const tileRef = useRef<HTMLDivElement>(null);
    const videoEl = useRef<HTMLVideoElement>(null);
    const [tileAspectRatio, setTileAspectRatio] = useState(aspectRatio);
    const videoState = useMediaTrack(
      sessionId,
      isScreen ? 'screenVideo' : 'video'
    );

    const activeId = useActiveSpeaker();
    const participant = useParticipant(sessionId);

    const hasPoorConnection = network === 'very-low' && participant?.local;

    useEffect(() => {
      if (aspectRatio === tileAspectRatio) return;
      setTileAspectRatio(aspectRatio);
    }, [aspectRatio, tileAspectRatio]);

    const displayName = useDisplayName(participant, showNames, network, name);

    /**
     * Update video aspect ratio if remote screen share window gets resized
     */
    const handleVideoResize = useCallback(
      (aspectRatio: number) => {
        if (isScreen) setTileAspectRatio(aspectRatio);
        onVideoResize?.(aspectRatio);
      },
      [isScreen, onVideoResize]
    );

    /**
     * Set up resize observer to update noVideo font styles
     */
    const updateNoVideoFontStyles = useCallback(
      (width = tileRef.current?.clientWidth) => {
        if (document.hidden || !tileRef.current) return;
        if (!tileRef.current.classList.contains('noVideo')) return;
        const noVideoText: HTMLParagraphElement =
          tileRef.current.querySelector('.noVideo strong');
        const shouldRenderSmallText = width < SM_TILE_MAX_WIDTH;
        if (!noVideoText) return;
        noVideoText.style.fontSize = isMobile
          ? pxToRem(16)
          : shouldRenderSmallText
          ? pxToRem(12)
          : pxToRem(20);
        noVideoText.style.lineHeight = isMobile
          ? pxToRem(20)
          : shouldRenderSmallText
          ? pxToRem(16)
          : pxToRem(24);
      },
      [isMobile]
    );
    useResizeObserver(tileRef, updateNoVideoFontStyles);
    useEffect(() => {
      updateNoVideoFontStyles();
    }, [updateNoVideoFontStyles, videoState.isOff]);

    return (
      <div
        ref={tileRef}
        className={classnames('tile', videoFit, {
          active: activeId === sessionId,
          local: participant?.local,
          isScreenshare: isScreen,
          noVideo: videoState.isOff,
        })}
        id={`${sessionId}${isScreen ? '-screen' : ''}`}
        {...props}
      >
        <div className="content">
          {videoState.isOff ? (
            <NoVideo
              hasPoorConnection={hasPoorConnection}
              isScreen={isScreen}
              name={displayName}
              network={network}
              sessionId={sessionId}
            />
          ) : (
            <TileVideo
              ref={videoEl}
              fit={videoFit}
              isLocal={participant?.local}
              isScreen={isScreen}
              onResize={handleVideoResize}
              sessionId={sessionId}
            />
          )}
          <TileInfo
            activeIndicator={activeIndicator}
            name={displayName}
            sessionId={sessionId}
            videoEl={videoEl}
          />
          {isSpeaking && !isMobile && (
            <div className="activeBorder" role="presentation" />
          )}
          {!hideActions && (
            <TileButtons sessionId={sessionId} videoEl={videoEl} />
            // <TileActions
            //   alignMenu={alignActionsMenu}
            //   isScreen={isScreen}
            //   sessionId={sessionId}
            // />
          )}
          <Debugger isScreen={isScreen} sessionId={sessionId} />
        </div>
        <style jsx>{`
          .tile .content {
            padding-bottom: ${100 / tileAspectRatio}%;
          }
        `}</style>
        <style jsx>{`
          .tile {
            background-color: ${colors.custom.mainAreaBgAccent};
            min-width: 1px;
            overflow: hidden;
            position: relative;
            width: 100%;
            border-radius: 8px;
          }
          .tile.noVideo :global(video) {
            visibility: hidden;
          }
          .activeBorder {
            border: 2px solid ${colors.system.yellow};
            height: 100%;
            left: 0;
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 100%;
          }
          .loading {
            left: 50%;
            margin: 0;
            position: absolute;
            top: calc(50% - 2em);
            transform: translate(-50%, -50%);
          }
          :global(.with-keyboard) .tile:focus-within :global(.tile-actions),
          .tile:hover :global(.tile-actions) {
            opacity: 1;
          }
        `}</style>
      </div>
    );
  }
);
Tile.displayName = 'Tile';
