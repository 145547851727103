import { useDaily } from '@daily-co/daily-react-hooks';
import { useIframeMeetingLifecycleHandlers } from 'hooks/iframe/useIframeMeetingLifecycleHandlers';
import { useIframeParticipantsHandlers } from 'hooks/iframe/useIframeParticipantsHandlers';
import { useIframeUIControlHandlers } from 'hooks/iframe/useIframeUIControlHandlers';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import IframeDriverMessageChannel from './IframeDriverMessageChannel';

interface Props {
  frameId: string;
}

interface ContextValue {
  sendMessageToDriver: (msg: any) => void;
}

export const IframeDriverContext = createContext<ContextValue>(null);

export const IframeDriverProvider: React.FC<Props> = ({
  children,
  frameId,
}) => {
  const daily = useDaily();
  const [messageChannel, setMessageChannel] =
    useState<IframeDriverMessageChannel>(null);

  const { handleCallMachineJoinedMeetingMsg, handleDriverJoinMeetingMsg } =
    useIframeMeetingLifecycleHandlers(messageChannel);

  const {
    handleCallMachineParticipantMsg,
    handleDriverUpdateParticipantMsg,
    handleDriverUpdateParticipantsMsg,
  } = useIframeParticipantsHandlers(messageChannel);

  const {
    handleDriverEnterFullscreen,
    handleDriverExitFullscreen,
    handleDriverSetActiveSpeakerModeMsg,
    handleDriverSetPlayNewParticipantSound,
    handleDriverShowLocalVideoMsg,
    handleDriverShowNames,
    handleDriverShowParticipantsBarMsg,
    handleSetThemeMsg,
    handleUpdateCustomTrayButtonsMsg,
  } = useIframeUIControlHandlers(messageChannel);

  /**
   * Create a message channel to the iframe driver, if we're in an iframe.
   */
  useEffect(() => {
    if (!frameId) return;

    setMessageChannel(new IframeDriverMessageChannel(frameId));

    return () => {
      setMessageChannel(null);
    };
  }, [frameId]);

  /**
   * Tell the driver that the prebuilt UI has loaded, unblocking it from sending
   * subsequent messages like 'join-meeting'.
   */
  useEffect(() => {
    if (!messageChannel) return;
    messageChannel.sendMessageToDriver({ action: 'loaded' });
  }, [messageChannel]);

  /**
   * Set up handling of messages from the iframe driver.
   *
   * Note that we don't require callObject to exist yet in this effect, since
   * 'join-meeting' can happen *before* we initialize our callObject.
   */
  useEffect(() => {
    if (!messageChannel) return;

    const driverListenerId = messageChannel.addListenerForMessagesFromDriver(
      (msg: any) => {
        switch (msg.action) {
          // Add any special-case message handling here. Special messages include:
          // - UI control messages (e.g. 'set-active-speaker-mode')
          // - call machine control messages that require non-default handling
          // Note: try not to modify the message, to not interfere with possible
          // other listeners in the chain.
          case 'update-custom-tray-buttons':
            handleUpdateCustomTrayButtonsMsg(msg);
            break;
          case 'set-theme':
            handleSetThemeMsg(msg);
            break;
          case 'join-meeting':
            handleDriverJoinMeetingMsg(msg);
            break;
          case 'daily-method-set-play-ding':
            handleDriverSetPlayNewParticipantSound(msg);
            break;
          case 'daily-method-subscribe-to-tracks-automatically':
            console.warn(
              'Prebuilt UI does not support setSubscribeToTracksAutomatically()'
            );
            // Swallow msg (don't forward to call machine)
            break;
          case 'update-participant':
            handleDriverUpdateParticipantMsg(msg);
            break;
          case 'update-participants':
            handleDriverUpdateParticipantsMsg(msg);
            break;
          case 'set-active-speaker-mode':
            handleDriverSetActiveSpeakerModeMsg(msg);
            break;
          case 'set-show-local-video':
            handleDriverShowLocalVideoMsg(msg);
            break;
          case 'set-show-names':
            handleDriverShowNames(msg);
            break;
          case 'set-show-participants-bar':
            handleDriverShowParticipantsBarMsg(msg);
            break;
          case 'fullscreen':
            handleDriverEnterFullscreen(msg);
            break;
          case 'exited-fullscreen':
            handleDriverExitFullscreen(msg);
            break;
          default:
            // By default, simply pass along the message to the call machine.
            // (Right now we're using a special "private" callObject method, for
            //  convenience. Note that it doesn't have to be this way: if we
            //  wanted to limit ourselves only to public callObject methods, we
            //  could handle each message from the driver as a special case,
            //  invoking the corresponding callObject method and forwarding the
            //  response to the iframe driver.)
            // @ts-ignore
            daily?.forwardPackagedMessageToCallMachine(msg);
            break;
        }
      }
    );

    return () => {
      messageChannel?.removeListenerForMessagesFromDriver(driverListenerId);
    };
  }, [
    daily,
    messageChannel,
    handleDriverEnterFullscreen,
    handleDriverExitFullscreen,
    handleDriverJoinMeetingMsg,
    handleDriverSetActiveSpeakerModeMsg,
    handleDriverSetPlayNewParticipantSound,
    handleDriverShowLocalVideoMsg,
    handleDriverShowNames,
    handleDriverShowParticipantsBarMsg,
    handleDriverUpdateParticipantMsg,
    handleDriverUpdateParticipantsMsg,
    handleUpdateCustomTrayButtonsMsg,
    handleSetThemeMsg,
  ]);

  /**
   * Set up handling of messages from the call machine.
   */
  useEffect(() => {
    if (!daily) return;

    // Listen for any messages from the call machine.
    // (Right now we're using a special "private" callObject method, for
    //  convenience. Note that it doesn't have to be this way: see comment in
    //  driver message listener for a description of the public-method-only
    //  approach.)
    const callMachineListenerId =
      // @ts-ignore
      daily.addListenerForPackagedMessagesFromCallMachine((msg) => {
        switch (msg.action) {
          // Add any special-case message handling here.
          // Note: try not to modify the message, to not interfere with possible
          // other listeners in the chain.
          case 'loaded':
            // Swallow msg (don't forward to driver), since prebuilt UI sends
            // its own 'loaded' message
            break;
          case 'joined-meeting':
            handleCallMachineJoinedMeetingMsg(msg);
            break;
          case 'participant-joined':
          case 'participant-updated':
          case 'participant-left':
            handleCallMachineParticipantMsg(msg);
            break;
          case 'track-started':
          case 'track-stopped':
            // Swallow track events, since tracks can't be serialized and sent
            // through the message channel.
            break;
          default:
            // By default, simply pass along the message to the iframe driver.
            messageChannel?.forwardPackagedMessageToDriver(msg);
            break;
        }
      });

    return () => {
      // @ts-ignore
      daily.removeListenerForPackagedMessagesFromCallMachine(
        callMachineListenerId
      );
    };
  }, [
    daily,
    messageChannel,
    handleCallMachineJoinedMeetingMsg,
    handleCallMachineParticipantMsg,
  ]);

  const sendMessageToDriver = useCallback(
    (message: any) => {
      if (!messageChannel) return;
      messageChannel.sendMessageToDriver(message);
    },
    [messageChannel]
  );

  return (
    <IframeDriverContext.Provider value={{ sendMessageToDriver }}>
      {children}
    </IframeDriverContext.Provider>
  );
};

export const useIframeDriver = () => useContext(IframeDriverContext);
