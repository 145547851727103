import { useTheme } from '@daily/shared/contexts/Theme';
import { useMediaTrack } from '@daily-co/daily-react-hooks';
import Bowser from 'bowser';
import classNames from 'classnames';
import {
  forwardRef,
  MutableRefObject,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDeepCompareMemo } from 'use-deep-compare';

interface Props extends React.HTMLAttributes<HTMLVideoElement> {
  fit?: 'contain' | 'cover';
  isLocal?: boolean;
  isScreen?: boolean;
  onResize?(aspectRatio: number): void;
  sessionId: string;
}

export const TileVideo = forwardRef<HTMLVideoElement, Props>(
  (
    {
      fit = 'contain',
      isLocal = false,
      isScreen = false,
      onResize,
      sessionId,
      ...props
    },
    videoEl: MutableRefObject<HTMLVideoElement>
  ) => {
    const { mediaQueries } = useTheme();
    const isLocalCam = useMemo(() => isLocal && !isScreen, [isLocal, isScreen]);
    const [isMirrored, setIsMirrored] = useState(isLocalCam);
    const videoState = useMediaTrack(
      sessionId,
      isScreen ? 'screenVideo' : 'video'
    );
    const videoTrack = useMemo(
      () => videoState.persistentTrack,
      [videoState.persistentTrack]
    );

    /**
     * Considered as playable video:
     * - local cam feed
     * - any screen share
     * - remote cam feed that is subscribed and reported as playable
     */
    const isPlayable = useDeepCompareMemo(
      () => isLocalCam || isScreen || !videoState.isOff,
      [isLocalCam, isScreen, videoState.isOff]
    );

    const isChrome92 = useMemo(() => {
      const { browser, os, platform } = Bowser.parse(navigator.userAgent);
      return (
        browser.name === 'Chrome' &&
        parseInt(browser.version, 10) >= 92 &&
        (platform.type === 'desktop' || os.name === 'Android')
      );
    }, []);

    /**
     * Determine if video needs to be mirrored.
     */
    useEffect(() => {
      if (!videoTrack) return;

      const videoTrackSettings = videoTrack.getSettings();
      const isUsersFrontCamera =
        'facingMode' in videoTrackSettings
          ? isLocalCam && videoTrackSettings.facingMode === 'user'
          : isLocalCam;
      // only apply mirror effect to user facing camera
      if (isMirrored !== isUsersFrontCamera) {
        setIsMirrored(isUsersFrontCamera);
      }
    }, [isMirrored, isLocalCam, videoTrack]);

    /**
     * Handle canplay & picture-in-picture events.
     */
    useEffect(() => {
      const video = videoEl.current;
      if (!video) return;
      const handleCanPlay = () => {
        if (!video.paused) return;
        video.play();
      };
      const handleEnterPIP = () => {
        video.style.transform = 'scale(1)';
      };
      const handleLeavePIP = () => {
        video.style.transform = '';
        setTimeout(() => {
          if (video.paused) video.play();
        }, 100);
      };
      video.addEventListener('canplay', handleCanPlay);
      video.addEventListener('enterpictureinpicture', handleEnterPIP);
      video.addEventListener('leavepictureinpicture', handleLeavePIP);
      return () => {
        video.removeEventListener('canplay', handleCanPlay);
        video.removeEventListener('enterpictureinpicture', handleEnterPIP);
        video.removeEventListener('leavepictureinpicture', handleLeavePIP);
      };
    }, [videoEl]);

    /**
     * Update srcObject.
     */
    useEffect(() => {
      const video = videoEl.current;
      if (!video || !videoTrack) return;
      video.srcObject = new MediaStream([videoTrack]);
      if (isChrome92) video.load();
      return () => {
        // clean up when unmounted
        video.srcObject = null;
        if (isChrome92) video.load();
      };
    }, [isChrome92, videoEl, videoTrack, videoTrack?.id]);

    /**
     * Add optional event listener for resize event so the parent component
     * can know the video's native aspect ratio.
     */
    useEffect(() => {
      const video = videoEl.current;
      if (!onResize || !video) return;

      let frame: ReturnType<typeof requestAnimationFrame>;
      const handleResize = () => {
        if (!video) return;
        if (frame) cancelAnimationFrame(frame);
        frame = requestAnimationFrame(() => {
          if (document.hidden) return;
          const settings = videoTrack?.getSettings();
          const trackRatio = settings?.width / settings?.height;
          const renderedRatio = video
            ? video.videoWidth / video.videoHeight
            : null;
          const finalRatio = renderedRatio ?? trackRatio;
          if (finalRatio) {
            onResize(finalRatio);
            return;
          }
        });
      };

      handleResize();
      video?.addEventListener('resize', handleResize);

      return () => video?.removeEventListener('resize', handleResize);
    }, [onResize, videoEl, videoTrack]);

    return (
      <>
        <video
          className={classNames(fit, {
            isMirrored,
            playable: isPlayable,
          })}
          autoPlay
          muted
          playsInline
          ref={videoEl}
          {...props}
        />
        <style jsx>{`
          video {
            height: calc(100% + 4px);
            left: -2px;
            object-position: center;
            opacity: 0;
            position: absolute;
            top: -2px;
            width: calc(100% + 4px);
          }
          @media ${mediaQueries.coarse} {
            video {
              border-radius: 4px;
            }
          }
          video.playable {
            opacity: 1;
          }
          video.isMirrored {
            transform: scale(-1, 1);
          }
          video.contain {
            object-fit: contain;
          }
          video.cover {
            object-fit: cover;
          }
        `}</style>
      </>
    );
  }
);
TileVideo.displayName = 'TileVideo';
