import { Select } from '@daily/shared/components/Select';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { sortByKey } from '@daily/shared/lib/sortByKey';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { robotsClassName } from '../../lib/robots';

const langLabels = {
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  fi: 'Suomi',
  fr: 'Français',
  it: 'Italiano',
  jp: '日本語',
  ka: 'ქართული',
  nl: 'Nederlands',
  no: 'Norsk',
  pl: 'Polski',
  pt: 'Português',
  ru: 'Pусский',
  sv: 'Svenska',
  tr: 'Türkçe',
};

export const LanguageSettings: React.FC = () => {
  const { i18n, t } = useTranslation();

  const handleChange = (value: string) => {
    i18n.changeLanguage(value);
  };

  const options = useMemo(
    () =>
      (i18n.options.supportedLngs || [])
        .filter((e) => e !== 'cimode')
        .map((lang) => ({
          label: langLabels[lang],
          selected: i18n.language === lang,
          value: lang,
        }))
        .sort(sortByKey('label')),
    [i18n]
  );

  return (
    <Stack gap={16}>
      <Text variant="large">{t('settings.modal.languageDesc')}</Text>
      <Select
        className={robotsClassName('select-language')}
        id="lang"
        name="lang"
        native
        onChange={handleChange}
        options={options}
      />
    </Stack>
  );
};
