import { useUniqueId } from 'hooks/useUniqueId';
import { SVGProps } from 'react';

export function Diamond(props: SVGProps<SVGSVGElement>) {
  // Gradient ID's
  const a = useUniqueId();
  const b = useUniqueId();
  const c = useUniqueId();
  const d = useUniqueId();
  const e = useUniqueId();
  const f = useUniqueId();
  const g = useUniqueId();
  const h = useUniqueId();
  const i = useUniqueId();
  const j = useUniqueId();
  const k = useUniqueId();
  const l = useUniqueId();
  const m = useUniqueId();
  const n = useUniqueId();
  const o = useUniqueId();
  const p = useUniqueId();
  const q = useUniqueId();

  return (
    <svg width={120} height={120} viewBox="0 0 120 120" fill="none" {...props}>
      <g clipPath={`url(#${a})`}>
        <path
          d="m18.757 45.319 7.16-12.477-11.9-10.854L3.43 40.348l15.327 4.97Z"
          fill={`url(#${b})`}
        />
        <path
          d="m62.018 16.612 14.016 3.043L82.59 4.947 62.018.483v16.129Z"
          fill={`url(#${c})`}
        />
        <path
          d="m28.637 29.798 11.597-8.52L33.78 6.569 16.738 19.046l11.9 10.752Z"
          fill={`url(#${d})`}
        />
        <path
          d="m43.623 24.124-13.225 9.608c-.1.102-.202.203-.303.304l-8.177 14.16c-.1.1-.1.303-.1.404L20 64.984v.405l5.048 15.575c0 .1.1.303.202.404l10.903 12.137c.1.101.202.202.302.202l14.942 6.675c.1.101.302.101.403.101h16.355c.1 0 .303 0 .404-.101l14.94-6.675c.102-.101.203-.101.304-.202l10.903-12.137c.1-.1.201-.202.201-.404l5.048-15.575c0-.102.101-.304 0-.405L98.24 48.701c0-.101-.101-.304-.101-.405l-8.177-14.159-.303-.303-13.225-9.609c-.101-.1-.202-.1-.404-.202l-15.648-3.54h-.403l-16.052 3.44c-.101 0-.202.1-.303.201Z"
          fill={`url(#${e})`}
        />
        <path
          d="m16.034 63.577 1.512-14.404-15.328-4.97L0 65.3l16.034-1.724Z"
          fill={`url(#${f})`}
        />
        <path
          d="m116.471 40.348-10.488-18.36-11.899 10.854 7.16 12.477 15.227-4.97Z"
          fill={`url(#${g})`}
        />
        <path
          d="m79.764 21.278 11.596 8.52 11.899-10.853-16.94-12.376-6.555 14.709Z"
          fill={`url(#${h})`}
        />
        <path
          d="m43.967 19.655 14.017-3.043V.483L37.412 4.947l6.555 14.708Z"
          fill={`url(#${i})`}
        />
        <path
          d="m103.564 67.635-4.437 13.694 13.916 8.013 6.454-20.084-15.933-1.623Z"
          fill={`url(#${j})`}
        />
        <path
          d="m97.11 84.777-9.58 10.854 9.377 12.984 14.118-15.723-13.916-8.115Z"
          fill={`url(#${k})`}
        />
        <path
          d="m20.874 81.329-4.437-13.694L.504 69.258l6.454 20.084 13.916-8.013Z"
          fill={`url(#${l})`}
        />
        <path
          d="m102.453 49.173 1.513 14.404 16.033 1.724-2.218-21.099-15.328 4.97Z"
          fill={`url(#${m})`}
        />
        <path
          d="m84.201 97.964-13.11 5.883 3.329 15.824 19.26-8.622-9.479-13.085Z"
          fill={`url(#${n})`}
        />
        <path
          d="M67.159 104.659h-14.32l-3.327 15.824h20.975l-3.328-15.824Z"
          fill={`url(#${o})`}
        />
        <path
          d="m32.47 95.53-9.58-10.651-13.915 8.013 14.016 15.723 9.48-13.085Z"
          fill={`url(#${p})`}
        />
        <path
          d="m48.907 103.847-13.21-5.883-9.379 13.085 19.26 8.622 3.329-15.824Z"
          fill={`url(#${q})`}
        />
      </g>
      <defs>
        <linearGradient
          id={b}
          x1={31.279}
          y1={30.683}
          x2={-19.431}
          y2={17.933}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5617F" />
          <stop offset={0.505} stopColor="#8F172F" />
          <stop offset={1} stopColor="#6F0000" />
        </linearGradient>
        <linearGradient
          id={c}
          x1={64.893}
          y1={14.745}
          x2={95.609}
          y2={6.052}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5617F" />
          <stop offset={0.505} stopColor="#8F172F" />
          <stop offset={1} stopColor="#6F0000" />
        </linearGradient>
        <linearGradient
          id={d}
          x1={41.711}
          y1={31.552}
          x2={-20.591}
          y2={-2.641}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5617F" />
          <stop offset={0.505} stopColor="#8F172F" />
          <stop offset={1} stopColor="#6F0000" />
        </linearGradient>
        <linearGradient
          id={e}
          x1={60}
          y1={20.483}
          x2={60}
          y2={100.483}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5617F" />
          <stop offset={0.505} stopColor="#8F172F" />
          <stop offset={1} stopColor="#6F0000" />
        </linearGradient>
        <linearGradient
          id={f}
          x1={23.165}
          y1={44.202}
          x2={0}
          y2={73.569}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5617F" />
          <stop offset={0.505} stopColor="#8F172F" />
          <stop offset={1} stopColor="#6F0000" />
        </linearGradient>
        <linearGradient
          id={g}
          x1={105.277}
          y1={21.988}
          x2={105.277}
          y2={45.319}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5617F" />
          <stop offset={0.505} stopColor="#8F172F" />
          <stop offset={1} stopColor="#6F0000" />
        </linearGradient>
        <linearGradient
          id={h}
          x1={74.165}
          y1={19.671}
          x2={105.171}
          y2={21.7}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5617F" />
          <stop offset={0.505} stopColor="#8F172F" />
          <stop offset={1} stopColor="#6F0000" />
        </linearGradient>
        <linearGradient
          id={i}
          x1={54.461}
          y1={10.069}
          x2={6.069}
          y2={-16.84}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5617F" />
          <stop offset={0.505} stopColor="#8F172F" />
          <stop offset={1} stopColor="#6F0000" />
        </linearGradient>
        <linearGradient
          id={j}
          x1={109.312}
          y1={67.635}
          x2={115.313}
          y2={94.723}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5617F" />
          <stop offset={0.505} stopColor="#8F172F" />
          <stop offset={1} stopColor="#6F0000" />
        </linearGradient>
        <linearGradient
          id={k}
          x1={95.319}
          y1={79.075}
          x2={87.529}
          y2={108.615}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5617F" />
          <stop offset={0.505} stopColor="#8F172F" />
          <stop offset={1} stopColor="#6F0000" />
        </linearGradient>
        <linearGradient
          id={l}
          x1={16.79}
          y1={63.717}
          x2={10.689}
          y2={89.342}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5617F" />
          <stop offset={0.505} stopColor="#8F172F" />
          <stop offset={1} stopColor="#6F0000" />
        </linearGradient>
        <linearGradient
          id={m}
          x1={111.226}
          y1={44.202}
          x2={104.302}
          y2={67.774}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5617F" />
          <stop offset={0.505} stopColor="#8F172F" />
          <stop offset={1} stopColor="#6F0000" />
        </linearGradient>
        <linearGradient
          id={n}
          x1={82.386}
          y1={92.115}
          x2={82.386}
          y2={119.671}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5617F" />
          <stop offset={0.505} stopColor="#8F172F" />
          <stop offset={1} stopColor="#6F0000" />
        </linearGradient>
        <linearGradient
          id={o}
          x1={54.461}
          y1={96.751}
          x2={59.999}
          y2={120.483}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5617F" />
          <stop offset={0.505} stopColor="#8F172F" />
          <stop offset={1} stopColor="#6F0000" />
        </linearGradient>
        <linearGradient
          id={p}
          x1={20.723}
          y1={84.879}
          x2={20.723}
          y2={108.615}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5617F" />
          <stop offset={0.505} stopColor="#8F172F" />
          <stop offset={1} stopColor="#6F0000" />
        </linearGradient>
        <linearGradient
          id={q}
          x1={43.739}
          y1={91.535}
          x2={37.612}
          y2={119.671}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5617F" />
          <stop offset={0.505} stopColor="#8F172F" />
          <stop offset={1} stopColor="#6F0000" />
        </linearGradient>
        <clipPath id={a}>
          <path fill="#fff" transform="translate(0 .483)" d="M0 0h120v120H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
