import {
  useAudioTrack,
  useScreenAudioTrack,
} from '@daily-co/daily-react-hooks';
import { captureMessage } from '@sentry/browser';
import { useEffect, useRef } from 'react';

import { useAutoplayFailedModal } from '../../contexts/UIState';

interface Props {
  sessionId: string;
}

export const AudioTrack: React.FC<Props> = ({ sessionId }) => {
  const audioEl = useRef<HTMLAudioElement>(null);
  const screenAudioEl = useRef<HTMLAudioElement>(null);
  const [, setShowAutoplayFailedModal] = useAutoplayFailedModal();
  const audio = useAudioTrack(sessionId);
  const screenAudio = useScreenAudioTrack(sessionId);

  /**
   * Setup audio tag.
   */
  useEffect(() => {
    const audioTag = audioEl.current;
    if (!audioTag || !audio?.persistentTrack) return;
    let playTimeout;
    const handleCanPlay = () => {
      playTimeout = setTimeout(() => {
        captureMessage(`Can't play audio for sessionId "${sessionId}".`);
        setShowAutoplayFailedModal(true);
      }, 1500);
    };
    const handlePlay = () => {
      clearTimeout(playTimeout);
    };
    audioTag.addEventListener('canplay', handleCanPlay);
    audioTag.addEventListener('play', handlePlay);
    audioTag.srcObject = new MediaStream([audio?.persistentTrack]);

    return () => {
      audioTag?.removeEventListener('canplay', handleCanPlay);
      audioTag?.removeEventListener('play', handlePlay);
    };
  }, [audio?.persistentTrack, sessionId, setShowAutoplayFailedModal]);

  /**
   * Setup screenAudio tag.
   */
  useEffect(() => {
    const screenAudioTag = screenAudioEl.current;
    if (!screenAudioTag || !screenAudio?.persistentTrack) return;
    let playTimeout;
    const handleCanPlay = () => {
      playTimeout = setTimeout(() => {
        captureMessage(`Can't play screenAudio for sessionId "${sessionId}".`);
        setShowAutoplayFailedModal(true);
      }, 1500);
    };
    const handlePlay = () => {
      clearTimeout(playTimeout);
    };
    screenAudioTag.addEventListener('canplay', handleCanPlay);
    screenAudioTag.addEventListener('play', handlePlay);
    screenAudioTag.srcObject = new MediaStream([screenAudio?.persistentTrack]);

    return () => {
      screenAudioTag?.removeEventListener('canplay', handleCanPlay);
      screenAudioTag?.removeEventListener('play', handlePlay);
    };
  }, [screenAudio?.persistentTrack, sessionId, setShowAutoplayFailedModal]);

  return (
    <>
      {/**
       * We'll have to reserve 2 audio tracks for each participant, specifically in Safari.
       * Otherwise audio might not play, when the call is backgrounded.
       * Reserving an audio tag for screen shares with audio makes sure, that
       * screen share audio can play anytime a screenshare starts, independent of
       * wether the call is backgrounded or not.
       */}
      <audio autoPlay playsInline ref={audioEl} />
      <audio autoPlay playsInline ref={screenAudioEl} />
    </>
  );
};
