import { useTheme } from '@daily/shared/contexts/Theme';
import { useMediaQuery } from '@daily/shared/hooks/useMediaQuery';
import { useResize } from '@daily/shared/hooks/useResize';
import classNames from 'classnames';
import deepEqual from 'fast-deep-equal';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useChatMessages } from '../../contexts/ChatProvider';
import { useCallConfig } from '../../hooks/useCallConfig';
import { usePreviousValue } from '../../hooks/usePreviousValue';
import { ChatMessage } from './ChatMessage';

interface Props {
  loading: boolean;
}

export const ChatScrollView: React.FC<Props> = ({ loading }) => {
  const { isDarkMode, mediaQueries } = useTheme();
  const { enableAdvancedChat } = useCallConfig();
  const { markMessagesAsRead, messages } = useChatMessages();
  const chatRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const lastScrollTime = useRef(0);
  const isScrolledToBottom = useRef(false);
  const [isVisible, setIsVisible] = useState(false);
  const [renderedMessages, setRenderedMessages] = useState<typeof messages>([]);
  const chatMessageHeights = useRef<Record<string, number>>({});
  const [updateHeightCount, setUpdateHeightCount] = useState(0);
  const [enableVirtualScrolling, setEnableVirtualScrolling] = useState(false);

  const isCoarse = useMediaQuery(mediaQueries.coarse);
  /**
   * Minimum height per chat message. Used to calculate minimum height of complete chat.
   */
  const MIN_MSG_HEIGHT = useMemo(() => (isCoarse ? 56 : 40), [isCoarse]);

  /**
   * Update rendered messages
   */
  useEffect(() => {
    const scrollable = chatRef.current;
    if (!scrollable || loading) return;

    setRenderedMessages((rmsgs) => {
      // Check if amount of messages or message structure changed.
      if (
        messages.length > rmsgs.length ||
        rmsgs.some((m, i) => !deepEqual(m, messages?.[i]))
      ) {
        isScrolledToBottom.current =
          // Ignore subpixel rounding differences
          Math.abs(
            scrollable.scrollTop -
              (scrollable.scrollHeight - scrollable.clientHeight)
          ) < 2;
        return messages;
      }
      return rmsgs;
    });
  }, [messages, loading]);

  const previousRenderedMessages = usePreviousValue(renderedMessages);

  useResize(() => {
    const scrollable = chatRef.current;
    if (!scrollable || loading) return;
    setEnableVirtualScrolling(
      scrollable.scrollHeight > scrollable.clientHeight
    );
  }, [loading, messages]);

  /**
   * Allows to update rendered heights for multiple messages.
   */
  const updateMessageHeights = useCallback(
    (updates: Record<string, number>) => {
      const oldHeights = { ...chatMessageHeights.current };
      const newHeights = { ...oldHeights, ...updates };
      chatMessageHeights.current = newHeights;
      if (!deepEqual(oldHeights, newHeights)) {
        setUpdateHeightCount((c) => c + 1);
      }
    },
    []
  );
  /**
   * Index based boundaries for virtual scrolling.
   */
  const [viewFrame, setViewFrame] = useState([
    Math.max(0, messages.length - 15),
    Math.max(0, messages.length - 1),
  ]);

  /**
   * Update relevant styles for virtual scrolling.
   */
  useEffect(() => {
    if (!enableVirtualScrolling) {
      innerRef.current.style.height = '';
      innerRef.current
        .querySelectorAll('[data-msg-id]')
        .forEach((msgEl: HTMLDivElement) => {
          msgEl.style.top = '';
        });
      return;
    }
    const totalHeight = renderedMessages.reduce(
      (sum, msg) =>
        sum + (chatMessageHeights.current?.[msg.id] ?? MIN_MSG_HEIGHT),
      0
    );
    innerRef.current.style.height = `calc(var(--padding) + ${totalHeight}px)`;

    const heightBefore = renderedMessages
      .slice(0, viewFrame[0])
      .reduce(
        (sum, msg) =>
          sum + (chatMessageHeights.current?.[msg.id] ?? MIN_MSG_HEIGHT),
        0
      );

    let top = 0;

    innerRef.current
      .querySelectorAll('[data-msg-id]')
      .forEach((msgEl: HTMLDivElement) => {
        const id = msgEl.getAttribute('data-msg-id');
        const height = chatMessageHeights.current?.[id] ?? MIN_MSG_HEIGHT;
        msgEl.style.top = `${heightBefore + top}px`;
        top += height;
      });
  }, [
    enableVirtualScrolling,
    MIN_MSG_HEIGHT,
    renderedMessages,
    updateHeightCount,
    viewFrame,
  ]);

  /**
   * Setup scroll listener for virtual scrolling.
   */
  useEffect(() => {
    const scrollable = chatRef.current;
    if (!scrollable || loading || !renderedMessages.length) return;

    const handleScroll = (ev: Event) => {
      const target: HTMLDivElement = ev.target as HTMLDivElement;
      const scrollBottom = target.scrollTop + target.clientHeight;
      let start: number;
      let end: number;
      let bottom = target.scrollHeight;
      if (enableVirtualScrolling) {
        /**
         * Chat is actually scrollable, so based on the scroll position
         * we'll have to find the messages of interest.
         * For that we'll loop through the messages and add each message's
         * height (or assumed fallback height) until we reach the actual
         * scroll frame visible to the user.
         * This way we can find the first and last visible messages at the
         * top and bottom of the scroll frame.
         */
        for (let i = renderedMessages.length - 1; i >= 0; i--) {
          const msgHeight =
            chatMessageHeights.current?.[renderedMessages[i].id] ??
            MIN_MSG_HEIGHT;
          bottom -= msgHeight;
          if (bottom < scrollBottom && typeof end === 'undefined') {
            end = i;
          }
          if (bottom <= target.scrollTop && typeof start === 'undefined') {
            start = i;
          }
          // Once we found the boundaries we can stop the loop.
          if (typeof end !== 'undefined' && typeof start !== 'undefined') break;
        }
      } else {
        // No scrolling: render all messages.
        start = 0;
        end = renderedMessages.length - 1;
      }
      if (target.scrollTop < 10) start = 0;
      if (target.scrollTop > target.scrollHeight - target.clientHeight - 10)
        end = renderedMessages.length - 1;
      const buffer = Math.ceil(target.clientHeight / MIN_MSG_HEIGHT / 2);
      requestAnimationFrame(() => {
        setViewFrame([
          Math.max(0, (start ?? 0) - buffer),
          Math.max(0, Math.min(end + buffer, renderedMessages.length - 1)),
        ]);
      });
    };

    scrollable.addEventListener('scroll', handleScroll);
    if (previousRenderedMessages.length < renderedMessages.length) {
      scrollable.dispatchEvent(new Event('scroll'));
    }

    return () => {
      scrollable.removeEventListener('scroll', handleScroll);
    };
  }, [
    enableVirtualScrolling,
    loading,
    MIN_MSG_HEIGHT,
    previousRenderedMessages,
    renderedMessages,
    updateHeightCount,
  ]);

  /**
   * Trigger recalculation of viewFrame in case no messages are rendered.
   */
  useEffect(() => {
    const renderedCount = viewFrame[1] - viewFrame[0];
    if (renderedMessages.length > 0 && renderedCount >= 0) return;
    const timeout = setTimeout(() => {
      setUpdateHeightCount((c) => c + 1);
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, [renderedMessages, viewFrame]);

  /**
   * Scrolls chat to the bottom.
   * Dependent of wether the chat is visible or not,
   * scrolling is done instantly (invisible) or smoothly (visible).
   */
  const scrollToBottom = useCallback(() => {
    if (!chatRef.current || loading) return;
    const timeout = setTimeout(() => {
      const scrollable = chatRef.current;
      if (!scrollable) return;
      lastScrollTime.current = new Date().getTime();
      scrollable.scrollTop = scrollable.scrollHeight;
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, [loading]);

  /**
   * Initially show chat
   */
  useEffect(() => {
    const scrollable = chatRef.current;
    if (!scrollable || loading || isVisible) return;
    const interval = setInterval(() => {
      // Keep scrolling to bottom until we eventually reach the bottom.
      if (
        scrollable.scrollTop >=
        Math.max(0, scrollable.scrollHeight - scrollable.clientHeight - 10)
      ) {
        setIsVisible(true);
        markMessagesAsRead();
        return;
      }
      scrollable.scrollTop = scrollable.scrollHeight;
    }, 250);

    return () => {
      clearInterval(interval);
      markMessagesAsRead();
    };
  }, [
    isVisible,
    loading,
    markMessagesAsRead,
    scrollToBottom,
    updateHeightCount,
    viewFrame,
  ]);

  /**
   * Automatically scroll to bottom.
   */
  useEffect(() => {
    const lastMessage = renderedMessages?.[renderedMessages.length - 1];
    // Last new message was sent from local
    if (
      renderedMessages.length > previousRenderedMessages?.length &&
      lastMessage?.local
    ) {
      scrollToBottom();
    }
    // First reaction was added to any message while scrolled down
    const prevMessagesWithReactions = (previousRenderedMessages ?? []).filter(
      (msg) =>
        Object.values(msg?.reactions ?? {}).reduce(
          (sum, r) => sum + r?.count,
          0
        ) > 0
    );
    const messagesWithReactions = renderedMessages.filter(
      (msg) =>
        Object.values(msg?.reactions ?? {}).reduce(
          (sum, r) => sum + r?.count,
          0
        ) > 0
    );
    if (
      previousRenderedMessages?.length === renderedMessages.length &&
      prevMessagesWithReactions.length !== messagesWithReactions.length &&
      isScrolledToBottom.current
    ) {
      scrollToBottom();
    }
    // Chat was scrolled to bottom previously -> continue scrolling to bottom
    if (
      renderedMessages.length > previousRenderedMessages?.length &&
      isScrolledToBottom.current
    ) {
      scrollToBottom();
    }

    // Have less than 500ms passed since the last scroll event?
    // If yes, we'll assume we are still scrolling.
    const isScrolling =
      lastScrollTime.current &&
      new Date().getTime() < lastScrollTime.current + 500;
    if (isScrolling) {
      scrollToBottom();
    }
  }, [previousRenderedMessages, renderedMessages, scrollToBottom]);

  const chatMessageRenderTimeout =
    useRef<ReturnType<typeof requestAnimationFrame>>(null);
  const updatedMessageHeights = useRef<Record<string, number>>({});
  const handleMessageRender = useCallback(
    (id: string, el: HTMLElement) => {
      const oldMsg = messages.find((msg) => msg.id === id);
      if (!oldMsg || !el) return;
      cancelAnimationFrame(chatMessageRenderTimeout.current);
      updatedMessageHeights.current[id] = el.clientHeight;
      chatMessageRenderTimeout.current = requestAnimationFrame(() => {
        updateMessageHeights(updatedMessageHeights.current);
        updatedMessageHeights.current = {};
      });
      /**
       * In case the last message reports an updated height (e.g. when a GIF loaded),
       * scroll to the bottom.
       */
      if (
        id === messages[messages.length - 1].id &&
        el.clientHeight > chatMessageHeights.current[id] &&
        isScrolledToBottom.current
      )
        scrollToBottom();
    },
    [messages, scrollToBottom, updateMessageHeights]
  );

  const wrappedChatMessages = useMemo(() => {
    let lastRenderedTime: number = 0;
    return renderedMessages
      .map((msg, i) => {
        const { fromId, received } = msg;
        const prevMsg = renderedMessages?.[i - 1];
        const isDifferentSender = prevMsg?.fromId !== fromId;
        const isNew5MinChunk =
          lastRenderedTime < received.getTime() - 5 * 60 * 1000;
        if (isNew5MinChunk) {
          lastRenderedTime = received.getTime();
        }
        return {
          ...msg,
          showSender: isDifferentSender || isNew5MinChunk,
          showTimestamp: isNew5MinChunk,
        };
      })
      .slice(viewFrame[0], viewFrame[1] + 1)
      .map((msg) => {
        const { id, local } = msg;
        return (
          <div
            key={id}
            className={classNames('message-wrapper', { local })}
            data-msg-id={id}
          >
            <ChatMessage
              enableAdvancedChat={enableAdvancedChat}
              message={msg}
              isLocal={local}
              onRender={handleMessageRender}
              showSender={msg.showSender}
              showTimestamp={msg.showTimestamp}
            />
          </div>
        );
      });
  }, [enableAdvancedChat, handleMessageRender, renderedMessages, viewFrame]);

  return (
    <div
      className={classNames('messages', {
        virtual: enableVirtualScrolling,
        visible: isVisible,
      })}
      ref={chatRef}
    >
      <div className="messages-inner" ref={innerRef}>
        {wrappedChatMessages}
      </div>
      <style jsx>{`
        .messages {
          flex-grow: 1;
          isolation: isolate;
          opacity: 0;
          overflow-x: hidden;
          overflow-y: auto;
          position: relative;
          scrollbar-color: ${isDarkMode ? 'dark' : 'light'};
          scroll-behavior: auto;
          transition: opacity 250ms ease;
        }
        .messages:not(.virtual) .messages-inner {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          min-height: 100%;
        }
        .messages.visible {
          opacity: 1;
          scroll-behavior: smooth;
        }
        .messages-inner {
          min-height: 100%;
          padding: 0 var(--padding) var(--padding);
          position: relative;
        }
        .virtual :global(.message-wrapper) {
          left: var(--padding);
          position: absolute;
          top: var(--padding);
          width: calc(100% - 2 * var(--padding));
        }
      `}</style>
    </div>
  );
};
