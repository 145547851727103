import classnames from 'classnames';
import React, {
  Children,
  cloneElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import { useTheme } from '../../contexts/Theme';
import { useScrollLock } from '../../hooks/useScrollLock';
import { Button } from '../Button';
import { Card, CardContent, CardHeader } from '../Card';
import { FocusTrap } from '../FocusTrap';
import { CloseIcon, LockIcon } from '../Icons';
import { Stack } from '../Stack';
import { Text } from '../Text';
import { VisuallyHidden } from '../VisuallyHidden';

interface Props {
  actions?: JSX.Element[];
  closeText?: string;
  hideOnBackdropClick?: boolean;
  hideOnEsc?: boolean;
  locked?: boolean;
  onClose(): void;
  position?: 'center' | 'top';
  title?: string;
  variant?: 'default' | 'closable' | 'secure';
}

const transitionMs = 200;

export const Modal: React.FC<Props> = ({
  actions = [],
  children,
  closeText = 'Close',
  hideOnBackdropClick = true,
  hideOnEsc = true,
  locked = false,
  onClose,
  position = 'center',
  title = null,
  variant = 'default',
  ...props
}) => {
  const { mediaQueries, zIndex } = useTheme();
  const [isVisible, setIsVisible] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const close = () => {
    setIsVisible(false);
    setTimeout(() => {
      onClose?.();
    }, transitionMs);
  };

  const handleBackdropClick = (ev: React.MouseEvent<HTMLDivElement>) => {
    const isBackDropTarget = ev.target === ev.currentTarget;
    const isFocusOutside = !ev.currentTarget.contains(document.activeElement);
    // close only if backdrop is actually clicked
    if (isBackDropTarget && isFocusOutside && hideOnBackdropClick && !locked) {
      close();
    }
  };

  useScrollLock(true, () => {
    if (hideOnEsc && !locked) close();
  });

  return createPortal(
    <div
      className={classnames('backdrop', { isVisible })}
      onClick={handleBackdropClick}
      role="presentation"
      {...props}
    >
      <div
        className={classnames('modal', position)}
        ref={modalRef}
        role="dialog"
        aria-labelledby="modal-title"
        aria-modal="true"
      >
        <FocusTrap>
          <Card noBorder shadow="strong">
            {title && (
              <CardHeader>
                <Stack align="center" justify="space-between" horizontal>
                  <Text id="modal-title" variant="largestrong">
                    {title}
                  </Text>
                  {variant === 'secure' && (
                    <Stack gap={4} horizontal>
                      <Text color="muted">Secure</Text>
                      <LockIcon color="var(--text-muted)" size={16} />
                    </Stack>
                  )}
                  {variant === 'closable' && (
                    <Button onClick={close} variant="ghost">
                      <CloseIcon />
                      <VisuallyHidden>{closeText}</VisuallyHidden>
                    </Button>
                  )}
                </Stack>
              </CardHeader>
            )}
            <CardContent>{children}</CardContent>
            {actions.length > 0 && (
              <CardContent>
                <div className="modal-actions">
                  {Children.map(actions, (child) =>
                    cloneElement(child, {
                      disabled: child.props?.disabled || locked,
                      onClick: () => {
                        if (child.props?.onClick) {
                          child.props?.onClick?.(close);
                        } else {
                          close();
                        }
                      },
                    })
                  )}
                </div>
              </CardContent>
            )}
          </Card>
        </FocusTrap>
      </div>
      <style jsx>{`
        .backdrop {
          background-color: var(--backdrop-hidden);
          bottom: 0;
          display: flex;
          left: 0;
          overflow-y: auto;
          padding: 40px 0;
          position: fixed;
          right: 0;
          top: 0;
          transition: background ${transitionMs}ms ease;
          z-index: ${zIndex.modal};
        }
        .backdrop .modal {
          max-width: 568px;
          opacity: 0;
          transform: scale(0.95);
          transition: opacity ${transitionMs}ms ease,
            transform ${transitionMs}ms ease;
          width: 90vw;
        }
        .backdrop .modal.center {
          margin: auto;
        }
        .backdrop .modal.top {
          margin: 0px auto auto;
        }
        .isVisible {
          background-color: var(--backdrop);
        }
        .isVisible .modal {
          opacity: 1;
          transform: scale(1);
        }
        .modal-actions {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 8px;
        }
        @media ${mediaQueries.large} {
          .backdrop {
            padding: 80px 0;
          }
        }
      `}</style>
    </div>,
    document.body
  );
};
