import classnames from 'classnames';
import { motion } from 'framer-motion';
import React, { useEffect } from 'react';

import { useTheme } from '../../contexts/Theme';
import { Stack } from '../Stack';
import { Text } from '../Text';
import { SnackbarIcon } from './SnackbarIcon';

interface Props {
  className?: string;
  icon?: JSX.Element;
  id: number;
  onHide?(): void;
  timeout?: number;
  type?: 'error' | 'success' | 'info';
}

export const Snackbar: React.FC<Props> = ({
  children,
  className,
  icon,
  id,
  onHide,
  timeout = 4000,
  type = 'success',
  ...props
}) => {
  const { zIndex } = useTheme();

  useEffect(() => {
    const hideTimeout = setTimeout(() => {
      onHide();
    }, timeout);
    return () => {
      clearTimeout(hideTimeout);
    };
  }, []);

  const handleClick = () => {
    onHide();
  };

  return (
    <motion.div
      layout
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.95 }}
      transition={{ duration: 0.25 }}
      className={classnames('snackbar', className, type)}
      onClick={handleClick}
      {...props}
    >
      <Stack align="center" gap={4} horizontal>
        <SnackbarIcon type={type} icon={icon} />
        <Text className="text" color="inherit">
          {children}
        </Text>
      </Stack>
      <style jsx global>{`
        .snackbar {
          border-radius: 8px;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
          cursor: pointer;
          padding: 8px 16px;
          transform-origin: center;
          z-index: ${zIndex.snackbar};
        }
        .snackbar.success {
          background-color: var(--snackbar-success-bg);
          color: var(--snackbar-success-color);
        }
        .snackbar.error {
          background-color: var(--snackbar-error-bg);
          color: var(--snackbar-error-color);
        }
        .snackbar.info {
          background-color: var(--snackbar-info-bg);
          color: var(--snackbar-info-color);
        }
        .snackbar :global(.text) {
          padding-bottom: 2px;
        }
      `}</style>
    </motion.div>
  );
};
