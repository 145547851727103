import { Anchor } from '@daily/shared/components/Anchor';
import { Button } from '@daily/shared/components/Button';
import { Card } from '@daily/shared/components/Card';
import { Form } from '@daily/shared/components/Form';
import {
  ArrowRightIcon,
  DownloadIcon,
  EmojiIcon,
  InfoIcon,
  LockIcon,
} from '@daily/shared/components/Icons';
import { LoadingSpinner } from '@daily/shared/components/LoadingSpinner';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { Tooltip } from '@daily/shared/components/Tooltip';
import { VisuallyHidden } from '@daily/shared/components/VisuallyHidden';
import { useTheme } from '@daily/shared/contexts/Theme';
import { pxToRem } from '@daily/shared/lib/pxToRem';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { atom, useRecoilState } from 'recoil';
import tinykeys from 'tinykeys';

import { useChatMessages, useChatState } from '../../contexts/ChatProvider';
import { useCallConfig } from '../../hooks/useCallConfig';
import { useStyleVariants } from '../../hooks/useStyleVariants';
import { getQueryParam } from '../../lib/query';
import { ChatInput } from './ChatInput';
import { ChatScrollView } from './ChatScrollView';
import { EmojiMenu } from './EmojiMenu';
import { GiphyPreview } from './GiphyPreview';
import { useGiphySearch } from './useGiphySearch';

const CHAR_LIMIT = 256;

export const localChatMessageState = atom({
  key: 'local-chat-message',
  default: '',
});

export const Chat: React.FC = () => {
  const { query } = useRouter();
  const { colors, mediaQueries } = useTheme();
  const { t } = useTranslation();
  const { broadcastRole, enableAdvancedChat, optimizeLargeCalls } =
    useCallConfig();
  const { iconVariant, textVariantSmall } = useStyleVariants();
  const { loading, sendMessage } = useChatState();
  const { messages } = useChatMessages();
  const [localMessage, setLocalMessage] = useRecoilState(localChatMessageState);
  const [chatFile, setChatFile] = useState(null);
  const [isEmojiMenuOpen, setIsEmojiMenuOpen] = useState(false);
  const [emojiFilter, setEmojiFilter] = useState('');
  const disableChatInput = optimizeLargeCalls && broadcastRole === 'attendee';

  /**
   * Update chat file download reference.
   */
  useEffect(() => {
    setChatFile(
      window.URL.createObjectURL(
        new Blob(
          messages.map(
            (m) =>
              `${m.received.toLocaleTimeString()} ${m.name}: ${m.message}\n`
          ),
          { type: 'octet/stream' }
        )
      )
    );
  }, [messages]);

  /**
   * Show/Hide emoji menu based on localMessage (= the text currently entered in ChatInput).
   */
  useEffect(() => {
    if (!enableAdvancedChat) return;
    const match = Array.from(localMessage.matchAll(/:\w+/g), (m) => m[0]);
    if (match?.length && localMessage.endsWith(match[match.length - 1])) {
      const searchStr = match[match.length - 1].substring(1);
      setEmojiFilter(searchStr);
      setIsEmojiMenuOpen(true);
    } else {
      setEmojiFilter('');
      setIsEmojiMenuOpen(false);
    }
  }, [enableAdvancedChat, localMessage]);

  const [gifQ, setGifQ] = useState('');
  const { gif, refresh } = useGiphySearch(gifQ);

  const handleSubmit = useCallback(async () => {
    if (localMessage.trim().length === 0) return;
    if (enableAdvancedChat && localMessage.startsWith('/giphy')) {
      const q = localMessage.slice(7);
      setGifQ(q);
    } else {
      sendMessage(localMessage);
    }
    setLocalMessage('');
  }, [enableAdvancedChat, localMessage, sendMessage, setLocalMessage]);

  const handleCancelGif = () => {
    setGifQ('');
  };

  const handleLoadGif = () => {
    const scrollable = document.querySelector('.chat .messages');
    scrollable.scrollTop = scrollable.scrollHeight;
  };

  const handleRefreshGif = () => {
    refresh();
  };

  const handleSendGif = useCallback(() => {
    sendMessage(`![${gif.q}-giphy|${gif.size}|${gif.title}](${gif.url})`);
    setGifQ('');
  }, [gif, sendMessage]);

  /**
   * Setup Escape key listener to cancel sending Gif.
   */
  useEffect(() => {
    const unsubscribe = tinykeys(document.body, {
      Escape: (ev) => {
        if (isEmojiMenuOpen) {
          ev.preventDefault();
          ev.stopImmediatePropagation();
          setIsEmojiMenuOpen(false);
        } else if (gif) {
          ev.preventDefault();
          ev.stopImmediatePropagation();
          setGifQ('');
        }
      },
    });
    return () => {
      unsubscribe();
    };
  }, [gif, isEmojiMenuOpen]);

  const handleInputKeyPress = (ev: React.KeyboardEvent) => {
    if (
      ev.key === 'Enter' &&
      !(ev.shiftKey || ev.altKey || ev.ctrlKey || ev.metaKey)
    ) {
      ev.preventDefault();
      handleSubmit();
    }
  };

  const toggleEmojiPicker = () => {
    setIsEmojiMenuOpen((open) => !open);
  };

  const handleEmojiClose = () => {
    setIsEmojiMenuOpen(false);
    const textarea = document.querySelector(
      '.chat textarea'
    ) as HTMLTextAreaElement;
    if (!textarea) return;
    textarea.focus();
    textarea.setSelectionRange(textarea.value.length, textarea.value.length);
  };

  const handleEmojiSelection = (emoji) => {
    let newMsg = '';
    if (!emojiFilter) {
      newMsg = localMessage + emoji;
    } else {
      newMsg =
        localMessage.substring(0, localMessage.lastIndexOf(`:${emojiFilter}`)) +
        emoji;
    }
    setLocalMessage(newMsg);
    setIsEmojiMenuOpen(false);
    setEmojiFilter('');
  };

  const filename = useMemo(() => {
    const room = getQueryParam('room', query);
    return `${room}-${new Date().toISOString().substring(0, 19)}`;
  }, [query]);

  const downloadChat = useMemo(() => {
    return (
      <Anchor href={chatFile} download={filename}>
        <Stack horizontal align="center" gap={4}>
          <DownloadIcon size={16} display="inline" />
          <Text variant={textVariantSmall} color="default">
            {t('chat.download')}
          </Text>
        </Stack>
      </Anchor>
    );
  }, [chatFile, filename, t, textVariantSmall]);

  return (
    <div
      className={classnames('chat', {
        advanced: enableAdvancedChat,
      })}
    >
      {loading ? (
        <div className="loading">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <ChatScrollView loading={loading} />
          {enableAdvancedChat && gif && (
            <div className="gif-preview">
              <GiphyPreview
                onCancel={handleCancelGif}
                onLoad={handleLoadGif}
                onRefresh={handleRefreshGif}
                onSubmit={handleSendGif}
                title={gif.title}
                url={gif.url}
              />
            </div>
          )}
          {enableAdvancedChat && isEmojiMenuOpen ? (
            <div className="emoji-menu-wrapper">
              <EmojiMenu
                filter={emojiFilter}
                onClose={handleEmojiClose}
                onSelect={handleEmojiSelection}
              />
            </div>
          ) : null}
          {disableChatInput ? (
            <div className="disabled-chat">
              <Card shadow="none">
                <Stack gap={6}>
                  <Stack horizontal align="center" gap={4}>
                    <InfoIcon size={16} color={colors.system.greyDark} />
                    <Text color="muted" variant="small">
                      {t('chat.disabled')}
                    </Text>
                  </Stack>

                  {messages.length > 0 && downloadChat}
                </Stack>
              </Card>
            </div>
          ) : (
            <Form onSubmit={handleSubmit}>
              <Stack>
                <div className="inputWrapper">
                  <ChatInput
                    maxLength={CHAR_LIMIT}
                    onKeyPress={handleInputKeyPress}
                    placeholder={t('chat.inputPlaceholder')}
                  />
                  {enableAdvancedChat ? (
                    <Button
                      className="emojiPicker"
                      variant="ghost"
                      onClick={toggleEmojiPicker}
                    >
                      <EmojiIcon color={colors.baseText} size={iconVariant} />
                    </Button>
                  ) : null}
                  <Button className="submit" variant="ghost" type="submit">
                    <ArrowRightIcon
                      color={colors.baseText}
                      size={iconVariant}
                      variant="thick"
                    />
                    <VisuallyHidden>{t('chat.submit')}</VisuallyHidden>
                  </Button>
                </div>
                <Stack align="center" justify="space-between" horizontal>
                  <Stack align="center" gap={4} horizontal>
                    <Tooltip
                      id="tt-chat-encrypted"
                      placement="top"
                      title={t('chat.encrypted')}
                    >
                      <LockIcon color="var(--text-muted)" size={iconVariant} />
                    </Tooltip>
                    <Text
                      className="char-limit"
                      color={
                        localMessage.length >= CHAR_LIMIT ? 'error' : 'muted'
                      }
                      variant={textVariantSmall}
                    >
                      {localMessage.length
                        ? t('chat.charLimit', {
                            length: localMessage.length,
                            max: CHAR_LIMIT,
                          })
                        : t('chat.charLimitEmpty', { max: CHAR_LIMIT })}
                    </Text>
                  </Stack>
                  {messages.length > 0 && downloadChat}
                </Stack>
              </Stack>
            </Form>
          )}
        </>
      )}
      <style jsx>{`
        .chat {
          --padding: 16px;

          display: flex;
          flex-direction: column;
          height: 100%;
        }
        .loading {
          margin: auto;
        }
        .gif-preview {
          padding: 0 var(--padding);
        }
        .emoji-menu-wrapper {
          padding: 0 var(--padding);
        }
        .chat :global(form) {
          flex: none;
          isolation: isolate;
          margin-top: auto;
          padding: 8px var(--padding) var(--padding);
        }
        .chat :global(.fakeInputCard) {
          box-shadow: none;
        }
        .chat :global(.fakeInput) {
          padding: 12px 28px 12px 12px;
          outline: none;
        }
        .chat.advanced :global(.fakeInput) {
          padding: 12px 52px 12px 12px;
        }
        .chat :global(.fakeInput:focus-within) {
          border-color: var(--focus-border);
        }
        .chat :global(.fakePlaceholder) {
          left: 12px;
          pointer-events: none;
          position: absolute;
          top: 12px;
        }
        .inputWrapper {
          position: relative;
        }
        .chat :global(.emojiPicker) {
          position: absolute;
          right: 30px;
          top: 50%;
          transform: translateY(-50%);
          z-index: 2;
        }
        .chat :global(.submit) {
          position: absolute;
          right: 12px;
          top: 50%;
          transform: translateY(-50%);
          z-index: 2;
        }
        .chat :global(.char-limit) {
          white-space: nowrap;
        }

        .disabled-chat {
          padding-top: 8px;
        }
        .disabled-chat :global(.card) {
          border-radius: 0;
          padding: var(--padding);
          height: auto;
          width: auto;
          border-left: 0;
          border-right: 0;
        }

        @media ${mediaQueries.coarse} {
          .chat :global(.emojiPicker) {
            --size: ${pxToRem(24)};
            font-size: var(--size);
            right: 44px;
            height: var(--size);
            width: var(--size);
          }
        }
      `}</style>
    </div>
  );
};
