import { Button } from '@daily/shared/components/Button';
import { GearIcon } from '@daily/shared/components/Icons';
import { VisuallyHidden } from '@daily/shared/components/VisuallyHidden';
import { useTranslation } from 'react-i18next';

import { useSettingsView } from '../../contexts/UIState';
import { SettingsModal } from '../SettingsModal';

export const SettingsButton: React.FC = () => {
  const { t } = useTranslation();
  const [settingsView, setSettingsView] = useSettingsView();

  const handleClick = () => {
    setSettingsView('devices');
  };

  const handleModalClose = () => {
    setSettingsView(null);
  };

  return (
    <div className="settings">
      {false && (
        <Button className="settings-btn" onClick={handleClick} variant="ghost">
          <GearIcon fill="currentColor" stroke="transparent" size={16} />
          <VisuallyHidden>{t('settings.open')}</VisuallyHidden>
        </Button>
      )}
      {settingsView && <SettingsModal onClose={handleModalClose} />}
      <style jsx>{`
        .settings :global(.settings-btn) {
          margin: -4px;
          padding: 4px;
        }
      `}</style>
    </div>
  );
};
