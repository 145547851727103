import { Badge } from '@daily/shared/components/Badge';
import { Tooltip } from '@daily/shared/components/Tooltip';
import {
  useLiveStreaming,
  useLocalParticipant,
} from '@daily-co/daily-react-hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDeepCompareMemo } from 'use-deep-compare';

import { useCallConfig } from '../../hooks/useCallConfig';

export const LiveBadge: React.FC = () => {
  const { t } = useTranslation();
  const { broadcast } = useCallConfig();
  const localParticipant = useLocalParticipant();

  const { isLiveStreaming, layout } = useLiveStreaming();

  const shouldRenderLiveBadge = useDeepCompareMemo(() => {
    const isOnlyLocalParticipantRecorded =
      layout?.preset === 'single-participant' &&
      layout?.session_id === localParticipant?.session_id;
    return (
      (isLiveStreaming &&
        (isOnlyLocalParticipantRecorded ||
          layout?.preset !== 'single-participant')) ||
      broadcast
    );
  }, [broadcast, isLiveStreaming, layout, localParticipant?.session_id]);

  if (!shouldRenderLiveBadge) return null;

  return (
    <div className="live-badge">
      <Tooltip
        disabled={!localParticipant?.owner}
        id="tt-live-badge"
        title={
          isLiveStreaming
            ? t('liveStreaming.streaming')
            : t('broadcast.presenting')
        }
        placement="bottom"
      >
        <Badge variant="error">{t('broadcast.live')}</Badge>
      </Tooltip>
      <style jsx>{`
        .live-badge {
          cursor: default;
          margin-top: 1px;
        }
      `}</style>
    </div>
  );
};
