import { Stack } from '@daily/shared/components/Stack';
import { useParticipantIds, useScreenShare } from '@daily-co/daily-react-hooks';
import { useEffect } from 'react';

import { usePinnedId } from '../../contexts/UIState';
import { ScreenshareButton } from './ScreenshareButton';
import { Unpin } from './Unpin';
import { ViewSwitch } from './ViewSwitch';

export const TopbarAction: React.FC = () => {
  const participantIds = useParticipantIds();
  const { screens } = useScreenShare();
  const [pinnedId, setPinnedId] = usePinnedId();

  const { stopScreenShare } = useScreenShare();

  useEffect(() => {
    if (!pinnedId) return;
    const pinned =
      participantIds.some((id) => id === pinnedId) ||
      screens.some((s) => s.screenId === pinnedId);
    if (!pinned) {
      // pinned tile is not present anymore
      setPinnedId(null);
    }
  }, [participantIds, pinnedId, screens, setPinnedId]);

  if (pinnedId) {
    return <Unpin />;
  }

  return (
    <Stack align="center" gap={0} horizontal>
      {/* {screens.length > 0 && (
        <div className="screenshare">
          <ScreenshareButton onClick={stopScreenShare} />
        </div>
      )} */}
      <ViewSwitch />
      <style jsx>{`
        .screenshare {
          display: flex;
        }
        .screenshare::after {
          display: none;
          background: var(--card-border);
          content: '';
          height: 16px;
          margin: auto 8px;
          width: 1px;
        }
      `}</style>
    </Stack>
  );
};
