import { Text } from '@daily/shared/components/Text';
import { DARK_THEME_CLASS, useTheme } from '@daily/shared/contexts/Theme';
import {
  useLocalParticipant,
  useParticipantIds,
  useScreenShare,
} from '@daily-co/daily-react-hooks';
import classnames from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useCallState } from '../../contexts/CallProvider';
import {
  useOrderedParticipantIds,
  useParticipants,
} from '../../contexts/ParticipantsProvider';
import { useTracks } from '../../contexts/TracksProvider';
import {
  usePinnedId,
  useShowLocalVideo,
  useSidebarView,
} from '../../contexts/UIState';
import { useCallConfig } from '../../hooks/useCallConfig';
import { ParticipantBar } from './ParticipantBar';
import { ScreensAndPins } from './ScreensAndPins';
import { SpeakerTile } from './SpeakerTile';

const PEOPLE_CHAT_WIDTH = 266;

export const SpeakerView: React.FC = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { showNames, showParticipantsBar } = useCallState();
  const [showLocalVideo] = useShowLocalVideo();
  const { broadcastRole } = useCallConfig();
  const { currentSpeakerId } = useParticipants();
  const orderedParticipantIds = useOrderedParticipantIds();
  const [pinnedId] = usePinnedId();
  const [sidebarView] = useSidebarView();
  const { updateCamSubscriptions } = useTracks();
  const activeRef = useRef<HTMLDivElement>(null);
  const { screens } = useScreenShare();

  const isAttendee = useMemo(
    () => broadcastRole === 'attendee',
    [broadcastRole]
  );

  const localParticipant = useLocalParticipant();
  const ownerIds = useParticipantIds({
    filter: useCallback((participant) => {
      return participant.owner && !participant.local;
    }, []),
  });
  const hasPinnedOrScreens = useMemo(
    () => screens.length > 0 || Boolean(pinnedId),
    [pinnedId, screens]
  );

  const isPinnedScreen = useMemo(
    () => screens.some((s) => s.screenId === pinnedId),
    [pinnedId, screens]
  );

  const showSidebar = useMemo(() => {
    if (isPinnedScreen) {
      return false;
    }
    return orderedParticipantIds.length > 0 || hasPinnedOrScreens;
  }, [hasPinnedOrScreens, isPinnedScreen, orderedParticipantIds]);

  const fixedItems = useMemo(() => {
    const items: string[] = [];
    if (!isAttendee && showLocalVideo) {
      items.push(localParticipant?.session_id);
    }
    if (hasPinnedOrScreens && orderedParticipantIds.length > 0) {
      items.push(orderedParticipantIds[0]);
    }
    return items;
  }, [
    hasPinnedOrScreens,
    isAttendee,
    localParticipant,
    orderedParticipantIds,
    showLocalVideo,
  ]);

  const otherItems = useMemo(() => {
    // TODO: Originally this condition (`orderedParticipantIds.length > 1`) had an issue,
    // when there was only 2 participants in the call and we were hiding one of them.
    if (showParticipantsBar && orderedParticipantIds.length > 0) {
      return orderedParticipantIds.slice(hasPinnedOrScreens ? 1 : 0);
    }
    return [];
  }, [hasPinnedOrScreens, orderedParticipantIds, showParticipantsBar]);

  /**
   * Update cam subscriptions, in case ParticipantBar is not shown.
   */
  useEffect(() => {
    // Sidebar takes care of cam subscriptions for all displayed participants.
    if (showSidebar) return;
    updateCamSubscriptions([
      currentSpeakerId,
      pinnedId,
      ...screens.map((s) => s.session_id),
    ]);
  }, [
    currentSpeakerId,
    pinnedId,
    screens,
    showSidebar,
    updateCamSubscriptions,
  ]);

  return (
    <div className={classnames('speaker', DARK_THEME_CLASS)}>
      {isAttendee && ownerIds.length === 0 ? (
        <Text variant="largestrong" color="white" style={{ margin: 'auto' }}>
          {t('broadcast.hostLeft')}
        </Text>
      ) : (
        <div ref={activeRef} className="active">
          {screens.length > 0 || pinnedId ? (
            <ScreensAndPins />
          ) : (
            <SpeakerTile
              screenRef={activeRef}
              sessionId={currentSpeakerId}
              showNames={showNames}
            />
          )}
        </div>
      )}
      <ParticipantBar
        fixed={fixedItems}
        others={otherItems}
        visible={showSidebar}
      />
      <style jsx>{`
        .speaker {
          display: flex;
          height: 100%;
          position: relative;
          width: calc(
            100% - ${sidebarView !== null ? PEOPLE_CHAT_WIDTH : '0'}px
          );
        }
        .active {
          align-items: center;
          display: flex;
          justify-content: center;
          height: 100%;
          width: 100%;
        }
        .active :global(.activeBorder) {
          display: none;
        }
        .active :global(.tile.isScreenshare) {
          background: ${colors.custom.mainAreaBg};
        }
      `}</style>
    </div>
  );
};
