import { useUniqueId } from 'hooks/useUniqueId';
import { SVGProps } from 'react';

export function Sphene(props: SVGProps<SVGSVGElement>) {
  // Gradient ID's
  const a = useUniqueId();
  const b = useUniqueId();
  const c = useUniqueId();
  const d = useUniqueId();
  const e = useUniqueId();
  const f = useUniqueId();
  const g = useUniqueId();
  const h = useUniqueId();
  const i = useUniqueId();
  const j = useUniqueId();
  const k = useUniqueId();
  const l = useUniqueId();
  return (
    <svg width={120} height={120} viewBox="0 0 120 120" fill="none" {...props}>
      <g clipPath={`url(#${a})`}>
        <path
          d="m34.782 23.252 23.117-7.28V0L25.01 10.312l9.773 12.94Z"
          fill={`url(#${b})`}
        />
        <path
          d="M15.867 71.778V48.323L0 43.37V76.73l15.867-4.953Z"
          fill={`url(#${c})`}
        />
        <path
          d="m17.129 44.481 14.29-18.804-9.772-12.94-20.28 26.79 15.762 4.954Z"
          fill={`url(#${d})`}
        />
        <path
          d="m62.102 15.973 23.117 7.279 9.772-12.94L62.101 0v15.973Z"
          fill={`url(#${e})`}
        />
        <path
          d="M84.042 92.784c.42-.1.84-.402 1.05-.704L99.58 73.073c.21-.301.42-.804.42-1.206V48.234a1.88 1.88 0 0 0-.42-1.207L85.092 28.02a1.927 1.927 0 0 0-1.05-.704l-23.412-7.24c-.42-.101-.84-.101-1.26 0l-23.412 7.24c-.42.1-.84.402-1.05.704L20.42 47.027c-.21.301-.42.804-.42 1.207v23.532c0 .402.105.804.42 1.207L34.908 91.98c.21.301.63.603 1.05.704l23.412 7.24c.42.101.84.101 1.26 0l23.412-7.14Z"
          fill={`url(#${f})`}
        />
        <path
          d="m118.635 39.628-20.28-26.89-9.773 12.838 14.291 18.905 15.762-4.853Z"
          fill={`url(#${g})`}
        />
        <path
          d="M104.133 48.424v23.252L120 76.63V43.47l-15.867 4.954Z"
          fill={`url(#${h})`}
        />
        <path
          d="M102.873 75.518 88.582 94.423l9.772 12.94 20.386-26.891-15.867-4.954Z"
          fill={`url(#${i})`}
        />
        <path
          d="m85.219 96.85-23.117 7.278V120l32.89-10.21-9.773-12.94Z"
          fill={`url(#${j})`}
        />
        <path
          d="m57.9 104.128-23.118-7.279-9.772 12.941L57.9 120v-15.872Z"
          fill={`url(#${k})`}
        />
        <path
          d="M31.42 94.524 17.129 75.619 1.367 80.573l20.28 26.79 9.773-12.84Z"
          fill={`url(#${l})`}
        />
      </g>
      <defs>
        <linearGradient
          id={b}
          x1={41.455}
          y1={26.745}
          x2={33.281}
          y2={0.173}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D8AE13" />
          <stop offset={0.467} stopColor="#FFC900" />
          <stop offset={1} stopColor="#FAFF00" />
        </linearGradient>
        <linearGradient
          id={c}
          x1={7.933}
          y1={34.608}
          x2={7.933}
          y2={76.731}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D8AE13" />
          <stop offset={0.49} stopColor="#FFC900" />
          <stop offset={1} stopColor="#FAFF00" />
        </linearGradient>
        <linearGradient
          id={d}
          x1={16.393}
          y1={12.737}
          x2={10.574}
          y2={62.723}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D8AE13" />
          <stop offset={0.49} stopColor="#FFC900" />
          <stop offset={1} stopColor="#FAFF00" />
        </linearGradient>
        <linearGradient
          id={e}
          x1={74.718}
          y1={39.639}
          x2={78.855}
          y2={0.032}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D8AE13" />
          <stop offset={0.66} stopColor="#FFC900" />
          <stop offset={1} stopColor="#FAFF00" />
        </linearGradient>
        <linearGradient
          id={f}
          x1={60}
          y1={20}
          x2={60}
          y2={100}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D8AE13" />
          <stop offset={0.49} stopColor="#FFC900" />
          <stop offset={1} stopColor="#FAFF00" />
        </linearGradient>
        <linearGradient
          id={g}
          x1={103.608}
          y1={12.737}
          x2={92.05}
          y2={60.168}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D8AE13" />
          <stop offset={0.49} stopColor="#FFC900" />
          <stop offset={1} stopColor="#FAFF00" />
        </linearGradient>
        <linearGradient
          id={h}
          x1={112.066}
          y1={43.471}
          x2={112.066}
          y2={85.563}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D8AE13" />
          <stop offset={0.49} stopColor="#FFC900" />
          <stop offset={1} stopColor="#FAFF00" />
        </linearGradient>
        <linearGradient
          id={i}
          x1={109.14}
          y1={62.66}
          x2={103.874}
          y2={107.388}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D8AE13" />
          <stop offset={0.49} stopColor="#FFC900" />
          <stop offset={1} stopColor="#FAFF00" />
        </linearGradient>
        <linearGradient
          id={j}
          x1={81.953}
          y1={71.672}
          x2={78.302}
          y2={119.982}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D8AE13" />
          <stop offset={0.49} stopColor="#FFC900" />
          <stop offset={1} stopColor="#FAFF00" />
        </linearGradient>
        <linearGradient
          id={k}
          x1={55.034}
          y1={73.791}
          x2={40.579}
          y2={119.725}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D8AE13" />
          <stop offset={0.49} stopColor="#FFC900" />
          <stop offset={1} stopColor="#FAFF00" />
        </linearGradient>
        <linearGradient
          id={l}
          x1={25.137}
          y1={45.712}
          x2={16.727}
          y2={99.661}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D8AE13" />
          <stop offset={0.49} stopColor="#FFC900" />
          <stop offset={1} stopColor="#FAFF00" />
        </linearGradient>
        <clipPath id={a}>
          <path fill="#fff" d="M0 0h120v120H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
