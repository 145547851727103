import { Anchor } from '@daily/shared/components/Anchor';
import { Text } from '@daily/shared/components/Text';
import { useTheme } from '@daily/shared/contexts/Theme';
import { useResize } from '@daily/shared/hooks/useResize';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';

export const PoweredByDailyBanner: React.FC = () => {
  const { colors } = useTheme();
  const [isVisible, setIsVisible] = useState(false);
  const bannerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsVisible(true);
    return () => {
      document.body.style.removeProperty('--banner-height');
    };
  }, []);

  useResize(() => {
    if (!bannerRef.current || !isVisible) return;
    document.body.style.setProperty(
      '--banner-height',
      `${bannerRef.current.clientHeight}px`
    );
  }, [isVisible]);

  return (
    <>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            initial={{ opacity: 0, y: '-100%' }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: '-100%' }}
            transition={{ duration: 0.25, ease: 'easeOut' }}
          >
            <div ref={bannerRef}>
              <Text color="white">
                Powered by{' '}
                <Anchor href="https://daily.co" underline>
                  Daily
                </Anchor>
              </Text>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <style jsx>{`
        div {
          align-items: center;
          background: ${colors.system.greyDark};
          display: flex;
          flex: none;
          justify-content: center;
          padding: 6px 8px 8px;
          width: 100%;
        }
      `}</style>
    </>
  );
};
