import { Button } from '@daily/shared/components/Button';
import { VisuallyHidden } from '@daily/shared/components/VisuallyHidden';
import DailyIframe from '@daily-co/daily-js';
import classnames from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useCallState } from '../../contexts/CallProvider';
import { useIframeDriver } from '../../contexts/IframeDriverProvider';
import { useIsMobile } from '../../contexts/UIState';
import { FullscreenIcon } from './FullscreenIcon';

export const FullscreenButton: React.FC = () => {
  const { t } = useTranslation();
  const [isMobile] = useIsMobile();
  const { isFullscreen } = useCallState();
  const { sendMessageToDriver } = useIframeDriver();

  const handleClick = useCallback(async () => {
    if (isFullscreen) {
      sendMessageToDriver({ action: 'request-exit-fullscreen' });
    } else {
      sendMessageToDriver({ action: 'request-fullscreen' });
    }
  }, [isFullscreen, sendMessageToDriver]);

  if (!DailyIframe.supportedBrowser().supportsFullscreen) return null;

  return (
    <div
      className={classnames('fullscreen-button', {
        mobile: isMobile,
      })}
    >
      <Button variant="ghost" onClick={handleClick}>
        <VisuallyHidden>
          {isFullscreen
            ? t('general.exitFullscreen')
            : t('general.enterFullscreen')}
        </VisuallyHidden>
        <FullscreenIcon size={isMobile ? 32 : 24} />
      </Button>
    </div>
  );
};
