import { useTheme } from '@daily/shared/contexts/Theme';
import { useLocalParticipant } from '@daily-co/daily-react-hooks';
import { useIsGridHidden } from 'contexts/UIState';
import React, { useCallback, useMemo } from 'react';

import { useCallState } from '../../contexts/CallProvider';
import { useViewMode } from '../../contexts/UIState';
import { useWaitingRoom } from '../../contexts/WaitingRoomProvider';
import { useCallConfig } from '../../hooks/useCallConfig';
import { useJoinSound } from '../../hooks/useJoinSound';
import { FullscreenButton } from '../FullscreenButton';
import { GridView } from '../GridView';
import { PoweredByDailyBanner } from '../PoweredByDailyBanner';
import { Sidebar } from '../Sidebar';
import { SpeakerView } from '../SpeakerView';
import { Topbar } from '../Topbar';
import { WaitingRoomModal, WaitingRoomNotification } from '../WaitingRoom';
import { AudioTracks } from './AudioTracks';
import { Modals } from './Modals';

export const Call = () => {
  const { colors, zIndex } = useTheme();
  const viewMode = useViewMode();
  const [isGridHidden] = useIsGridHidden();
  const { showFullscreenButton } = useCallState();
  const { poweredByDaily } = useCallConfig();
  const { setShowModal, showModal } = useWaitingRoom();

  const localParticipant = useLocalParticipant();

  const handleModalClose = useCallback(
    () => setShowModal(false),
    [setShowModal]
  );

  useJoinSound();

  return useMemo(
    () => (
      <div className={isGridHidden ? 'call is-grid-hidden' : 'call'}>
        {poweredByDaily && <PoweredByDailyBanner />}
        <Topbar />
        <div className="main">
          {showFullscreenButton && <FullscreenButton />}
          <AudioTracks />
          {/* <GridView /> */}
          {viewMode === 'grid' ? <GridView /> : <SpeakerView />}
          <Sidebar />
        </div>
        {localParticipant?.owner && (
          <>
            <WaitingRoomNotification />
            {showModal && <WaitingRoomModal onClose={handleModalClose} />}
          </>
        )}
        <Modals />
        <style jsx>{`
          .is-grid-hidden :global(.grid) {
            visibility: hidden;
          }
          .call {
            // background: ${colors.custom.mainAreaBg};
            background: transparent;
            display: flex;
            flex-direction: column;
            height: 100vh;
            padding-bottom: 59px;
            position: relative;
            width: 100vw;
          }
          .main {
            display: flex;
            flex: auto;
            height: 100%;
            min-height: 0;
            overflow: hidden;
          }
          .main :global(.fullscreen-button) {
            display: none;
            left: 8px;
            position: fixed;
            top: calc(var(--header-height) + 8px);
            z-index: ${zIndex.header};
          }
        `}</style>
      </div>
    ),
    [
      colors.custom.mainAreaBg,
      handleModalClose,
      localParticipant?.owner,
      poweredByDaily,
      showFullscreenButton,
      showModal,
      isGridHidden,
      viewMode,
      zIndex.header,
    ]
  );
};
