import { Card, CardContent } from '@daily/shared/components/Card';
import { CamIcon } from '@daily/shared/components/Icons';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { useTheme } from '@daily/shared/contexts/Theme';
import { useMediaQuery } from '@daily/shared/hooks/useMediaQuery';
import { StatefulDevice } from '@daily-co/daily-react-hooks';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useStyleVariants } from '../../hooks/useStyleVariants';
import { AudioVideoSettings } from '../SettingsModal/AudioVideoSettings';
import { CTAContent } from './CTAContent';
import { AccessState, GeneralState, RequestState } from './Setup';
import { SetupTray } from './SetupTray';
import { VideoContainer } from './VideoContainer';

interface Props {
  camState: GeneralState;
  micState: GeneralState;
  hasMicError: boolean;
  hasCamError: boolean;
  shouldUseLandscapeLayout: boolean;
  cameras: StatefulDevice[];
  accessState: AccessState;
  requestState: RequestState;
  onComplete(): void;
  onCancelClick(): void;
}

export const DeviceInUseError: React.FC<Props> = ({
  accessState,
  cameras,
  camState,
  hasCamError,
  hasMicError,
  micState,
  onCancelClick,
  onComplete,
  requestState,
  shouldUseLandscapeLayout,
}) => {
  const { t } = useTranslation();
  const { textVariant } = useStyleVariants();
  const { mediaQueries } = useTheme();

  const isSmallScreen = useMediaQuery(mediaQueries.small);

  const showExtraContent = !isSmallScreen && !shouldUseLandscapeLayout;

  return (
    <Card spacing={8} columns={shouldUseLandscapeLayout && [1, 2, 2, 2]}>
      <CardContent noPadding>
        <VideoContainer hasVideo={false}>
          <div
            className={classnames('device-in-use-content', {
              landscape: shouldUseLandscapeLayout,
            })}
          >
            <div style={{ textAlign: 'center' }}>
              <CamIcon
                muted
                id="camicon"
                style={{ display: 'inline', verticalAlign: 'bottom' }}
              />
              <Text
                variant="largestrong"
                style={{ display: 'inline', paddingLeft: '4px' }}
              >
                {
                  // i18next-extract-mark-context-next-line ["camera", "other"]
                  t('haircheck.deviceInUse.title', {
                    context: camState === 'in-use' ? 'camera' : 'other',
                  })
                }
              </Text>
            </div>
            <Text El="div" textAlign="center" variant={textVariant}>
              {camState === 'in-use'
                ? t('haircheck.deviceInUse.desc_camera', {
                    count: cameras.length,
                  })
                : t('haircheck.deviceInUse.desc_other')}
            </Text>
          </div>
        </VideoContainer>
        <SetupTray
          micState={micState}
          camState={camState}
          hasMicError={hasMicError}
          cameras={cameras}
        />
      </CardContent>
      <CardContent
        style={
          shouldUseLandscapeLayout
            ? {
                flex: '1 0 40%',
                minWidth: '30%',
                padding: '46px 8px 16px',
              }
            : { padding: '0' }
        }
      >
        <Stack padding={showExtraContent ? 24 : 16} gap={24} align="stretch">
          {showExtraContent && (
            <AudioVideoSettings shouldShowMicVolume={false} />
          )}
          <CTAContent
            accessState={accessState}
            requestState={requestState}
            onCancelClick={onCancelClick}
            onComplete={onComplete}
            hasCamError={hasCamError}
            hasMicError={hasMicError}
            camState={camState}
            micState={micState}
            shouldUseLandscapeLayout={shouldUseLandscapeLayout}
          />
        </Stack>
      </CardContent>
      <style jsx>{`
        .device-in-use-content {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
        @media ${mediaQueries.medium} {
          .device-in-use-content:not(.landscape) {
            padding: 16px;
          }
        }
        @media ${mediaQueries.large} {
          .device-in-use-content:not(.landscape) {
            padding: 32px;
          }
        }
      `}</style>
    </Card>
  );
};
