import { HiddenIcon } from '@daily/shared/components/Icons';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { Tooltip } from '@daily/shared/components/Tooltip';
import {
  useLocalParticipant,
  useParticipant,
} from '@daily-co/daily-react-hooks';
import classNames from 'classnames';
import { CALL_CUSTOM_EVENTS } from 'components/App/IframeListener';
import { Roles } from 'components/Roles/Roles';
import { useIframeDriver } from 'contexts/IframeDriverProvider';
import { useHiddenIds } from 'contexts/UIState';
import React, { memo, useCallback, useRef, useState } from 'react';

import { useDisplayName } from '../../hooks/useDisplayName';
import { useResizeObserver } from '../../hooks/useResizeObserver';
import { useStyleVariants } from '../../hooks/useStyleVariants';
import { robotsClassName } from '../../lib/robots';
import { ParticipantActions } from './ParticipantActions';

interface Props {
  iconVariant: ReturnType<typeof useStyleVariants>['iconVariant'];
  localParticipant: ReturnType<typeof useLocalParticipant>;
  showActions?: boolean;
  sessionId: string;
  showMicMutedState: boolean;
  textVariant: ReturnType<typeof useStyleVariants>['textVariant'];
}

export const ParticipantRow: React.FC<Props> = memo(
  ({
    iconVariant,
    localParticipant,
    sessionId,
    showActions = false,
    showMicMutedState,
    textVariant,
  }) => {
    const nameRef = useRef<HTMLDivElement>(null);
    const [isTruncated, setIsTruncated] = useState(false);

    const participant = useParticipant(sessionId);
    const displayName = useDisplayName(participant, true);
    const [hiddenIds] = useHiddenIds();
    const color = hiddenIds.includes(sessionId) ? 'muted' : 'default';

    const { sendMessageToDriver } = useIframeDriver();

    useResizeObserver(
      nameRef,
      useCallback(() => {
        if (!nameRef.current) return;
        const p = nameRef.current.querySelector('p');
        setIsTruncated(p?.scrollWidth > p?.clientWidth);
      }, [])
    );

    const handleClick = useCallback(
      (event) => {
        console.log({ event });
        sendMessageToDriver({
          action: CALL_CUSTOM_EVENTS.ST_SHOW_PARTICIPANT_DETAILS,
          payload: {
            from: 'participant-row',
            userID: participant.user_id,
            x: event.clientX,
            y: event.clientY,
          },
        });
      },
      [participant.user_id, sendMessageToDriver]
    );

    if (!participant) return null;

    return (
      <div className="participant-row">
        <Tooltip
          disabled={!isTruncated}
          id={`tt-${sessionId}`}
          title={participant.user_name}
        >
          <div style={{ overflow: 'hidden' }} ref={nameRef}>
            <Stack align="center" horizontal>
              <Text
                className={classNames(
                  'participant-name',
                  robotsClassName('display-name')
                )}
                El="div"
                truncate
                color={color}
                variant={textVariant}
                onClick={handleClick}
                style={{
                  cursor: 'pointer',
                  color: participant?.userData?.['color'],
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {hiddenIds.includes(sessionId) && (
                  <HiddenIcon className="participant-hidden-icon" size={12} />
                )}
                {displayName}
                <Roles badges={participant?.userData?.['roleBadge']} />
              </Text>
            </Stack>
          </div>
        </Tooltip>
        {(showActions || showMicMutedState) && (
          <div className="actions">
            <ParticipantActions
              iconVariant={iconVariant}
              localParticipant={localParticipant}
              participant={participant}
              sessionId={sessionId}
              showActions={showActions}
              showMicMutedState={showMicMutedState}
            />
          </div>
        )}
        <style jsx>{`
          .participant-name {
            display: grid;
            cursor: pointer;
          }
          .participant-row {
            align-items: center;
            display: flex;
            gap: 8px;
            justify-content: space-between;
          }
          .actions {
            flex: none;
          }
          :global(.participant-hidden-icon) {
            display: inline-block !important;
            vertical-align: middle;
            margin-right: 4px;
          }
        `}</style>
      </div>
    );
  }
);
ParticipantRow.displayName = 'ParticipantRow';
