import { DARK_THEME_CLASS } from '@daily/shared/contexts/Theme';
import { DailyReceiveSettings } from '@daily-co/daily-js';
import {
  useLocalParticipant,
  useNetwork,
  useReceiveSettings,
  useScreenShare,
} from '@daily-co/daily-react-hooks';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDeepCompareEffect, useDeepCompareMemo } from 'use-deep-compare';

import { useCallState } from '../../contexts/CallProvider';
import {
  useOrderedParticipantIds,
  useParticipantMetaData,
  useParticipants,
} from '../../contexts/ParticipantsProvider';
import { useTracks } from '../../contexts/TracksProvider';
import {
  useMaxGridTilesPerPage,
  useMinGridTilesPerPage,
  useShowLocalVideo,
} from '../../contexts/UIState';
import { useActiveSpeaker } from '../../hooks/useActiveSpeaker';
import { useCallConfig } from '../../hooks/useCallConfig';
import { useCamSubscriptions } from '../../hooks/useCamSubscriptions';
import { useResizeObserver } from '../../hooks/useResizeObserver';
import { useVideoGrid } from '../../hooks/useVideoGrid';
import { isSafari } from '../../lib/browserConfig';
import { getQueryParam } from '../../lib/query';
import { Tile } from '../Tile';
import { PaginationButton } from './PaginationButton';

const GAP = 12;
const MIN_TILE_WIDTH = 280;

export const GridView: React.FC = () => {
  const { query } = useRouter();
  const [maxGridTilesPerPage] = useMaxGridTilesPerPage();
  const [minGridTilesPerPage] = useMinGridTilesPerPage();
  const [showLocalVideo] = useShowLocalVideo();
  const { broadcastRole, enablePIPUI } = useCallConfig();
  const { showNames } = useCallState();
  const { currentSpeakerId, swapParticipantPosition } = useParticipants();
  const orderedParticipantIds = useOrderedParticipantIds();
  const participantMetaData = useParticipantMetaData();
  const { maxCamSubscriptions } = useTracks();
  const activeSpeakerId = useActiveSpeaker();
  const gridRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({
    width: 1,
    height: 1,
  });
  const [maxTilesPerPage, setMaxTilesPerPage] = useState(maxGridTilesPerPage);

  const { threshold } = useNetwork();

  const { screens } = useScreenShare();

  const localParticipant = useLocalParticipant();
  const shouldRenderLocalVideo = useMemo(
    () =>
      (showLocalVideo && broadcastRole !== 'attendee') ||
      orderedParticipantIds.length === 0,
    [broadcastRole, orderedParticipantIds.length, showLocalVideo]
  );
  const sessionIds = useMemo(
    () => [
      ...(shouldRenderLocalVideo ? [localParticipant?.session_id] : []),
      ...orderedParticipantIds,
    ],
    [
      localParticipant?.session_id,
      orderedParticipantIds,
      shouldRenderLocalVideo,
    ]
  );

  /**
   * Update max tiles per page from ps query param.
   */
  useEffect(() => {
    if (query.ps) {
      const tiles = parseInt(getQueryParam('ps', query), 10);
      if (tiles && !Number.isNaN(Math.abs(tiles))) {
        setMaxTilesPerPage(tiles);
      }
    }
  }, [query]);

  useResizeObserver(
    gridRef,
    useCallback((width, height) => {
      setDimensions({
        width: Math.floor(width),
        height: Math.floor(height),
      });
    }, [])
  );

  const {
    columns,
    containerHeight,
    containerWidth,
    currentIds,
    currentPage,
    nextPage,
    pageSize,
    prevPage,
    totalPages,
  } = useVideoGrid({
    width: dimensions.width,
    height: dimensions.height,
    minTileWidth: MIN_TILE_WIDTH,
    gap: GAP,
    sessionIds,
    maxCountPerPage: maxTilesPerPage,
    minCountPerPage: minGridTilesPerPage,
  });

  /**
   * Update track subscriptions based on pagination
   */
  const camSubscriptions = useMemo(() => {
    const maxSubs = maxCamSubscriptions
      ? // avoid subscribing to only a portion of a page
        Math.max(maxCamSubscriptions, pageSize)
      : // if no maximum is set, subscribe to adjacent pages
        3 * pageSize;

    // Determine participant ids to subscribe to or stage, based on page.
    let renderedOrBufferedIds: string[];
    switch (currentPage) {
      // First page
      case 1:
        renderedOrBufferedIds = orderedParticipantIds.slice(
          0,
          Math.min(maxSubs, 2 * pageSize - (shouldRenderLocalVideo ? 1 : 0))
        );
        break;
      // Last page
      case Math.ceil(orderedParticipantIds.length / pageSize):
        renderedOrBufferedIds = orderedParticipantIds.slice(
          -Math.min(maxSubs, 2 * pageSize)
        );
        break;
      // Any other page
      default:
        {
          const buffer = Math.floor((maxSubs - pageSize) / 2);
          const min = Math.max(0, (currentPage - 1) * pageSize - buffer);
          const max = Math.min(
            orderedParticipantIds.length,
            currentPage * pageSize + buffer
          );
          renderedOrBufferedIds = orderedParticipantIds.slice(min, max);
        }
        break;
    }

    const subscribedIds: string[] = [];
    const stagedIds: string[] = [];

    // Decide whether to subscribe to or stage participants' track based on
    // visibility
    for (const id of renderedOrBufferedIds) {
      if (id !== localParticipant?.session_id) {
        if (currentIds.some((visId) => visId === id)) {
          subscribedIds.push(id);
        } else {
          stagedIds.push(id);
        }
      }
    }

    /**
     * For PIP in Safari, when the user is in grid view,
     * but the active speaeker is not rendered,
     * we'll want to keep our subscription to the speaker's video.
     */
    if (
      enablePIPUI &&
      isSafari() &&
      !subscribedIds.includes(currentSpeakerId)
    ) {
      subscribedIds.push(currentSpeakerId);
    }

    return {
      subscribedIds,
      stagedIds,
    };
  }, [
    currentIds,
    currentSpeakerId,
    enablePIPUI,
    localParticipant?.session_id,
    maxCamSubscriptions,
    orderedParticipantIds,
    currentPage,
    pageSize,
    shouldRenderLocalVideo,
  ]);

  useCamSubscriptions(
    camSubscriptions?.subscribedIds,
    camSubscriptions?.stagedIds
  );

  const { updateReceiveSettings } = useReceiveSettings();

  /**
   * Set bandwidth layer based on amount of visible participants
   */
  useEffect(() => {
    const timeout = setTimeout(() => {
      const count = currentIds.length;
      const layer = count < 5 ? 2 : count < 10 ? 1 : 0;
      const receiveSettings = currentIds.reduce<DailyReceiveSettings>(
        (settings, id) => {
          if (id === localParticipant?.session_id) return settings;
          settings[id] = { video: { layer } };
          return settings;
        },
        {}
      );
      if (Object.keys(receiveSettings).length === 0) return;
      updateReceiveSettings(receiveSettings);
    }, 250);
    return () => {
      clearTimeout(timeout);
    };
  }, [currentIds, localParticipant?.session_id, updateReceiveSettings]);

  /**
   * Handle position updates based on active speaker events
   */
  const sortedVisibleRemoteParticipantIds = useDeepCompareMemo(
    () =>
      currentIds.slice(shouldRenderLocalVideo ? 1 : 0).sort((a, b) => {
        const lastActiveA =
          participantMetaData[a]?.last_active_date ?? new Date('1970-01-01');
        const lastActiveB =
          participantMetaData[b]?.last_active_date ?? new Date('1970-01-01');
        if (lastActiveA > lastActiveB) return 1;
        if (lastActiveA < lastActiveB) return -1;
        return 0;
      }),
    [currentIds, participantMetaData, shouldRenderLocalVideo]
  );

  useDeepCompareEffect(() => {
    if (!activeSpeakerId) return;

    // active participant is already visible
    if (currentIds.some((id) => id === activeSpeakerId)) return;
    // ignore repositioning when viewing page > 1
    if (currentPage > 1) return;

    /**
     * We can now assume that
     * a) the user is looking at page 1
     * b) the most recent active participant is not visible on page 1
     * c) we'll have to promote the most recent participant's position to page 1
     *
     * To achieve that, we'll have to
     * - find the least recent active participant on page 1
     * - swap least & most recent active participant's position via setParticipantPosition
     */

    if (!sortedVisibleRemoteParticipantIds.length) return;

    swapParticipantPosition(
      sortedVisibleRemoteParticipantIds[0],
      activeSpeakerId
    );
  }, [
    activeSpeakerId,
    currentIds,
    currentPage,
    sortedVisibleRemoteParticipantIds,
    swapParticipantPosition,
  ]);

  const tiles = useMemo(
    () => (
      <div className={classnames('tiles', DARK_THEME_CLASS)}>
        {currentIds.map((id) => (
          <Tile
            key={id}
            isScreen={screens.some((screen) => screen.session_id === id)}
            isSpeaking={id === activeSpeakerId}
            network={id === localParticipant?.session_id ? threshold : null}
            sessionId={id}
            showNames={showNames}
          />
        ))}
      </div>
    ),
    [
      activeSpeakerId,
      currentIds,
      localParticipant?.session_id,
      showNames,
      threshold,
      screens,
    ]
  );

  /**
   * Update grid custom properties.
   */
  useEffect(() => {
    if (!gridRef.current) return;
    gridRef.current.style.setProperty('--grid-gap', `${GAP}px`);
    gridRef.current.style.setProperty('--grid-columns', columns.toString());
    gridRef.current.style.setProperty('--grid-width', `${containerWidth}px`);
    gridRef.current.style.setProperty('--grid-height', `${containerHeight}px`);
  }, [columns, containerHeight, containerWidth]);

  return (
    <div ref={gridRef} className="grid">
      {totalPages > 1 && currentPage > 1 && (
        <PaginationButton onClick={prevPage} variant="prev" />
      )}
      {tiles}
      {totalPages > 1 && currentPage < totalPages && (
        <PaginationButton onClick={nextPage} variant="next" />
      )}
      <style jsx>{`
        .grid {
          align-items: center;
          display: flex;
          height: 100%;
          justify-content: center;
          position: relative;
          width: 100%;
        }
        .grid :global(.tiles) {
          align-items: center;
          display: flex;
          flex-flow: row wrap;
          gap: var(--grid-gap, 1px);
          max-height: var(--grid-height, 100%);
          max-width: var(--grid-width, 100%);
          justify-content: center;
          margin: auto;
          overflow: hidden;
          transition: height 100ms ease, width 100ms ease;
          width: 100%;
        }
        .grid :global(.tiles .tile) {
          max-width: calc(100% / var(--grid-columns, 1) - var(--grid-gap));
          border-radius: 8px;
        }
      `}</style>
    </div>
  );
};
