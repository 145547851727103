interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  alt: string;
  padding?: number;
  src: string;
  size?: number;
}

export const CustomIcon: React.FC<Props> = ({
  alt,
  padding = 4,
  src,
  size = 24,
  style = {},
  ...props
}) => (
  <img
    alt={alt}
    src={src}
    height={size}
    width={size}
    {...props}
    style={{ ...style, padding }}
  />
);
