import React, { useEffect, useRef } from 'react';

import { getFocusableElements } from '../../lib/dom';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  active?: boolean;
  arrowKeys?: 'updown' | 'leftright' | 'all' | 'none';
  restoreFocus?: boolean;
  searchByCharacter?: boolean;
}

export const FocusTrap: React.FC<Props> = ({
  active = true,
  arrowKeys = 'none',
  children,
  restoreFocus = true,
  searchByCharacter = false,
  ...props
}) => {
  const ref = useRef(null);
  const previousFocusRef = useRef(null);

  const handleKeyDown = (ev: KeyboardEvent) => {
    const els: HTMLElement[] = getFocusableElements(ref.current);
    const currentIndex = els.findIndex((el) => el === document.activeElement);
    const nextIndex = (currentIndex + 1) % els.length;
    const prevIndex = currentIndex === 0 ? els.length - 1 : currentIndex - 1;
    switch (ev.key) {
      case 'ArrowDown':
        if (!['updown', 'all'].includes(arrowKeys)) return;
        ev.preventDefault();
        els[nextIndex].focus();
        break;
      case 'ArrowLeft':
        if (!['leftright', 'all'].includes(arrowKeys)) return;
        els[prevIndex].focus();
        break;
      case 'ArrowRight':
        if (!['leftright', 'all'].includes(arrowKeys)) return;
        els[nextIndex].focus();
        break;
      case 'ArrowUp':
        if (!['updown', 'all'].includes(arrowKeys)) return;
        ev.preventDefault();
        els[prevIndex].focus();
        break;
      case 'End':
      case 'PageDown':
        if (arrowKeys === 'none') return;
        ev.preventDefault();
        els[els.length - 1].focus();
        break;
      case 'Home':
      case 'PageUp':
        if (arrowKeys === 'none') return;
        ev.preventDefault();
        els[0].focus();
        break;
      case 'Tab':
        // Keeps focus inside container.
        ev.preventDefault();
        els?.[ev.shiftKey ? prevIndex : nextIndex]?.focus();
        break;
    }
    const elStartsWithKey = (el: HTMLElement) =>
      el.textContent.toLowerCase().startsWith(ev.key);
    if (
      searchByCharacter &&
      ev.which >= 65 &&
      ev.which <= 90 &&
      els.some(elStartsWithKey)
    ) {
      // a-z, with shift A-Z
      const first = els.findIndex(elStartsWithKey);
      const next = els.findIndex(
        (el, i) =>
          el.textContent.toLowerCase().startsWith(ev.key) && i > currentIndex
      );
      els[next >= 0 ? next : first].focus();
    }
  };

  useEffect(() => {
    if (!active || !ref.current) return;

    document.addEventListener('keydown', handleKeyDown);
    previousFocusRef.current = document.activeElement;
    const els = getFocusableElements(ref.current);
    els?.[0]?.focus();
    return () => {
      if (!active) return;
      document.removeEventListener('keydown', handleKeyDown);
      if (restoreFocus) previousFocusRef.current.focus();
    };
  }, [active]);

  return (
    <div ref={ref} {...props}>
      {children}
    </div>
  );
};
