import { useUniqueId } from 'hooks/useUniqueId';
import { SVGProps } from 'react';

export function ChampionIcon(props: SVGProps<SVGSVGElement>) {
  const idA = useUniqueId();
  const idB = useUniqueId();
  return (
    <svg width="76" height="73" viewBox="0 0 76 73" fill="none" {...props}>
      <g clipPath={`url(#${idA})`}>
        <path
          fill={`url(#${idB})`}
          d="M75.255 36.4a4.26 4.26 0 0 1-.51 2.65 11.23 11.23 0 0 1-4.11 3.89c-.77.43-2 1.18-2.78 1.66-1.17.74-1.36.92-1.26 1.25.1.33.58 1.59 1.16 3.07 1.25 3.32 1.63 4.73 1.53 6.22a5.65 5.65 0 0 1-1.63 3.69c-1.66 1.77-3.88 2.29-8.37 2-2.39-.17-4-.13-4 .13 0 0-.36 1.49-.79 3.22-.93 3.64-1.68 5.37-2.82 6.47-2.8 2.73-6.95 2.11-11.17-1.66-.88-.78-1.9-1.63-2.26-1.88l-.67-.46-.57.49c-.36.26-1.49 1.19-2.51 2.09-2.34 2-3.6 2.86-5.05 3.26a5.85 5.85 0 0 1-6.74-2.66 16.558 16.558 0 0 1-1.94-5.33c-.34-1.37-.69-2.71-.8-3l-.18-.49-3.91.13c-4.21.14-5 0-6.66-.75a5.52 5.52 0 0 1-3-3.7c-.53-1.83-.17-3.71 1.52-8 1.27-3.17 1.27-2.75-.51-3.9-3.32-2.12-4.07-2.65-5.12-3.65a10.725 10.725 0 0 1-1.59-2 4.26 4.26 0 0 1-.51-2.74 4.27 4.27 0 0 1 .47-2.57c1-1.82 2.36-3.06 5.67-5 1-.6 2-1.23 2.23-1.4l.4-.31-.33-1c-.19-.53-.7-1.88-1.15-3-1.87-4.68-1.79-7.11.31-9.36 1.66-1.77 3.94-2.31 8.36-2 1.1.105 2.206.135 3.31.09l.62-.12.48-2c1.1-4.49 1.88-6.37 3.24-7.72a5.7 5.7 0 0 1 3.75-1.69c2.14-.18 4.1.82 7.57 3.73 2.7 2.27 2.56 2.21 3.36 1.61.36-.27 1.1-.91 1.64-1.41a30.612 30.612 0 0 1 4.15-3.22c.3-.15.94-.43 1.42-.61a6.83 6.83 0 0 1 4.35.16 7.38 7.38 0 0 1 3.7 4.39c.22.58.67 2.15 1 3.48.33 1.33.68 2.63.78 2.89l.18.48 3.94-.13c3.4-.1 4.09-.08 5.09.18a5.79 5.79 0 0 1 4.46 4.17c.51 1.74.26 3.46-1 6.65-.41 1.06-.94 2.45-1.17 3.08l-.44 1.15.38.3c.2.17 1.38.93 2.6 1.69 2.59 1.6 4.55 3.39 5.3 4.83.476.801.68 1.734.58 2.66Z"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M30.865 21.733c-5.25 0-9 3.553-9 9.606 0 4.138 2.578 8.36 8.802 13.981l.69.647 3.838 3.54a3.9 3.9 0 0 0 5.302.001l2.342-2.15 1.561-1.446c6.704-5.919 9.465-10.29 9.465-14.573 0-6.053-3.75-9.605-9-9.605-2.52 0-4.86 1.11-7 3.23-2.14-2.12-4.481-3.23-7-3.23Zm8.427 7.746c.055-.058.106-.118.153-.18 1.838-2.348 3.631-3.426 5.42-3.426 3.115 0 5 1.785 5 5.466 0 2.19-1.698 5.137-5.183 8.701l-.008-.008-.13.13-.715.697-1.666 1.57-4.316 3.978-4.005-3.694-1.83-1.72-.44-.425c-3.839-3.802-5.707-6.932-5.707-9.229 0-3.68 1.884-5.466 5-5.466 1.788 0 3.581 1.078 5.42 3.425a2.05 2.05 0 0 0 .74.61 1.938 1.938 0 0 0 1.68 0c.214-.102.414-.246.587-.43Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <linearGradient
          id={idB}
          x1="30.68"
          x2="-41.998"
          y1="81.813"
          y2="44.518"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9656A1" />
          <stop offset="1" stopColor="#FF3939" />
        </linearGradient>
        <clipPath id={idA}>
          <path fill="#fff" d="M0 .169h75.25v72.54H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
