import { SVGProps } from 'react';

// TODO: is this being used anywhere?
export function Amethyst(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={120} height={120} viewBox="0 0 128 128" fill="none" {...props}>
      <g clipPath="url(#a)">
        <path
          d="M62.454 107.68a7.843 7.843 0 0 1-3.787-2.08L22.401 69.333a7.845 7.845 0 0 1-2.08-3.786H3.894c.267 1.76 1.12 3.466 2.507 4.853l51.2 51.2c1.386 1.387 3.093 2.24 4.853 2.507V107.68Z"
          fill="url(#b)"
          fillOpacity={0.2}
        />
        <path
          d="M62.454 107.68a7.85 7.85 0 0 1-3.787-2.08L22.401 69.333a7.845 7.845 0 0 1-2.08-3.787H3.894c.267 1.76 1.12 3.467 2.507 4.854l51.2 51.2c1.386 1.386 3.093 2.24 4.853 2.506V107.68Z"
          fill="url(#c)"
        />
        <path
          d="M20.32 62.453a7.845 7.845 0 0 1 2.08-3.786L58.668 22.4a7.846 7.846 0 0 1 3.787-2.08V3.893c-1.76.267-3.467 1.12-4.853 2.507L6.4 57.6c-1.387 1.387-2.24 3.093-2.507 4.853h16.427Z"
          fill="url(#d)"
        />
        <path
          d="M60.8 24.533 24.535 60.8a4.539 4.539 0 0 0 0 6.4l36.267 36.267a4.539 4.539 0 0 0 6.4 0L103.467 67.2a4.539 4.539 0 0 0 0-6.4L67.201 24.533a4.539 4.539 0 0 0-6.4 0Z"
          fill="url(#e)"
        />
        <path
          d="M107.681 65.547a7.851 7.851 0 0 1-2.08 3.786L69.334 105.6a7.843 7.843 0 0 1-3.787 2.08v16.427a9.088 9.088 0 0 0 4.854-2.507l51.2-51.2c1.386-1.387 2.24-3.093 2.506-4.853h-16.426Z"
          fill="url(#f)"
        />
        <path
          d="M65.547 3.893V20.32a7.846 7.846 0 0 1 3.787 2.08l36.267 36.267a7.851 7.851 0 0 1 2.08 3.786h16.426a9.087 9.087 0 0 0-2.506-4.853l-51.2-51.2c-1.387-1.387-3.094-2.24-4.854-2.507Z"
          fill="url(#g)"
        />
      </g>
      <defs>
        <linearGradient
          id="b"
          x1={37.572}
          y1={82.798}
          x2={26.148}
          y2={95.906}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="c"
          x1={-37.701}
          y1={18.226}
          x2={65.317}
          y2={121.243}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.447} stopColor="#6F4077" />
          <stop offset={0.734} stopColor="#9656A1" />
          <stop offset={1} stopColor="#D3A1DC" />
        </linearGradient>
        <linearGradient
          id="d"
          x1={47.432}
          y1={47.432}
          x2={32.001}
          y2={32}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.447} stopColor="#6F4077" />
          <stop offset={1} stopColor="#9656A1" />
        </linearGradient>
        <linearGradient
          id="e"
          x1={35.822}
          y1={-2.417}
          x2={82.356}
          y2={88.315}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.447} stopColor="#6F4077" />
          <stop offset={0.734} stopColor="#9656A1" />
          <stop offset={1} stopColor="#D3A1DC" />
        </linearGradient>
        <linearGradient
          id="f"
          x1={75.389}
          y1={83.586}
          x2={91.902}
          y2={100.099}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.447} stopColor="#6F4077" />
          <stop offset={0.734} stopColor="#9656A1" />
          <stop offset={1} stopColor="#D3A1DC" />
        </linearGradient>
        <linearGradient
          id="g"
          x1={95.203}
          y1={49.976}
          x2={104.59}
          y2={40.589}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.447} stopColor="#6F4077" />
          <stop offset={0.734} stopColor="#9656A1" />
          <stop offset={1} stopColor="#D3A1DC" />
        </linearGradient>
        <clipPath id="a">
          <path
            fill="#fff"
            transform="rotate(-45 77.255 32)"
            d="M0 0h90.51v90.51H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
