import { Radio } from '@daily/shared/components/Radio';
import { Stack, StackItem } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { useNetwork } from '@daily-co/daily-react-hooks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCallState, VideoQuality } from '../../contexts/CallProvider';

export const VideoQualitySettings: React.FC = () => {
  const { t } = useTranslation();
  const { setVideoQuality, videoQuality } = useCallState();
  const { threshold } = useNetwork();

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setVideoQuality(ev.target.value as VideoQuality);
  };

  const autoQuality = useMemo(() => {
    if (videoQuality !== 'auto') return null;
    switch (threshold) {
      case 'good':
        return t('settings.modal.highQuality');
      case 'low':
        return t('settings.modal.lowQuality');
      case 'very-low':
        return t('settings.modal.bandwidthSaver');
      default:
        return null;
    }
  }, [t, threshold, videoQuality]);

  return (
    <Stack gap={16}>
      <Text variant="large">{t('settings.modal.videoQualityDesc')}</Text>
      <Stack align="center" justify="space-between" horizontal>
        <Stack gap={4}>
          <Stack gap={4} horizontal style={{ display: 'inline-flex' }}>
            <Text El="label" htmlFor="video-quality-auto" variant="strong">
              {t('settings.modal.auto')}
            </Text>
            {autoQuality && (
              <Text El="label" htmlFor="video-quality-auto">
                ({autoQuality})
              </Text>
            )}
          </Stack>
          <Text color="muted">{t('settings.modal.autoDesc')}</Text>
        </Stack>
        <StackItem style={{ flex: 'none' }}>
          <Radio
            checked={videoQuality === 'auto'}
            id="video-quality-auto"
            label={null}
            name="video-quality"
            onChange={handleChange}
            value="auto"
          />
        </StackItem>
      </Stack>
      <Stack align="center" justify="space-between" horizontal>
        <Stack gap={4}>
          <Text El="label" htmlFor="video-quality-high" variant="strong">
            {t('settings.modal.highQuality')}
          </Text>
          <Text color="muted">{t('settings.modal.highQualityDesc')}</Text>
        </Stack>
        <StackItem style={{ flex: 'none' }}>
          <Radio
            checked={videoQuality === 'high'}
            id="video-quality-high"
            label={null}
            name="video-quality"
            onChange={handleChange}
            value="high"
          />
        </StackItem>
      </Stack>
      <Stack align="center" justify="space-between" horizontal>
        <Stack gap={4}>
          <Text El="label" htmlFor="video-quality-low" variant="strong">
            {t('settings.modal.lowQuality')}
          </Text>
          <Text color="muted">{t('settings.modal.lowQualityDesc')}</Text>
        </Stack>
        <StackItem style={{ flex: 'none' }}>
          <Radio
            checked={videoQuality === 'low'}
            id="video-quality-low"
            label={null}
            name="video-quality"
            onChange={handleChange}
            value="low"
          />
        </StackItem>
      </Stack>
      <Stack align="center" justify="space-between" horizontal>
        <Stack gap={4}>
          <Text
            El="label"
            htmlFor="video-quality-bandwidth-saver"
            variant="strong"
          >
            {t('settings.modal.bandwidthSaver')}
          </Text>
          <Text color="muted">{t('settings.modal.bandwidthSaverDesc')}</Text>
        </Stack>
        <StackItem style={{ flex: 'none' }}>
          <Radio
            checked={videoQuality === 'bandwidth-saver'}
            id="video-quality-bandwidth-saver"
            label={null}
            name="video-quality"
            onChange={handleChange}
            value="bandwidth-saver"
          />
        </StackItem>
      </Stack>
    </Stack>
  );
};
