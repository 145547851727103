import { Button } from '@daily/shared/components/Button';
import { Card, CardContent } from '@daily/shared/components/Card';
import { CloseIcon } from '@daily/shared/components/Icons';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { VisuallyHidden } from '@daily/shared/components/VisuallyHidden';
import { LIGHT_THEME_CLASS, useTheme } from '@daily/shared/contexts/Theme';
import { useDaily, useWaitingParticipants } from '@daily-co/daily-react-hooks';
import classnames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsMobile, useSidebarView } from '../../contexts/UIState';
import { useWaitingRoom } from '../../contexts/WaitingRoomProvider';
import { useStyleVariants } from '../../hooks/useStyleVariants';
import { robotsClassName } from '../../lib/robots';

const MAX_DISPLAYED_NAME_LENGTH = 20;

export const WaitingRoomNotification: React.FC = () => {
  const { t } = useTranslation();
  const { colors, mediaQueries } = useTheme();
  const daily = useDaily();
  const [isMobile] = useIsMobile();
  const [sidebarView, setSidebarView] = useSidebarView();
  const { showModal } = useWaitingRoom();
  const { denyAccess, grantAccess, waitingParticipants } =
    useWaitingParticipants();
  const { iconVariant, textVariantStrong } = useStyleVariants();
  const [showNotification, setShowNotification] = useState(false);

  /**
   * Show notification when waiting participants change.
   */
  useEffect(() => {
    if (showModal || sidebarView === 'people') return;

    const handleWaitingParticipantAdded = () => {
      setShowNotification(Object.keys(daily.waitingParticipants()).length > 0);
    };

    daily.on('waiting-participant-added', handleWaitingParticipantAdded);
    return () => {
      daily.off('waiting-participant-added', handleWaitingParticipantAdded);
    };
  }, [daily, showModal, sidebarView]);

  /**
   * Hide notification when people panel is opened.
   */
  useEffect(() => {
    if (showModal || sidebarView === 'people') setShowNotification(false);
  }, [showModal, sidebarView]);

  const hasMultiplePeopleWaiting = waitingParticipants.length > 1;

  const handleViewAllClick = () => {
    setSidebarView('people');
    setShowNotification(false);
  };
  const handleAllowClick = () => {
    grantAccess(waitingParticipants[0].id);
  };
  const handleDenyClick = () => {
    denyAccess(hasMultiplePeopleWaiting ? '*' : waitingParticipants[0].id);
  };
  const handleClose = () => setShowNotification(false);

  const waitingName = useMemo(() => {
    if (hasMultiplePeopleWaiting || waitingParticipants.length === 0)
      return null;
    if (waitingParticipants[0].name.length < MAX_DISPLAYED_NAME_LENGTH)
      return waitingParticipants[0].name;
    return `${waitingParticipants[0].name.slice(
      0,
      MAX_DISPLAYED_NAME_LENGTH
    )}…`;
  }, [hasMultiplePeopleWaiting, waitingParticipants]);

  if (!showNotification || waitingParticipants.length === 0) return null;

  return (
    // Force light theme styles
    <div className={classnames('waiting-room-notification', LIGHT_THEME_CLASS)}>
      <div className="backdrop" role="presentation" />
      <div
        className={classnames('notification', {
          'with-sidebar': sidebarView,
        })}
      >
        <Card noBorder spacing={8} variant="small">
          <CardContent>
            <Stack>
              <Text variant={textVariantStrong}>
                {hasMultiplePeopleWaiting
                  ? t('waitingRoom.peopleWouldLikeToJoin', {
                      amount: waitingParticipants.length,
                    })
                  : t('waitingRoom.personWouldLikeToJoin', {
                      name: waitingName,
                    })}
              </Text>
              <Stack align="center" gap={isMobile ? 16 : 4} horizontal>
                {hasMultiplePeopleWaiting ? (
                  <Button
                    className={robotsClassName('btn-allow')}
                    onClick={handleViewAllClick}
                    size="small"
                    variant="primary"
                  >
                    <Text El="span" variant={textVariantStrong} color="inherit">
                      {t('waitingRoom.viewAll')}
                    </Text>
                  </Button>
                ) : (
                  <Button
                    className={classnames(
                      robotsClassName('btn-allow'),
                      robotsClassName(waitingParticipants[0].id)
                    )}
                    onClick={handleAllowClick}
                    size="small"
                    variant="primary"
                  >
                    <Text El="span" variant={textVariantStrong} color="inherit">
                      {t('waitingRoom.allow')}
                    </Text>
                  </Button>
                )}
                <Button
                  className={classnames(robotsClassName('btn-deny'), {
                    [robotsClassName(waitingParticipants?.[0]?.id)]:
                      waitingParticipants.length === 1,
                  })}
                  onClick={handleDenyClick}
                  size="small"
                  variant="secondary"
                >
                  <Text El="span" variant={textVariantStrong}>
                    {hasMultiplePeopleWaiting
                      ? t('waitingRoom.denyAll')
                      : t('waitingRoom.deny')}
                  </Text>
                </Button>
              </Stack>
            </Stack>
            <span className="close">
              <Button onClick={handleClose} variant="ghost">
                <CloseIcon color={colors.baseText} size={iconVariant} />
                <VisuallyHidden>{t('general.close')}</VisuallyHidden>
              </Button>
            </span>
          </CardContent>
        </Card>
      </div>
      <style jsx>{`
        .waiting-room-notification {
          height: 100%;
          position: absolute;
          width: 100%;
          z-index: var(--zindex-waitingroom);
        }
        .notification.with-sidebar {
          right: 274px;
        }
        .notification :global(.card-content) {
          padding-right: 24px;
        }
        @media ${mediaQueries.coarse} {
          .notification :global(.card-content) {
            padding-right: 32px;
          }
        }
        .backdrop {
          background: linear-gradient(
            180deg,
            var(--backdrop) 0%,
            var(--backdrop-hidden) 100%
          );
          height: calc(100% - 59px);
          left: 0;
          position: absolute;
          top: var(--header-height);
          width: 100%;
        }
        .notification {
          max-width: calc(100% - 16px);
          position: absolute;
          right: 8px;
          top: calc(var(--header-height) + 8px);
        }
        .close {
          position: absolute;
          right: 4px;
          top: 4px;
        }
      `}</style>
    </div>
  );
};
