import { Badge } from '@daily/shared/components/Badge';
import { Button } from '@daily/shared/components/Button';
import { HomeIcon, ScreenshareIcon } from '@daily/shared/components/Icons';
import { Text } from '@daily/shared/components/Text';
import { ThemeScope, useTheme } from '@daily/shared/contexts/Theme';
import { hexToRgba } from '@daily/shared/lib/colors';
import {
  useParticipant,
  useParticipantIds,
  useScreenShare,
} from '@daily-co/daily-react-hooks';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCallConfig } from '../../hooks/useCallConfig';
import { PAGINATION_HEIGHT } from './constants';

interface Props {
  dotsRef: React.MutableRefObject<HTMLDivElement>;
  onChangePage(p: number): void;
  page: number;
  pages: number;
}

export const MobilePaginationBar: React.FC<Props> = ({
  dotsRef,
  onChangePage,
  page,
  pages,
}) => {
  const { t } = useTranslation();
  const { broadcast, broadcastRole } = useCallConfig();
  const router = useRouter();
  const enableChat = router.query['chat'] !== 'false';
  const { colors } = useTheme();
  const allIds = useParticipantIds();
  const ownerIds = useParticipantIds({ filter: 'owner' });
  const { screens } = useScreenShare();

  const handleChangePage = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>, p: number) => {
      ev.preventDefault();
      ev.stopPropagation();
      onChangePage(p);
    },
    [onChangePage]
  );

  const dots = useMemo(() => {
    const dots: JSX.Element[] = [];
    for (let i = 2; i <= pages; i++) {
      dots.push(
        <Button
          key={i}
          focusable={false}
          variant="ghost"
          onClick={(ev: React.MouseEvent<HTMLButtonElement>) =>
            handleChangePage(ev, i)
          }
        >
          <span
            className={classNames('dot', {
              active: page === i,
            })}
          />
        </Button>
      );
    }
    return dots;
  }, [handleChangePage, page, pages]);

  const showParticipantCount = useMemo(
    () => broadcastRole !== 'attendee' || enableChat,
    [broadcastRole, enableChat]
  );

  /**
   * In OOB calls, owners should always see the full count, not just visible
   * participants. Attendees can see the full count if chat is enabled. Otherwise
   * we don't share participant info.
   *
   * In non-OOB calls, show the usual participant count.
   */
  const count = useMemo(
    () =>
      broadcastRole === 'owner' ||
      (broadcastRole === 'attendee' && enableChat) ||
      !broadcast
        ? allIds.length
        : ownerIds.length,
    [allIds.length, broadcast, broadcastRole, enableChat, ownerIds.length]
  );

  const peopleInCall = useMemo(
    () => (
      <div className="people">
        <Text>
          {count <= 1 && !broadcast ? null : t('people.withCount', { count })}
        </Text>
        {broadcastRole === 'owner' && (
          <Badge
            style={{ alignSelf: 'flex-start', marginLeft: 'auto' }}
            variant="success"
          >
            {t('broadcast.live')}
          </Badge>
        )}
        <style jsx>{`
          .people {
            display: flex;
            flex-direction: row;
            gap: 8px;
          }
        `}</style>
      </div>
    ),
    [broadcast, broadcastRole, count, t]
  );

  const firstPageColor = useMemo(
    () =>
      page === 1
        ? colors.custom.mainAreaText
        : hexToRgba(colors.custom.mainAreaText, 0.4),
    [colors.custom.mainAreaText, page]
  );

  const homeIcon = useMemo(
    () => (
      <Button
        focusable={false}
        variant="ghost"
        onClick={() => onChangePage?.(1)}
      >
        {screens.length ? (
          <ScreenshareIcon color={firstPageColor} size={16} />
        ) : (
          <HomeIcon color={firstPageColor} size={16} />
        )}
      </Button>
    ),
    [firstPageColor, onChangePage, screens?.length]
  );

  const firstScreenParticipant = useParticipant(screens?.[0]?.session_id);
  const screenShareInfo = useMemo(() => {
    if (!screens.length) return null;
    return (
      <Text color="muted">
        {screens.length > 1
          ? t('screenShare.peopleWithCount', {
              count: screens.length,
            })
          : t('screenShare.personWithName', {
              name: firstScreenParticipant?.user_name || t('people.someone'),
            })}
      </Text>
    );
  }, [firstScreenParticipant?.user_name, screens.length, t]);

  const showWaitingMsg = useMemo(() => count < 1, [count]);

  return (
    <ThemeScope theme="dark">
      <div
        className={classNames('bar', {
          hasScreens: screens.length > 0,
        })}
      >
        {pages > 1 && (
          <div
            className={classNames('pagination', {
              moreLeft: page > 3,
              moreRight: page < pages - 4,
            })}
          >
            <div className="home-icon">{homeIcon}</div>
            <div className="dots-wrapper">
              <div
                className="dots"
                ref={dotsRef}
                style={{
                  transform: `translate3d(${Math.min(
                    0,
                    -16 * (page - 3) + 0 * 16
                  )}px, 0, 0)`,
                }}
              >
                {dots}
              </div>
              <div
                className={classNames('activeIndicator', { active: page > 2 })}
              />
            </div>
          </div>
        )}
        {showWaitingMsg ? (
          <div className="waiting">
            <Text>{t('people.waitingForOthers')}</Text>
          </div>
        ) : (
          <div className="status">
            {showParticipantCount && peopleInCall}
            {screenShareInfo}
          </div>
        )}
      </div>
      <style jsx>{`
        .bar {
          --status-gap: 2px;

          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: ${(PAGINATION_HEIGHT - 16) / 2}px 16px;
          width: 100%;
        }
        .bar.hasScreens {
          padding: calc(
              calc(calc(${PAGINATION_HEIGHT}px - var(--status-gap)) - 32px) / 2
            )
            16px;
        }
        .waiting {
          margin: 0 auto;
        }
        .status {
          align-items: flex-end;
          display: flex;
          flex-direction: column;
          gap: var(--status-gap);
          margin-left: auto;
        }
        .pagination {
          align-items: center;
          display: flex;
          flex: none;
          flex-direction: row;
          max-width: 112px; // 16px (Home) + 6 * 16px (margin-left + .dot)
          position: relative;
        }
        .pagination::before,
        .pagination::after {
          content: '';
          height: 16px;
          opacity: 0;
          pointer-events: none;
          position: absolute;
          top: 0;
          width: 20px;
          z-index: 1;
        }
        .pagination::before {
          background: linear-gradient(
            to right,
            ${colors.custom.mainAreaBg},
            ${hexToRgba(colors.custom.mainAreaBg, 0)}
          );
          left: 16px;
        }
        .pagination::after {
          background: linear-gradient(
            to left,
            ${colors.custom.mainAreaBg},
            ${hexToRgba(colors.custom.mainAreaBg, 0)}
          );
          right: -8px;
        }
        .pagination.moreLeft::before,
        .pagination.moreRight::after {
          opacity: 1;
        }
        .home-icon {
          flex: none;
        }
        .dots-wrapper {
          overflow: hidden;
        }
        .dots {
          align-items: center;
          display: flex;
          flex-direction: row;
          transition: transform 250ms ease;
        }
        .dots :global(button) {
          margin: -4px;
          padding: 4px;
          margin-left: 4px;
        }
        .dots :global(.dot) {
          background: ${hexToRgba(colors.custom.mainAreaText, 0.4)};
          border-radius: 4px;
          display: block;
          flex: none;
          height: 8px;
          transition: background 250ms ease;
          width: 8px;
        }
        .dots :global(.dot.active) {
          background: ${colors.custom.mainAreaText};
        }
        .activeIndicator {
          border: 1px solid ${colors.custom.mainAreaText};
          border-radius: 7px;
          height: 14px;
          left: 37px;
          opacity: 0;
          position: absolute;
          top: 1px;
          transition: opacity 250ms ease;
          width: 14px;
        }
        .activeIndicator.active {
          opacity: 1;
        }
      `}</style>
    </ThemeScope>
  );
};
