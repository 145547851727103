import { Button } from '@daily/shared/components/Button';
import { GridViewIcon, SpeakerViewIcon } from '@daily/shared/components/Icons';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { VisuallyHidden } from '@daily/shared/components/VisuallyHidden';
import { useTheme } from '@daily/shared/contexts/Theme';
import classnames from 'classnames';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PreferredViewMode,
  usePreferredViewMode,
  useViewMode,
} from '../../contexts/UIState';
import { robotsClassName } from '../../lib/robots';

const BLOCK_SWITCH_TIMEOUT = 500;

export const ViewSwitch: React.FC = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const viewMode = useViewMode();
  const [, setPreferredViewMode] = usePreferredViewMode();
  const [blockSwitch, setBlockSwitch] = useState(false);

  /**
   * Prevent users from switching between views too quickly.
   */
  const switchView = useCallback(
    (mode: PreferredViewMode) => {
      if (blockSwitch || mode === viewMode) {
        return;
      }
      setPreferredViewMode(mode);
      setBlockSwitch(true);
      setTimeout(() => {
        setBlockSwitch(false);
      }, BLOCK_SWITCH_TIMEOUT);
    },
    [blockSwitch, setPreferredViewMode, viewMode]
  );

  return (
    // We could simply `return null` here, or `display:none`, but it breaks the layout, so we used `visibility:hidden` here.
    <div style={{ visibility: 'hidden' }} className="view-switch">
      <Stack align="center" horizontal>
        <Text>{viewMode === 'grid' ? t('view.grid') : t('view.speaker')}</Text>
        <Stack align="center" horizontal gap={4}>
          <Button
            className={classnames('btn', {
              active: viewMode === 'speaker',
              [robotsClassName('btn-speaker-view-switch')]:
                viewMode !== 'speaker',
              [robotsClassName('btn-speaker-view-noop')]:
                viewMode === 'speaker',
            })}
            disabled={viewMode !== 'speaker' && blockSwitch}
            onClick={() => switchView('speaker')}
            variant="ghost"
          >
            <SpeakerViewIcon
              color={
                viewMode === 'speaker' ? colors.accentText : colors.baseText
              }
            />
            <VisuallyHidden>{t('view.switchToSpeaker')}</VisuallyHidden>
          </Button>
          <Button
            className={classnames('btn', {
              active: viewMode === 'grid',
              [robotsClassName('btn-grid-view-switch')]: viewMode !== 'grid',
              [robotsClassName('btn-grid-view-noop')]: viewMode === 'grid',
            })}
            disabled={viewMode !== 'grid' && blockSwitch}
            onClick={() => switchView('grid')}
            variant="ghost"
          >
            <GridViewIcon
              color={viewMode === 'grid' ? colors.accentText : colors.baseText}
            />
            <VisuallyHidden>{t('view.switchToGrid')}</VisuallyHidden>
          </Button>
        </Stack>
      </Stack>
      <style jsx>{`
        .view-switch :global(.btn) {
          background-color: ${colors.backgroundAccent};
          border-radius: 4px !important;
        }
        .view-switch :global(.btn.active) {
          background-color: ${colors.accent};
        }
        .view-switch :global(.btn[disabled]) {
          background-color: ${colors.backgroundAccent};
        }
      `}</style>
    </div>
  );
};
