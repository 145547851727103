import { DailyNetworkStats } from '@daily-co/daily-js';
import { ExtendedDailyParticipant } from '@daily-co/daily-react-hooks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useDisplayName = (
  participant: ExtendedDailyParticipant,
  showNames: boolean,
  network?: DailyNetworkStats['threshold'],
  name?: string
): string => {
  const { t } = useTranslation();
  const hasPoorConnection = network === 'very-low' && participant?.local;
  const hasWeakConnection = network === 'low' && participant?.local;

  return useMemo(() => {
    if (!showNames) return '';
    if (name) return name;

    const displayName =
      participant?.user_name ||
      (participant?.local ? t('people.you') : t('people.guest'));

    if (hasPoorConnection) {
      return t('people.usernamePoorCoonection', {
        displayName,
      });
    }
    if (hasWeakConnection) {
      return t('people.usernameWeakNetwork', {
        displayName,
      });
    }
    if (participant?.local) {
      return t('people.youWithName', {
        displayName: participant?.user_name || t('people.guest'),
      });
    }
    return displayName;
  }, [participant, hasPoorConnection, hasWeakConnection, showNames, t]);
};
