import classnames from 'classnames';
import React, { useContext } from 'react';

import { TabsContext } from './Tabs';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  // index is automatically set by TabPanels component
  index?: number;
}

export const TabPanel: React.FC<Props> = ({
  children,
  className,
  index,
  ...props
}) => {
  const { activeTab, id, vertical } = useContext(TabsContext);

  const isVisible = activeTab === index;

  return (
    <div
      className={classnames('tabpanel', className, {
        'tabpanel-vertical': vertical,
        visible: isVisible,
      })}
      role="tabpanel"
      aria-labelledby={`tabpanel-${id}-${index}`}
      id={`tabpanel-${id}-${index}`}
      {...props}
    >
      {isVisible ? children : null}
      <style jsx>{`
        .tabpanel {
          display: none;
        }
        .tabpanel.visible {
          display: block;
        }
        .tabpanel:not(.tabpanel-vertical).visible {
          padding-top: 16px;
        }
      `}</style>
    </div>
  );
};
