import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { useTheme } from '@daily/shared/contexts/Theme';
import Bowser from 'bowser';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useCallState } from '../../contexts/CallProvider';
import { useMediaDevices } from '../../contexts/MediaDeviceProvider';
import {
  isAndroidChrome,
  isIOSMobile,
  isSafari,
} from '../../lib/browserConfig';
import { CTAButton } from './CTAButton';
import { getBrowserIcon } from './getBrowserIcon';

const EDGE = 'Microsoft Edge';
const FALLBACK_BROWSER = 'Chrome';
const SUPPORTED_BROWSERS = [EDGE, 'Chrome', 'Firefox', 'Opera', 'Safari'];

interface Props {
  hideUnblockButton?: boolean;
}

export const UnblockPermissionInstructions: React.FC<Props> = ({
  hideUnblockButton = false,
}) => {
  const { t } = useTranslation();
  const { isDarkMode } = useTheme();
  const { mode } = useCallState();
  const { promptForAccess } = useMediaDevices();
  const [browser, setBrowser] = useState<Bowser.Parser.ParsedResult>(null);

  useEffect(() => {
    setBrowser(Bowser.parse(navigator.userAgent));
  }, []);
  const browserIcon = useMemo(
    () => getBrowserIcon(browser, isDarkMode),
    [browser, isDarkMode]
  );

  const browserName = SUPPORTED_BROWSERS.includes(browser?.browser?.name)
    ? browser?.browser?.name
    : FALLBACK_BROWSER;

  const handleGrantAccessClick = useCallback(() => {
    if (mode === 'direct-link') {
      window.location.reload();
    } else {
      promptForAccess();
    }
  }, [mode, promptForAccess]);

  const i18nOptions = useMemo(() => {
    if (isSafari(13) && isIOSMobile()) {
      return 'Safari13+iOS';
    }
    if (isSafari(15)) {
      return 'Safari15';
    }
    return browserName;
  }, [browserName]);

  return (
    <>
      {(isSafari() || isIOSMobile()) && mode === 'direct-link' ? (
        <Stack gap={16}>
          <Text variant="large" El="p" textAlign="left">
            <Trans i18nKey="haircheck.blocked.safariText" />
          </Text>
          {!hideUnblockButton && (
            <CTAButton onClick={handleGrantAccessClick} fullWidth>
              {t('haircheck.blocked.grantAccess')}
            </CTAButton>
          )}
        </Stack>
      ) : isAndroidChrome(browserName) ? (
        <Text variant="large" El="p">
          <Trans i18nKey="haircheck.blocked.androidChromeText">
            1. Tap the lock icon{' '}
            <img
              alt=""
              src={browserIcon}
              height="24"
              style={{ display: 'inline', verticalAlign: 'middle ' }}
            />{' '}
            in your browser&apos;s address bar
            <br />
            2. Tap Permissions
            <br />
            3. Tap Camera and Microphone and Allow both
            <br />
            4. Tap Try again below to refresh
          </Trans>
        </Text>
      ) : (
        <>
          <ol className={isSafari() || isIOSMobile() ? 'withButton' : ''}>
            <Text variant="large" El="li">
              {
                // i18next-extract-mark-context-next-line ["Firefox", "Chrome", "Microsoft Edge", "Opera", "Safari", "Safari15", "Safari13+iOS"]
                <Trans
                  i18nKey="haircheck.blocked.unblockModalStep1"
                  tOptions={{ context: i18nOptions }}
                  values={{ url: window.location.hostname }}
                >
                  Click the camera icon{' '}
                  <img
                    alt=""
                    className="blocked-icon"
                    src={browserIcon}
                    height="24"
                  />{' '}
                  in your browser&apos;s address bar
                </Trans>
              }
            </Text>
            <Text variant="large" El="li">
              {
                // i18next-extract-mark-context-next-line ["Firefox", "Chrome", "Microsoft Edge", "Opera", "Safari", "Safari15", "Safari13+iOS"]
                t('haircheck.blocked.unblockModalStep2', {
                  context: i18nOptions,
                })
              }
            </Text>
            {(isSafari() || isIOSMobile()) && (
              <Text variant="large" El="li">
                {
                  // i18next-extract-mark-context-next-line ["Safari", "Safari15", "Safari13+iOS"]
                  t('haircheck.blocked.unblockModalStep3', {
                    context: i18nOptions,
                  })
                }
              </Text>
            )}
          </ol>
          {!hideUnblockButton && (isSafari() || isIOSMobile()) && (
            <Stack justify="stretch">
              <CTAButton onClick={handleGrantAccessClick} fullWidth>
                {t('haircheck.blocked.grantAccess')}
              </CTAButton>
            </Stack>
          )}
        </>
      )}
      <style jsx>{`
        .blocked-icon {
          vertical-align: bottom;
        }
        ol {
          padding: 0 0 0 20px;
          text-align: left;
        }
        ol.withButton {
          padding: 0;
          margin-bottom: 16px;
        }
        ol.withButton :global(li) {
          margin-left: 20px;
        }
      `}</style>
    </>
  );
};
