import { useDaily } from '@daily-co/daily-react-hooks';
import { cloneParticipantWithoutTracks } from 'lib/participants';
import { useCallback } from 'react';

import IframeDriverMessageChannel from '../../contexts/IframeDriverMessageChannel';

export const useIframeParticipantsHandlers = (
  messageChannel: IframeDriverMessageChannel
) => {
  const daily = useDaily();

  const handleDriverUpdateParticipantMsg = useCallback(
    (msg: any) => {
      if (
        msg.properties &&
        msg.properties['setSubscribedTracks'] !== undefined
      ) {
        console.warn(
          'Prebuilt UI does not support setSubscribedTracks in updateParticipant(); ignoring'
        );
        delete msg.properties['setSubscribedTracks'];
      }
      // @ts-ignore
      daily?.forwardPackagedMessageToCallMachine(msg);
    },
    [daily]
  );

  const handleDriverUpdateParticipantsMsg = useCallback(
    (msg: any) => {
      if (msg.participants) {
        for (const participantUpdates of Object.values(msg.participants)) {
          if (
            participantUpdates &&
            participantUpdates['setSubscribedTracks'] !== undefined
          ) {
            console.warn(
              'Prebuilt UI does not support setSubscribedTracks in updateParticipants(); ignoring'
            );
            delete participantUpdates['setSubscribedTracks'];
          }
        }
      }
      // @ts-ignore
      daily?.forwardPackagedMessageToCallMachine(msg);
    },
    [daily]
  );

  const handleCallMachineParticipantMsg = useCallback(
    (msg: any) => {
      // Remove tracks, which can't be serialized and sent through the
      // message channel.
      const newMsg = { ...msg };
      if (msg.participant) {
        newMsg.participant = cloneParticipantWithoutTracks(msg.participant);
      }
      messageChannel?.forwardPackagedMessageToDriver(newMsg);
    },
    [messageChannel]
  );

  return {
    handleDriverUpdateParticipantMsg,
    handleDriverUpdateParticipantsMsg,
    handleCallMachineParticipantMsg,
  };
};
