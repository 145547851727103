import { Button } from '@daily/shared/components/Button';
import { Modal } from '@daily/shared/components/Modal';
import { Text } from '@daily/shared/components/Text';
import { useTranslation } from 'react-i18next';

import { useRecording } from '../../contexts/RecordingProvider';
import { useCallConfig } from '../../hooks/useCallConfig';

interface Props extends React.ComponentProps<typeof Modal> {}

export const StartRecordingModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { enableRecording } = useCallConfig();
  const { startRecording } = useRecording();

  const handleStartClick = (close) => {
    startRecording();
    close();
  };

  return (
    <Modal
      actions={[
        <Button key="start" onClick={handleStartClick} variant="danger">
          {t('recording.startRecording')}
        </Button>,
        <Button key="close" variant="secondary">
          {props.closeText}
        </Button>,
      ]}
      title={t('recording.startModal.title')}
      {...props}
    >
      <Text variant="large">
        {
          // i18next-extract-mark-context-next-line ["cloud-beta"]
          t('recording.startModal.content', {
            context: enableRecording,
          })
        }
      </Text>
    </Modal>
  );
};
