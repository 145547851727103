import { useTheme } from '@daily/shared/contexts/Theme';
import { DailyReceiveSettings } from '@daily-co/daily-js';
import {
  useDaily,
  useReceiveSettings,
  useScreenShare,
} from '@daily-co/daily-react-hooks';
import { Tray } from 'components/Tray';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  useOrderedParticipantIds,
  useParticipants,
} from '../../contexts/ParticipantsProvider';
import { useTracks } from '../../contexts/TracksProvider';
import { useIsGridHidden, useMobileTray } from '../../contexts/UIState';
import { useCamSubscriptions } from '../../hooks/useCamSubscriptions';
import { useJoinSound } from '../../hooks/useJoinSound';
import { usePreviousValue } from '../../hooks/usePreviousValue';
import { MobileTray } from '../MobileTray';
import { PAGINATION_HEIGHT } from './constants';
import { Home } from './Home';
import { MobilePaginationBar } from './MobilePaginationBar';
import { MobileTopBar } from './MobileTopBar';
import { Page } from './Page';
import { SwipeContainer } from './SwipeContainer';

interface Props {
  pageSize?: number;
}

interface CamSubscriptions {
  stagedIds: string[];
  subscribedIds: string[];
}

const MAX_RENDERED_PAGES = 6;
const MAX_CAROUSEL_PAGES = MAX_RENDERED_PAGES - 2;

const CURRENT_PAGE_SUBSCRIPTION_DELAY = 400;
const ADJACENT_PAGES_SUBSCRIPTION_DELAY = 2000;

export const MobileCall: React.FC<Props> = ({ pageSize = 3 }) => {
  const [isGridHidden] = useIsGridHidden();
  const { colors } = useTheme();
  const daily = useDaily();
  const { currentSpeakerId } = useParticipants();
  const orderedParticipantIds = useOrderedParticipantIds();
  const { subscribeToCam } = useTracks();
  const [, setShowMobileTray] = useMobileTray();
  const [page, setPage] = useState(1);
  const callRef = useRef<HTMLDivElement>(null);
  const dotsRef = useRef<HTMLDivElement>(null);

  const { screens } = useScreenShare();

  useJoinSound();

  const pages = useMemo(() => {
    if (orderedParticipantIds.length <= 1) return 1;
    return 1 + Math.ceil(orderedParticipantIds.length / pageSize);
  }, [pageSize, orderedParticipantIds.length]);

  const previousPage = usePreviousValue(page);
  const previousRenderedPages = useRef<number[]>(null);
  /**
   * Determine rendered pages.
   */
  const renderedPages = useMemo(() => {
    if (pages <= MAX_RENDERED_PAGES) {
      // All pages fit within one chunk
      return new Array(pages).fill(0).map((_, i) => i + 1);
    }
    if (page <= MAX_CAROUSEL_PAGES) {
      // User is viewing first chunk of pages
      return [
        ...new Array(MAX_RENDERED_PAGES - 1).fill(0).map((_, i) => i + 1),
        pages,
      ];
    }
    if (
      [page - 1, page, Math.min(page + 1, pages)].every((p) =>
        previousRenderedPages.current.includes(p)
      )
    ) {
      // All important pages are already present from a previous memoization cycle.
      // No need to recalculate ☺️
      return previousRenderedPages.current;
    }
    if (Math.abs(page - previousPage) > 1) {
      // User navigated using pagination dots
      return [
        1,
        ...new Array(MAX_CAROUSEL_PAGES).fill(0).map((_, i) => page + (i - 1)),
        pages,
      ];
    }
    if (page === previousPage) {
      // Page hasn't changed, but pages and one of the adjacent pages is missing.
      const missingPage = [page - 1, page, Math.min(page + 1, pages)].find(
        (p) => !previousRenderedPages.current.includes(p)
      );
      const swapPage =
        missingPage > page
          ? Math.min(...previousRenderedPages.current.filter((p) => p > 1))
          : Math.max(...previousRenderedPages.current.filter((p) => p < pages));
      const idx = previousRenderedPages.current.findIndex(
        (p) => p === swapPage
      );
      const newPages = [...previousRenderedPages.current];
      if (missingPage && idx >= 0) {
        newPages[idx] = missingPage;
        return newPages;
      }
    }
    // Determine buffer page and promote it to be a new adjacent page
    const swapIndex = previousRenderedPages.current.findIndex(
      (p) =>
        p !== 1 &&
        p !== pages &&
        (previousPage < page ? p < previousPage - 1 : p > previousPage + 1)
    );
    const newPages = [...previousRenderedPages.current];
    if (swapIndex >= 0) {
      if (previousPage < page) {
        // Example: [1,2,3,4,(5),10] => [1,6,3,4,(5),10]
        newPages[swapIndex] = page + 1;
      } else {
        // Example: [1,(6),7,8,9,10] => [1,(6),7,8,5,10]
        newPages[swapIndex] = page - 1;
      }
    }
    return newPages;
  }, [page, pages, previousPage]);
  useEffect(() => {
    previousRenderedPages.current = [...renderedPages];
  }, [renderedPages]);

  const getParticipantsOnPage = useCallback(
    (p: number) =>
      orderedParticipantIds.slice(
        Math.max(0, pageSize * (p - 2)),
        Math.min(orderedParticipantIds.length, pageSize * (p - 1))
      ),
    [orderedParticipantIds, pageSize]
  );

  /**
   * Automatically switch to page 1, when a screen share starts.
   */
  const prevScreens = usePreviousValue(screens.length);
  useEffect(() => {
    if (screens.length <= prevScreens) return;
    setPage(1);
  }, [screens.length, prevScreens]);

  const { updateReceiveSettings } = useReceiveSettings();

  /**
   * Set bandwidth layer based on visible participants
   */
  useEffect(() => {
    const receiveSettings = orderedParticipantIds.reduce<DailyReceiveSettings>(
      (settings, id) => {
        settings[id] = {
          video: { layer: page === 1 && id === currentSpeakerId ? 1 : 0 },
        };
        return settings;
      },
      {}
    );
    if (Object.keys(receiveSettings).length === 0) return;
    updateReceiveSettings(receiveSettings);
  }, [currentSpeakerId, orderedParticipantIds, page, updateReceiveSettings]);

  /**
   * Subscribe to videos on current page, delaying subscribing to
   * not-already-staged ones as a resource usage optimization.
   */
  useEffect(() => {
    if (!daily) return;
    const users = daily.participants();

    /**
     * First we try to subscribe to videos that are already staged.
     */
    const isStaged = (id: string) =>
      users[id]?.tracks?.video?.subscribed === 'staged';
    if (page === 1) {
      if (isStaged(currentSpeakerId)) subscribeToCam(currentSpeakerId);
      return;
    }
    getParticipantsOnPage(page).forEach((id) => {
      if (isStaged(id)) subscribeToCam(id);
    });

    /**
     * If the user stays on page, subscribe to not-already-staged videos.
     */
    const timeout = setTimeout(() => {
      getParticipantsOnPage(page).forEach((id) => {
        subscribeToCam(id);
      });
    }, CURRENT_PAGE_SUBSCRIPTION_DELAY);
    return () => {
      clearTimeout(timeout);
    };
  }, [
    currentSpeakerId,
    daily,
    getParticipantsOnPage,
    page,
    pageSize,
    subscribeToCam,
  ]);

  /**
   * Memoize subscribed & staged ids.
   */
  const camSubscriptions = useMemo<CamSubscriptions>(() => {
    const renderedOrBufferedIds = [currentSpeakerId];

    // Buffer adjacent pages (meaning allow staging subscriptions for them)
    for (let p = Math.max(1, page - 1); p <= Math.min(pages, page + 1); p++) {
      renderedOrBufferedIds.push(...getParticipantsOnPage(p));
    }

    const renderedIds =
      page === 1 ? [currentSpeakerId] : getParticipantsOnPage(page);

    const stagedIds = renderedOrBufferedIds.filter(
      (id) => !renderedIds.includes(id)
    );

    return {
      stagedIds,
      subscribedIds: renderedIds,
    };
  }, [currentSpeakerId, getParticipantsOnPage, page, pages]);

  useCamSubscriptions(
    camSubscriptions?.subscribedIds,
    camSubscriptions?.stagedIds,
    ADJACENT_PAGES_SUBSCRIPTION_DELAY
  );

  const content = useMemo(
    () =>
      renderedPages.map((p, i) => {
        const isBufferPage = ![1, pages, page - 1, page, page + 1].includes(p);
        const props = {
          key: `page${i}`,
          active: page === p,
          className: 'swipe-page',
          'data-page': p,
          style: {
            transform: `translate3d(${Math.max(
              -200,
              Math.min(200, 100 * (p - page))
            )}vw, 0, 0)`,
          },
        };
        const ids = orderedParticipantIds.slice(
          (p - 2) * pageSize,
          (p - 1) * pageSize
        );
        return p === 1 ? (
          <Home {...props} />
        ) : (
          <Page
            index={i}
            isBufferPage={isBufferPage}
            page={p}
            sessionIds={ids}
            {...props}
          />
        );
      }),
    [orderedParticipantIds, page, pages, pageSize, renderedPages]
  );

  const handleChangePage = (p: number) => {
    setPage(p);
  };

  const handleSwipe = (delta: number) => {
    if (!dotsRef.current) return;
    dotsRef.current.style.transform = `translate3d(${Math.min(
      0,
      -16 * (page - 3) + delta * 16
    )}px, 0, 0)`;
  };

  const handleTouchEnd = useCallback<React.TouchEventHandler<HTMLDivElement>>(
    (e) => {
      const target = e.target as HTMLElement;
      // don't hide the tray if the user is pressing a button
      if (
        target?.closest('button') ||
        target?.tagName?.toLowerCase() === 'button' ||
        e.touches.length > 1
      )
        return;

      setShowMobileTray((showMobileTray) => !showMobileTray);
    },
    [setShowMobileTray]
  );

  return (
    <div className="mobile-call">
      <MobileTopBar />
      <div
        className={isGridHidden ? 'call is-grid-hidden' : 'call'}
        ref={callRef}
        onTouchEnd={handleTouchEnd}
      >
        <SwipeContainer
          onChangePage={handleChangePage}
          onSwipe={handleSwipe}
          page={page}
          pages={pages}
        >
          {content}
        </SwipeContainer>
      </div>
      <div className="pagination">
        <MobilePaginationBar
          dotsRef={dotsRef}
          onChangePage={setPage}
          page={page}
          pages={pages}
        />
      </div>
      <Tray />
      <style jsx>{`
        .is-grid-hidden.call {
          visibility: hidden;
        }
        .mobile-call {
          // background-color: ${colors.custom.mainAreaBg};
          display: grid;
          grid-template-columns: minmax(0, 1fr);
          grid-template-rows: minmax(0, 1fr) ${PAGINATION_HEIGHT}px;
          grid-template-areas:
            'call'
            'pagination';
          height: 100%;
          justify-content: center;
          overflow: hidden;
          position: relative;
          width: 100%;
        }
        .call {
          grid-area: call;
        }
        .call :global(.swipe-page) {
          content-visibility: auto;
          height: 100%;
          position: absolute;
          transition: transform 0.25s ease;
          width: 100%;
        }
        .call :global(.swipe-page.swiping) {
          transition-duration: 0ms;
        }
        .pagination {
          display: none;
          grid-area: pagination;
        }
      `}</style>
    </div>
  );
};
