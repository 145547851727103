import { Button } from '@daily/shared/components/Button';
import { CloseIcon, MetricsIcon } from '@daily/shared/components/Icons';
import { Text } from '@daily/shared/components/Text';
import { VisuallyHidden } from '@daily/shared/components/VisuallyHidden';
import { useTheme } from '@daily/shared/contexts/Theme';
import { hexToRgba } from '@daily/shared/lib/colors';
import { DailyTrackState } from '@daily-co/daily-js';
import {
  useDaily,
  useNetwork,
  useParticipant,
  useReceiveSettings,
} from '@daily-co/daily-react-hooks';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Portal } from 'react-portal';

import { isSafari } from '../../lib/browserConfig';
import { DebuggerAudioCanvas } from './DebuggerAudioCanvas';

interface Props {
  isScreen?: boolean;
  sessionId: string;
}

interface SessionData {
  connection: string;
  sfuWorker?: string;
}

interface AudioData {
  connected: boolean;
  trackState?: DailyTrackState['state'];
  trackId?: string;
}

interface VideoData {
  fps: number;
  renderedHeight: number;
  renderedWidth: number;
  trackId?: string;
  trackState?: DailyTrackState['state'];
  videoHeight: number;
  videoWidth: number;
}

class DebugManager {
  enabled = false;
  ids: Record<string, Function> = {};
  enable() {
    this.enabled = true;
    for (let id in this.ids) {
      this.ids[id]();
    }
  }
  disable() {
    this.enabled = false;
    for (let id in this.ids) {
      this.ids[id]();
    }
  }
  register(id: string, callback: Function) {
    if (!this.ids[id]) {
      this.ids[id] = callback;
    }
  }
  unregister(id: string) {
    delete this.ids[id];
  }
}
export const prebuiltDebugger = new DebugManager();
if (typeof window !== 'undefined') {
  window['dailyEnablePrebuiltDebugger'] = function () {
    prebuiltDebugger.enable();
  };
  window['dailyDisablePrebuiltDebugger'] = function () {
    prebuiltDebugger.disable();
  };
}

export const Debugger: React.FC<Props> = memo(({ isScreen, sessionId }) => {
  const { query } = useRouter();
  const { colors, zIndex } = useTheme();
  const daily = useDaily();
  const [showModal, setShowModal] = useState(false);
  const debuggerRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const participant = useParticipant(sessionId);

  const handleClick = () => setShowModal((show) => !show);

  const video = useRef<HTMLVideoElement>(null);
  const [audioData, setAudioData] = useState<AudioData>(null);
  const [videoData, setVideoData] = useState<VideoData>(null);
  const [sessionData, setSessionData] = useState<SessionData>(null);

  const { receiveSettings } = useReceiveSettings({ id: sessionId });

  const forceUpdate: () => void = useState()[1].bind(null, {});
  const isDebugging = 'debug' in query || prebuiltDebugger.enabled;

  useEffect(() => {
    prebuiltDebugger.register(sessionId, forceUpdate);
    return () => {
      prebuiltDebugger.unregister(sessionId);
    };
  }, [forceUpdate, sessionId]);

  useEffect(() => {
    if (!daily || !isDebugging) return;

    const tile = document.getElementById(sessionId);

    let lastTime = 0;
    let lastFrames = 0;

    const interval = setInterval(async () => {
      video.current = tile.querySelector('video');
      const participant = Object.values(daily.participants()).find(
        (p) => p.session_id === sessionId
      );
      const audioTrack = isScreen
        ? participant?.tracks?.screenAudio
        : participant?.tracks?.audio;
      let connected = false;
      if (isSafari()) {
        const audioEls: HTMLAudioElement[] = Array.from(
          document.querySelectorAll('.audioTracks audio')
        );
        for (const audio of audioEls) {
          const audioStream = audio?.srcObject as MediaStream;
          const audioTracks = audioStream?.getAudioTracks?.() ?? [];
          if (
            audioTracks.some((t) => t.id === audioTrack?.persistentTrack?.id)
          ) {
            connected = true;
            break;
          }
        }
      } else {
        const node: MediaStreamAudioSourceNode =
          window?.['dailyAudioNodes']?.[audioTrack?.persistentTrack?.id] ??
          null;
        if (node) connected = true;
      }
      setAudioData({
        connected,
        trackId: audioTrack?.persistentTrack?.id,
        trackState: audioTrack?.state,
      });

      const videoTrack = isScreen
        ? participant?.tracks?.screenVideo
        : participant?.tracks?.video;

      if (!video.current) {
        setVideoData((vd) => ({
          ...vd,
          fps: 0,
          renderedHeight: 0,
          renderedWidth: 0,
          trackId: videoTrack?.persistentTrack?.id,
          trackState: videoTrack?.state,
          videoHeight: 0,
          videoWidth: 0,
        }));
        return;
      }

      const playbackQuality = video.current.getVideoPlaybackQuality();
      const time = video.current.currentTime;
      const frames = playbackQuality.totalVideoFrames;
      const fps = Math.round((frames - lastFrames) / (time - lastTime));

      setVideoData((vd) => ({
        ...vd,
        fps: Number.isNaN(fps) ? 0 : fps,
        renderedHeight: video.current?.clientHeight,
        renderedWidth: video.current?.clientWidth,
        trackId: videoTrack?.persistentTrack?.id,
        trackState: videoTrack?.state,
        videoHeight: video.current?.videoHeight,
        videoWidth: video.current?.videoWidth,
      }));
      lastTime = time;
      lastFrames = frames;
    }, 1000);

    /**
     * Gets and stores initial session connection type information.
     */
    const setInitialSessionData = async () => {
      const { topology } = await daily.getNetworkTopology();
      setSessionData({
        connection: topology,
        sfuWorker: topology === 'sfu' ? (rtcpeers as any)?.sfu?.workerId : null,
      });
    };
    setInitialSessionData();

    return () => {
      clearInterval(interval);
      setShowModal(false);
    };
  }, [daily, isDebugging, isScreen, sessionId]);

  /**
   * Updates connection information based on network-connection events.
   */
  useNetwork({
    onNetworkConnection: useCallback((ev) => {
      setSessionData({
        connection: ev.type,
        sfuWorker: ev.type === 'sfu' ? (rtcpeers as any)?.sfu?.workerId : null,
      });
    }, []),
  });

  const bringToFront = useCallback(() => {
    const popover = popoverRef.current;
    const highestZIndex = Math.max(
      ...Array.from(document.querySelectorAll('.debug-data')).map((el) =>
        parseInt(window.getComputedStyle(el).zIndex, 10)
      )
    );
    const zIndex = parseInt(window.getComputedStyle(popover).zIndex, 10);
    if (zIndex < highestZIndex) {
      popover.style.zIndex = `${highestZIndex + 1}`;
    }
  }, []);

  useEffect(() => {
    const popover = popoverRef.current;
    if (!showModal || !popover) return;

    let currentX: number = null;
    let currentY: number = null;

    const debug = debuggerRef.current;
    const { left, top } = debug.getBoundingClientRect();

    const highestZIndex = Math.max(
      ...Array.from(document.querySelectorAll('.debug-data')).map((el) =>
        parseInt(window.getComputedStyle(el).zIndex, 10)
      )
    );

    popover.style.top = `${top + debug.clientHeight}px`;
    popover.style.left = `${left}px`;
    popover.style.zIndex = `${highestZIndex + 1}`;

    const handleDragStart = (ev: MouseEvent) => {
      currentX = ev.clientX;
      currentY = ev.clientY;
      bringToFront();
    };

    const handleDrag = (ev: MouseEvent) => {
      if (currentX === null) return;
      popover.style.left = `${popover.offsetLeft + ev.clientX - currentX}px`;
      popover.style.top = `${popover.offsetTop + ev.clientY - currentY}px`;
      currentX = ev.clientX;
      currentY = ev.clientY;
    };

    const handleDragEnd = () => {
      currentX = null;
      currentY = null;
    };

    const header = popover.querySelector('header');

    popover.addEventListener('click', bringToFront);
    header.addEventListener('mousedown', handleDragStart);
    header.addEventListener('touchstart', handleDragStart);
    document.addEventListener('mousemove', handleDrag);
    document.addEventListener('mouseup', handleDragEnd);
    return () => {
      popover.removeEventListener('click', bringToFront);
      header.removeEventListener('mousedown', handleDragStart);
      header.removeEventListener('touchstart', handleDragStart);
      document.removeEventListener('mousemove', handleDrag);
      document.removeEventListener('mouseup', handleDragEnd);
    };
  }, [bringToFront, showModal]);

  if (!isDebugging) return null;

  return (
    <div
      ref={debuggerRef}
      className={classNames('debugger', { visible: showModal })}
    >
      <Button onClick={handleClick} variant="ghost">
        <MetricsIcon color={colors.baseText} size={16} />
        <VisuallyHidden>Debug participant</VisuallyHidden>
      </Button>
      {showModal && (
        <Portal>
          <div ref={popoverRef} className="debug-data">
            <DebuggerAudioCanvas isScreen={isScreen} sessionId={sessionId} />
            <header>
              <div className="close">
                <Button onClick={handleClick} variant="ghost">
                  <CloseIcon color={colors.custom.mainAreaText} size={16} />
                  <VisuallyHidden>Close debugger</VisuallyHidden>
                </Button>
              </div>
            </header>
            <Text color="inherit">
              Participant ID:{' '}
              {participant?.local ? (
                <abbr title="local">🏠</abbr>
              ) : (
                <abbr title="remote">🛰</abbr>
              )}{' '}
              <code>{sessionId}</code>
            </Text>
            {isScreen ? <Text color="inherit">Screen share</Text> : null}
            <Text color="inherit">
              Connection: <code>{sessionData?.connection}</code>
            </Text>
            {sessionData?.connection === 'sfu' && (
              <Text color="inherit">
                SFU worker: <code>{sessionData.sfuWorker}</code>
              </Text>
            )}
            <Text color="inherit">
              Name:{' '}
              <code>
                {participant?.user_name ? participant.user_name : 'Guest'}
              </code>
            </Text>
            {audioData && (
              <>
                {audioData?.trackId && (
                  <Text color="inherit">
                    Audio Track ID: <code>{audioData?.trackId}</code>
                  </Text>
                )}
                <Text color="inherit">
                  Audio State: <code>{audioData?.trackState}</code>
                </Text>
                <Text color="inherit">
                  Audio DOM track playing: {audioData.connected ? '✅' : '❌'}
                </Text>
              </>
            )}
            {videoData && (
              <>
                {videoData?.trackId && (
                  <Text color="inherit">
                    Video Track ID: <code>{videoData?.trackId}</code>
                  </Text>
                )}
                <Text color="inherit">
                  Video State: <code>{videoData?.trackState}</code>
                </Text>
                <Text color="inherit">
                  Rendered size:{' '}
                  <code>
                    {videoData?.renderedWidth}x{videoData?.renderedHeight}
                  </code>
                </Text>
                <Text color="inherit">
                  Resolution:{' '}
                  <code>
                    {videoData?.videoWidth}x{videoData?.videoHeight}
                  </code>
                </Text>
                <Text color="inherit">
                  Frame rate: <code>{videoData?.fps}</code>
                </Text>
                {!participant?.local && receiveSettings && (
                  <Text color="inherit">
                    Receive settings:{' '}
                    <code>{JSON.stringify(receiveSettings)}</code>
                  </Text>
                )}
              </>
            )}
          </div>
        </Portal>
      )}
      <style jsx>{`
        .debugger {
          left: 8px;
          opacity: 0;
          pointer-events: none;
          position: absolute;
          top: 8px;
          transition: opacity 0.2s ease;
        }
        :global(.tile:hover) .debugger,
        .debugger.visible {
          opacity: 1;
        }
        .debugger :global(button) {
          background: ${colors.background};
          border-radius: 4px;
          padding: 4px;
        }
        .debug-data {
          background: ${hexToRgba(colors.custom.mainAreaBg, 0.8)};
          border: 1px solid ${hexToRgba(colors.custom.mainAreaBg, 0.8)};
          border-radius: 2px;
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
          color: ${colors.custom.mainAreaText};
          display: flex;
          flex-direction: column;
          gap: 4px;
          overflow: auto;
          padding: 4px;
          pointer-events: visible;
          position: fixed;
          resize: both;
          z-index: ${zIndex.tooltip};
        }
        .debug-data header {
          background: ${colors.custom.mainAreaBg};
          cursor: move;
          display: flex;
          margin: -4px;
          padding: 4px;
          position: sticky;
          top: -4px;
        }
        .debug-data .close {
          margin-left: auto;
        }
        .debug-data abbr {
          cursor: help;
        }
      `}</style>
    </div>
  );
});
Debugger.displayName = 'Debugger';
