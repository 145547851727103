import { useUniqueId } from 'hooks/useUniqueId';
import { SVGProps } from 'react';

export function Sapphire(props: SVGProps<SVGSVGElement>) {
  // Gradient ID's
  const a = useUniqueId();
  const b = useUniqueId();
  const c = useUniqueId();
  const d = useUniqueId();
  const e = useUniqueId();
  const f = useUniqueId();

  return (
    <>
      <svg
        width={120}
        height={120}
        viewBox="0 0 120 120"
        fill="none"
        {...props}
      >
        <path
          d="M62 20.473a9.808 9.808 0 0 1 3.775 1.67l32.881 23.89a9.809 9.809 0 0 1 2.767 3.093l17.058-5.542a12.556 12.556 0 0 0-3.806-4.454L67.39 4.776A12.546 12.546 0 0 0 62 2.536v17.936Z"
          fill={`url(#${a})`}
        />
        <path
          d="M18.576 49.126a9.808 9.808 0 0 1 2.766-3.093l32.881-23.89A9.807 9.807 0 0 1 58 20.474V2.535a12.546 12.546 0 0 0-5.392 2.24L5.323 39.13a12.548 12.548 0 0 0-3.805 4.453l17.058 5.543Z"
          fill={`url(#${b})`}
        />
        <path
          d="M23.592 49.129a6 6 0 0 0-2.18 6.708l12.56 38.654a6 6 0 0 0 5.706 4.146h40.644a6 6 0 0 0 5.706-4.146l12.56-38.654a6 6 0 0 0-2.18-6.708l-32.881-23.89a5.999 5.999 0 0 0-7.054 0l-32.88 23.89Z"
          fill={`url(#${c})`}
        />
        <path
          d="M102.651 52.933a9.793 9.793 0 0 1-.425 4.087l-12.56 38.654a9.807 9.807 0 0 1-2.078 3.578l10.542 14.511a12.543 12.543 0 0 0 3.052-4.988l18.061-55.586a12.54 12.54 0 0 0 .467-5.799l-17.059 5.543Z"
          fill={`url(#${d})`}
        />
        <path
          d="M84.35 101.6a9.816 9.816 0 0 1-4.029.864H39.678a9.79 9.79 0 0 1-4.006-.854l-10.543 14.511a12.544 12.544 0 0 0 5.647 1.343h58.447c1.97 0 3.912-.463 5.67-1.353L84.35 101.6Z"
          fill={`url(#${e})`}
        />
        <path
          d="m21.886 113.781 10.543-14.51a9.812 9.812 0 0 1-2.096-3.597L17.774 57.02a9.809 9.809 0 0 1-.425-4.088L.291 47.39a12.546 12.546 0 0 0 .466 5.799l18.061 55.586a12.552 12.552 0 0 0 3.068 5.006Z"
          fill={`url(#${f})`}
        />
        <defs>
          <linearGradient
            id={a}
            x1={107.203}
            y1={6.616}
            x2={90.371}
            y2={82.156}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.185} stopColor="#9E9DDD" />
            <stop offset={0.526} stopColor="#5756A1" />
            <stop offset={1} stopColor="#32315B" />
          </linearGradient>
          <linearGradient
            id={b}
            x1={29.759}
            y1={2.536}
            x2={64.575}
            y2={71.067}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.2} stopColor="#9E9DDD" />
            <stop offset={0.526} stopColor="#5756A1" />
            <stop offset={1} stopColor="#32315B" />
          </linearGradient>
          <linearGradient
            id={c}
            x1={60}
            y1={24.094}
            x2={60}
            y2={98.637}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.052} stopColor="#9E9DDD" />
            <stop offset={0.526} stopColor="#5756A1" />
            <stop offset={1} stopColor="#32315B" />
          </linearGradient>
          <linearGradient
            id={d}
            x1={103.723}
            y1={47.39}
            x2={103.723}
            y2={113.763}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.052} stopColor="#9E9DDD" />
            <stop offset={0.349} stopColor="#5756A1" />
            <stop offset={1} stopColor="#32315B" />
          </linearGradient>
          <linearGradient
            id={e}
            x1={60.011}
            y1={86.877}
            x2={60.011}
            y2={117.464}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.052} stopColor="#9E9DDD" />
            <stop offset={0.526} stopColor="#5756A1" />
            <stop offset={1} stopColor="#32315B" />
          </linearGradient>
          <linearGradient
            id={f}
            x1={16.286}
            y1={47.39}
            x2={16.286}
            y2={113.781}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.052} stopColor="#9E9DDD" />
            <stop offset={0.349} stopColor="#5756A1" />
            <stop offset={1} stopColor="#32315B" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
}
