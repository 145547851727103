import { useTheme } from '@daily/shared/contexts/Theme';
import classnames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

interface Props {
  color: string;
  top?: number;
  variant?: 'default' | 'inline';
  visible?: boolean;
}

export const TrayBadge: React.FC<Props> = ({
  color,
  top = 4,
  variant = 'default',
  visible = false,
}) => {
  const { colors } = useTheme();
  return (
    <div className="tray-badge">
      <AnimatePresence>
        {visible && (
          <motion.span
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            className={classnames('badge', variant, { visible })}
            style={{ background: color }}
          />
        )}
      </AnimatePresence>
      <style jsx>{`
        .tray-badge :global(.badge) {
          align-items: center;
          border-radius: 50%;
          display: inline-flex;
          height: 12px;
          justify-content: center;
          padding: 4px;
          visibility: hidden;
          width: 12px;
        }
        .tray-badge :global(.badge::after) {
          background: ${colors.system.white};
          border-radius: 4px;
          content: '';
          height: 4px;
          width: 4px;
        }
        .tray-badge :global(.badge.default) {
          left: 50%;
          position: absolute;
          top: ${top}px;
        }
        .tray-badge :global(.badge.inline) {
          position: relative;
        }
        .tray-badge :global(.badge.visible) {
          visibility: visible;
        }
      `}</style>
    </div>
  );
};
