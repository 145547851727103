import { Card, CardContent } from '@daily/shared/components/Card';
import { CamIcon, MicrophoneIcon } from '@daily/shared/components/Icons';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { useTheme } from '@daily/shared/contexts/Theme';
import { StatefulDevice } from '@daily-co/daily-react-hooks';
import classnames from 'classnames';
import { useIframeDriver } from 'contexts/IframeDriverProvider';
import { useStyleVariants } from 'hooks/useStyleVariants';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import { isSafari } from '../../lib/browserConfig';
import { CTAButton } from './CTAButton';
import { CTAContent } from './CTAContent';
import { DeviceInUseError } from './DeviceInUse';
import { AccessState, GeneralState, RequestState } from './Setup';
import { UnblockPermissionInstructions } from './UnblockPermissionInstructions';

interface Props {
  camState: GeneralState;
  micState: GeneralState;
  hasMicError: boolean;
  hasCamError: boolean;
  cameras: StatefulDevice[];
  accessState: AccessState;
  requestState: RequestState;
  onComplete(): void;
  onCancelClick(): void;
  shouldUseLandscapeLayout: boolean;
}

export const DeviceErrorHandling: React.FC<Props> = ({
  accessState,
  cameras,
  camState,
  hasCamError,
  hasMicError,
  micState,
  onCancelClick,
  onComplete,
  requestState,
  shouldUseLandscapeLayout,
}) => {
  // When camera is mandatory for the rooms, we should look for the query params in url.
  // For instance, if ?video=forced, that it means that camera is required for the room.
  const router = useRouter();
  const isCameraRequired = router.query['video'] === 'forced';

  const { sendMessageToDriver } = useIframeDriver();

  const { t } = useTranslation();
  const { textVariant } = useStyleVariants();
  const { mediaQueries } = useTheme();

  const isDeviceInUseError = camState === 'in-use' || micState === 'in-use';
  const isBlockedError = camState === 'blocked' || micState === 'blocked';
  const camNotFound = camState === 'not-found';
  const micNotFound = micState === 'not-found';

  const getHeading = (title) => {
    return (
      <div className="heading">
        {micNotFound ? (
          <MicrophoneIcon muted id="micicon" />
        ) : (
          <CamIcon muted id="camicon" />
        )}
        <Text variant="largestrong">{title}</Text>
        <style jsx>{`
          .heading {
            display: flex;
            gap: 4px;
            text-align: left;
          }
          .heading :global(svg) {
            margin-left: -2px;
          }
        `}</style>
      </div>
    );
  };

  return (
    <div
      className={classnames('device-error', {
        unblockSafari: isSafari() && isBlockedError,
      })}
    >
      {isDeviceInUseError ? (
        <DeviceInUseError
          camState={camState}
          micState={micState}
          hasMicError={hasMicError}
          hasCamError={hasCamError}
          shouldUseLandscapeLayout={shouldUseLandscapeLayout}
          cameras={cameras}
          accessState={accessState}
          requestState={requestState}
          onComplete={onComplete}
          onCancelClick={onCancelClick}
        />
      ) : (
        <Card>
          <CardContent>
            {isBlockedError ? (
              <Stack gap={16}>
                {getHeading(t('haircheck.blocked.title'))}
                <UnblockPermissionInstructions />
                {!isCameraRequired && (
                  <CTAContent
                    accessState={accessState}
                    requestState={requestState}
                    onCancelClick={onCancelClick}
                    onComplete={onComplete}
                    hasCamError={hasCamError}
                    hasMicError={hasMicError}
                    camState={camState}
                    micState={micState}
                    shouldUseLandscapeLayout={shouldUseLandscapeLayout}
                  />
                )}
              </Stack>
            ) : (
              <Stack gap={16}>
                {getHeading(
                  // i18next-extract-mark-context-next-line ["both", "camera", "mic"]
                  t('haircheck.noDevices.title', {
                    context:
                      camNotFound && micNotFound
                        ? 'both'
                        : camNotFound
                        ? 'camera'
                        : 'mic',
                  })
                )}
                <Text El="div" variant={textVariant}>
                  {
                    // i18next-extract-mark-context-next-line ["both", "camera", "mic"]
                    t('haircheck.noDevices.modalContent', {
                      context:
                        camNotFound && micNotFound
                          ? 'both'
                          : camNotFound
                          ? 'camera'
                          : 'mic',
                    })
                  }
                </Text>
                <Stack align="stretch">
                  <CTAButton
                    onClick={() =>
                      // Originally it was simple `window.location.reload()` here.
                      // But, since this is loaded in iframe, it can't reload parent.
                      // `parent.location.reload()` does not work because of the CORS issues.
                      // So we simply fire an event, that will be handled by the parent.
                      sendMessageToDriver({ action: 'st-try-again' })
                    }
                    fullWidth
                  >
                    {t('general.tryAgain')}
                  </CTAButton>
                </Stack>
                {!isCameraRequired && (
                  <CTAContent
                    accessState={accessState}
                    requestState={requestState}
                    onCancelClick={onCancelClick}
                    onComplete={onComplete}
                    hasCamError={hasCamError}
                    hasMicError={hasMicError}
                    camState={camState}
                    micState={micState}
                    shouldUseLandscapeLayout={shouldUseLandscapeLayout}
                  />
                )}
              </Stack>
            )}
          </CardContent>
        </Card>
      )}
      <style jsx>{`
        .device-error :global(.card-content) {
          padding: 32px;
          width: 92vw;
        }
        @media ${mediaQueries.small} {
          .device-error :global(.card-content) {
            padding: 24px;
            width: 92vw;
          }
        }
        @media ${mediaQueries.medium} {
          .device-error :global(.card-content) {
            max-width: 500px;
            width: 82vw;
          }
        }
        @media ${mediaQueries.medium} {
          .device-error.unblockSafari :global(.card-content) {
            max-width: 388px;
          }
        }
      `}</style>
    </div>
  );
};
