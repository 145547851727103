import { useUniqueId } from 'hooks/useUniqueId';
import { SVGProps } from 'react';

export function Kunzite(props: SVGProps<SVGSVGElement>) {
  // Gradient ID's
  const a = useUniqueId();
  const b = useUniqueId();
  const c = useUniqueId();
  const d = useUniqueId();
  const e = useUniqueId();
  const f = useUniqueId();
  const g = useUniqueId();
  const h = useUniqueId();
  const i = useUniqueId();
  const j = useUniqueId();
  const k = useUniqueId();
  const l = useUniqueId();
  const m = useUniqueId();
  const n = useUniqueId();
  const o = useUniqueId();
  const p = useUniqueId();
  return (
    <svg width={120} height={120} viewBox="0 0 120 120" fill="none" {...props}>
      <g clipPath={`url(#${a})`}>
        <path
          d="m62.05 16.517 15.488 3.429 7.077-14.42L62.05.482v16.034Z"
          fill={`url(#${b})`}
        />
        <path
          d="m42.46 19.946 15.487-3.429V.483L35.383 5.525l7.077 14.42Z"
          fill={`url(#${c})`}
        />
        <path
          d="m16.821 48.785 6.872-14.117-12.718-9.983L.924 45.155l15.897 3.63Z"
          fill={`url(#${d})`}
        />
        <path
          d="m26.255 31.442 12.513-9.782L31.69 7.24 13.537 21.46l12.718 9.983Z"
          fill={`url(#${e})`}
        />
        <path
          d="m81.23 21.66 12.513 9.782 12.718-9.983L88.307 7.24l-7.076 14.42Z"
          fill={`url(#${f})`}
        />
        <path
          d="m77.949 96.574 13.948-10.827.308-.301 7.692-15.64c.103-.1.103-.3.103-.4V51.862c0-.2 0-.3-.103-.401l-7.692-15.64c-.102-.1-.205-.3-.308-.3L77.95 24.594c-.103-.1-.308-.2-.41-.2l-17.334-3.91h-.41l-17.334 3.91c-.102 0-.307.1-.41.2L28.103 35.42l-.308.3-7.692 15.64c-.103.2-.103.4-.103.5v17.344c0 .2 0 .301.103.401l7.692 15.64c.102.1.205.3.308.3L42.05 96.373c.103.1.308.2.41.2l17.334 3.91h.41l17.334-3.91c.205.201.307.1.41 0Z"
          fill={`url(#${g})`}
        />
        <path
          d="m119.078 45.155-10.051-20.47-12.718 9.983 6.871 14.017 15.898-3.53Z"
          fill={`url(#${h})`}
        />
        <path
          d="m23.693 86.299-6.872-14.017-15.897 3.53 10.051 20.571 12.718-10.084Z"
          fill={`url(#${i})`}
        />
        <path
          d="m93.743 89.525-12.512 9.782 7.076 14.42 18.154-14.218-12.718-9.984Z"
          fill={`url(#${j})`}
        />
        <path
          d="M103.18 72.282 96.309 86.4l12.718 9.983 10.051-20.571-15.898-3.53Z"
          fill={`url(#${k})`}
        />
        <path
          d="M104.104 52.618V68.35l15.897 3.53v-22.79l-15.897 3.53Z"
          fill={`url(#${l})`}
        />
        <path
          d="m38.665 99.206-12.41-9.68-12.718 9.983 18.051 14.218 7.077-14.521Z"
          fill={`url(#${m})`}
        />
        <path
          d="M15.897 68.35V52.617v.101L0 49.09v22.79l15.897-3.53Z"
          fill={`url(#${n})`}
        />
        <path
          d="m57.95 104.45-15.59-3.429-7.077 14.421 22.667 5.042V104.45Z"
          fill={`url(#${o})`}
        />
        <path
          d="m77.538 101.021-15.487 3.429v16.034l22.564-5.042-7.077-14.421Z"
          fill={`url(#${p})`}
        />
      </g>
      <defs>
        <linearGradient
          id={b}
          x1={64.579}
          y1={3.268}
          x2={81.529}
          y2={24.72}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF0335" />
          <stop offset={0.531} stopColor="#CC2546" />
          <stop offset={1} stopColor="#6C0015" />
        </linearGradient>
        <linearGradient
          id={c}
          x1={37.911}
          y1={3.268}
          x2={60.837}
          y2={23.244}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF0335" />
          <stop offset={0.531} stopColor="#CC2546" />
          <stop offset={1} stopColor="#6C0015" />
        </linearGradient>
        <linearGradient
          id={d}
          x1={3.475}
          y1={28.133}
          x2={24.767}
          y2={47.612}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF0335" />
          <stop offset={0.531} stopColor="#CC2546" />
          <stop offset={1} stopColor="#6C0015" />
        </linearGradient>
        <linearGradient
          id={e}
          x1={16.364}
          y1={10.703}
          x2={28.82}
          y2={41.109}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF0335" />
          <stop offset={0.531} stopColor="#CC2546" />
          <stop offset={1} stopColor="#6C0015" />
        </linearGradient>
        <linearGradient
          id={f}
          x1={84.057}
          y1={10.703}
          x2={105.589}
          y2={32.311}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF0335" />
          <stop offset={0.531} stopColor="#CC2546" />
          <stop offset={1} stopColor="#6C0015" />
        </linearGradient>
        <linearGradient
          id={g}
          x1={28.962}
          y1={31.93}
          x2={75.261}
          y2={91.457}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF0335" />
          <stop offset={0.531} stopColor="#CC2546" />
          <stop offset={1} stopColor="#6C0015" />
        </linearGradient>
        <linearGradient
          id={h}
          x1={96.309}
          y1={30.805}
          x2={112.768}
          y2={45.136}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF0335" />
          <stop offset={0.531} stopColor="#CC2546" />
          <stop offset={1} stopColor="#6C0015" />
        </linearGradient>
        <linearGradient
          id={i}
          x1={3.475}
          y1={75.731}
          x2={18.971}
          y2={98.986}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF0335" />
          <stop offset={0.531} stopColor="#CC2546" />
          <stop offset={1} stopColor="#6C0015" />
        </linearGradient>
        <linearGradient
          id={j}
          x1={68.401}
          y1={77.681}
          x2={97.267}
          y2={112.162}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF0335" />
          <stop offset={0.531} stopColor="#CC2546" />
          <stop offset={1} stopColor="#6C0015" />
        </linearGradient>
        <linearGradient
          id={k}
          x1={82.89}
          y1={65.323}
          x2={113.506}
          y2={91.976}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF0335" />
          <stop offset={0.531} stopColor="#CC2546" />
          <stop offset={1} stopColor="#6C0015" />
        </linearGradient>
        <linearGradient
          id={l}
          x1={88.856}
          y1={49.089}
          x2={118.522}
          y2={58.726}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF0335" />
          <stop offset={0.531} stopColor="#CC2546" />
          <stop offset={1} stopColor="#6C0015" />
        </linearGradient>
        <linearGradient
          id={m}
          x1={16.352}
          y1={92.988}
          x2={30.221}
          y2={111.501}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF0335" />
          <stop offset={0.531} stopColor="#CC2546" />
          <stop offset={1} stopColor="#6C0015" />
        </linearGradient>
        <linearGradient
          id={n}
          x1={1.781}
          y1={52.35}
          x2={20.484}
          y2={65.208}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF0335" />
          <stop offset={0.531} stopColor="#CC2546" />
          <stop offset={1} stopColor="#6C0015" />
        </linearGradient>
        <linearGradient
          id={o}
          x1={33.457}
          y1={101.021}
          x2={48.059}
          y2={120.453}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF0335" />
          <stop offset={0.531} stopColor="#CC2546" />
          <stop offset={1} stopColor="#6C0015" />
        </linearGradient>
        <linearGradient
          id={p}
          x1={53.059}
          y1={101.021}
          x2={74.705}
          y2={121.402}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF0335" />
          <stop offset={0.531} stopColor="#CC2546" />
          <stop offset={1} stopColor="#6C0015" />
        </linearGradient>
        <clipPath id="a">
          <path fill="#fff" transform="translate(0 .483)" d="M0 0h120v120H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
