import React from 'react';

export const TabPanels: React.FC = ({ children }) => (
  <>
    {React.Children.map(children, (tab: JSX.Element, i) =>
      tab
        ? React.cloneElement(tab, {
            index: i,
          })
        : null
    )}
  </>
);
