import {
  useActiveParticipant,
  useAppMessage,
  useDaily,
  useNetwork,
  useParticipantIds,
  useThrottledDailyEvent,
} from '@daily-co/daily-react-hooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useCallState, VideoQuality } from '../../contexts/CallProvider';
import { useCallConfig } from '../../hooks/useCallConfig';

export type NetworkState = 'good' | 'low' | 'very-low';

const STANDARD_HIGH_BITRATE_CAP = 980;
const STANDARD_LOW_BITRATE_CAP = 300;

export interface RequestTopLayerAppMessage {
  event: 'request-top-layer';
}

/**
 * Automatically updates bandwidth controls based on topology, network threshold and videoQuality.
 */
export const useBandwidthControls = () => {
  const { optimizeLargeCalls } = useCallConfig();
  const { videoQuality } = useCallState();
  const daily = useDaily();
  const { threshold, topology } = useNetwork();
  const lastQualitySet = useRef<VideoQuality>(null);
  const lastSetKBS = useRef<number>(null);

  const activeParticipant = useActiveParticipant();
  const participants = useParticipantIds();
  const [topLayerRequested, setTopLayerRequested] = useState(false);

  /**
   * In optimized large calls with at least 50 participants,
   * we'll only want to send the top layer if
   * a) we know the local participant is the active participant
   * b) any remote participant specifically requested top layer (due to pinning)
   */
  const shouldSendTopLayer = useMemo(
    () =>
      optimizeLargeCalls && participants.length > 50
        ? topLayerRequested || activeParticipant?.local
        : true,
    [
      activeParticipant?.local,
      optimizeLargeCalls,
      participants.length,
      topLayerRequested,
    ]
  );
  const topLayerTimeout = useRef<ReturnType<typeof setTimeout>>(null);
  useAppMessage<RequestTopLayerAppMessage>({
    onAppMessage: useCallback((ev) => {
      if (ev.data?.event === 'request-top-layer') {
        setTopLayerRequested(true);
        clearTimeout(topLayerTimeout.current);
        topLayerTimeout.current = setTimeout(() => {
          setTopLayerRequested(false);
        }, 15000);
      }
    }, []),
  });

  /**
   * Query param based flag to turn off auto-cam-mute on very-low network.
   */
  const disableCameraNetworkQualityMute = useRef(false);
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    disableCameraNetworkQualityMute.current = query.has(
      'disableCameraNetworkQualityMute'
    );
  }, []);

  const setQuality = useCallback(
    (q: VideoQuality) => {
      if (!daily) return;
      if (['new', 'loading'].includes(daily.meetingState())) return;
      const peers = Object.keys(daily.participants()).length - 1;
      const isSFU = topology === 'sfu';
      const lowKbs = isSFU
        ? STANDARD_LOW_BITRATE_CAP
        : Math.floor(STANDARD_LOW_BITRATE_CAP / Math.max(1, peers));
      const highKbs = isSFU
        ? shouldSendTopLayer
          ? STANDARD_HIGH_BITRATE_CAP
          : STANDARD_LOW_BITRATE_CAP
        : Math.floor(STANDARD_HIGH_BITRATE_CAP / Math.max(1, peers));

      switch (q) {
        case 'auto':
        case 'high':
          if (lastSetKBS.current !== highKbs) {
            daily.setBandwidth({ kbs: highKbs });
            lastSetKBS.current = highKbs;
          }
          break;
        case 'low':
          if (lastSetKBS.current !== lowKbs) {
            daily.setBandwidth({
              kbs: lowKbs,
            });
            lastSetKBS.current = lowKbs;
          }
          break;
        case 'bandwidth-saver':
          daily.setLocalVideo(false);
          if (lastSetKBS.current !== lowKbs) {
            daily.setBandwidth({
              kbs: lowKbs,
            });
            lastSetKBS.current = lowKbs;
          }
          break;
      }
      lastQualitySet.current = q;
    },
    [daily, shouldSendTopLayer, topology]
  );

  /**
   * Re-calculate bandwidth limits when network threshold or videoQuality changes.
   */
  useEffect(() => {
    switch (threshold) {
      case 'very-low':
        if (!disableCameraNetworkQualityMute.current) {
          setQuality('bandwidth-saver');
        }
        break;
      case 'low':
        setQuality(videoQuality === 'bandwidth-saver' ? videoQuality : 'low');
        break;
      case 'good':
        setQuality(
          ['bandwidth-saver', 'low'].includes(videoQuality)
            ? videoQuality
            : 'high'
        );
        break;
    }
  }, [setQuality, threshold, videoQuality]);

  /**
   * Re-calculates bandwidth limits, when participants join or leave,
   * opt topology changes.
   */
  const timeout = useRef<NodeJS.Timeout>(null);
  const handleParticipantCountChange = useCallback(() => {
    if (!lastQualitySet.current) return;
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setQuality(lastQualitySet.current);
    }, 500);
  }, [setQuality]);
  /**
   * Re-call handleParticipantCountChange in case joined or left participant
   * triggered a topology switch (p2p->sfu or sfu->p2p).
   */
  useEffect(handleParticipantCountChange, [
    handleParticipantCountChange,
    topology,
  ]);
  useThrottledDailyEvent('participant-joined', handleParticipantCountChange);
  useThrottledDailyEvent('participant-left', handleParticipantCountChange);
};
