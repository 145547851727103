import { useParticipantIds } from '@daily-co/daily-react-hooks';
import { captureException } from '@sentry/browser';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Portal } from 'react-portal';

import { useCallState } from '../../contexts/CallProvider';
import { useAutoplayFailedModal } from '../../contexts/UIState';
import { AudioTrack } from './AudioTrack';

export const AudioTracks: React.FC = () => {
  const { disableAudio } = useCallState();
  const [, setShowAutoplayFailedModal] = useAutoplayFailedModal();
  const [isClient, setIsClient] = useState(false);

  const subscribedIds = useParticipantIds({
    filter: useCallback(
      (p) =>
        !p.local &&
        (p.tracks.audio.subscribed === true ||
          p.tracks.screenAudio.subscribed === true),
      []
    ),
  });

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    const playTracks = () => {
      document
        .querySelectorAll('.audioTracks audio')
        .forEach(async (audio: HTMLAudioElement) => {
          try {
            if (audio.paused && audio.readyState === audio.HAVE_ENOUGH_DATA) {
              await audio?.play();
            }
          } catch (e) {
            if (e instanceof DOMException && e.name === 'NotAllowedError') {
              setShowAutoplayFailedModal(true);
            }
            captureException(e);
          }
        });
    };

    navigator.mediaDevices.addEventListener('devicechange', playTracks);
    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', playTracks);
    };
  }, [setShowAutoplayFailedModal]);

  const tracksComponent = useMemo(() => {
    if (disableAudio) return null;
    return subscribedIds.map((sessionId) => (
      // @ts-ignore
      <AudioTrack key={sessionId} sessionId={sessionId} />
    ));
  }, [disableAudio, subscribedIds]);

  // Only render audio tracks in browser
  if (!isClient) return null;

  return (
    <Portal key="AudioTracks">
      <div className="audioTracks">
        {tracksComponent}
        <style jsx>{`
          .audioTracks {
            position: absolute;
            visibility: hidden;
          }
        `}</style>
      </div>
    </Portal>
  );
};
