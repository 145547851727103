import { useTheme } from '@daily/shared/contexts/Theme';
import { hexToRgba } from '@daily/shared/lib/colors';
import { useDaily } from '@daily-co/daily-react-hooks';
import { useEffect, useRef } from 'react';

interface Props {
  isScreen: boolean;
  sessionId: string;
}

export const DebuggerAudioCanvas: React.FC<Props> = ({
  isScreen,
  sessionId,
}) => {
  const { colors } = useTheme();
  const daily = useDaily();
  const audioCanvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!daily) return;
    const participant = Object.values(daily.participants()).find(
      (p) => p.session_id === sessionId
    );
    const audioTrack = isScreen
      ? participant?.tracks?.screenAudio
      : participant?.tracks?.audio;
    if (!audioTrack?.persistentTrack) return;
    const stream = new MediaStream([audioTrack.persistentTrack]);
    const audioCtx = window['dailyAudioContext'] ?? window['audioContext'];
    const analyser = audioCtx.createAnalyser();
    const source = audioCtx.createMediaStreamSource(stream);
    source.connect(analyser);
    analyser.fftSize = 64;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    let frame;

    function draw() {
      if (!audioCanvasRef.current) return;
      const popover = audioCanvasRef.current.closest('.debug-data');
      audioCanvasRef.current.width = popover.clientWidth;
      audioCanvasRef.current.height = popover.clientHeight;
      const { height, width } = audioCanvasRef.current.getBoundingClientRect();
      const canvasCtx = audioCanvasRef.current.getContext('2d');
      canvasCtx.clearRect(0, 0, width, height);
      analyser.getByteFrequencyData(dataArray);
      var barWidth = (width - (bufferLength - 1)) / bufferLength;
      var x = 0;
      for (var i = 0; i < bufferLength; i++) {
        const fill = dataArray[i] / 255;
        const barHeight = height * fill;
        canvasCtx.fillStyle = hexToRgba(colors.accent, fill);
        canvasCtx.fillRect(x, height - barHeight, barWidth, barHeight);
        x += barWidth + 1;
      }
      frame = requestAnimationFrame(draw);
    }
    draw();
    return () => {
      cancelAnimationFrame(frame);
    };
  }, [colors.accent, daily, isScreen, sessionId]);

  return (
    <canvas ref={audioCanvasRef}>
      <style jsx>{`
        canvas {
          height: 100%;
          left: 0;
          opacity: 0.5;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: -1;
        }
      `}</style>
    </canvas>
  );
};
