import {
  ChatIcon,
  EllipsisIcon,
  GaugeIcon,
  RecordIcon,
  ScreenshareIcon,
  UserIcon,
} from '@daily/shared/components/Icons';
import { Menu } from '@daily/shared/components/Menu';
import { Text } from '@daily/shared/components/Text';
import { useTheme } from '@daily/shared/contexts/Theme';
import {
  useNetwork,
  useRecording as useDailyRecording,
  useScreenShare,
  useWaitingParticipants,
} from '@daily-co/daily-react-hooks';
import { useRouter } from 'next/router';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useChatUnread } from '../../contexts/ChatProvider';
import { useIframeDriver } from '../../contexts/IframeDriverProvider';
import { useRecording } from '../../contexts/RecordingProvider';
import {
  useCustomTrayButtons,
  useRecordingUI,
  useSidebarView,
  useStartRecordingModal,
} from '../../contexts/UIState';
import { useCallConfig } from '../../hooks/useCallConfig';
import { ACTION_CUSTOM_BUTTON_CLICK } from './constant';
import { CustomIcon } from './CustomIcon';
import { Control } from './Tray';
import { TrayBadge } from './TrayBadge';
import { TrayButton } from './TrayButton';

interface Props {
  controls: Control[];
}

export const MoreControls: React.FC<Props> = ({ controls }) => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const { sendMessageToDriver } = useIframeDriver();
  const { enableNetworkUI, enableRecording, enableScreenShare } =
    useCallConfig();
  const router = useRouter();
  const enableChat = router.query['chat'] !== 'false';
  const { isSharingScreen, screens, startScreenShare, stopScreenShare } =
    useScreenShare();
  const chatUnread = useChatUnread();
  const [customTrayButtons] = useCustomTrayButtons();
  const [sidebarView, setSidebarView] = useSidebarView();
  const [, setShowStartRecordingModal] = useStartRecordingModal();
  const { stopRecording } = useRecording();
  const [recordingUIState] = useRecordingUI();
  const { isLocalParticipantRecorded, isRecording } = useDailyRecording();
  const { waitingParticipants } = useWaitingParticipants();
  const [showMenu, setShowMenu] = useState(false);

  const { threshold } = useNetwork();

  const handlePeopleClick = useCallback(() => {
    setSidebarView(sidebarView !== 'people' ? 'people' : null);
    setShowMenu(false);
  }, [sidebarView, setSidebarView]);
  const handleChatClick = useCallback(() => {
    setSidebarView(sidebarView !== 'chat' ? 'chat' : null);
    setShowMenu(false);
  }, [sidebarView, setSidebarView]);
  const handleNetworkClick = useCallback(() => {
    setSidebarView(sidebarView !== 'network' ? 'network' : null);
    setShowMenu(false);
  }, [sidebarView, setSidebarView]);
  const handleRecordClick = useCallback(() => {
    if (isRecording) {
      stopRecording();
    } else {
      setShowStartRecordingModal(true);
    }
    setShowMenu(false);
  }, [isRecording, setShowStartRecordingModal, stopRecording]);

  const handleScreenshareClick = useCallback(() => {
    isSharingScreen ? stopScreenShare() : startScreenShare();
    setShowMenu(false);
  }, [isSharingScreen, startScreenShare, stopScreenShare]);

  const peopleIcon = useMemo(() => {
    if (waitingParticipants.length > 0)
      return <TrayBadge color={colors.system.red} visible variant="inline" />;
    return <UserIcon size={16} />;
  }, [colors.system.red, waitingParticipants]);

  const networkIcon = useMemo(() => {
    switch (threshold) {
      case 'low':
        return (
          <TrayBadge color={colors.system.orange} visible variant="inline" />
        );
      case 'very-low':
        return <TrayBadge color={colors.system.red} visible variant="inline" />;
      default:
        return <GaugeIcon size={16} />;
    }
  }, [colors.system.orange, colors.system.red, threshold]);

  const items: React.ComponentProps<typeof Menu>['items'] = useMemo(
    () =>
      controls
        .map((c) => {
          switch (c) {
            case 'chat':
              if (!enableChat) return null;
              return {
                icon: chatUnread ? (
                  <TrayBadge color={colors.system.red} visible />
                ) : (
                  <ChatIcon size={16} />
                ),
                label: t('general.chat'),
                onClick: handleChatClick,
              };
            case 'network':
              if (!enableNetworkUI) return null;
              return {
                icon: networkIcon,
                label: t('general.network'),
                onClick: handleNetworkClick,
              };
            case 'people':
              return {
                icon: peopleIcon,
                label: t('general.people'),
                onClick: handlePeopleClick,
              };
            case 'record':
              if (!enableRecording) return null;
              return {
                disabled:
                  recordingUIState === 'saved' ||
                  (isRecording && !isLocalParticipantRecorded),
                icon: (
                  <RecordIcon
                    color={isRecording ? colors.system.red : 'currentColor'}
                    size={16}
                  />
                ),
                label: isRecording ? t('recording.stop') : t('general.record'),
                onClick: handleRecordClick,
              };
            case 'screenshare':
              if (!enableScreenShare) return null;
              return {
                disabled: screens.length >= 2 && !isSharingScreen,
                icon: (
                  <ScreenshareIcon
                    color={
                      isSharingScreen ? colors.system.white : 'currentColor'
                    }
                    size={16}
                    style={{
                      background: isSharingScreen ? colors.accent : undefined,
                      borderRadius: 2,
                    }}
                  />
                ),
                label: isSharingScreen
                  ? t('screenShare.stop')
                  : t('screenShare.start'),
                onClick: handleScreenshareClick,
              };
            default:
              if (!(c in customTrayButtons)) return null;
              const btn = customTrayButtons[c];
              return {
                icon: (
                  <CustomIcon
                    alt={btn.label}
                    src={btn.iconPath}
                    size={16}
                    padding={2}
                  />
                ),
                label: btn.tooltip,
                onClick: () => {
                  sendMessageToDriver({
                    action: ACTION_CUSTOM_BUTTON_CLICK,
                    button_id: c,
                  });
                  setShowMenu(false);
                },
              };
          }
        })
        .filter(Boolean),

    [
      chatUnread,
      colors.accent,
      colors.system.red,
      colors.system.white,
      controls,
      customTrayButtons,
      enableChat,
      enableNetworkUI,
      enableRecording,
      enableScreenShare,
      handleChatClick,
      handleNetworkClick,
      handlePeopleClick,
      handleRecordClick,
      handleScreenshareClick,
      isLocalParticipantRecorded,
      isRecording,
      isSharingScreen,
      networkIcon,
      peopleIcon,
      recordingUIState,
      screens,
      sendMessageToDriver,
      t,
    ]
  );

  const badgeConfig: React.ComponentProps<typeof TrayBadge> = useMemo(() => {
    if (controls.includes('people') && waitingParticipants.length > 0)
      return {
        color: colors.system.red,
        visible: true,
      };
    if (controls.includes('chat') && chatUnread)
      return {
        color: colors.system.red,
        visible: true,
      };
    if (controls.includes('network')) {
      switch (threshold) {
        case 'low':
          return {
            color: colors.system.orange,
            visible: true,
          };
        case 'very-low':
          return {
            color: colors.system.red,
            visible: true,
          };
      }
    }
  }, [
    chatUnread,
    colors.system.orange,
    colors.system.red,
    controls,
    threshold,
    waitingParticipants,
  ]);

  if (controls.length === 0) return null;

  return (
    <>
      <TrayButton
        aria-controls="tray-more-menu"
        badge={badgeConfig}
        id="tray-more-btn"
        onClick={() => setShowMenu(true)}
      >
        <EllipsisIcon />
        <Text>{t('general.more')}</Text>
      </TrayButton>
      {showMenu && (
        <Menu
          align="right"
          aria-labelledby="tray-more-btn"
          id="tray-more-menu"
          items={items}
          offsetTop={4}
          onClose={() => setShowMenu(false)}
          placement="top"
        />
      )}
    </>
  );
};
