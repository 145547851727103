import { Anchor } from '@daily/shared/components/Anchor';
import { DataList, DD, DT } from '@daily/shared/components/DataList';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { useTheme } from '@daily/shared/contexts/Theme';
import { useDaily, useNetwork } from '@daily-co/daily-react-hooks';
import { useEffect } from 'react';
import { atomFamily, useRecoilCallback, useRecoilValue } from 'recoil';

interface IVersionInfo {
  app_name: string;
  git_hash: string;
  git_ref: string;
  environment_name: string;
}

type BackendApp = 'webapp' | 'sfu' | 'rtmp';

const appState = atomFamily<IVersionInfo | null, BackendApp>({
  key: 'backend-app-info',
  default: null,
});

export const VersionInfo: React.FC = () => {
  const daily = useDaily();
  const { topology } = useNetwork();

  const prebuiltInfo = {
    app_name: 'prebuilt',
    git_hash: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    git_ref: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF,
    environment_name:
      process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT ??
      (location.hostname.includes('localhost') ||
      location.hostname.includes('khk-local.wss.daily.co')
        ? 'local-development'
        : process.env.NODE_ENV),
  };

  const webapp = useRecoilValue(appState('webapp'));
  const sfu = useRecoilValue(appState('sfu'));
  const storeAppInfo = useRecoilCallback(
    ({ set }) =>
      (app: BackendApp, info) => {
        set(appState(app), info);
      }
  );
  /**
   * Fetch webapp info.
   */
  useEffect(() => {
    if (!daily || webapp) return;
    if (!daily.properties['url']) return;
    const url = new URL(daily.properties['url']);
    fetch(`${url.origin}/ctrl/version-info`)
      .then(async (response) => {
        if (!response.ok) return;
        storeAppInfo('webapp', await response.json());
      })
      .catch((e) => {
        console.warn('Could not fetch webapp version info', e);
      });
  }, [daily, storeAppInfo, webapp]);
  /**
   * Fetch sfu info.
   */
  useEffect(() => {
    if (sfu) return;
    if (topology !== 'sfu') {
      storeAppInfo('sfu', null);
      return;
    }
    // TODO: How can we get the workerId via official daily-js API?
    const sfuWorker = window?.['rtcpeers']?.['soup']?.['workerId'];
    if (!sfuWorker) return;
    fetch(`https://${sfuWorker}.wss.daily.co/ctrl/version-info`)
      .then(async (response) => {
        if (!response.ok) return;
        storeAppInfo('sfu', await response.json());
      })
      .catch((e) => {
        console.warn('Could not fetch sfu version info', e);
      });
  }, [sfu, storeAppInfo, topology]);

  return (
    <div className="version-info">
      <Stack gap={16}>
        <AppInfo {...prebuiltInfo} />
        {webapp && <AppInfo {...webapp} />}
        {sfu && <AppInfo {...sfu} />}
      </Stack>
    </div>
  );
};

const AppInfo = (app: IVersionInfo) => {
  const { mediaQueries } = useTheme();
  return (
    <div>
      <DataList>
        <DT>App</DT>
        <DD>{app.app_name}</DD>
        <DT>Commit</DT>
        <DD>
          {app.git_hash ? (
            <Anchor
              href={`https://github.com/daily-co/pluot-core/commit/${app.git_hash}`}
              target="_blank"
              underline
            >
              <Text variant="mono" truncate>
                {app.git_hash}
              </Text>
            </Anchor>
          ) : (
            'unspecified'
          )}
        </DD>
        <DT>Branch</DT>
        <DD>{app.git_ref ?? 'unspecified'}</DD>
        <DT>Environment</DT>
        <DD>{app.environment_name}</DD>
      </DataList>
      <style jsx>{`
        div :global(dl) {
          grid-template-columns: 1fr;
          grid-template-areas: '.';
        }
        @media ${mediaQueries.large} {
          div :global(dl) {
            grid-template-columns: 1fr 3fr;
            grid-template-areas: '. .';
          }
        }
      `}</style>
    </div>
  );
};
