import { Button } from '@daily/shared/components/Button';
import { Modal } from '@daily/shared/components/Modal';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { useWaitingParticipants } from '@daily-co/daily-react-hooks';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from '../../contexts/UIState';
import { useStyleVariants } from '../../hooks/useStyleVariants';
import { robotsClassName } from '../../lib/robots';
import { WaitingParticipantRow } from './WaitingParticipantRow';

interface Props {
  onClose(): void;
}

export const WaitingRoomModal: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const { denyAccess, grantAccess, waitingParticipants } =
    useWaitingParticipants();
  const [isMobile] = useIsMobile();
  const { textVariantStrong } = useStyleVariants();

  const handleAllowAllClick = (close) => {
    grantAccess('*');
    close();
  };
  const handleDenyAllClick = (close) => {
    denyAccess('*');
    close();
  };

  return (
    <Modal
      actions={[
        <Button
          key="allow"
          className={robotsClassName('btn-allow')}
          onClick={handleAllowAllClick}
          variant="primary"
        >
          <Text El="span" color="inherit" variant={textVariantStrong}>
            {t('waitingRoom.allowAll')}
          </Text>
        </Button>,
        <Button
          key="deny"
          className={robotsClassName('btn-deny')}
          onClick={handleDenyAllClick}
          variant="secondary"
        >
          <Text El="span" variant={textVariantStrong}>
            {t('waitingRoom.denyAll')}
          </Text>
        </Button>,
      ]}
      closeText={t('general.close')}
      onClose={onClose}
      title={t('waitingRoom.modal.title')}
      variant="closable"
    >
      <Stack gap={isMobile ? 16 : 8}>
        {waitingParticipants.map((p) => (
          <WaitingParticipantRow key={p.id} participant={p} />
        ))}
      </Stack>
    </Modal>
  );
};
