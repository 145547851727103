import { Button } from '@daily/shared/components/Button';
import { Card, CardContent } from '@daily/shared/components/Card';
import { Form } from '@daily/shared/components/Form';
import { Input } from '@daily/shared/components/Input';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { useTheme } from '@daily/shared/contexts/Theme';
import { pxToRem } from '@daily/shared/lib/pxToRem';
import { useLocalParticipant } from '@daily-co/daily-react-hooks';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCallState } from '../../contexts/CallProvider';
import { useIsMobile } from '../../contexts/UIState';
import { useCallConfig } from '../../hooks/useCallConfig';
import { useSoundLoader } from '../../hooks/useSoundLoader';
import { useStyleVariants } from '../../hooks/useStyleVariants';
import { robotsClassName } from '../../lib/robots';
import { LockedMessage } from './LockedMessage';

interface Props {
  onComplete(username: string): void;
}

export const NameForm: React.FC<Props> = ({ onComplete }) => {
  const { t } = useTranslation();
  const { preJoinNonAuthorized } = useCallState();
  const { broadcastRole } = useCallConfig();
  const [isMobile] = useIsMobile();
  const { textVariant } = useStyleVariants();
  const { colors } = useTheme();
  const [username, setUsername] = useState('');
  const localParticipant = useLocalParticipant();

  const { load: loadSounds } = useSoundLoader();

  useEffect(() => {
    setUsername(localParticipant?.user_name || '');
  }, [localParticipant]);

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(ev.target.value);
  };

  const handleSubmit = () => {
    loadSounds();
    onComplete(username);
  };

  const textVariantStrong = useMemo(
    () => (isMobile ? 'largestrong' : 'strong'),
    [isMobile]
  );
  const disabled = useMemo(() => username.length === 0, [username]);

  const subtitle = useMemo(() => {
    /**
     * Owner only broadcast calls have different copy for owners
     * and attendees. Non-OOB calls have different copy based
     * on room privacy settings.
     */
    switch (broadcastRole) {
      case 'attendee':
        return preJoinNonAuthorized
          ? t('haircheck.nameForm.broadcastViewerPrivate')
          : t('haircheck.nameForm.broadcastViewerPublic');
      case 'owner':
        return t('haircheck.nameForm.broadcastOwner');
      default:
        return preJoinNonAuthorized
          ? t('haircheck.nameForm.subtitlePrivate')
          : t('haircheck.nameForm.subtitle');
    }
  }, [broadcastRole, preJoinNonAuthorized, t]);

  const buttonText = useMemo(() => {
    if (broadcastRole === 'attendee') {
      return preJoinNonAuthorized
        ? t('haircheck.setup.requestToJoin')
        : t('general.joinMeeting');
    }
    return t('general.continue');
  }, [broadcastRole, preJoinNonAuthorized, t]);

  return (
    <Stack gap={24} className="card-container">
      <Card>
        <CardContent style={{ padding: '24px' }}>
          <Form onSubmit={handleSubmit}>
            <Stack gap={16}>
              <Stack gap={8}>
                <Text variant="largestrong" El="label" htmlFor="username">
                  {preJoinNonAuthorized
                    ? t('haircheck.nameForm.titlePrivate')
                    : t('haircheck.nameForm.title')}
                </Text>
                <Text variant={textVariant}>{subtitle}</Text>
              </Stack>
              <Stack gap={4}>
                <Input
                  autoFocus
                  className={robotsClassName('input-name')}
                  id="username"
                  name="username"
                  maxLength={80}
                  onChange={handleChange}
                  size={52}
                  value={username}
                  style={
                    isMobile
                      ? {
                          fontSize: pxToRem(16),
                          height: 48,
                        }
                      : {}
                  }
                />
              </Stack>
              <Button
                className={robotsClassName('btn-continue')}
                disabled={disabled}
                type="submit"
                fullWidth
                style={isMobile ? { padding: '10px 16px 12px' } : {}}
              >
                <Text
                  variant={textVariantStrong}
                  El="span"
                  style={{
                    color: disabled ? colors.supportiveText : colors.accentText,
                  }}
                >
                  {buttonText}
                </Text>
              </Button>
              {preJoinNonAuthorized && <LockedMessage />}
            </Stack>
          </Form>
        </CardContent>
      </Card>
    </Stack>
  );
};
