import { useTheme } from '@daily/shared/contexts/Theme';

export const DownloadIcon: React.FC = () => {
  const { colors } = useTheme();

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.60579 11.1771L11.1772 8.60607C11.5124 8.27095 11.5124 7.72929 11.1772 7.39418C10.8421 7.05907 10.3004 7.05907 9.96521 7.39418L9 8.3526V2.08295C11.8377 2.55902 14 5.02699 14 7.99999C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 7.99999C2 5.02699 4.16229 2.55903 7 2.08295V8.35281L6.03436 7.39418C5.69921 7.05907 5.1575 7.05907 4.82236 7.39418C4.65521 7.56131 4.57121 7.78071 4.57121 8.00012C4.57121 8.21953 4.65521 8.43894 4.82236 8.60607L7.39379 11.1771C7.72893 11.5122 8.27064 11.5122 8.60579 11.1771Z"
        fill={colors.baseText}
      />
      <style jsx>{`
        svg {
          display: block;
        }
      `}</style>
    </svg>
  );
};
