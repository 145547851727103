import { MicrophoneIcon, WarningIcon } from '@daily/shared/components/Icons';
import { Text } from '@daily/shared/components/Text';
import { useTheme } from '@daily/shared/contexts/Theme';
import {
  useAudioTrack,
  useDaily,
  useLocalParticipant,
  useNetwork,
  useParticipant,
} from '@daily-co/daily-react-hooks';
import classnames from 'classnames';
import { CALL_CUSTOM_EVENTS } from 'components/App/IframeListener';
import { Gemstone } from 'components/Gemstone/Gemstone';
import { Roles } from 'components/Roles/Roles';
import { useIframeDriver } from 'contexts/IframeDriverProvider';
import { motion } from 'framer-motion';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCallState } from '../../contexts/CallProvider';
import { useAudioLevel } from '../../hooks/useAudioLevel';

interface Props {
  activeIndicator?: 'meter' | 'static';
  name?: string;
  sessionId: string;
  videoEl: any;
}

export const TileInfo: React.FC<Props> = memo(
  ({ activeIndicator = 'static', name, sessionId, videoEl }) => {
    const { sendMessageToDriver } = useIframeDriver();
    const daily = useDaily();
    const { t } = useTranslation();
    const { disableAudio } = useCallState();
    const { colors } = useTheme();
    const participant = useParticipant(sessionId);
    const audio = useAudioTrack(sessionId);
    const localParticipant = useLocalParticipant();

    const { threshold } = useNetwork();

    const audioStream = useMemo(() => {
      if (!process.browser || disableAudio) return null;
      if (audio?.track) return new MediaStream([audio?.track]);
      if (daily && sessionId === localParticipant?.session_id) {
        const localParticipant = daily.participants()?.local;
        if (!localParticipant) return null;
        const { persistentTrack } = localParticipant?.tracks?.audio;
        if (!persistentTrack) return null;
        return new MediaStream([persistentTrack]);
      }
      return null;
    }, [
      audio?.track,
      daily,
      disableAudio,
      localParticipant?.session_id,
      sessionId,
    ]);
    const volume = useAudioLevel(
      activeIndicator === 'meter' ? audioStream : null
    );
    const volumeHeight = useMemo(() => {
      switch (activeIndicator) {
        case 'meter':
          return Math.min(24, 2 * 24 * volume);
        case 'static':
          return 24;
      }
    }, [activeIndicator, volume]);

    const hasNetworkIssues = useMemo(
      () =>
        sessionId === localParticipant?.session_id &&
        ['low', 'very-low'].includes(threshold),
      [localParticipant?.session_id, sessionId, threshold]
    );

    const NameText = useMemo(() => {
      if (!name) return null;
      let color: React.ComponentProps<typeof Text>['color'] = 'white';
      let text = name;
      if (hasNetworkIssues) {
        switch (threshold) {
          case 'low':
            color = 'warning';
            text = t('network.weakNetwork');
            break;
          case 'very-low':
            color = 'error';
            text = t('network.poorConnection');
            break;
        }
      }
      return (
        <Text
          className="name"
          El="div"
          color={color}
          variant="strong"
          truncate
          style={{ color: participant?.userData?.['color'] }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {participant?.userData?.['gemstone'] && (
              <Gemstone
                name={participant?.userData?.['gemstone']}
                style={{ marginRight: 8 }}
                size={16}
              />
            )}
            {text}
            <Roles badges={participant?.userData?.['roleBadge']} />
          </div>
        </Text>
      );
    }, [hasNetworkIssues, name, t, participant, threshold]);

    const userID = participant?.user_id;
    const handleClick = useCallback(
      (event) => {
        const canvas = document.createElement('canvas');
        if (videoEl.current) {
          canvas.width = videoEl.current.videoWidth;
          canvas.height = videoEl.current.videoHeight;
          const context = canvas.getContext('2d');
          context?.drawImage(
            videoEl.current,
            0,
            0,
            canvas.width,
            canvas.height
          );
        }
        const screenshot = canvas.toDataURL();
        sendMessageToDriver({
          action: CALL_CUSTOM_EVENTS.ST_SHOW_PARTICIPANT_DETAILS,
          payload: {
            from: 'tile-info',
            userID: userID,
            videoEl: videoEl.current ? screenshot : '',
            x: event.clientX,
            y: event.clientY,
          },
        });
      },
      [userID, sendMessageToDriver]
    );

    return (
      <div className="info" onClick={handleClick}>
        {(hasNetworkIssues || !disableAudio) && (
          <div
            className={classnames('mic', activeIndicator, {
              muted: !participant?.audio,
            })}
          >
            {hasNetworkIssues && (
              <WarningIcon
                color={
                  threshold === 'low' ? colors.system.orange : colors.system.red
                }
                size={16}
              />
            )}
            {!hasNetworkIssues && !disableAudio && (
              <>
                {activeIndicator === 'meter' && (
                  <motion.div
                    initial={{ height: 0 }}
                    animate={{ height: volumeHeight }}
                    className="volume"
                    style={{ originY: 1 }}
                  />
                )}
                <MicrophoneIcon
                  color={colors.system.white}
                  id={`Tile${sessionId}`}
                  muted={!participant?.audio}
                  transition={false}
                  size={16}
                />
              </>
            )}
          </div>
        )}
        {NameText}
        <style jsx>{`
          .info {
            cursor: pointer;
            align-items: stretch;
            background: rgba(40, 35, 34, 0.9); // #282322
            bottom: 0;
            display: flex;
            flex-direction: row;
            justify-content: stretch;
            left: 0;
            max-width: 100%;
            position: absolute;
            // iOS: Avoids TileInfo from disappearing while swiping on Mobile
            transform: translate3d(0, 0, 0);
            padding-left: 8px;
            padding-right: 8px;
            border-radius: 0 4px 0 0;
          }
          .info .mic {
            margin-right: 1px;
            min-width: 24px;
          }
          .info .mic,
          .info :global(.name) {
            padding: 4px;
          }
          .info :global(.name) {
            height: 100%;
          }
          .mic :global(.volume) {
            background: ${colors.accent};
            bottom: 0;
            left: 0;
            position: absolute;
            width: 24px;
            z-index: 1;
          }
          .mic :global(svg) {
            position: relative;
            z-index: 2;
          }
        `}</style>
      </div>
    );
  }
);
TileInfo.displayName = 'TileInfo';
