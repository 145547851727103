import { Button } from '@daily/shared/components/Button';
import { EllipsisIcon, MicrophoneIcon } from '@daily/shared/components/Icons';
import { MenuButton } from '@daily/shared/components/Menu';
import { Text } from '@daily/shared/components/Text';
import { VisuallyHidden } from '@daily/shared/components/VisuallyHidden';
import {
  useLocalParticipant,
  useParticipant,
} from '@daily-co/daily-react-hooks';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsMobile, useNameModal } from '../../contexts/UIState';
import { useStyleVariants } from '../../hooks/useStyleVariants';
import { ParticipantMenu } from '../People';

interface Props {
  iconVariant: ReturnType<typeof useStyleVariants>['iconVariant'];
  localParticipant: ReturnType<typeof useLocalParticipant>;
  participant: ReturnType<typeof useParticipant>;
  sessionId: string;
  showActions?: boolean;
  showMicMutedState: boolean;
}

export const ParticipantActions: React.FC<Props> = memo(
  ({
    iconVariant,
    localParticipant,
    participant,
    sessionId,
    showActions = true,
    showMicMutedState,
  }) => {
    const { t } = useTranslation();
    const [isMobile] = useIsMobile();
    const [, setShowNameModal] = useNameModal();
    const [showMenu, setShowMenu] = useState(false);
    const handleChangeNameClick = () => {
      setShowNameModal(true);
      setShowMenu(false);
    };
    const handleMenuClick = () => setShowMenu(true);
    const handleMenuClose = () => setShowMenu(false);

    if (!participant) return null;

    if (participant.local && !participant.user_name) {
      return (
        <Button
          onClick={handleChangeNameClick}
          variant="ghost"
          style={{ display: 'inline-flex' }}
        >
          <Text color="muted" underline>
            {t('people.enterYourName')}
          </Text>
        </Button>
      );
    }

    const btnId = `${sessionId}-actions-btn`;
    const menuId = `${sessionId}-actions`;

    // Mobile options are limited to changing name and host muting
    if (!localParticipant?.owner && !participant?.local && isMobile)
      return null;

    return (
      <div>
        {showActions && (
          <MenuButton
            aria-controls={menuId}
            focusable
            id={btnId}
            onClick={handleMenuClick}
            open={showMenu}
            variant="ghost"
          >
            <EllipsisIcon size={iconVariant} />
            <VisuallyHidden>{t('people.showOptions')}</VisuallyHidden>
          </MenuButton>
        )}
        {showMicMutedState && (
          <MicrophoneIcon
            color={!participant.audio ? 'var(--text-error)' : 'currentColor'}
            id={`People${sessionId}`}
            muted={!participant.audio}
            size={iconVariant}
            transition={false}
          />
        )}
        {showMenu && (
          <ParticipantMenu
            align="right"
            aria-labelledby={btnId}
            id={menuId}
            onClose={handleMenuClose}
            sessionId={sessionId}
          />
        )}
        <style jsx>{`
          div {
            display: flex;
            gap: 8px;
          }
        `}</style>
      </div>
    );
  }
);
ParticipantActions.displayName = 'ParticipantActions';
