import { useTheme } from '@daily/shared/contexts/Theme';
import { useScreenShare } from '@daily-co/daily-react-hooks';
import { AnimateSharedLayout, motion } from 'framer-motion';
import { useCallback, useMemo, useRef, useState } from 'react';

import { useCallState } from '../../contexts/CallProvider';
import { usePinnedId } from '../../contexts/UIState';
import { useResizeObserver } from '../../hooks/useResizeObserver';
import { ScreenPinTile } from './ScreenPinTile';

type Dimensions = {
  height: number;
  width: number;
};

const MAX_SCREENS_AND_PINS = 1;

export const ScreensAndPins: React.FC = () => {
  const { colors } = useTheme();
  const [pinnedId] = usePinnedId();
  const { showNames } = useCallState();
  const viewRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: 1,
    height: 1,
  });
  const { screens } = useScreenShare();
  const pinnedScreen = useMemo(
    () => screens.find((s) => s.screenId === pinnedId + '-screen'),
    [pinnedId, screens]
  );
  const renderedItemsCount = useMemo(() => {
    if (pinnedScreen || pinnedId) return 1;
    return screens.length + (pinnedId ? 1 : 0);
  }, [pinnedId, pinnedScreen, screens]);

  useResizeObserver(
    viewRef,
    useCallback((width, height) => {
      setDimensions({
        width,
        height,
      });
    }, [])
  );

  const { aspectRatio, height, maxWidth } = useMemo(() => {
    /**
     * We're relying on calculating what there is room for
     * for the total number of s+p tiles instead of using
     * videoTrack.getSettings because (currently) getSettings
     * is unreliable in Firefox.
     */
    const containerAR = dimensions.width / dimensions.height;
    const maxItems = Math.min(renderedItemsCount, MAX_SCREENS_AND_PINS);
    const cols = Math.min(maxItems, Math.ceil(containerAR));
    const rows = Math.ceil(renderedItemsCount / cols);
    const height = dimensions.height / rows;
    const maxWidth = dimensions.width / cols;
    return {
      height,
      maxWidth,
      aspectRatio: maxWidth / height,
    };
  }, [dimensions, renderedItemsCount]);

  return (
    <div ref={viewRef}>
      <AnimateSharedLayout>
        {pinnedScreen ? (
          <motion.div
            className="tileWrapper"
            key={pinnedScreen.screenId}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              height,
              maxWidth,
            }}
          >
            <ScreenPinTile
              fullWidth
              height={height}
              isScreen
              maxWidth={maxWidth}
              ratio={aspectRatio}
              sessionId={pinnedScreen.session_id}
              showNames={showNames}
            />
          </motion.div>
        ) : (
          <>
            {pinnedId ? (
              <motion.div
                className="tileWrapper"
                key={pinnedId}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{
                  height,
                  maxWidth: '100%',
                }}
              >
                <ScreenPinTile
                  fullWidth
                  height={height}
                  maxWidth={maxWidth}
                  ratio={aspectRatio}
                  sessionId={pinnedId}
                  showNames={showNames}
                />
              </motion.div>
            ) : (
              <>
                {screens.map((s) => (
                  <motion.div
                    className="tileWrapper"
                    key={s.screenId}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    style={{
                      height,
                      maxWidth,
                    }}
                  >
                    <ScreenPinTile
                      fullWidth
                      height={height}
                      isScreen
                      maxWidth={maxWidth}
                      ratio={aspectRatio}
                      sessionId={s.session_id}
                      showNames={showNames}
                    />
                  </motion.div>
                ))}
              </>
            )}
          </>
        )}
      </AnimateSharedLayout>
      <style jsx>{`
        div {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          height: 100%;
          justify-content: center;
          width: 100%;
        }
        div :global(.tileWrapper) {
          // background: ${colors.custom.mainAreaBg};
          align-items: center;
          display: flex;
          flex: none;
          justify-content: center;
          position: relative;
          width: 100%;
        }
        div :global(.tile .content) {
          margin: auto;
          max-height: 100%;
          max-width: 100%;
        }
      `}</style>
    </div>
  );
};
