import { SVGProps } from 'react';

import { Amethyst } from './stones/Amethyst';
import { Diamond } from './stones/Diamond';
import { Emerald } from './stones/Emerald';
import { Jade } from './stones/Jade';
import { Jasper } from './stones/Jasper';
import { Kunzite } from './stones/Kunzite';
import { Moonstone } from './stones/Moonstone';
import { Quartz } from './stones/Quartz';
import { Ruby } from './stones/Ruby';
import { Sapphire } from './stones/Sapphire';
import { Sphene } from './stones/Sphene';
import { Topaz } from './stones/Topaz';

export type GemstoneNames =
  | 'Amethyst'
  | 'Sapphire'
  | 'Moonstone'
  | 'Topaz'
  | 'Jade'
  | 'Emerald'
  | 'Sphene'
  | 'Jasper'
  | 'Quartz'
  | 'Ruby'
  | 'Kunzite'
  | 'Diamond';

type GemstoneProps = {
  name: GemstoneNames | string;
  size?: number;
} & SVGProps<SVGSVGElement>;

export function Gemstone({ name, size = 126, ...props }: GemstoneProps) {
  const height = size;
  const width = size;
  if (name == 'Amethyst')
    return <Amethyst height={height} width={width} {...props} />;
  if (name == 'Sapphire')
    return <Sapphire height={height} width={width} {...props} />;
  if (name == 'Moonstone')
    return <Moonstone height={height} width={width} {...props} />;
  if (name == 'Topaz')
    return <Topaz height={height} width={width} {...props} />;
  if (name == 'Jade') return <Jade height={height} width={width} {...props} />;
  if (name == 'Emerald')
    return <Emerald height={height} width={width} {...props} />;
  if (name == 'Sphene')
    return <Sphene height={height} width={width} {...props} />;
  if (name == 'Jasper')
    return <Jasper height={height} width={width} {...props} />;
  if (name == 'Quartz')
    return <Quartz height={height} width={width} {...props} />;
  if (name == 'Ruby') return <Ruby height={height} width={width} {...props} />;
  if (name == 'Kunzite')
    return <Kunzite height={height} width={width} {...props} />;
  if (name == 'Diamond')
    return <Diamond height={height} width={width} {...props} />;

  return null;
}
