import { RecordIcon } from '@daily/shared/components/Icons';
import { Menu } from '@daily/shared/components/Menu';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { Tooltip } from '@daily/shared/components/Tooltip';
import { useTheme } from '@daily/shared/contexts/Theme';
import { useRecording as useDailyRecording } from '@daily-co/daily-react-hooks';
import classnames from 'classnames';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRecording } from '../../contexts/RecordingProvider';
import { useIsMobile, useRecordingUI } from '../../contexts/UIState';
import { useCallConfig } from '../../hooks/useCallConfig';
import { robotsClassName } from '../../lib/robots';
import { TOOLTIP_DELAY } from './constant';
import { ToggleText } from './ToggleText';
import { TrayButton } from './TrayButton';

interface Props extends React.ComponentProps<typeof TrayButton> {}

export const RecordControls: React.FC<Props> = ({
  children,
  className,
  onClick,
  ...props
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { enableRecording, enableRecordingUI } = useCallConfig();
  const { startRecording, stopRecording } = useRecording();
  const [recordingUIState] = useRecordingUI();
  const { isLocalParticipantRecorded, isRecording, local } =
    useDailyRecording();
  const [isMobile] = useIsMobile();
  const [showRecordingMenu, setShowRecordingMenu] = useState(false);

  const disabled = useMemo(
    () =>
      (enableRecording === 'local' && isRecording && !local) ||
      recordingUIState === 'saved' ||
      (isRecording && !isLocalParticipantRecorded),
    [
      enableRecording,
      isLocalParticipantRecorded,
      isRecording,
      local,
      recordingUIState,
    ]
  );
  const showAsRecording = isRecording && !disabled;

  const handleButtonClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    setShowRecordingMenu(true);
    onClick?.(ev);
  };

  const handleMenuItemClick = () => {
    setShowRecordingMenu(false);
    if (showAsRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const handleMenuClose = () => {
    setShowRecordingMenu(false);
  };

  const tooltipTitle = useMemo(() => {
    if (isMobile) return null;
    if (disabled) return t('recording.notAvailable');
    if (isRecording) return t('recording.stopRecording');
    return t('recording.startRecording');
  }, [disabled, isMobile, isRecording, t]);

  if (!enableRecording || !enableRecordingUI) return null;

  return (
    <div
      className={classnames('record-controls', {
        active: showAsRecording,
      })}
    >
      <Tooltip
        title={tooltipTitle}
        delay={TOOLTIP_DELAY}
        disabled={!tooltipTitle}
        id="tt-recording-btn"
        tabIndex={-1}
      >
        <TrayButton
          className={classnames(className, {
            [robotsClassName('btn-record-disabled')]: disabled,
            [robotsClassName('btn-record-start')]:
              !disabled && !showAsRecording,
            [robotsClassName('btn-record-stop')]: showAsRecording,
          })}
          disabled={disabled}
          onClick={handleButtonClick}
          {...props}
          id={props?.id ?? 'recording-btn'}
        >
          <RecordIcon
            className="icon"
            color={
              showAsRecording
                ? colors.system.white
                : disabled
                ? 'var(--text-muted)'
                : 'currentColor'
            }
          />
          <ToggleText
            on={showAsRecording}
            onText={t('recording.stop')}
            offText={t('recording.start')}
            disabled={disabled}
          />
        </TrayButton>
      </Tooltip>
      {showRecordingMenu && (
        <Menu
          align="left"
          aria-labelledby={props?.id ?? 'recording-btn'}
          id="recording-menu"
          items={[
            {
              label: (
                <Stack align="center" gap={4} horizontal>
                  <RecordIcon size={16} />
                  <Text variant="strong">{t('general.recording')}</Text>
                </Stack>
              ),
              static: true,
            },
            {
              label: showAsRecording
                ? t('recording.stopRecording')
                : t('recording.startRecording'),
              onClick: handleMenuItemClick,
            },
          ]}
          onClose={handleMenuClose}
          placement="top"
        />
      )}
      <style jsx>{`
        .record-controls :global(.icon) {
          border-radius: 4px;
        }
        .record-controls.active :global(.icon) {
          background: ${colors.system.red};
        }
      `}</style>
    </div>
  );
};
