import { AnimatePresence } from 'framer-motion';
import React, { createContext, useCallback, useState } from 'react';

import { Snackbar } from '../../components/Snackbar';

interface Message {
  content: string;
  type?: 'success' | 'error' | 'info';
  icon?: JSX.Element;
}

interface ActiveMessage {
  id: number;
  message: Message;
  icon?: JSX.Element;
}

interface SnackbarProviderValue {
  addMessage(m: Message): void;
}

export const SnackbarContext = createContext<SnackbarProviderValue>({
  addMessage: () => {},
});

let counter = 0;

export const SnackbarProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<ActiveMessage[]>([]);

  const addMessage = useCallback((m: Message) => {
    if (!m.type) m.type = 'success';
    setMessages((msgs) => [
      {
        id: ++counter,
        message: m,
      },
      ...msgs,
    ]);
  }, []);

  const handleHide = (hideId: number) => {
    setMessages((msgs) => msgs.filter(({ id }) => id !== hideId));
  };

  return (
    <SnackbarContext.Provider
      value={{
        addMessage,
      }}
    >
      {children}
      <div className="snackbars">
        <AnimatePresence presenceAffectsLayout>
          {messages.map(({ id, message }) => (
            <Snackbar
              key={id}
              id={id}
              onHide={() => handleHide(id)}
              type={message.type}
              icon={message?.icon}
            >
              {message.content}
            </Snackbar>
          ))}
        </AnimatePresence>
      </div>
      <style jsx>{`
        .snackbars {
          align-items: flex-end;
          display: flex;
          flex-direction: column;
          position: fixed;
          right: 8px;
          top: calc(var(--header-height) + 8px);
          z-index: 999;
        }
        .snackbars :global(.snackbar + .snackbar) {
          margin-top: 8px;
        }
      `}</style>
    </SnackbarContext.Provider>
  );
};
