/**
 * A message channel to and from a driver controlling the call UI via an iframe
 * (e.g. daily-js).
 * Based on WebMessageChannel in pluot-core.
 */
export default class IframeDriverMessageChannel {
  private _frameId: string;

  private _wrappedListeners: { [listenerId: number]: (msg: any) => void } = {};

  private _incrementingListenerId: number = 0;

  constructor(frameId: string) {
    this._frameId = frameId;
  }

  addListenerForMessagesFromDriver(listener: (msg: any) => void): number {
    const wrappedListener = (evt: any) => {
      const isIframeCallMessage = evt?.data?.what === 'iframe-call-message';
      const hasAction = evt?.data?.action;
      const isFromModule = !evt?.data?.from || evt.data.from === 'module';
      const isTargetFrame =
        evt?.data?.callFrameId && this._frameId
          ? evt.data.callFrameId === this._frameId
          : true;
      if (
        !(isIframeCallMessage && hasAction && isFromModule && isTargetFrame)
      ) {
        return;
      }
      const msg = evt.data;
      listener(msg);
    };
    this._wrappedListeners[this._incrementingListenerId] = wrappedListener;
    window.addEventListener('message', wrappedListener);
    return this._incrementingListenerId++;
  }

  removeListenerForMessagesFromDriver(listenerId: number): void {
    const wrappedListener = this._wrappedListeners[listenerId];
    if (wrappedListener) {
      window.removeEventListener('message', wrappedListener);
      delete this._wrappedListeners[listenerId];
    }
  }

  sendMessageToDriver(message: any) {
    message.what = 'iframe-call-message';
    message.callFrameId = this._frameId;
    message.from = 'embedded';
    window.parent?.postMessage(message, '*');
  }

  forwardPackagedMessageToDriver(msg: any): void {
    const newMsg = { ...msg };
    newMsg.callFrameId = this._frameId;
    window.parent?.postMessage(newMsg, '*');
  }
}
