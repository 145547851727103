import { Button } from '@daily/shared/components/Button';
import { ReverseIcon } from '@daily/shared/components/Icons';
import { VisuallyHidden } from '@daily/shared/components/VisuallyHidden';
import { useResize } from '@daily/shared/hooks/useResize';
import { useDaily } from '@daily-co/daily-react-hooks';
import classnames from 'classnames';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useCallState } from '../../contexts/CallProvider';
import { useMobileTray } from '../../contexts/UIState';
import { useCallConfig } from '../../hooks/useCallConfig';
import { RecordingState } from '../Call/RecordingState';
import { FullscreenButton } from '../FullscreenButton';

export const MobileTopBar: React.FC = () => {
  const { t } = useTranslation();
  const {
    enableCallButtons,
    leaveCall,
    showFullscreenButton,
    showLeaveButton,
  } = useCallState();
  const { broadcastRole, poweredByDaily } = useCallConfig();
  const daily = useDaily();
  const [showMobileTray] = useMobileTray();
  const topbarRef = useRef<HTMLDivElement>(null);

  const reverseCamera = async () => {
    await daily.cycleCamera();
  };

  useResize(() => {
    if (!topbarRef.current) return;
    const { height, top } = topbarRef.current.getBoundingClientRect();
    document.body.style.setProperty('--header-height', `${height + top}px`);
  }, [poweredByDaily, topbarRef]);

  return (
    <div
      className={classnames('mobile-topbar', {
        hidden: !showMobileTray,
      })}
      ref={topbarRef}
    >
      <div className="button-container">
        {broadcastRole !== 'attendee' && (
          <Button
            className="cam-switch"
            onClick={reverseCamera}
            disabled={!showMobileTray}
            onTouchStart={(e) => e.stopPropagation()}
            variant="ghost"
          >
            <ReverseIcon size={24} className="icon" />
            <VisuallyHidden>{t('video.changeCamera')}</VisuallyHidden>
          </Button>
        )}
        {showFullscreenButton && <FullscreenButton />}
      </div>
      <div className="message">
        <RecordingState />
      </div>
      {showLeaveButton && (
        <Button
          className="leave"
          disabled={!enableCallButtons || !showMobileTray}
          onClick={leaveCall}
          variant="danger"
          onTouchStart={(e) => e.stopPropagation()}
        >
          {t('general.leave')}
        </Button>
      )}
      <style global jsx>{`
        :root {
          --header-height: 0;
        }
      `}</style>
      <style jsx>
        {`
          .mobile-topbar {
            align-items: center;
            // background: linear-gradient(
            //   to bottom,
            //   var(--backdrop),
            //   var(--backdrop-hidden)
            // );
            display: grid;
            gap: 8px;
            grid-template-columns: 1fr auto 1fr;
            justify-items: center;
            opacity: 1;
            padding: 4px 12px 32px;
            position: absolute;
            top: 0;
            transition: opacity 0.2s ease, transform 0.2s ease;
            width: 100%;
            z-index: 10;
          }
          .mobile-topbar.hidden {
            opacity: 0;
            transform: translateY(-100%);
          }
          .mobile-topbar .button-container {
            align-items: center;
            display: flex;
            margin-right: auto;
          }
          .mobile-topbar :global(.cam-switch) {
            padding: 12px 12px 12px 0;
            margin-left: 4px;
          }
          .mobile-topbar :global(.leave) {
            grid-column-start: 3;
            margin-left: auto;
          }
          .message {
            grid-column-start: 2;
            margin: 0 auto;
          }
        `}
      </style>
    </div>
  );
};
