import { Button } from '@daily/shared/components/Button';
import { Card, CardContent, CardHeader } from '@daily/shared/components/Card';
import { Input } from '@daily/shared/components/Input';
import { useTheme } from '@daily/shared/contexts/Theme';
import { useClosable } from '@daily/shared/hooks/useClosable';
import { hexToRgba } from '@daily/shared/lib/colors';
import classNames from 'classnames';
import { Emoji, emojiIndex } from 'emoji-mart';
import data from 'emoji-mart/data/all.json';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tinykeys from 'tinykeys';

interface Props {
  filter: string;
  onClose?(): void;
  onSelect(emoji: string): void;
}
const categories = Object.fromEntries(data.categories.map((c) => [c.id, c]));
const sortedCategories = Object.keys(categories);
const categoryValues = Object.values(categories);

const COLUMNS = 6;

export const EmojiMenu: React.FC<Props> = ({ filter, onClose, onSelect }) => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const [emojiList, setEmojiList] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const menuRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const spaceBeforeRef = useRef<HTMLDivElement>(null);
  const spaceAfterRef = useRef<HTMLDivElement>(null);
  const [renderRange, setRenderRange] = useState([0, 36]);

  useClosable(
    {
      onClose,
      ref: menuRef,
    },
    []
  );

  const localizedCategories = {
    Activities: t('emoji.categories.activity'),
    Flags: t('emoji.categories.flags'),
    'Food & Drink': t('emoji.categories.foods'),
    'Animals & Nature': t('emoji.categories.nature'),
    Objects: t('emoji.categories.objects'),
    'Smileys & People': t('emoji.categories.people'),
    'Travel & Places': t('emoji.categories.places'),
    Symbols: t('emoji.categories.symbols'),
  };

  useEffect(() => {
    let emojis = [];

    const search = filter || searchTerm;

    if (search) {
      emojis = emojiIndex.search(search);
    } else {
      emojis = Object.values(emojiIndex.emojis).map((o: object) => {
        return '1' in o ? o['1'] : o;
      });
    }

    const emojisWithCategories = emojis
      .map((emoji) => ({
        ...emoji,
        category: categoryValues.find(({ emojis }) =>
          emojis.includes(emoji.id)
        ),
      }))
      .filter((emoji) => emoji.category);

    setEmojiList(
      emojisWithCategories.sort((a, b) => {
        const aIdx = sortedCategories.indexOf(a.category?.id);
        const bIdx = sortedCategories.indexOf(b.category?.id);
        if (aIdx < bIdx) return -1;
        if (aIdx > bIdx) return 1;
        return 0;
      })
    );

    setHighlightedIndex(0);
    scrollRef.current.scrollTop = 0;
  }, [filter, searchTerm]);

  useEffect(() => {
    const unsubscribe = tinykeys(document.body, {
      ArrowDown: (ev) => {
        ev.preventDefault();
        setHighlightedIndex((i) => {
          const currentEmoji = emojiList[i];
          let targetIndex = i + COLUMNS;
          const targetEmoji = emojiList[targetIndex];
          const col =
            emojiList
              .filter((emoji) => emoji.category.id === currentEmoji.category.id)
              .findIndex((emoji) => emoji.id === currentEmoji.id) % COLUMNS;
          if (
            targetEmoji &&
            targetEmoji?.category.id !== currentEmoji?.category.id
          ) {
            const nextFirst = emojiList.find(
              (emoji, idx) =>
                emoji.category.id !== currentEmoji?.category.id && idx > i
            );
            const nextCategory = emojiList.filter(
              (emoji) => emoji.category.id === nextFirst.category.id
            );
            targetIndex =
              emojiList.findIndex(
                (emoji, idx) =>
                  emoji.category.id !== currentEmoji?.category.id && idx > i
              ) + Math.min(nextCategory.length - 1, col);
          }
          return Math.min(emojiList.length - 1, targetIndex);
        });
      },
      ArrowUp: (ev) => {
        ev.preventDefault();
        setHighlightedIndex((i) => {
          const currentEmoji = emojiList[i];
          let targetIndex = Math.max(0, i - COLUMNS);
          const targetEmoji = emojiList[targetIndex];
          const col =
            emojiList
              .filter((emoji) => emoji.category.id === currentEmoji.category.id)
              .findIndex((emoji) => emoji.id === currentEmoji.id) % COLUMNS;
          if (
            targetEmoji &&
            targetEmoji?.category.id !== currentEmoji?.category.id
          ) {
            const prevLastIdx =
              emojiList.findIndex(
                (emoji) => emoji.category.id === currentEmoji.category.id
              ) - 1;
            const prevLast = emojiList[prevLastIdx];
            const prevCategory = emojiList.filter(
              (emoji) => emoji.category.id === prevLast?.category?.id
            );
            const lastCol = prevCategory.length % COLUMNS;
            const lastRow = prevCategory.slice(
              lastCol > 0 ? -lastCol : -COLUMNS
            );
            targetIndex = emojiList.findIndex((emoji) =>
              lastRow?.[col]
                ? emoji.id === lastRow[col].id
                : emoji.id === prevLast.id
            );
          }
          return Math.max(0, targetIndex);
        });
      },
      ArrowLeft: (ev) => {
        ev.preventDefault();
        setHighlightedIndex((i) => Math.max(0, i - 1));
      },
      ArrowRight: (ev) => {
        ev.preventDefault();
        setHighlightedIndex((i) => Math.min(emojiList.length - 1, i + 1));
      },
      Enter: (ev) => {
        ev.preventDefault();
        onSelect(emojiList[highlightedIndex].native);
        onClose?.();
      },
      Tab: (ev) => {
        ev.preventDefault();
        setHighlightedIndex((i) => Math.min(emojiList.length - 1, i + 1));
      },
      'Shift+Tab': (ev) => {
        ev.preventDefault();
        setHighlightedIndex((i) => Math.max(0, i - 1));
      },
    });
    return () => {
      unsubscribe();
    };
  }, [emojiList, highlightedIndex, onClose, onSelect]);

  useEffect(() => {
    if (!menuRef.current) return;
    const emoji = menuRef.current.querySelector('.unoEmoji.highlighted');
    if (!emoji) return;
    const results = menuRef.current.querySelector('.results');
    const { height: resultsHeight, top: resultsTop } =
      results.getBoundingClientRect();
    const { top: emojiTop } = emoji.getBoundingClientRect();

    results.scrollTo({
      behavior: 'smooth',
      top:
        results.scrollTop + (emojiTop - resultsTop) - (resultsHeight + 12) / 2,
    });
  }, [highlightedIndex]);

  useEffect(() => {
    if (!menuRef.current) return;

    const results = menuRef.current.querySelector('.results');

    const chunks = emojiList.reduce((res, emoji, i, arr) => {
      if (i === 0 || arr?.[i - 1]?.category?.id !== emoji.category.id) {
        res.push([]);
      }
      res[res.length - 1].push(emoji);
      return res;
    }, []);

    const getChunkHeight = (chunk, lastChunk = false) => {
      const menu = menuRef.current;
      const category = menu?.querySelector('.category');
      const grid = menu?.querySelector('.grid');
      const emoji = menu?.querySelector('.unoEmoji');

      const headerHeight = category?.clientHeight ?? 36;
      const gapHeight =
        grid && window.getComputedStyle(grid).gap
          ? parseInt(window.getComputedStyle(grid).gap, 10)
          : 8;
      const rowHeight = emoji ? emoji.clientHeight : 24;
      const rows = Math.ceil(chunk.length / COLUMNS);
      return (
        headerHeight +
        rows * rowHeight +
        (lastChunk ? rows : rows + 1) * gapHeight
      );
    };

    const handleScroll = () => {
      const scrollTop = results.scrollTop;
      const scrollBottom = scrollTop + results.clientHeight;
      let beforeSpace = 0;
      let afterSpace = 0;
      let chunksToRender = [];

      let height = 0;
      for (let i = 0; i < chunks.length; i++) {
        const chunkHeight = getChunkHeight(chunks[i], i === chunks.length - 1);
        const chunkTop = height;
        const chunkBottom = chunkTop + chunkHeight;
        height += chunkHeight;
        if (chunkBottom < scrollTop) {
          // Chunk is somewhere up
          beforeSpace += chunkHeight;
        } else if (chunkTop > scrollBottom) {
          // Chunk is somewhere down
          afterSpace += chunkHeight;
        } else if (chunkTop <= scrollBottom && chunkBottom >= scrollTop) {
          // Chunk is (partially) visible
          chunksToRender.push(i);
        }
      }

      const chunkLengths = chunks.map((c) => c.length);

      const startIdx = chunkLengths
        .filter((_, i) => i < Math.min(...chunksToRender))
        .reduce((sum, count) => sum + count, 0);
      const endIdx =
        startIdx +
        chunksToRender.reduce((sum, idx) => sum + chunkLengths[idx], 0);

      if (spaceBeforeRef.current)
        spaceBeforeRef.current.style.height = `${beforeSpace}px`;
      if (spaceAfterRef.current)
        spaceAfterRef.current.style.height = `${afterSpace}px`;
      setRenderRange([startIdx, endIdx]);
    };

    handleScroll();

    results.addEventListener('scroll', handleScroll);
    return () => {
      results.removeEventListener('scroll', handleScroll);
    };
  }, [emojiList]);

  const handleSearchChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(ev.target.value);
  };

  return (
    <div className="emojiMenu" ref={menuRef}>
      <Card>
        {!filter && (
          <CardHeader className="search">
            <Input
              autoFocus
              value={searchTerm}
              placeholder={t('emoji.searchPlaceholder')}
              onChange={handleSearchChange}
            />
          </CardHeader>
        )}
        <div className="results" ref={scrollRef}>
          <CardContent>
            <div ref={spaceBeforeRef} />
            <div className="grid">
              {emojiList
                .slice(renderRange[0], renderRange[1])
                .map((emoji, i, arr) => (
                  <Fragment key={emoji.id}>
                    {(i === 0 ||
                      arr?.[i - 1]?.category?.id !== emoji.category.id) && (
                      <div className="category">
                        {localizedCategories[emoji.category.name]}
                      </div>
                    )}
                    <Button
                      className={classNames('unoEmoji', {
                        highlighted: renderRange[0] + i === highlightedIndex,
                      })}
                      key={emoji.id}
                      variant="ghost"
                      onClick={() => {
                        onSelect(emoji.native);
                        onClose?.();
                      }}
                    >
                      <Emoji emoji={emoji.id} set="apple" size={24} />
                    </Button>
                  </Fragment>
                ))}
            </div>
            <div ref={spaceAfterRef} />
          </CardContent>
        </div>
      </Card>
      <style jsx>{`
        .emojiMenu {
          align-self: flex-end;
          height: 40vh;
          max-height: 200px;
          max-width: 233px;
          width: 100%;
        }
        .emojiMenu :global(.search) {
          margin-bottom: 1px;
          padding: 8px;
        }
        .emojiMenu :global(.results) {
          overflow: auto;
        }
        .grid {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 8px;
          margin-top: -16px;
        }
        .category {
          background: var(--card-bg);
          border-bottom: 1px solid var(--card-border);
          padding: 15px 0 4px;
          position: sticky;
          top: 0px;
          width: 100%;
          z-index: 2;
        }
        .category::before,
        .category::after {
          background: var(--card-bg);
          content: '';
          height: 100%;
          position: absolute;
          top: 0;
          width: 8px;
        }
        .category::before {
          right: 100%;
        }
        .category::after {
          left: 100%;
        }
        .emojiMenu :global(.unoEmoji) {
          cursor: pointer;
          height: 24px;
        }
        .emojiMenu :global(.unoEmoji.highlighted) {
          box-shadow: 0 0 0 2px ${hexToRgba(colors.accent, 0.6)};
        }
        .emojiMenu :global(.emoji-mart-emoji) {
          display: block;
          height: 24px;
        }
      `}</style>
    </div>
  );
};
