import {
  BanUserIcon,
  KickUserIcon,
  SendKudosIcon,
  SendMessageIcon,
} from '@daily/shared/components/Icons';
import { Menu } from '@daily/shared/components/Menu';
import { Text } from '@daily/shared/components/Text';
import { useTheme } from '@daily/shared/contexts/Theme';
import {
  useDaily,
  useLocalParticipant,
  useParticipant,
} from '@daily-co/daily-react-hooks';
import { CALL_CUSTOM_EVENTS } from 'components/App/IframeListener';
import { useIframeDriver } from 'contexts/IframeDriverProvider';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

type MenuPropsWithoutItems = Omit<React.ComponentProps<typeof Menu>, 'items'>;

interface Props extends MenuPropsWithoutItems {
  isScreen?: boolean;
  sessionId: string;
}

export const TileMenu: React.FC<Props> = ({
  isScreen = false,
  onClose,
  sessionId,
  ...menuProps
}) => {
  const { sendMessageToDriver } = useIframeDriver();
  const { t } = useTranslation();
  const { colors } = useTheme();
  const daily = useDaily();
  const [sendKudos, setSendKudos] = useState(false);

  const localParticipant = useLocalParticipant();

  const participant = useParticipant(
    sessionId === 'local' ? localParticipant?.session_id : sessionId
  );

  const menuItems: React.ComponentProps<typeof Menu>['items'] = [];

  const handleSendMessageClick = useCallback(
    (event) => {
      sendMessageToDriver({
        action: CALL_CUSTOM_EVENTS.ST_SEND_MESSAGE,
        payload: {
          from: 'tile-send-message',
          userID: participant.user_id,
        },
      });
    },
    [participant.user_id, sendMessageToDriver]
  );

  const handleSendKudosClick = useCallback(
    (event) => {
      setSendKudos(true);
      sendMessageToDriver({
        action: CALL_CUSTOM_EVENTS.ST_SEND_KUDOS,
        payload: {
          from: 'tile-send-kudos',
          userID: participant.user_id,
        },
      });
    },
    [participant.user_id, sendMessageToDriver]
  );

  if (!participant?.local) {
    menuItems.push({
      label: <Text>{t('people.dmParticipant')}</Text>,
      icon: <SendMessageIcon color={colors.baseText} size={20} />,
      // @ts-ignore
      onClick: handleSendMessageClick,
    });
  }

  if (!participant?.local) {
    menuItems.push({
      label: <Text>{t('people.sendKudos')}</Text>,
      icon: sendKudos ? (
        <SendKudosIcon color="#F9CE1B" size={20} />
      ) : (
        <SendKudosIcon color={colors.baseText} size={20} />
      ),
      // @ts-ignore
      onClick: handleSendKudosClick,
    });
  }

  const handleKick = (to: 'kick' | 'temp' | 'perm') => {
    sendMessageToDriver({
      action: CALL_CUSTOM_EVENTS.ST_KICK_PARTICIPANT,
      receiverID: localParticipant.user_id,
      payload: {
        userID: participant.user_id,
        sessionID: participant.session_id,
        to: to,
      },
    });

    // Kick user visually from room.
    if (!participant?.local) {
      daily.updateParticipant(participant.session_id, { eject: true });
    }
  };

  if (localParticipant?.owner && !participant?.local && !isScreen) {
    // Kick user
    menuItems.push({
      label: <Text color="error">Kick User</Text>,
      icon: <KickUserIcon color={colors.system.red} size={20} />,
      onClick: () => handleKick('kick'),
    });
    // Timeout user
    menuItems.push({
      label: <Text color="error">Kick User (30 Minutes)</Text>,
      icon: <KickUserIcon color={colors.system.red} size={20} />,
      onClick: () => handleKick('temp'),
    });
    // Ban user
    menuItems.push({
      label: <Text color="error">Ban User</Text>,
      icon: <BanUserIcon color={colors.system.red} size={20} />,
      onClick: () => handleKick('perm'),
    });
  }

  if (menuItems.length === 0) return null;

  return (
    <Menu
      items={menuItems}
      onClose={onClose}
      className="revert-row"
      {...menuProps}
    />
  );
};
