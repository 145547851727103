import { Button } from '@daily/shared/components/Button';
import { Modal } from '@daily/shared/components/Modal';
import { Text } from '@daily/shared/components/Text';
import { useDevices } from '@daily-co/daily-react-hooks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends React.ComponentProps<typeof Modal> {}

export const DeviceNotFoundModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { camState, micState } = useDevices();

  const camNotFound = camState === 'not-found';
  const micNotFound = micState === 'not-found';

  /**
   * When there are no devices found, we ask the user to connect devices and then reload.
   * The CTA in this Modal therefore triggers a reload.
   */
  const handleReload = () => {
    window.location.reload();
  };

  const modalTitle = useMemo(() => {
    const context =
      camNotFound && micNotFound ? 'both' : camNotFound ? 'camera' : 'mic';
    // i18next-extract-mark-context-next-line ["camera", "mic", "both"]
    return t('haircheck.noDevices.title', {
      context,
    });
  }, [camNotFound, micNotFound, t]);

  const modalContent = useMemo(() => {
    const context =
      camNotFound && micNotFound ? 'both' : camNotFound ? 'camera' : 'mic';
    // i18next-extract-mark-context-next-line ["camera", "mic", "both"]
    return t('haircheck.noDevices.modalContent', {
      context,
    });
  }, [camNotFound, micNotFound, t]);

  return (
    <Modal
      actions={[
        <Button key="reload" onClick={handleReload} variant="primary">
          {t('general.reloadCall')}
        </Button>,
        <Button key="close" variant="secondary">
          {props.closeText}
        </Button>,
      ]}
      title={modalTitle}
      {...props}
    >
      <Text El="p" variant="large">
        {modalContent}
      </Text>
    </Modal>
  );
};
