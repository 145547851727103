import { Stack } from '@daily/shared/components/Stack';
import { useResize } from '@daily/shared/hooks/useResize';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useCallConfig } from '../../hooks/useCallConfig';
import { useResizeObserver } from '../../hooks/useResizeObserver';
import { RecordingState } from '../Call/RecordingState';
import { LiveBadge } from './LiveBadge';
import { PeopleInCall } from './PeopleInCall';
import { PictureInPictureButton } from './PictureInPictureButton';
import { SettingsButton } from './SettingsButton';
import { TopbarAction } from './TopbarAction';

export const Topbar: React.FC = () => {
  const { enablePIPUI, poweredByDaily } = useCallConfig();
  const topbarRef = useRef<HTMLDivElement>(null);
  const [supportsPIP, setSupportsPIP] = useState(false);

  /**
   * Recalculates and sets the --header-height CSS variable.
   */
  const updateCSSHeaderHeight = useCallback(() => {
    if (!topbarRef.current) return;
    const { height, top } = topbarRef.current.getBoundingClientRect();
    document.body.style.setProperty('--header-height', `${height + top}px`);
  }, []);
  /**
   * Set up ResizeObserver to determine when Topbar shifts position.
   */
  useResizeObserver(
    topbarRef,
    useCallback(() => {
      updateCSSHeaderHeight();
    }, [updateCSSHeaderHeight])
  );
  useResize(updateCSSHeaderHeight, [poweredByDaily, updateCSSHeaderHeight]);

  useEffect(() => {
    // TODO: Figure out why pictureInPicture types are missing in `next build`
    // @ts-ignore
    setSupportsPIP(document.pictureInPictureEnabled);
  }, []);

  return (
    <div ref={topbarRef} className="topbar">
      <div className="topbar-participants-count">
        <Stack align="center" justify="center" horizontal height="100%">
          <SettingsButton />
          <Stack align="center" gap={4} horizontal>
            <LiveBadge />
            <PeopleInCall />
          </Stack>
        </Stack>
      </div>
      <Stack align="center" justify="end" horizontal>
        <Stack horizontal gap={0}>
          <RecordingState />
          <TopbarAction />
          {enablePIPUI && supportsPIP && <PictureInPictureButton />}
        </Stack>
      </Stack>
      <style jsx>{`
        .topbar {
          background: transparent;
          // background: var(--card-bg);
          // border-bottom: 1px solid var(--card-border);
          flex: none;
          padding: 4px 8px;
          width: 100vw;
          z-index: var(--zindex-tray);
          position: relative;
        }
        .topbar-participants-count {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      `}</style>
    </div>
  );
};
