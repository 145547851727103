import { Stack } from '@daily/shared/components/Stack';
import { useTheme } from '@daily/shared/contexts/Theme';
import classNames from 'classnames';
import React from 'react';

import { TrayBadge } from './TrayBadge';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  badge?: React.ComponentProps<typeof TrayBadge>;
  visible?: boolean;
}

export const TrayButton: React.FC<Props> = ({
  badge = null,
  children,
  className,
  visible = true,
  ...props
}) => {
  const { colors, mediaQueries } = useTheme();
  return (
    <button
      className={classNames(className, { visible })}
      type="button"
      {...props}
      onTouchStart={(e) => e.stopPropagation()}
    >
      <Stack align="center" gap={2}>
        {children}
      </Stack>
      {badge && <TrayBadge {...badge} />}
      <style jsx>{`
        button {
          background: rgba(40, 35, 34, 1); // #282322
          border: none;
          border-radius: 8px;
          color: inherit;
          margin: -4px 0;
          height: 48px;
          width: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          transition: background 250ms ease;
        }
        button:focus {
          outline: none;
        }
        @media ${mediaQueries.nonCoarse} {
          :global(.with-keyboard) button:focus:not([disabled]),
          button:hover:not([disabled]) {
            background: rgba(40, 35, 34, 0.7); // #282322_70%
          }
        }
        :global(.with-keyboard) button:focus:not([disabled]) {
          box-shadow: 0 0 0 2px var(--focus-shadow);
        }
        button:not([disabled]) {
          cursor: pointer;
        }
        button:not(.visible) {
          margin: 0;
          position: absolute;
          visibility: hidden;
        }
      `}</style>
    </button>
  );
};
