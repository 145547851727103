import { isAndroidChrome } from 'lib/browserConfig';
import getConfig from 'next/config';

const CHROME = 'Chrome';
const EDGE = 'Microsoft Edge';
const FIREFOX = 'Firefox';
const OPERA = 'Opera';

/**
 * Helper method to calculate the right image path for permissions icon in browsers.
 */
export const getBrowserIcon = (
  browser: Bowser.Parser.ParsedResult,
  isDarkMode: boolean
) => {
  const browserIcons = {
    [CHROME]: 'chrome',
    [EDGE]: 'edge',
    [FIREFOX]: 'firefox',
    [OPERA]: 'opera',
  };

  if (!browser) return null;

  const browserName = browser.browser.name;

  const parts = [];

  if (isAndroidChrome()) {
    parts.push('mobile');
  }

  parts.push(
    [CHROME, EDGE, FIREFOX, OPERA].includes(browserName)
      ? browserIcons[browserName]
      : 'chrome'
  );

  // New icons in Firefox 89
  if (browserName === FIREFOX && parseFloat(browser.browser.version) >= 89) {
    parts.push('new');
  }

  parts.push(isDarkMode ? 'dark' : 'light');

  const { assetPrefix } = getConfig().publicRuntimeConfig;

  return `${assetPrefix}/unblock/${parts.join('-')}.png`;
};
