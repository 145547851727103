import getConfig from 'next/config';

export const GiphyIcon = () => {
  const { assetPrefix } = getConfig().publicRuntimeConfig;

  return (
    <img
      alt="Giphy Logo"
      src={`${assetPrefix}/giphy-36-light.png`}
      height="12"
      width="9"
    />
  );
};
