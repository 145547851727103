import { useUniqueId } from 'hooks/useUniqueId';
import { SVGProps } from 'react';

export function Jade(props: SVGProps<SVGSVGElement>) {
  // Gradient ID's
  const a = useUniqueId();
  const b = useUniqueId();
  const c = useUniqueId();
  const d = useUniqueId();
  const e = useUniqueId();
  const f = useUniqueId();
  const g = useUniqueId();
  const h = useUniqueId();
  const i = useUniqueId();
  const j = useUniqueId();
  return (
    <svg width={120} height={120} viewBox="0 0 120 120" fill="none" {...props}>
      <g clipPath={`url(#${a})`}>
        <path
          d="M27.365 89.696 15.912 62.027H0l16.115 38.919 11.25-11.25Z"
          fill={`url(#${b})`}
        />
        <path
          d="m15.912 57.972 11.453-27.77-11.149-11.148L0 57.972h15.912Z"
          fill={`url(#${c})`}
        />
        <path
          d="M62.027 0v15.912l27.77 11.453 11.251-11.149L62.028 0Z"
          fill={`url(#${d})`}
        />
        <path
          d="m88.489 87.987 11.436-27.586c.1-.2.1-.501 0-.802L88.489 32.013c-.1-.201-.3-.402-.502-.502L60.401 20.075c-.2-.1-.501-.1-.802 0L32.013 31.511c-.201.1-.402.3-.502.502L20.075 59.599c-.1.2-.1.501 0 .802l11.436 27.586c.1.201.3.402.502.502l27.586 11.436c.2.1.501.1.802 0l27.586-11.436c.201-.1.402-.3.502-.502Z"
          fill={`url(#${e})`}
        />
        <path
          d="m30.305 27.365 27.669-11.453V0l-38.92 16.115 11.25 11.25Z"
          fill={`url(#${f})`}
        />
        <path
          d="m92.635 30.304 11.452 27.668H120l-16.115-38.918-11.25 11.25Z"
          fill={`url(#${g})`}
        />
        <path
          d="m57.971 104.088-27.77-11.453-11.25 11.149L57.971 120v-15.912Z"
          fill={`url(#${h})`}
        />
        <path
          d="M104.087 62.027 92.635 89.696l11.25 11.25L120 62.027h-15.913Z"
          fill={`url(#${i})`}
        />
        <path
          d="m89.796 92.635-27.77 11.453V120l38.918-16.216-11.148-11.149Z"
          fill={`url(#${j})`}
        />
      </g>
      <defs>
        <linearGradient
          id={b}
          x1={42.313}
          y1={41.726}
          x2={3.795}
          y2={85.203}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C0E6F2" />
          <stop offset={0.536} stopColor="#62C5E6" />
          <stop offset={1} stopColor="#007994" />
        </linearGradient>
        <linearGradient
          id={c}
          x1={49.746}
          y1={25.692}
          x2={9.959}
          y2={41.781}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C0E6F2" />
          <stop offset={0.536} stopColor="#62C5E6" />
          <stop offset={1} stopColor="#007994" />
        </linearGradient>
        <linearGradient
          id={d}
          x1={93.626}
          y1={0}
          x2={58.106}
          y2={35.488}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C0E6F2" />
          <stop offset={0.536} stopColor="#62C5E6" />
          <stop offset={1} stopColor="#007994" />
        </linearGradient>
        <linearGradient
          id={e}
          x1={84.783}
          y1={20}
          x2={60}
          y2={85.646}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C0E6F2" />
          <stop offset={0.536} stopColor="#62C5E6" />
          <stop offset={1} stopColor="#007994" />
        </linearGradient>
        <linearGradient
          id={f}
          x1={50.571}
          y1={0}
          x2={44.209}
          y2={23.967}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C0E6F2" />
          <stop offset={0.536} stopColor="#62C5E6" />
          <stop offset={1} stopColor="#007994" />
        </linearGradient>
        <linearGradient
          id={g}
          x1={114.795}
          y1={19.054}
          x2={92.503}
          y2={75.384}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C0E6F2" />
          <stop offset={0.536} stopColor="#62C5E6" />
          <stop offset={1} stopColor="#19A4D2" />
          <stop offset={1} stopColor="#007994" />
        </linearGradient>
        <linearGradient
          id={h}
          x1={50.549}
          y1={92.635}
          x2={44.202}
          y2={116.61}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C0E6F2" />
          <stop offset={0.536} stopColor="#62C5E6" />
          <stop offset={1} stopColor="#007994" />
        </linearGradient>
        <linearGradient
          id={i}
          x1={114.795}
          y1={62.027}
          x2={80.185}
          y2={115.419}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C0E6F2" />
          <stop offset={0.536} stopColor="#62C5E6" />
          <stop offset={1} stopColor="#007994" />
        </linearGradient>
        <linearGradient
          id={j}
          x1={93.541}
          y1={92.635}
          x2={69.894}
          y2={156.093}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C0E6F2" />
          <stop offset={0.251} stopColor="#62C5E6" />
          <stop offset={0.676} stopColor="#007994" />
        </linearGradient>
        <clipPath id={a}>
          <path fill="#fff" d="M0 0h120v120H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
