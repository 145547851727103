import { useParticipant } from '@daily-co/daily-react-hooks';
import { CALL_CUSTOM_EVENTS } from 'components/App/IframeListener';
import { useIframeDriver } from 'contexts/IframeDriverProvider';
import { useHiddenIds, useHiddenParticipantModal } from 'contexts/UIState';
import { useCallback } from 'react';

type Props = {
  onClose?: () => void;
};

const IconClose = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.86801 17.7707C7.47748 18.1612 6.84432 18.1612 6.45379 17.7707C6.09116 17.408 6.06526 16.8362 6.37609 16.4437L6.45379 16.3565L16.3533 6.45696C16.7438 6.06643 17.377 6.06643 17.7675 6.45696C18.1301 6.81959 18.156 7.39143 17.8452 7.78395L17.7675 7.87117L7.86801 17.7707Z"
        fill="#FF6F61"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66354 10.0702C9.05606 10.381 9.62791 10.3551 9.99054 9.99249C10.3811 9.60197 10.3811 8.9688 9.99054 8.57828L7.86922 6.45696L7.782 6.37925C7.38948 6.06842 6.81763 6.09433 6.455 6.45696C6.06448 6.84748 6.06448 7.48064 6.455 7.87117L8.57632 9.99249L8.66354 10.0702ZM16.4417 17.8484C16.8342 18.1592 17.4061 18.1333 17.7687 17.7707C18.1592 17.3801 18.1592 16.747 17.7687 16.3565L15.6474 14.2351L15.5602 14.1574C15.1677 13.8466 14.5958 13.8725 14.2332 14.2351C13.8427 14.6257 13.8427 15.2588 14.2332 15.6493L16.3545 17.7707L16.4417 17.8484Z"
        fill="#9656A1"
      />
    </svg>
  );
};

const IconInfo = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2.25C12.7279 2.25 15.75 5.27208 15.75 9C15.75 9.97941 15.5408 10.9307 15.142 11.8033C14.9698 12.18 14.5248 12.3458 14.1481 12.1736C13.7713 12.0014 13.6055 11.5565 13.7777 11.1797C14.0875 10.5019 14.25 9.76314 14.25 9C14.25 6.10051 11.8995 3.75 9 3.75C6.10051 3.75 3.75 6.10051 3.75 9C3.75 11.8995 6.10051 14.25 9 14.25C10.0772 14.25 11.1039 13.9257 11.9717 13.3287C12.3129 13.0939 12.7799 13.1802 13.0147 13.5215C13.2495 13.8627 13.1632 14.3297 12.8219 14.5645C11.706 15.3322 10.3832 15.75 9 15.75C5.27208 15.75 2.25 12.7279 2.25 9C2.25 5.27208 5.27208 2.25 9 2.25ZM9.75 12C9.75 12.4142 9.41421 12.75 9 12.75C8.58579 12.75 8.25 12.4142 8.25 12C8.25 11.5858 8.58579 11.25 9 11.25C9.41421 11.25 9.75 11.5858 9.75 12ZM9.74495 5.91253C9.70163 5.53953 9.38463 5.25 9 5.25C8.58579 5.25 8.25 5.58579 8.25 6V9.75L8.25505 9.83747C8.29837 10.2105 8.61537 10.5 9 10.5C9.41421 10.5 9.75 10.1642 9.75 9.75V6L9.74495 5.91253Z"
        fill="white"
      />
    </svg>
  );
};

export const ParticipantHiddenModal: React.FC<Props> = ({ onClose }) => {
  const [, setShowParticipantHiddenModal] = useHiddenParticipantModal();

  const { sendMessageToDriver } = useIframeDriver();

  const [hiddenIds, setHiddenIds] = useHiddenIds();
  const lastHiddenId = hiddenIds[hiddenIds.length - 1];
  const participant = useParticipant(lastHiddenId);
  const userID = participant?.user_id;

  const handleClose = useCallback(() => {
    setShowParticipantHiddenModal(false);
    onClose?.();
  }, [onClose, setShowParticipantHiddenModal]);

  const handleUnhide = useCallback(() => {
    setShowParticipantHiddenModal(false);
    setHiddenIds((ids) => ids.filter((id) => id !== lastHiddenId));
  }, [lastHiddenId, setHiddenIds, setShowParticipantHiddenModal]);

  const handleReport = useCallback(
    (event) => {
      setShowParticipantHiddenModal(false);

      sendMessageToDriver({
        action: CALL_CUSTOM_EVENTS.ST_REPORT_PARTICIPANT,
        payload: {
          from: 'tile-report-user',
          userID: userID,
          x: event.clientX,
          y: event.clientY,
        },
      });
    },
    [userID, sendMessageToDriver]
  );

  return (
    <>
      <div className="modal-wrapper">
        <div className="modal-backdrop" onClick={handleClose}></div>
        <div className="modal">
          <button className="modal-close" onClick={handleClose}>
            <IconClose />
          </button>
          <div className="modal-title">User was hidden</div>
          <div className="modal-body">
            In case this user was not just distracting to you but also showing{' '}
            <strong>inappropriate content</strong> or <strong>behaviour</strong>
            , do not hesitate to report them.
          </div>
          <div className="modal-footer">
            <button onClick={handleUnhide} className="button-secondary">
              Undo hiding
            </button>
            <button onClick={handleReport} className="button-primary">
              <IconInfo />
              <span>Report User</span>
            </button>
          </div>
        </div>
      </div>
      <style jsx>{`
        * {
          font-family: 'Source Sans Pro', sans-serif;
        }
        .modal-backdrop,
        .modal-wrapper {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1000;
        }

        .modal-backdrop {
          background-color: rgba(0, 0, 0, 0.8);
        }

        .modal-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .modal {
          position: relative;
          background: white;
          border-radius: 4px;
          padding: 40px;
          color: #27110f;
          max-width: 350px;
          z-index: 1001;
        }

        .modal-title {
          color: #ff6f61;
          font-size: 24px;
          line-height: 28px;
          font-weight: 900;
          margin-top: 6px;
        }

        .modal-body {
          font-size: 14px;
          line-height: 18px;
          margin-top: 12px;
        }

        .modal-close {
          border: 0;
          cursor: pointer;
          height: 24px;
          width: 24px;
          margin: 0;
          padding: 0;
          position: absolute;
          top: 16px;
          right: 16px;
          background: none;
        }
        .modal-close:hover {
          opacity: 0.75;
        }
        .modal-footer {
          display: flex;
          justify-content: space-between;
          margin-top: 40px;
        }

        .button-secondary {
          background: white;
          color: #27110f;
          border: 0;
          cursor: pointer;
          text-decoration: underline;
          text-decoration-color: #9656a1;
        }
        .button-secondary:hover {
          text-decoration: none;
        }
        .button-primary {
          display: flex;
          align-items: center;
          padding-left: 16px;
          padding-right: 16px;
          height: 40px;
          background: #ff3939;
          color: white;
          border-radius: 8px;
          border: 0;
          cursor: pointer;
          font-size: 14px;
          font-weight: bold;
          text-decoration: none;
        }

        .button-primary span {
          padding-left: 10px;
        }
      `}</style>
    </>
  );
};
