import DailyIframe from '@daily-co/daily-js';
import { useRoom } from '@daily-co/daily-react-hooks';
import Bowser from 'bowser';
import { useEffect } from 'react';

import { isCloudRecordingType } from '../contexts/RecordingProvider';
import {
  useMaxGridTilesPerPage,
  usePreferredViewMode,
} from '../contexts/UIState';

type BroadcastRole = 'owner' | 'attendee';

export const useCallConfig = () => {
  const room = useRoom();

  const [, setPreferredViewMode] = usePreferredViewMode();
  const [, setMaxGridTilesPerPage] = useMaxGridTilesPerPage();

  /**
   * Apply Prebuilt UI constraints, when experimental_optimize_large_calls is set.
   */
  useEffect(() => {
    const optimizeLargeCalls = room?.config?.experimental_optimize_large_calls;
    if (!optimizeLargeCalls) return;
    setMaxGridTilesPerPage(12);
    // TBC: For now, start the call in Speaker view to match the group call default
    // setPreferredViewMode('grid');
  }, [room, setMaxGridTilesPerPage, setPreferredViewMode]);

  if (!room)
    return {
      broadcast: null,
      broadcastRole: null,
      poweredByDaily: false,
    };

  const { config, domainConfig, tokenConfig } = room;

  const broadcast = config?.owner_only_broadcast ?? null;
  const isOwner = tokenConfig?.is_owner;

  const hasFullUIControls = !broadcast || (broadcast && isOwner);

  const recordingType =
    tokenConfig?.enable_recording ?? config?.enable_recording;

  const browser =
    typeof window === 'undefined'
      ? null
      : Bowser.parse(window.navigator.userAgent);
  const supportsLocalRecording =
    browser?.platform?.type === 'desktop' && browser?.engine?.name === 'Blink';

  const isLocalRecordingReady =
    recordingType === 'local' && supportsLocalRecording;

  const recordingsBucket =
    config?.recordings_bucket ?? domainConfig?.recordings_bucket;
  const isCloudRecordingAllowed =
    !domainConfig.hipaa || (domainConfig.hipaa && recordingsBucket);
  const isCloudRecordingReady =
    isCloudRecordingAllowed && isCloudRecordingType(recordingType);

  const enableRecording =
    hasFullUIControls && (isLocalRecordingReady || isCloudRecordingReady)
      ? recordingType
      : null;

  return {
    broadcast,
    broadcastRole: broadcast
      ? ((isOwner ? 'owner' : 'attendee') as BroadcastRole)
      : null,
    enableAdvancedChat:
      config?.enable_advanced_chat ?? !!domainConfig?.enable_advanced_chat,
    enableChat: !!config?.enable_chat ?? !!domainConfig?.enable_chat,
    enableImplicitPassiveParticipant:
      !!config?.['enable_implicit_passive_participants'],
    enableNetworkUI:
      config?.enable_network_ui ?? !!domainConfig?.enable_network_ui,
    enablePeopleUI:
      config?.enable_people_ui ?? domainConfig?.enable_people_ui ?? true,
    enablePIPUI: config?.enable_pip_ui ?? !!domainConfig?.enable_pip_ui,
    enableRecording,
    enableRecordingUI: tokenConfig?.enable_recording_ui ?? !!enableRecording,
    enableScreenShare:
      hasFullUIControls &&
      (tokenConfig?.enable_screenshare ?? config?.enable_screenshare) &&
      DailyIframe.supportedBrowser().supportsScreenShare,
    enableVideoProcessingUI:
      DailyIframe.supportedBrowser().supportsVideoProcessing &&
      (config?.enable_video_processing_ui ??
        !!domainConfig?.enable_video_processing_ui),
    optimizeLargeCalls: config?.experimental_optimize_large_calls,
    poweredByDaily:
      domainConfig && 'hide_daily_branding' in domainConfig
        ? !domainConfig?.hide_daily_branding
        : false,
  };
};
