import { Button } from '@daily/shared/components/Button';
import {
  CamIcon,
  CloseIcon,
  MicrophoneIcon,
} from '@daily/shared/components/Icons';
import { Text } from '@daily/shared/components/Text';
import { useSnackbar } from '@daily/shared/contexts/Snackbar';
import { useTheme } from '@daily/shared/contexts/Theme';
import { DailyEventObjectParticipant } from '@daily-co/daily-js';
import {
  useDevices,
  useLiveStreaming,
  useLocalParticipant,
  useParticipantIds,
  useThrottledDailyEvent,
} from '@daily-co/daily-react-hooks';
import Bowser from 'bowser';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCallState } from '../../contexts/CallProvider';
import { useIsMobile } from '../../contexts/UIState';
import { WaitingRoomProvider } from '../../contexts/WaitingRoomProvider';
import { useCallConfig } from '../../hooks/useCallConfig';
import { useSoundLoader } from '../../hooks/useSoundLoader';
import { isIOSMobile } from '../../lib/browserConfig';
import { Call } from '../Call';
import { Haircheck } from '../Haircheck';
import { MobileView } from '../MobileView';
import { Tray } from '../Tray';
import { WaitingForHost } from '../WaitingForHost';
import { FinalMessage } from './FinalMessage';
import { Layout } from './Layout';
import { LoadingScreen } from './LoadingScreen';
import { useBandwidthControls } from './useBandwidthControls';
import { useIntercomIntegration } from './useIntercomIntegration';

export const App: React.FC = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { addMessage } = useSnackbar();
  const { mode, reloadCall, state } = useCallState();
  const { broadcast, broadcastRole } = useCallConfig();
  const { camState, micState } = useDevices();
  const [isMobile, setIsMobile] = useIsMobile();
  const [disableWaitingForHostMsg, setDisableWaitingForHostMsg] =
    useState(false);
  const localParticipant = useLocalParticipant();
  const ownerParticipantIds = useParticipantIds({ filter: 'owner' });
  const { poweredByDaily } = useCallConfig();
  const { errorSound } = useSoundLoader();

  useBandwidthControls();

  useIntercomIntegration();

  /**
   * Listen for live streaming errors and show a snackbar in case.
   */
  useLiveStreaming({
    onLiveStreamingError: useCallback(() => {
      addMessage({
        content: t('notification.liveStreamingError'),
        type: 'error',
      });
    }, [addMessage, t]),
  });

  /**
   * Determine wether the client runs on mobile.
   * This activates the mobile UI.
   */
  useEffect(() => {
    if (isMobile) return;

    const { platform } = Bowser.parse(window.navigator.userAgent);
    const browserType = platform?.type;
    if (['mobile', 'tablet'].includes(browserType) || isIOSMobile()) {
      setIsMobile(true);
    }
  }, [isMobile, setIsMobile]);

  useThrottledDailyEvent(
    'participant-joined',
    useCallback((evts: DailyEventObjectParticipant[]) => {
      // the "waiting for host" message only shows before a host has joined
      if (evts.some((ev) => ev.participant.owner))
        setDisableWaitingForHostMsg(true);
    }, [])
  );

  /**
   * Display a muted by host message, in case the participant's mic got muted.
   */
  useEffect(() => {
    if (!localParticipant?.tracks?.audio?.off?.byRemoteRequest) return;
    addMessage({
      content: t('notification.micMutedByHost'),
      type: 'info',
      icon: (
        <MicrophoneIcon
          id={`snackbarIcon${Date.now()}`} // randomize ID in case of multiple snackbars
          muted
          size={16}
        />
      ),
    });
  }, [addMessage, localParticipant?.tracks?.audio?.off?.byRemoteRequest, t]);

  /**
   * Display a muted by host message, in case the participant's cam got muted.
   */
  useEffect(() => {
    if (!localParticipant?.tracks?.video?.off?.byRemoteRequest) return;
    addMessage({
      content: t('notification.camMutedByHost'),
      type: 'info',
      icon: (
        <CamIcon
          id={`snackbarIcon${Date.now()}`} // randomize ID in case of multiple snackbars
          muted
          size={16}
        />
      ),
    });
  }, [addMessage, localParticipant?.tracks?.video?.off?.byRemoteRequest, t]);

  /**
   * Play error sound on 'knocking-denied' CallState.
   * TODO: Refactor to 'useEvent' once it's available.
   */
  useEffect(() => {
    if (state !== 'knocking-denied') return;
    errorSound.play();
  }, [errorSound, state]);

  const messageProps: React.ComponentProps<typeof FinalMessage> =
    useMemo(() => {
      switch (state) {
        case 'ended':
          return {
            desc: t('lobby.ended.desc'),
            title: t('lobby.ended.title'),
            variant: 'error',
          };
        case 'error':
          return {
            desc: t('lobby.error.desc'),
            title: t('lobby.error.title'),
            variant: 'error',
          };
        case 'expired':
          return {
            desc: t('lobby.expired.desc'),
            title: t('lobby.expired.title'),
            variant: 'error',
          };
        case 'full':
          return {
            desc: t('lobby.full.desc'),
            title: t('lobby.full.title'),
            variant: 'error',
          };
        case 'joining':
          if (
            (camState === 'pending' || micState === 'pending') &&
            broadcast !== null &&
            broadcastRole !== 'attendee'
          ) {
            return {
              desc: t('lobby.devicePermissions.desc'),
              title: t('lobby.devicePermissions.title'),
            };
          }
          break;
        case 'knocking-cancelled':
          return mode === 'direct-link'
            ? { showLoadingScreen: true }
            : {
                desc: t('lobby.canceled.desc'),
                title: t('lobby.canceled.title'),
              };
        case 'knocking-denied':
          return {
            desc: t('lobby.denied.desc'),
            icon: <CloseIcon color={colors.system.red} />,
            title: t('lobby.denied.title'),
          };
        case 'left':
          return {
            cta: poweredByDaily && (
              <Button href="https://www.daily.co" variant="secondary">
                {t('lobby.left.learnMore')}
              </Button>
            ),
            desc: poweredByDaily
              ? t('lobby.left.descBranded')
              : t('lobby.left.descUnbranded'),
            icon: <Text variant="largestrong">👋</Text>,
            poweredByDaily,
            title: t('lobby.left.title'),
          };
        case 'nbf':
          return {
            cta:
              mode === 'direct-link' ? (
                <Button onClick={reloadCall} variant="primary">
                  {t('general.tryAgain')}
                </Button>
              ) : null,
            desc: t('lobby.notAvailable.desc'),
            title: t('lobby.notAvailable.title'),
            variant: 'error',
          };
        case 'not-allowed':
          return {
            desc: t('lobby.noAccess.desc'),
            title: t('lobby.noAccess.title'),
            variant: 'error',
          };
        case 'not-found':
          return {
            desc: t('lobby.notFound.desc'),
            title: t('lobby.notFound.title'),
            variant: 'error',
          };
        case 'not-secure':
          return {
            desc: t('lobby.notSecure.desc'),
            title: t('lobby.notSecure.title'),
            variant: 'error',
          };
        case 'removed-from-call':
          return {
            desc: t('lobby.removed.desc'),
            title: t('lobby.removed.title'),
            variant: 'error',
          };
      }
      return null;
    }, [
      broadcast,
      broadcastRole,
      camState,
      colors.system.red,
      micState,
      mode,
      poweredByDaily,
      reloadCall,
      state,
      t,
    ]);

  return useMemo(() => {
    if (mode === 'embedded' && state === 'redirecting') {
      // Don't redirect in embedded mode, just clear out UI
      return null;
    }

    if (messageProps) {
      return (
        <Layout>
          <FinalMessage {...messageProps} />
        </Layout>
      );
    }

    if (
      ['awaiting-args', 'ready', 'joining'].includes(state) ||
      // Wait for localParticipant to be present
      !localParticipant
    ) {
      return <LoadingScreen />;
    }

    if (state === 'lobby') {
      return (
        <Layout>
          <Haircheck />
        </Layout>
      );
    }

    /**
     * A host must be present for OOB attendees to see the in-call UI.
     * Once attendees have entered the call, this message won't be shown
     * again.
     */
    if (
      broadcastRole === 'attendee' &&
      ownerParticipantIds.length === 0 &&
      !disableWaitingForHostMsg
    ) {
      return (
        <Layout>
          <WaitingForHost />;
        </Layout>
      );
    }

    return (
      <Layout>
        <WaitingRoomProvider>
          {isMobile ? (
            <MobileView />
          ) : (
            <>
              <Call />
              <Tray />
            </>
          )}
        </WaitingRoomProvider>
      </Layout>
    );
  }, [
    broadcastRole,
    disableWaitingForHostMsg,
    isMobile,
    localParticipant,
    messageProps,
    ownerParticipantIds.length,
    state,
    mode,
  ]);
};
