import { useUniqueId } from 'hooks/useUniqueId';
import { SVGProps } from 'react';

export function Moonstone(props: SVGProps<SVGSVGElement>) {
  // Gradient ID's
  const a = useUniqueId();
  const b = useUniqueId();
  const c = useUniqueId();
  const d = useUniqueId();
  const e = useUniqueId();
  const f = useUniqueId();
  const g = useUniqueId();

  return (
    <svg width={120} height={120} viewBox="0 0 120 120" fill="none" {...props}>
      <path
        d="m97.16 36.194 13.866-7.953L62.088 0v15.905c.306.102.612.204.816.408L96.14 35.48c.408.204.714.51 1.02.714Z"
        fill={`url(#${a})`}
      />
      <path
        d="M20.595 80.339c-.102-.306-.102-.714-.102-1.122V40.781c0-.408 0-.918.102-1.326l-13.56-7.748v56.482l13.56-7.85Z"
        fill={`url(#${b})`}
      />
      <path
        d="M94.932 79.004V40.996c0-.706-.403-1.411-1.008-1.714L60.957 20.33a1.832 1.832 0 0 0-2.016 0L26.075 39.282c-.605.404-1.009 1.009-1.009 1.714v38.008c0 .706.404 1.411 1.009 1.714L58.94 99.67a1.83 1.83 0 0 0 2.016 0l32.866-18.953c.706-.303 1.11-.907 1.11-1.714Z"
        fill={`url(#${c})`}
      />
      <path
        d="M22.738 36.092a2.22 2.22 0 0 1 .816-.51L56.79 16.415c.407-.204.815-.408 1.223-.51V0L9.076 28.14l13.662 7.952Z"
        fill={`url(#${d})`}
      />
      <path
        d="M99.201 39.761c.102.408.102.714.102 1.122v38.436c0 .306 0 .612-.102.918l13.764 7.952V31.707L99.201 39.76Z"
        fill={`url(#${e})`}
      />
      <path
        d="M58.012 104.299c-.408-.102-.816-.306-1.223-.51L23.552 84.52c-.306-.204-.612-.408-.918-.714l-13.56 7.85 48.938 28.242v-15.599Z"
        fill={`url(#${f})`}
      />
      <path
        d="M97.262 83.806c-.306.306-.714.51-1.121.816l-33.238 19.167c-.305.204-.611.306-.815.408V120l48.938-28.241-13.764-7.953Z"
        fill={`url(#${g})`}
      />
      <defs>
        <linearGradient
          id={a}
          x1={95.189}
          y1={30.92}
          x2={72.971}
          y2={-17.919}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B90FF" />
          <stop offset={0.51} stopColor="#0059A3" />
          <stop offset={1} stopColor="#003561" />
        </linearGradient>
        <linearGradient
          id={b}
          x1={0.304}
          y1={41.129}
          x2={26.657}
          y2={62.749}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B90FF" />
          <stop offset={0.51} stopColor="#0059A3" />
          <stop offset={1} stopColor="#003561" />
        </linearGradient>
        <linearGradient
          id={c}
          x1={94.932}
          y1={41.284}
          x2={54.55}
          y2={100.022}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B90FF" />
          <stop offset={0.51} stopColor="#0059A3" />
          <stop offset={1} stopColor="#003561" />
        </linearGradient>
        <linearGradient
          id={d}
          x1={33.545}
          y1={0}
          x2={47.288}
          y2={28.351}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B90FF" />
          <stop offset={0.51} stopColor="#0059A3" />
          <stop offset={1} stopColor="#003561" />
        </linearGradient>
        <linearGradient
          id={e}
          x1={106.083}
          y1={31.707}
          x2={115.38}
          y2={104.718}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B90FF" />
          <stop offset={0.51} stopColor="#0059A3" />
          <stop offset={1} stopColor="#003561" />
        </linearGradient>
        <linearGradient
          id={f}
          x1={61.502}
          y1={97.999}
          x2={33.328}
          y2={110.351}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B90FF" />
          <stop offset={0.51} stopColor="#0059A3" />
          <stop offset={1} stopColor="#003561" />
        </linearGradient>
        <linearGradient
          id={g}
          x1={99.563}
          y1={77.875}
          x2={82.533}
          y2={107.739}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B90FF" />
          <stop offset={0.51} stopColor="#0059A3" />
          <stop offset={1} stopColor="#003561" />
        </linearGradient>
      </defs>
    </svg>
  );
}
