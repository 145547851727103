import { Button } from '@daily/shared/components/Button';
import { CaretLeftIcon, CaretRightIcon } from '@daily/shared/components/Icons';
import { Text } from '@daily/shared/components/Text';
import { useTheme } from '@daily/shared/contexts/Theme';
import classnames from 'classnames';
import { robotsClassName } from 'lib/robots';
import { useTranslation } from 'react-i18next';

interface Props {
  disabled?: boolean;
  onClick(): void;
  variant: 'prev' | 'next';
}

export const PaginationButton: React.FC<Props> = ({
  disabled = false,
  onClick,
  variant,
}) => {
  const { t } = useTranslation();
  const { colors, zIndex } = useTheme();

  const Icon = variant === 'prev' ? CaretLeftIcon : CaretRightIcon;
  const label = variant === 'prev' ? t('general.prev') : t('general.next');

  return (
    <div className={classnames('pagination-btn', variant)}>
      <Button
        className={robotsClassName(`btn-pagination-${variant}`)}
        disabled={disabled}
        onClick={onClick}
        variant="secondary"
      >
        <Icon size={24} variant="default" />
        <Text El="span">{label}</Text>
      </Button>
      <style jsx>{`
        .pagination-btn {
          --dur: 150ms;
          --ease: ease-in-out;

          display: flex;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: ${zIndex.tooltip};
        }
        .pagination-btn.prev {
          justify-content: flex-start;
          left: 0;
        }
        .pagination-btn.next {
          justify-content: flex-end;
          right: 0;
        }
        .pagination-btn :global(button) {
          align-items: center;
          background: ${colors.background};
          border: none;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04),
            0px 0px 4px rgba(0, 0, 0, 0.08);
          padding: 0;
          transition: opacity var(--dur) var(--ease),
            padding var(--dur) var(--ease), width var(--dur) var(--ease);
          width: 24px;
        }
        .pagination-btn.prev :global(button) {
          border-radius: 0 4px 4px 0;
          flex-direction: row;
        }
        .pagination-btn.next :global(button) {
          border-radius: 4px 0 0 4px;
          flex-direction: row-reverse;
        }
        .pagination-btn :global(button[disabled]) {
          opacity: 0.2;
        }
        .pagination-btn :global(svg) {
          flex: none;
        }
        .pagination-btn :global(span) {
          flex-shrink: 1;
          min-width: 0;
          opacity: 0;
          overflow: hidden;
          transition: opacity var(--dur) var(--ease);
        }
        :global(.with-keyboard)
          .pagination-btn.prev
          :global(button:not([disabled]):focus),
        .pagination-btn.prev :global(button:not([disabled]):hover) {
          box-shadow: none;
          padding: 4px 8px 4px 0;
          width: 100%;
        }
        :global(.with-keyboard)
          .pagination-btn.next
          :global(button:not([disabled]):focus),
        .pagination-btn.next :global(button:not([disabled]):hover) {
          box-shadow: none;
          padding: 4px 0 4px 8px;
          width: 100%;
        }
        :global(.with-keyboard)
          .pagination-btn
          :global(button:not([disabled]):focus span),
        .pagination-btn :global(button:not([disabled]):hover span) {
          opacity: 1;
        }
      `}</style>
    </div>
  );
};
