import { Button } from '@daily/shared/components/Button';
import { Card, CardContent } from '@daily/shared/components/Card';
import { FocusTrap } from '@daily/shared/components/FocusTrap';
import { CloseIcon } from '@daily/shared/components/Icons';
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@daily/shared/components/Tabs';
import { VisuallyHidden } from '@daily/shared/components/VisuallyHidden';
import { useTheme } from '@daily/shared/contexts/Theme';
import { useMediaQuery } from '@daily/shared/hooks/useMediaQuery';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import tinykeys from 'tinykeys';

import {
  SidebarView,
  useAdminsList,
  useIsMobile,
  useSidebarView,
} from '../../contexts/UIState';
import { useCallConfig } from '../../hooks/useCallConfig';
import { useMountTransition } from '../../hooks/useMountTransition';
import { usePreviousValue } from '../../hooks/usePreviousValue';
import { useStyleVariants } from '../../hooks/useStyleVariants';
import { Chat } from '../Chat';
import { Admins } from './Admins';
import { Network } from './Network';
import { People } from './People';

const TABS_HEIGHT = 48;
const TRANSITION_TIME = 250;

export const Sidebar: React.FC = () => {
  const [admins] = useAdminsList();
  const { fontSizes, mediaQueries } = useTheme();
  const { iconVariant } = useStyleVariants();
  const { t } = useTranslation();
  const [isMobile] = useIsMobile();
  const [sidebarView, setSidebarView] = useSidebarView();
  const { enableNetworkUI, enablePeopleUI } = useCallConfig();
  const router = useRouter();
  const enableChat = router.query['chat'] !== 'false';
  const enableAdminsUI = router.query['enable_admins_ui'] === 'true';
  const sidebarRef = useRef<HTMLDivElement>(null);
  const isMedium = useMediaQuery(mediaQueries.medium);
  const transitionTime = useMemo(
    () => (isMobile && !isMedium ? TRANSITION_TIME : 0),
    [isMedium, isMobile]
  );
  const hasTransitionedIn = useMountTransition(sidebarView, transitionTime);

  /**
   * Holds last known sidebarView. Keeps view intact while transitioning out.
   */
  const previousSidebarView = usePreviousValue(sidebarView);
  const handleClickPeople = useCallback(() => {
    setSidebarView('people');
  }, [setSidebarView]);
  const handleClickChat = useCallback(() => {
    setSidebarView('chat');
  }, [setSidebarView]);
  const handleClickNetwork = useCallback(() => {
    setSidebarView('network');
  }, [setSidebarView]);
  const handleClickAdmins = useCallback(() => {
    setSidebarView('admins');
  }, [setSidebarView]);
  const handleCloseClick = useCallback(() => {
    setSidebarView(null);
  }, [setSidebarView]);

  const indexMap: Record<SidebarView, number> = {
    people: 0,
    chat: 1,
    network: 2,
    admins: 3,
  };

  const prevFocus = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!sidebarView) {
      // restore focus, when sidebar is closed
      prevFocus.current?.focus();
      prevFocus.current = null;
      return;
    }

    const unsubscribe = tinykeys(window, {
      Escape: () => {
        const isMenuOpen =
          sidebarRef.current.querySelectorAll(
            '.menuButton[aria-haspopup="true"][aria-expanded="true"]'
          ).length > 0;
        if (isMenuOpen) return;
        setSidebarView(null);
      },
    });

    if (!prevFocus.current) {
      prevFocus.current = document.activeElement as HTMLElement;
    }

    // focus first sidebar item
    if (!sidebarRef.current.contains(document.activeElement)) {
      (
        sidebarRef.current.querySelector(
          'button:not([disabled]),a,input:not([disabled]),textarea:not([disabled])'
        ) as HTMLElement
      )?.focus();
    }

    return () => {
      unsubscribe();
    };
  }, [setSidebarView, sidebarView]);

  if (!enableChat && !enableNetworkUI && !enablePeopleUI) return null;

  /**
   * To ensure proper transitions when showing / hiding the Sidebar,
   * mounting / unmounting the markup happens in two phases, using the useMountTransition hook:
   *
   * Mounting:
   * 1) sidebarView is set to true
   *    -> Sidebar markup is rendered into the DOM,
   *       the .mounted class is set, but the css transition is not yet triggered.
   * 2) Because sidebarView is changed, the useMountTransition() hook is triggered,
   *    which in turn sets hasTransitionedIn to true.
   *    The .transitioned class is now also set.
   *    Now that both .mounted and .transitioned classes are set, the transition starts.
   *
   * Unmounting:
   * 1) sidebarView is set to false
   *    -> One condition for rendering Sidebar into the DOM is removed, the .mounted class is removed,
   *       but hasTransitionedIn is still true, so it's still available in the DOM!
   *    -> This causes the css transition to revert, because only one of both necessary classes is left.
   * 2) Because sidebarView is changed, the useMountTransition() hook is triggered again
   *    and now sets the hasTransitionedIn flag back to false after a configured delay (to give the reverted transition time to finish).
   *    -> Now finally both conditions for rendering the Sidebar into the DOM are removed,
   *       and with it the markup as well.
   */

  return (
    <div
      ref={sidebarRef}
      className={classnames('sidebar', {
        /**
         * If a user on a tablet uses an actual mouse pointer,
         * (pointer: coarse) could switch to (pointer: fine), which could break some CSS styles.
         * That's why we keep the dependency to 'isMobile' here.
         */
        mobile: isMobile,
        hidden: !sidebarView && !hasTransitionedIn,
      })}
    >
      {(hasTransitionedIn || sidebarView) && (
        <FocusTrap
          active={isMobile && !!sidebarView}
          className={classnames('container', {
            mounted: sidebarView,
            transitioned: hasTransitionedIn,
          })}
        >
          <Tabs
            id="sidebar"
            initialIndex={indexMap[sidebarView ?? previousSidebarView]}
          >
            <Card noBorder style={{ borderRadius: 0 }}>
              <CardContent style={{ padding: '16px 20px 0' }}>
                <TabList style={{ paddingBottom: 16 }} gap={isMobile ? 24 : 16}>
                  {enablePeopleUI && (
                    <Tab className="tab" onClick={handleClickPeople}>
                      {t('general.people')}
                    </Tab>
                  )}
                  {enableChat && (
                    <Tab className="tab" onClick={handleClickChat}>
                      {t('general.chat')}
                    </Tab>
                  )}
                  {enableNetworkUI && (
                    <Tab className="tab" onClick={handleClickNetwork}>
                      {t('general.network')}
                    </Tab>
                  )}
                  {enableAdminsUI && (
                    <Tab className="tab" onClick={handleClickAdmins}>
                      Admins ({admins?.length})
                    </Tab>
                  )}
                </TabList>
                <Button
                  className="close"
                  onClick={handleCloseClick}
                  variant="ghost"
                >
                  <CloseIcon size={iconVariant} />
                  <VisuallyHidden>{t('general.closeSidebar')}</VisuallyHidden>
                </Button>
              </CardContent>
              <CardContent className="sidebarContent" noPadding>
                <TabPanels>
                  {enablePeopleUI && (
                    <TabPanel className="sidebarPanel">
                      <People />
                    </TabPanel>
                  )}
                  {enableChat && (
                    <TabPanel className="sidebarPanel">
                      <Chat />
                    </TabPanel>
                  )}
                  {enableNetworkUI && (
                    <TabPanel className="sidebarPanel">
                      <Network />
                    </TabPanel>
                  )}
                  {enableAdminsUI && (
                    <TabPanel className="sidebarPanel">
                      <Admins />
                    </TabPanel>
                  )}
                </TabPanels>
              </CardContent>
            </Card>
          </Tabs>
        </FocusTrap>
      )}
      <style jsx>{`
        .sidebar {
          border-radius: 8px;
          overflow: hidden;
          height: calc(100vh - var(--header-height) - var(--tray-height));
          isolation: isolate;
          left: 0;
          position: fixed;
          top: var(--header-height);
          width: 100vw;
          z-index: var(--zindex-sidebar);
        }
        .sidebar.mobile {
          border: none;
          bottom: 0;
          left: 0;
          position: fixed;
          height: calc(100vh - 65px);
          top: var(--banner-height, 0);
          width: 100% !important;
          padding-top: 24px;
        }
        .sidebar.hidden {
          /* Keep container in place, to avoid animation glitches. */
          pointer-events: none;
        }
        .sidebar :global(.container) {
          height: 100%;
          width: 100%;
        }
        .sidebar.mobile :global(.container) {
          // transform: translateY(100%);
          // transition: transform ${TRANSITION_TIME}ms linear;
          transition: opacity ${TRANSITION_TIME}ms linear;
          opacity: 0;
        }
        .sidebar.mobile :global(.container.transitioned.mounted) {
          // transform: translateY(0%);
          opacity: 1;
        }
        .sidebar.mobile :global(.close) {
          top: 12px;
          right: 60px;
        }
        .sidebar.mobile :global(.tablist strong) {
          font-size: ${fontSizes.large};
        }
        .sidebar :global(.tab) {
          margin: -8px -8px;
          padding: 8px 8px;
        }
        .sidebar :global(.close) {
          position: absolute;
          right: 16px;
          top: 16px;
        }
        .sidebar :global(.sidebarContent) {
          align-items: stretch;
          display: flex;
          flex-grow: 1;
          height: calc(100% - ${TABS_HEIGHT}px);
          margin-top: 1px;
          overflow-x: hidden;
          overflow-y: auto;
        }
        .sidebar :global(.sidebarPanel) {
          padding: 0;
          width: 100%;
        }
        @media ${mediaQueries.medium} {
          .sidebar:not(.mobile):not(.hidden) {
            // border-left: 1px solid var(--card-border);
            flex: none;
            height: 100%;
            position: static;
            width: 266px;
          }
          .sidebar.hidden {
            width: 0;
          }
        }
        /* Render mobile sidebar only on big enough screens on the side */
        @media ${mediaQueries.large} and (min-height: 500px) {
          .sidebar.mobile:not(.hidden) {
            flex-basis: 300px;
            flex-grow: 0;
            flex-shrink: 0;
            height: calc(100% - var(--banner-height));
            position: relative;
            top: 0;
            width: 300px;
          }
          .sidebar.mobile :global(.container) {
            transform: none;
            transition: none;
          }
        }
      `}</style>
    </div>
  );
};
