import { useLocalParticipant } from '@daily-co/daily-react-hooks';
import React, { useRef } from 'react';

import { useWaitingRoom } from '../../contexts/WaitingRoomProvider';
import { useCallConfig } from '../../hooks/useCallConfig';
import { AudioTracks } from '../Call/AudioTracks';
import { Modals } from '../Call/Modals';
import { PoweredByDailyBanner } from '../PoweredByDailyBanner';
import { Sidebar } from '../Sidebar';
import { WaitingRoomModal, WaitingRoomNotification } from '../WaitingRoom';
import { MobileCall } from './MobileCall';

export const MobileView: React.FC = () => {
  const mobileRef = useRef<HTMLDivElement>(null);
  const { poweredByDaily } = useCallConfig();
  const localParticipant = useLocalParticipant();
  const { setShowModal, showModal } = useWaitingRoom();

  const handleModalClose = () => setShowModal(false);

  return (
    <div ref={mobileRef} className="mobile">
      {poweredByDaily && <PoweredByDailyBanner />}
      <main>
        <MobileCall />
        <Sidebar />
      </main>
      <AudioTracks />
      <Modals />
      {localParticipant?.owner && (
        <>
          <WaitingRoomNotification />
          {showModal && <WaitingRoomModal onClose={handleModalClose} />}
        </>
      )}
      <style jsx>{`
        .mobile {
          display: flex;
          flex-direction: column;
          height: 100%;
          overflow: hidden;
          position: relative;
          width: 100%;
        }
        main {
          display: flex;
          height: 100%;
          position: relative;
          width: 100%;
        }
      `}</style>
    </div>
  );
};
