import { HideUserIcon, ShowUserIcon } from '@daily/shared/components/Icons';
import { Tooltip } from '@daily/shared/components/Tooltip';
import { useIsGridHidden } from 'contexts/UIState';

import { TrayButton } from './TrayButton';

interface Props extends React.ComponentProps<typeof TrayButton> {}

export const HideGridControls: React.FC<Props> = ({ ...props }) => {
  const [isGridHidden, setGridHidden] = useIsGridHidden();
  const handleClick = () => {
    console.log('clicked', isGridHidden);
    setGridHidden((h) => !h);
  };

  return (
    <div className="hide-grid-controls">
      <Tooltip
        id={'tt-hide-grid'}
        tabIndex={-1}
        title={isGridHidden ? 'Show participants' : 'Hide participants'}
      >
        <TrayButton
          onClick={handleClick}
          {...props}
          id={`hide-grid-button-controls`}
        >
          {isGridHidden ? <ShowUserIcon /> : <HideUserIcon />}
        </TrayButton>
      </Tooltip>
    </div>
  );
};
