import { useTheme } from '@daily/shared/contexts/Theme';
import { useDaily, useLocalParticipant } from '@daily-co/daily-react-hooks';
import classnames from 'classnames';
import { PeopleInCall } from 'components/Topbar/PeopleInCall';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useCallState } from '../../contexts/CallProvider';
import { useCallConfig } from '../../hooks/useCallConfig';
import { useSoundLoader } from '../../hooks/useSoundLoader';
import { PoweredByDailyBanner } from '../PoweredByDailyBanner';
import { NameForm } from './NameForm';
import { NewSetup } from './NewSetup';
import { OwnerOnlyBroadcastWaiting } from './OwnerOnlyBroadcastWaiting';

type Step = 'name' | 'setup' | 'owner-only-waiting';
type AccessState = 'none' | 'lobby' | 'full';

export const Haircheck: React.FC = () => {
  const { leaveCall, mode, setAttendeeUIInteraction, setRedirectOnLeaveCall } =
    useCallState();
  const { mediaQueries, zIndex } = useTheme();
  const { broadcast, broadcastRole, poweredByDaily } = useCallConfig();
  const { joinSound, load: loadSounds } = useSoundLoader();
  const daily = useDaily();
  const localParticipant = useLocalParticipant();

  const [step, setStep] = useState<Step>('name');

  const accessState: AccessState = useMemo(() => {
    if (!daily) return null;
    const { access } = daily.accessState();
    if (access === 'unknown' || access.level === 'none') return 'none';
    return access.level;
  }, [daily]);

  const handleCancelJoinRequest = useCallback(() => {
    if (!daily) return;
    setRedirectOnLeaveCall(false);
    if (mode === 'direct-link') {
      leaveCall();
      window.location.reload();
    } else {
      leaveCall();
    }
  }, [daily, leaveCall, mode, setRedirectOnLeaveCall]);

  /**
   * For owner only broadcast meetings, attendees join directly
   * from the name form since there's no device set up to consider.
   */
  const handleOOBAttendeeJoin = useCallback(
    async (name) => {
      if (!daily) return;

      loadSounds();

      await daily.join();

      if (accessState === 'lobby') {
        const { granted } = await daily.requestAccess({
          name,
          access: {
            level: 'full',
          },
        });
        if (granted) {
          joinSound.play();
        } else {
          setRedirectOnLeaveCall(false);
          leaveCall();
        }
      }
    },
    [
      accessState,
      daily,
      joinSound,
      leaveCall,
      loadSounds,
      setRedirectOnLeaveCall,
    ]
  );

  useEffect(() => {
    if (broadcast === null) return;
    /**
     * All participants from non-owner-only-broadcast calls can skip to
     * the set up if their name is already set. In owner-only-broadcast
     * calls, only owners see the set up. Attendees join from the name form.
     */
    if (
      localParticipant?.user_name &&
      (!broadcast || broadcastRole === 'owner')
    ) {
      setStep('setup');
    }
  }, [broadcast, broadcastRole, localParticipant?.user_name]);

  // This used to be invoked even when user name was specified by token. It's
  // important to *not* re-set the user name, otherwise a session-specific
  // token-specified user name will be stored and used in future sessions.
  const handleNameComplete = useCallback(
    (username: string) => {
      daily.setUserName(username);
      if (broadcastRole === 'attendee') {
        setStep('owner-only-waiting');
        handleOOBAttendeeJoin(username);
        // Track the only UI interaction an attendee can have before joining
        setAttendeeUIInteraction(true);
      }
    },
    [broadcastRole, daily, handleOOBAttendeeJoin, setAttendeeUIInteraction]
  );

  return (
    <>
      <div className={classnames('haircheck', { banner: poweredByDaily })}>
        {poweredByDaily && (
          <div className="banner">
            <PoweredByDailyBanner />
          </div>
        )}

        {step === 'name' &&
          (!localParticipant?.user_name || broadcastRole === 'attendee') && (
            <NameForm onComplete={handleNameComplete} />
          )}
        {step === 'setup' && (
          <NewSetup
            accessState={accessState}
            onCancelJoinRequest={handleCancelJoinRequest}
          />
        )}
        {step === 'owner-only-waiting' && (
          <OwnerOnlyBroadcastWaiting
            onCancelJoinRequest={handleCancelJoinRequest}
          />
        )}
        <style jsx>{`
          .haircheck {
            align-items: center;
            display: flex;
            height: 100%;
            overflow: auto;
            padding: 16px;
            width: 100%;
          }
          .haircheck.banner {
            padding-top: 46px;
          }
          @media ${mediaQueries.coarse} {
            .haircheck {
              padding: 32px;
              margin: 0;
              justify-content: center;
              overflow: hidden;
            }
            .haircheck.banner {
              padding-top: 62px;
            }
          }
          @media ${mediaQueries.small} {
            .haircheck {
              padding: 8px;
            }
            .haircheck.banner {
              padding-top: 38px;
            }
          }
          @media ${mediaQueries.nonCoarse} {
            .haircheck > :global(*:not(.banner)) {
              margin: auto;
            }
          }
          .banner {
            flex: none;
            left: 0;
            position: fixed;
            top: 0;
            width: 100vw;
            z-index: ${zIndex.header};
          }
          .haircheck :global(.card-container) {
            width: 460px;
          }
        `}</style>
      </div>
    </>
  );
};
