import { useTheme } from '@daily/shared/contexts/Theme';
import { useResize } from '@daily/shared/hooks/useResize';
import { useLocalParticipant, useNetwork } from '@daily-co/daily-react-hooks';
import classNames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useCallState } from '../../contexts/CallProvider';
import { useParticipants } from '../../contexts/ParticipantsProvider';
import { useStyleVariants } from '../../hooks/useStyleVariants';
import { DEFAULT_LANDSCAPE_AR, DEFAULT_PORTRAIT_AR } from './constants';
import { MobileTile } from './MobileTile';
import { Dimensions } from './types';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  active: boolean;
  index: number;
  isBufferPage?: boolean;
  page: number;
  sessionIds: string[];
}

export const Page: React.FC<Props> = ({
  active,
  className,
  index,
  isBufferPage = false,
  page,
  sessionIds,
  ...props
}) => {
  const { colors } = useTheme();
  const { currentSpeakerId } = useParticipants();
  const { showNames } = useCallState();
  const { mobilePortrait: isPortrait } = useStyleVariants();
  const pageRef = useRef<HTMLDivElement>(null);
  const [pageDimensions, setPageDimensions] = useState<Dimensions>({
    height: 1,
    width: 1,
  });
  const localParticipant = useLocalParticipant();

  const { threshold } = useNetwork();

  useResize(() => {
    setPageDimensions({
      height: pageRef.current?.clientHeight ?? 1,
      width: pageRef.current?.clientWidth - 8 ?? 1,
    });
  }, [active]);

  /**
   * Add a small delay to offload video updates of offscreen pages,
   * to prioritize visible page while user is swiping.
   */
  const [renderedPage, setRenderedPage] = useState(page);
  useEffect(() => {
    if (page === renderedPage) return;
    Array.from(pageRef.current?.querySelectorAll('video')).forEach((video) => {
      video.srcObject = null;
    });
    const timeout = setTimeout(() => {
      setRenderedPage(page);
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [page, renderedPage]);

  const tileStyle = useMemo(() => {
    if (!pageRef.current) return {};
    const { height: maxHeight, width: maxWidth } = pageDimensions;
    if (isPortrait) {
      const maxHeightPerTile = maxHeight / sessionIds.length - 4;
      const ar = maxWidth / maxHeightPerTile;
      if (ar < DEFAULT_LANDSCAPE_AR) {
        return {
          height: maxWidth / DEFAULT_LANDSCAPE_AR,
          width: maxWidth,
        };
      }
      return {
        height: maxHeightPerTile,
        width: maxHeightPerTile * DEFAULT_LANDSCAPE_AR,
      };
    }
    const maxWidthPerTile = maxWidth / sessionIds.length - 4;
    const ar = maxWidthPerTile / maxHeight;
    if (ar < DEFAULT_PORTRAIT_AR) {
      return {
        height: maxWidthPerTile / DEFAULT_PORTRAIT_AR,
        width: maxWidthPerTile,
      };
    }
    return {
      height: maxHeight,
      width: maxHeight * DEFAULT_PORTRAIT_AR,
    };
  }, [isPortrait, pageDimensions, sessionIds.length]);

  return (
    <div
      ref={pageRef}
      className={classNames('page', className, {
        active,
        isBufferPage,
        isPortrait,
      })}
      {...props}
    >
      {sessionIds.map((sessionId, i) => {
        const key = `page${index}slot${i}`;
        return (
          <div key={key} className="tile" style={tileStyle}>
            <MobileTile
              isSpeaking={sessionId === currentSpeakerId}
              network={
                sessionId === localParticipant?.session_id ? threshold : null
              }
              orientation={isPortrait ? 'landscape' : 'portrait'}
              sessionId={sessionId}
              showNames={showNames}
            />
          </div>
        );
      })}
      <style jsx>{`
        .page {
          align-items: center;
          display: flex;
          flex: none;
          flex-direction: row;
          justify-content: center;
          padding: 4px 4px 0;
        }
        .page:not(.isPortrait) .tile:not(:first-child) {
          margin-left: 4px;
        }
        .page.isPortrait {
          flex-direction: column;
        }
        .page.isPortrait .tile:not(:first-child) {
          margin-top: 4px;
        }
        .page.active {
          background: ${colors.custom.mainAreaBg};
          z-index: 5;
        }
        .page.isBufferPage {
          opacity: 0;
          transition-duration: 0ms !important;
          visiblity: hidden;
        }
      `}</style>
    </div>
  );
};
