import { Button } from '@daily/shared/components/Button';
import { Checkbox } from '@daily/shared/components/Checkbox';
import { Modal } from '@daily/shared/components/Modal';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useParticipants } from '../../contexts/ParticipantsProvider';

interface Props extends React.ComponentProps<typeof Modal> {}

export const MuteAllModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { muteAll, muteNewParticipants } = useParticipants();
  const [isChecked, setIsChecked] = useState(muteNewParticipants);

  const handleCheckboxChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(ev.target.checked);
  };
  const handleMuteAll = useCallback(
    (close) => {
      muteAll(isChecked);
      close();
    },
    [isChecked, muteAll]
  );

  return (
    <Modal
      actions={[
        <Button key="cancel" variant="secondary">
          {t('general.cancel')}
        </Button>,
        <Button key="mute" onClick={handleMuteAll} variant="primary">
          {t('people.muteAllParticipants')}
        </Button>,
      ]}
      title={t('people.muteAllModalTitle')}
      {...props}
    >
      <Stack>
        <Text variant="large">{t('people.muteAllModalDesc')}</Text>
        <Checkbox
          id="mute-new-participants"
          label={t('people.muteNewParticipantsCheckboxLabel')}
          name="mute-new-participants"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
      </Stack>
    </Modal>
  );
};
