import { Text } from '@daily/shared/components/Text';
import { DailyParticipant } from '@daily-co/daily-js';
import {
  ExtendedDailyParticipant,
  useLocalParticipant,
  useParticipantIds,
} from '@daily-co/daily-react-hooks';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useVirtual } from 'react-virtual';

import { useCallState } from '../../contexts/CallProvider';
import { useIsMobile } from '../../contexts/UIState';
import { useCallConfig } from '../../hooks/useCallConfig';
import { useParticipantCounts } from '../../hooks/useParticipantCounts';
import { useStyleVariants } from '../../hooks/useStyleVariants';
import { ParticipantRow } from './ParticipantRow';
import { PeopleActions } from './PeopleActions';
import { WaitingList } from './WaitingList';

/**
 * Sort participants alphabetically by user_name. Put the local user first.
 */
const sortParticipantIds = (
  a: ExtendedDailyParticipant,
  b: ExtendedDailyParticipant
) => {
  if (a.local) return -1;
  if (b.local) return 1;
  if (a.user_name.toLowerCase() < b.user_name.toLowerCase()) return -1;
  if (a.user_name.toLowerCase() > b.user_name.toLowerCase()) return 1;
  return 0;
};

export const People: React.FC = () => {
  const { t } = useTranslation();
  const [isMobile] = useIsMobile();
  const { iconVariant, textVariant, textVariantSmall } = useStyleVariants();
  const { broadcast, broadcastRole } = useCallConfig();
  const { disableAudio } = useCallState();
  const localParticipant = useLocalParticipant();

  const peopleRef = useRef<HTMLDivElement>(null);

  const stackGap = isMobile ? 16 : 8;

  const itemHeight = useMemo(() => {
    const fontSize = textVariant === 'base' ? 16 : 24;
    return fontSize + stackGap;
  }, [stackGap, textVariant]);

  const { present } = useParticipantCounts();
  /**
   * Holds ids of presenters, which means participants with presence data.
   * In broadcasts we'll limit presenters to owners.
   */
  const presenterIds = useParticipantIds({
    filter: useCallback(
      (p: DailyParticipant) => (broadcast ? p.owner : true),
      [broadcast]
    ),
    sort: sortParticipantIds,
  });
  /**
   * Holds ids of viewers (which is only a thing in broadcasts).
   * In regular calls this will always be empty.
   * In regular broadcasts this will be participants with presence data,
   * but without owner rights.
   * In broadcasts with enabled passive participants this will be empty,
   * because passive participants don't appear in daily.participants().
   */
  const viewerIds = useParticipantIds({
    filter: useCallback(
      (p: DailyParticipant) => (broadcast ? !p.owner : false),
      [broadcast]
    ),
    sort: sortParticipantIds,
  });

  /**
   * Holds all elements for virtualized scrolling.
   * 'presenters' and 'viewers' are placeholder values
   * for their respective titles.
   */
  const rows = useMemo(() => {
    const rows = ['presenters', ...presenterIds];
    if (viewerIds.length > 0) {
      rows.push('viewers', ...viewerIds);
    }
    return rows;
  }, [presenterIds, viewerIds]);

  const virtualRows = useVirtual({
    size: rows.length,
    parentRef: peopleRef,
    estimateSize: useCallback(
      (index) =>
        rows[index] === 'presenters'
          ? 16
          : rows[index] === 'viewers'
          ? 48 // includes gap to list of presenters
          : itemHeight,
      [itemHeight, rows]
    ),
    overscan: 5,
  });

  useEffect(() => {
    const people = peopleRef.current;
    if (!people) return;
    peopleRef.current.style.setProperty('--gap', `${stackGap}px`);
  }, [stackGap]);

  const presentersTitle = useMemo(
    () => (
      <Text color="muted" variant={textVariantSmall}>
        {broadcast
          ? t('people.presentersWithCount', {
              count: presenterIds.length,
            })
          : t('people.withCount', { count: present })}
      </Text>
    ),
    [broadcast, present, presenterIds.length, t, textVariantSmall]
  );

  const viewersTitle = useMemo(
    () => (
      <Text color="muted" variant={textVariantSmall}>
        {t('people.viewersWithCount', {
          count: viewerIds.length,
        })}
      </Text>
    ),
    [viewerIds.length, t, textVariantSmall]
  );

  return (
    <div ref={peopleRef} className="people">
      <WaitingList />
      <div className="list" style={{ height: `${virtualRows.totalSize}px` }}>
        {virtualRows.virtualItems.map((row) => (
          <div
            key={row.index}
            className={classNames('row', {
              title: ['presenters', 'viewers'].includes(rows[row.index]),
            })}
            style={{
              transform: `translateY(${row.start}px)`,
            }}
          >
            {rows[row.index] === 'presenters' ? (
              presentersTitle
            ) : rows[row.index] === 'viewers' ? (
              viewersTitle
            ) : (
              <ParticipantRow
                iconVariant={iconVariant}
                localParticipant={localParticipant}
                showActions={broadcastRole !== 'attendee'}
                sessionId={rows[row.index]}
                showMicMutedState={
                  !disableAudio && presenterIds.includes(rows[row.index])
                }
                textVariant={textVariant}
              />
            )}
          </div>
        ))}
      </div>
      <PeopleActions />
      <style jsx>{`
        .people {
          height: 100%;
          overflow-x: hidden;
          overflow-y: auto;
          padding: var(--card-spacing);
          position: relative;
        }
        .list {
          display: flex;
          flex-direction: column;
          min-height: calc(100% - 2 * var(--card-spacing));
          position: relative;
        }
        .list .row:first-child {
          padding: 0;
        }
        .list .row {
          padding-top: var(--gap);
          position: absolute;
          width: 100%;
        }
        .list .row + .title {
          padding-top: calc(24px + var(--gap));
        }
      `}</style>
    </div>
  );
};
