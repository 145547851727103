import { WarningIcon } from '@daily/shared/components/Icons';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { useTheme } from '@daily/shared/contexts/Theme';
import { useTranslation } from 'react-i18next';

import { useStyleVariants } from '../../hooks/useStyleVariants';
import { robotsClassName } from '../../lib/robots';
import { CTAButton } from './CTAButton';

export const NotSupportedMessage: React.FC = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const { textVariant } = useStyleVariants();

  return (
    <Stack align="center" gap={16}>
      <Stack align="center">
        <WarningIcon color={colors.system.red} />
        <Text textAlign="center" variant="largestrong">
          {t('haircheck.setup.notSupportedTitle')}
        </Text>
        <Text
          textAlign="center"
          style={{ maxWidth: 464 }}
          variant={textVariant}
        >
          {t('haircheck.setup.notSupportedSubtitle')}
        </Text>
      </Stack>
      <CTAButton className={robotsClassName('btn-join')}>
        <Text color="muted" underline variant={textVariant}>
          {t('haircheck.setup.joinAnyway')}
        </Text>
      </CTAButton>
    </Stack>
  );
};
