import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { useTheme } from '@daily/shared/contexts/Theme';
import { useLocalParticipant, useRecording } from '@daily-co/daily-react-hooks';
import classnames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsMobile, useRecordingUI } from '../../contexts/UIState';

const bannerVariants = {
  hidden: {
    opacity: 0,
    y: '-100%',
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const redVariants = {
  starting: {
    height: '100%',
    left: 0,
    top: 0,
    width: '100%',
  },
  recording: {
    height: 8,
    left: 8,
    top: 8,
    width: 8,
  },
};

export const RecordingState: React.FC = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const {
    isLocalParticipantRecorded,
    isRecording,
    layout,
    local,
    recordingStartedDate,
    startedBy,
    type,
  } = useRecording();
  const [recordingUIState] = useRecordingUI();
  const [now, setNow] = useState<Date>(null);
  const [isMobile] = useIsMobile();
  const localParticipant = useLocalParticipant();

  const time = useMemo(() => {
    if (!recordingStartedDate || !now) return null;
    const diff = now.getTime() - recordingStartedDate.getTime();
    if (diff < 0) return null;
    const progress = new Date(diff);
    const hours = progress.getUTCHours();
    const minutes = progress.getUTCMinutes();
    const seconds = progress.getUTCSeconds();

    const withLeadingZero = (n: number) => n.toString().padStart(2, '0');

    return [
      hours > 0 ? hours : null,
      hours > 0 ? withLeadingZero(minutes) : minutes,
      withLeadingZero(seconds),
    ]
      .filter((n) => n !== null)
      .join(':');
  }, [now, recordingStartedDate]);

  useEffect(() => {
    // Only show recording time for initiator or recorded single participant
    if (
      !isRecording ||
      (type !== 'local' &&
        layout?.preset === 'single-participant' &&
        layout?.session_id !== localParticipant?.session_id) ||
      (type !== 'local' &&
        layout?.preset !== 'single-participant' &&
        startedBy !== localParticipant?.session_id) ||
      (type === 'local' && !local)
    ) {
      setNow(null);
      return;
    }
    const newNow = () => {
      setNow(new Date());
    };
    newNow();
    const interval = setInterval(newNow, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [
    isLocalParticipantRecorded,
    isRecording,
    layout,
    local,
    localParticipant?.session_id,
    startedBy,
    type,
  ]);

  const isStarting = ['starting3', 'starting2', 'starting1'].includes(
    recordingUIState
  );

  return (
    <div className="recordingState">
      <AnimatePresence>
        {(recordingUIState || isLocalParticipantRecorded) && (
          <motion.div
            className={classnames('banner', {
              recording: isRecording,
              starting: isStarting,
            })}
            variants={bannerVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            transition={{ duration: 0.25, ease: 'easeInOut' }}
          >
            {(isStarting || isRecording) && (
              // Transitions from red background in starting to red dot in recording
              <motion.span
                className="red-bg"
                variants={redVariants}
                initial="starting"
                animate={isStarting ? 'starting' : 'recording'}
                transition={{
                  duration: 0.2,
                  ease: 'easeInOut',
                }}
              />
            )}
            {isRecording ? (
              <div className="recording-text">
                <span className="indicator" role="presentation" />
                <Stack align="center" gap={4} horizontal>
                  <Text variant="strong" color={isMobile ? 'white' : 'default'}>
                    {t('recording.status.recording')}
                  </Text>
                  {time && (
                    <Text
                      className="time"
                      color={isMobile ? 'white' : 'default'}
                    >
                      {time}
                    </Text>
                  )}
                </Stack>
              </div>
            ) : recordingUIState === 'saved' ? (
              <Text>{t('recording.status.saved')}</Text>
            ) : recordingUIState?.startsWith('starting') ? (
              // 'starting1' | 'starting2' | 'starting3'
              <div className="starting-text">
                <span className="indicator" role="presentation" />
                <Stack align="center" gap={4} horizontal>
                  <Text color="white">
                    {t('recording.status.starting', {
                      sec: recordingUIState.slice(-1),
                    })}
                  </Text>
                </Stack>
              </div>
            ) : null}
          </motion.div>
        )}
      </AnimatePresence>
      <style jsx>{`
        .recordingState :global(.banner) {
          background: transparent;
          border-radius: 4px;
          padding: 3px 8px 5px;
        }
        .recordingState :global(.banner.starting) .indicator {
          background: ${colors.system.white};
        }
        .recordingState :global(.banner.recording) .indicator {
          opacity: 0;
        }
        .recordingState .recording-text,
        .recordingState .starting-text {
          align-items: center;
          display: flex;
          position: relative;
          z-index: 1;
        }
        .recordingState :global(.red-bg) {
          background: ${colors.system.red};
          border-radius: 4px;
          position: absolute;
          z-index: 0;
        }
        .recordingState .indicator {
          background: ${colors.system.red};
          border-radius: 4px;
          display: block;
          flex: none;
          height: 8px;
          margin-right: 4px;
          width: 8px;
        }
      `}</style>
    </div>
  );
};
