import { SnackbarProvider } from '@daily/shared/contexts/Snackbar';
import { safeStorage } from '@daily/shared/lib/safeStorage';
import { IframeListener } from 'components/App/IframeListener';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RecoilRoot } from 'recoil';

import { App } from '../components/App';
import { FinalMessage } from '../components/App/FinalMessage';
import { Layout } from '../components/App/Layout';
import { LoadingScreen } from '../components/App/LoadingScreen';
import { CallProvider } from '../contexts/CallProvider';
import { ChatProvider } from '../contexts/ChatProvider';
import { IframeDriverProvider } from '../contexts/IframeDriverProvider';
import { MediaDeviceProvider } from '../contexts/MediaDeviceProvider';
import { ParticipantsProvider } from '../contexts/ParticipantsProvider';
import { RecordingProvider } from '../contexts/RecordingProvider';
import { TracksProvider } from '../contexts/TracksProvider';
import { getQueryParam } from '../lib/query';

export default function CallUI({ musicMode = false }) {
  const { t } = useTranslation();
  const { query, replace } = useRouter();
  const [domain, setDomain] = useState(null);
  const [isDomainSet, setIsDomainSet] = useState(true);
  const [customHost, setCustomHost] = useState(null);
  const [bypassRegionDetection, setBypassRegionDetection] = useState(false);
  const [roomsCheckOrigin, setRoomsCheckOrigin] = useState<string>(null);
  const [apiHost, setApiHost] = useState<string>(null);
  const [room, setRoom] = useState(null);
  const [token, setToken] = useState('');
  const [frameId, setFrameId] = useState('');

  /**
   * Read all required query params, once query is ready,
   * making sure they'll never be removed from state,
   * when they're removed from the URL (like token).
   */
  useEffect(() => {
    if (Object.keys(query).length === 0) return;
    if (query.debug) {
      console.log({
        app_name: 'prebuilt',
        git_hash: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
        git_ref: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF,
        environment_name:
          process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT ??
          (location.hostname.includes('localhost') ||
          location.hostname.includes('khk-local.wss.daily.co')
            ? 'local-development'
            : process.env.NODE_ENV),
      });
    }
    if (query.name) {
      // HACK: Set PLUOT_PARTICIPANT_NAME in localStorage directly,
      // so daily-js will pick that up immediately.
      // This has the advantage of not having to wait until daily-js is loaded.
      const safeLocalStorage = safeStorage(() => localStorage);
      safeLocalStorage.setItem(
        'PLUOT_PARTICIPANT_NAME',
        getQueryParam('name', query)
      );
    }
    if (query.customHost) setCustomHost(getQueryParam('customHost', query));
    if (query.room) setRoom(getQueryParam('room', query));
    if (query.bypassRegionDetection)
      setBypassRegionDetection(
        getQueryParam('bypassRegionDetection', query) === 'true'
      );
    if (query.roomsCheckOrigin)
      setRoomsCheckOrigin(getQueryParam('roomsCheckOrigin', query));
    if (query.apiHost) setApiHost(getQueryParam('apiHost', query));
    if (query.t) {
      setToken(getQueryParam('t', query));
    }
    if (query.emb) {
      setFrameId(getQueryParam('emb', query));
    }
    if (query.domain) {
      setDomain(getQueryParam('domain', query));
      setIsDomainSet(true);
      return;
    }
    // Find domain via location.hostname
    if (location.hostname.endsWith('.daily.co')) {
      setDomain(location.hostname.replace('.daily.co', ''));
      setIsDomainSet(true);
      return;
    }
    setIsDomainSet(false);
  }, [query]);

  /**
   * Once we got the token, remove it from the URL.
   */
  useEffect(() => {
    if (!token || !query.t) return;
    const { t, ...newQuery } = query;
    replace(
      {
        query: newQuery,
      },
      undefined,
      { shallow: true }
    );
  }, [query, replace, token]);

  const isEmbedded = useMemo(() => !!frameId, [frameId]);

  if (!isDomainSet) {
    return (
      <RecoilRoot>
        <Layout>
          <FinalMessage
            title={t('lobby.notFound.title')}
            desc={t('lobby.notFound.desc')}
            variant="error"
          />
        </Layout>
      </RecoilRoot>
    );
  }

  if (!domain) {
    return (
      <RecoilRoot>
        <LoadingScreen />
      </RecoilRoot>
    );
  }

  return (
    <RecoilRoot>
      <SnackbarProvider>
        <CallProvider
          domain={domain}
          room={room}
          customHost={customHost}
          token={token}
          isEmbedded={isEmbedded}
          bypassRegionDetection={bypassRegionDetection}
          roomsCheckOrigin={roomsCheckOrigin}
          apiHost={apiHost}
          musicMode={musicMode}
        >
          <ParticipantsProvider>
            <TracksProvider>
              <IframeDriverProvider frameId={frameId}>
                <MediaDeviceProvider>
                  <RecordingProvider>
                    <ChatProvider>
                      <IframeListener />
                      <App />
                    </ChatProvider>
                  </RecordingProvider>
                </MediaDeviceProvider>
              </IframeDriverProvider>
            </TracksProvider>
          </ParticipantsProvider>
        </CallProvider>
      </SnackbarProvider>
    </RecoilRoot>
  );
}
