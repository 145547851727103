import { Button } from '@daily/shared/components/Button';
import { Stack, StackItem } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { DailyWaitingParticipant } from '@daily-co/daily-js';
import { useWaitingParticipants } from '@daily-co/daily-react-hooks';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from '../../contexts/UIState';
import { useStyleVariants } from '../../hooks/useStyleVariants';
import { robotsClassName } from '../../lib/robots';

interface Props {
  participant: DailyWaitingParticipant;
}

export const WaitingParticipantRow: React.FC<Props> = ({ participant }) => {
  const { t } = useTranslation();
  const { denyAccess, grantAccess } = useWaitingParticipants();
  const { textVariant, textVariantStrong } = useStyleVariants();
  const [isMobile] = useIsMobile();

  const handleAllowClick = () => {
    grantAccess(participant.id);
  };
  const handleDenyClick = () => {
    denyAccess(participant.id);
  };

  return (
    <Stack align="center" justify="space-between" horizontal>
      <Text truncate variant={textVariant}>
        {participant.name}
      </Text>
      <StackItem style={{ flex: 'none' }}>
        <Stack align="center" gap={isMobile ? 16 : 4} horizontal>
          <Button
            className={classnames(
              robotsClassName('btn-allow'),
              robotsClassName(participant.id)
            )}
            onClick={handleAllowClick}
            size="small"
            variant="primary"
          >
            <Text El="span" variant={textVariantStrong} color="inherit">
              {t('waitingRoom.allow')}
            </Text>
          </Button>
          <Button
            className={classnames(
              robotsClassName('btn-deny'),
              robotsClassName(participant.id)
            )}
            onClick={handleDenyClick}
            size="small"
            variant="secondary"
          >
            <Text El="span" variant={textVariantStrong}>
              {t('waitingRoom.deny')}
            </Text>
          </Button>
        </Stack>
      </StackItem>
    </Stack>
  );
};
