import React, { useEffect, useState } from 'react';

/**
 * Takes a ref to the scrolling element in ParticipantBar.
 * Observes DOM changes and returns true, if a TileActions menu is opened.
 * @returns boolean
 */
export const useBlockScrolling = (
  scrollRef: React.MutableRefObject<HTMLElement>
) => {
  const [blockScrolling, setBlockScrolling] = useState(false);

  useEffect(() => {
    const scrollEl = scrollRef.current;
    if (!scrollEl || typeof MutationObserver === 'undefined') return;

    const observer = new MutationObserver(() => {
      if (!scrollEl) return;
      setBlockScrolling(!!scrollEl.querySelector('.tile-actions.showMenu'));
    });

    observer.observe(scrollEl, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [scrollRef]);

  return blockScrolling;
};
