import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Portal } from 'react-portal';

import { useTheme } from '../../contexts/Theme';
import { useResize } from '../../hooks/useResize';
import { Text } from '../Text';

interface Props {
  delay?: number;
  disabled?: boolean;
  disableListeners?: boolean;
  id: string;
  open?: boolean;
  placement?: 'top' | 'bottom';
  toggleStyle?: React.CSSProperties;
  tabIndex?: number;
  title: string;
}

type TooltipAlign = 'center' | 'left' | 'right';
interface TooltipPosition {
  left?: number;
  top?: number;
}

const ALIGN_OFFSET = 8;
const SPACING = 8;

export const Tooltip: React.FC<Props> = ({
  children,
  delay = 0,
  disabled = false,
  disableListeners = false,
  id,
  open = false,
  placement = 'top',
  tabIndex = 0,
  title,
  toggleStyle = null,
}) => {
  const { zIndex } = useTheme();
  const [isOpen, setIsOpen] = useState(open);
  const toggleRef = useRef<HTMLSpanElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [align, setAlign] = useState<TooltipAlign>('center');
  const [position, setPosition] = useState<TooltipPosition>(null);
  const [wrap, setWrap] = useState(false);

  const handleClick = () => setIsOpen(true);
  const handleFocus = () => {
    if (document.body.classList.contains('with-mouse')) return;
    setIsOpen(true);
  };
  const handleKeyDown = (ev: React.KeyboardEvent) => {
    if (ev.key !== 'Enter') return;
    setIsOpen(true);
  };
  const handleMouseEnter = () => setIsOpen(true);
  const handleMouseLeave = () => setIsOpen(false);

  const placeBelow = placement === 'bottom';

  /**
   * Reset isOpen state to open, unless tooltip is disabled.
   */
  useEffect(() => {
    setIsOpen(open && !disabled);
  }, [disabled, open]);

  const realign = () => {
    if (!isOpen || disabled) {
      setPosition(null);
      return;
    }
    if (!toggleRef.current || !tooltipRef.current) return;
    const toggleRect = toggleRef.current.getBoundingClientRect();
    const tooltipRect = tooltipRef.current.getBoundingClientRect();
    const main = document.querySelector('main');
    const mainRect = main?.getBoundingClientRect();
    const center = toggleRect.left + toggleRect.width / 2;
    const leftEdge = center - tooltipRect.width / 2;
    const rightEdge = center + tooltipRect.width / 2;
    let top = window.scrollY + toggleRect.top - tooltipRect.height - SPACING;

    if (placeBelow) {
      top = window.scrollY + toggleRect.bottom + SPACING;
    }

    if (
      rightEdge >
      (mainRect?.left || 0) + (mainRect?.width || window.innerWidth)
    ) {
      setAlign('right');
      setPosition({
        left: toggleRect.right + ALIGN_OFFSET - tooltipRect.width,
        top,
      });
    } else if (leftEdge < (mainRect?.left || 0)) {
      setAlign('left');
      setPosition({
        left: toggleRect.left - ALIGN_OFFSET,
        top,
      });
    } else {
      setAlign('center');
      setPosition({
        left:
          toggleRect.left +
          (toggleRect.right - toggleRect.left) / 2 -
          tooltipRect.width / 2,
        top,
      });
    }
    setWrap(tooltipRef.current.scrollWidth > tooltipRef.current.clientWidth);
  };

  useResize(() => {
    realign();
  }, [isOpen, open, title, toggleStyle]);

  if (disabled) {
    return <>{children}</>;
  }

  return (
    <span
      ref={toggleRef}
      aria-describedby={id}
      onBlur={!disableListeners ? handleMouseLeave : null}
      onClick={!disableListeners ? handleClick : null}
      onFocus={!disableListeners ? handleFocus : null}
      onKeyDown={!disableListeners ? handleKeyDown : null}
      onMouseEnter={!disableListeners ? handleMouseEnter : null}
      onMouseLeave={!disableListeners ? handleMouseLeave : null}
      tabIndex={tabIndex}
      style={toggleStyle}
    >
      <Portal>
        <div
          ref={tooltipRef}
          className={classnames('tooltip', align, {
            open: isOpen,
            placeBelow,
            wrap,
          })}
          id={id}
          role="tooltip"
          aria-hidden={!open}
          style={
            {
              ...position,
              '--toggle-width': `${toggleRef.current?.clientWidth}px`,
            } as React.CSSProperties
          }
        >
          <Text color="inherit">{title}</Text>
        </div>
      </Portal>
      {children}
      <style jsx>{`
        .tooltip.open {
          transition-delay: ${delay}ms;
        }
      `}</style>
      <style jsx>{`
        span {
          border-radius: 2px;
          box-shadow: 0 0 0 0 var(--focus-shadow);
          display: block;
          outline: none;
          position: relative;
          transition: box-shadow 200ms ease;
        }
        span:not([tabindex='-1']):focus-within {
          box-shadow: 0 0 0 2px var(--focus-shadow);
        }
        .tooltip {
          background-color: var(--tooltip-bg);
          border-radius: 8px;
          box-shadow: var(--tooltip-shadow);
          color: var(--tooltip-color);
          max-width: 60vw;
          opacity: 0;
          padding: 7px 8px 9px;
          pointer-events: none;
          position: absolute;
          text-align: center;
          top: -99em;
          transition: opacity 200ms ease;
          z-index: ${zIndex.tooltip};
        }
        .tooltip::after {
          border: 5px solid transparent;
          border-top-color: var(--tooltip-bg);
          content: '';
          height: 0;
          position: absolute;
          top: 100%;
          transform: scaleY(0.8);
          transform-origin: top center;
          width: 0;
        }
        .tooltip.placeBelow::after {
          top: 0;
          transform: scaleY(-0.8);
        }
        .tooltip.open {
          opacity: 1;
        }
        .tooltip.wrap {
          white-space: normal;
          width: 60vw;
        }
        .tooltip.center::after {
          left: calc(50% - 5px);
        }
        .tooltip.left::after {
          left: calc(${ALIGN_OFFSET}px + calc(var(--toggle-width) / 2) - 5px);
        }
        .tooltip.right::after {
          right: calc(${ALIGN_OFFSET}px + calc(var(--toggle-width) / 2) - 5px);
        }
      `}</style>
    </span>
  );
};
