import { SVGProps } from 'react';

export function TutorIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" {...props}>
      <path
        fill="#A1D38A"
        fillRule="evenodd"
        d="M5.333.672A5.333 5.333 0 0 0 0 6.005v21.333a5.333 5.333 0 0 0 5.333 5.334h21.334A5.333 5.333 0 0 0 32 27.339V6.005A5.333 5.333 0 0 0 26.667.672H5.333Zm3.769 20.742 6.875 3.735 6.898-3.735v-5.5l1.81-.894v6.417h1.902V13.92l-10.61-5.752L5.412 13.92l3.69 1.994v5.5Zm13.452-7.494-6.577 3.598-6.532-3.598 6.532-3.53 6.577 3.53Zm-1.604 6.325-4.973 2.75-4.973-2.75v-3.254l4.973 2.681 4.973-2.635v3.208Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
