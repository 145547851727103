import { Text } from '@daily/shared/components/Text';
import { useMediaQuery } from '@daily/shared/hooks/useMediaQuery';
import React, { useMemo } from 'react';

import { useIsMobile } from '../contexts/UIState';

type TextVariant = React.ComponentProps<typeof Text>['variant'];

export const useStyleVariants = () => {
  const [isMobile] = useIsMobile();

  const iconVariant: number = useMemo(() => (isMobile ? 24 : 16), [isMobile]);

  const isPortrait = useMediaQuery('(orientation: portrait)');

  const mobileLandscape = useMemo(
    () => isMobile && !isPortrait,
    [isMobile, isPortrait]
  );

  const mobilePortrait = useMemo(
    () => isMobile && isPortrait,
    [isMobile, isPortrait]
  );

  const textVariant: TextVariant = useMemo(
    () => (isMobile ? 'large' : 'base'),
    [isMobile]
  );

  const textVariantSmall: TextVariant = useMemo(
    () => (isMobile ? 'base' : 'small'),
    [isMobile]
  );

  const textVariantStrong: TextVariant = useMemo(
    () => (isMobile ? 'largestrong' : 'strong'),
    [isMobile]
  );

  return {
    isPortrait,
    mobileLandscape,
    mobilePortrait,
    textVariant,
    textVariantSmall,
    textVariantStrong,
    iconVariant,
  };
};
