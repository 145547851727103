import { Text } from '@daily/shared/components/Text';
import classnames from 'classnames';
import React, { useEffect, useRef } from 'react';

interface Props {
  disabled?: boolean;
  offText: string;
  on?: boolean;
  onText: string;
}

export const ToggleText: React.FC<Props> = ({
  disabled = false,
  offText,
  on = false,
  onText,
}) => {
  const onRef = useRef<HTMLElement>(null);
  const offRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!onRef.current || !offRef.current) return;

    offRef.current?.classList.remove('short');
    onRef.current?.classList.remove('short');

    const { width: onWidth } =
      onRef.current?.firstElementChild.getBoundingClientRect();
    const { width: offWidth } =
      offRef.current?.firstElementChild.getBoundingClientRect();

    if (onWidth < offWidth) {
      onRef.current?.classList.add('short');
    } else {
      offRef.current?.classList.add('short');
    }
  }, [onRef, offRef, onText, offText, on]);

  const color = disabled ? 'muted' : 'default';

  return (
    <div className={classnames({ on })}>
      <span ref={onRef} className="onText">
        <Text El="span" color={color}>
          {onText}
        </Text>
      </span>
      <span ref={offRef} className="offText">
        <Text El="span" color={color}>
          {offText}
        </Text>
      </span>
      <style jsx>{`
        div {
          position: relative;
          white-space: nowrap;
        }
        div .onText {
          visibility: hidden;
        }
        div.on .offText {
          visibility: hidden;
        }
        div.on .onText {
          visibility: visible;
        }
        div .short {
          left: 50%;
          position: absolute;
          top: 0;
          transform: translateX(-50%);
        }
      `}</style>
    </div>
  );
};
