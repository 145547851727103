import classnames from 'classnames';
import React from 'react';

import { Button } from '../Button';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { ButtonProps } from '../Button/Button';

interface Props extends ButtonProps {
  'aria-controls': string;
  id: string;
  open: boolean;
}

export const MenuButton: React.FC<Props> = ({
  children,
  className,
  id,
  onClick,
  open,
  ...props
}) => {
  const handleClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    /**
     * Timeout prevents this click to be handled by useClosable,
     * which would close the Menu straight away.
     */
    setTimeout(() => {
      onClick?.(ev);
    }, 10);
  };

  return (
    <Button
      className={classnames('menuButton', className)}
      onClick={handleClick}
      {...props}
      aria-haspopup="true"
      aria-expanded={open}
      focusable={false}
      id={id}
    >
      {children}
      <style jsx>{`
        :global(.menuButton) {
          transition: box-shadow 200ms ease;
        }
        :global(.with-keyboard .menuButton:focus) {
          box-shadow: 0 0 0 2px var(--focus-shadow);
          border-radius: 4px;
        }
      `}</style>
    </Button>
  );
};
