import { useResize } from '@daily/shared/hooks/useResize';
import deepEqual from 'fast-deep-equal';
import { useRouter } from 'next/router';
import React, { useMemo, useRef, useState } from 'react';

import { useCallState } from '../../contexts/CallProvider';
import { useCustomTrayButtons } from '../../contexts/UIState';
import { useCallConfig } from '../../hooks/useCallConfig';
import { AudioControls } from './AudioControls';
import { ChatControls } from './ChatControls';
import { CustomButton } from './CustomButton';
import { HideGridControls } from './HideGridControls';
import { LeaveButton } from './LeaveButton';
import { MoreControls } from './MoreControls';
import { NetworkControls } from './NetworkControls';
import { PeopleControls } from './PeopleControls';
import { RecordControls } from './RecordControls';
import { ReportUserControls } from './ReportUserControls';
import { ScreenShareControls } from './ScreenShareControls';
import { VideoControls } from './VideoControls';

export type Control =
  | 'people'
  | 'chat'
  | 'screenshare'
  | 'record'
  | 'network'
  | string;

type ControlWidths = Partial<{
  [K in Control]: number;
}>;

export const Tray: React.FC = () => {
  const [customTrayButtons] = useCustomTrayButtons();
  const { disableAudio, showLeaveButton } = useCallState();
  const {
    broadcastRole,
    // enableChat,
    enableNetworkUI,
    enablePeopleUI,
    enableRecording,
    enableRecordingUI,
    // enableScreenShare,
  } = useCallConfig();

  const router = useRouter();
  const enableChat = router.query['chat'] !== 'false';
  const enableScreenShare = router.query['screenShare'] !== 'false';
  const isAttendee = useMemo(
    () => broadcastRole === 'attendee',
    [broadcastRole]
  );

  const controlsRef = useRef<HTMLDivElement>(null);
  const defaultControls = useMemo(() => {
    const controls: Control[] = [];
    if (enablePeopleUI) controls.push('people');
    if (enableChat) controls.push('chat');
    if (enableScreenShare) controls.push('screenshare');
    if (enableRecording && enableRecordingUI) controls.push('record');
    if (enableNetworkUI) controls.push('network');
    if (customTrayButtons) controls.push(...Object.keys(customTrayButtons));
    return controls;
  }, [
    customTrayButtons,
    enableChat,
    enableNetworkUI,
    enablePeopleUI,
    enableRecording,
    enableRecordingUI,
    enableScreenShare,
  ]);
  const [visibleControls, setVisibleControls] =
    useState<Control[]>(defaultControls);
  const [menuControls, setMenuControls] = useState<Control[]>([]);

  useResize(() => {
    if (!controlsRef.current) return;

    document.body.style.setProperty(
      '--tray-height',
      `${controlsRef.current.closest('.tray').clientHeight}px`
    );

    const { clientWidth } = controlsRef.current;

    const controlWidths: ControlWidths = defaultControls.reduce(
      (widths, control) => {
        widths[control] = document.getElementById(
          `${control}-controls`
        )?.clientWidth;
        return widths;
      },
      {}
    );

    let availableWidth = clientWidth;
    const visControls = [];
    const menControls = [];
    for (const [control, width] of Object.entries(controlWidths)) {
      if (availableWidth >= width) {
        availableWidth -= width;
        visControls.push(control);
      } else {
        availableWidth = 0;
        menControls.push(control);
      }
    }

    // Don't update visibleControls, if nothing has changed.
    // Avoids infinite state update loop.
    if (deepEqual(visibleControls, visControls)) return;

    setMenuControls(menControls);
    setVisibleControls(visControls);
  }, [controlsRef, defaultControls, visibleControls]);

  return (
    <div className="tray">
      <div ref={controlsRef} className="controls">
        {!isAttendee && <VideoControls />}
        {!disableAudio && <AudioControls />}
        <ScreenShareControls
          id="screenshare-controls"
          visible={visibleControls.includes('screenshare')}
        />
        <HideGridControls />
        <PeopleControls
          id="people-controls"
          visible={visibleControls.includes('people')}
        />
        <ChatControls
          id="chat-controls"
          visible={visibleControls.includes('chat')}
        />
        <RecordControls
          id="record-controls"
          visible={visibleControls.includes('record')}
        />
        <NetworkControls
          id="network-controls"
          visible={visibleControls.includes('network')}
        />
        {Object.entries(customTrayButtons ?? {}).map(([id, btn]) => (
          <CustomButton
            btn={btn}
            id={id}
            key={id}
            visible={visibleControls.includes(id)}
          />
        ))}
        <MoreControls controls={menuControls} />
        {/* <ReportUserControls /> */}
        {showLeaveButton && <LeaveButton />}
      </div>
      <style jsx>{`
        .tray {
          align-items: center;
          background: transparent;
          // background: var(--card-bg);
          // border-top: 1px solid var(--card-border);
          bottom: 0;
          display: flex;
          justify-content: space-between;
          left: 0;
          padding: 8px 4px;
          position: fixed;
          width: 100vw;
          z-index: var(--zindex-tray);
        }
        .tray .controls {
          display: flex;
          align-items: center;
          flex: 1 1 auto;
          flex-direction: row;
          justify-content: center;
          min-width: 0;
        }
        .tray .controls :global(button) {
          margin-left: 2px;
          margin-right: 2px;
        }
      `}</style>
    </div>
  );
};
