import { Button } from '@daily/shared/components/Button';
import { PictureInPictureIcon } from '@daily/shared/components/Icons';
import { Tooltip } from '@daily/shared/components/Tooltip';
import { useTheme } from '@daily/shared/contexts/Theme';
import { captureException } from '@sentry/browser';
import classnames from 'classnames';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { robotsClassName } from '../../lib/robots';
import { PictureInPicture } from '../PictureInPicture';

export const PictureInPictureButton: React.FC = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const [isPIP, setIsPIP] = useState(false);
  const pipRef = useRef<HTMLVideoElement>(null);

  const handleClick = useCallback(() => {
    // TODO: Figure out why pictureInPicture types are missing in `next build`
    // @ts-ignore
    if (isPIP && document.pictureInPictureElement) {
      document
        // @ts-ignore
        .exitPictureInPicture()
        .catch((e) => {
          console.error(e);
          captureException(e);
        })
        .finally(() => {
          setIsPIP(false);
        });
    }
    // @ts-ignore
    if (!isPIP && !document.pictureInPictureElement) {
      pipRef.current
        // @ts-ignore
        .requestPictureInPicture()
        .catch((e) => {
          console.error(e);
          captureException(e);
          setIsPIP(false);
        })
        .then(() => {
          setIsPIP(true);
        });
    }
  }, [isPIP]);

  const handleLeavePIP = () => {
    setIsPIP(false);
  };

  return (
    <div className="pip">
      <Tooltip
        id="tt-pip"
        title={isPIP ? t('pip.stop') : t('pip.start')}
        placement="bottom"
        tabIndex={-1}
      >
        <Button
          className={classnames('btn', {
            active: isPIP,
            [robotsClassName('btn-speaker-enter-pip')]: !isPIP,
            [robotsClassName('btn-speaker-leave-pip')]: isPIP,
          })}
          onClick={handleClick}
          variant="ghost"
        >
          <PictureInPictureIcon
            color={isPIP ? colors.accentText : colors.baseText}
            variant={isPIP ? 'leave' : 'enter'}
          />
        </Button>
      </Tooltip>
      <PictureInPicture
        ref={pipRef}
        enabled={isPIP}
        onLeavePIP={handleLeavePIP}
      />
      <style jsx>{`
        .pip {
          display: flex;
        }
        .pip::before {
          background: var(--card-border);
          content: '';
          height: 16px;
          margin: auto 8px;
          width: 1px;
        }
        .pip :global(.btn) {
          background-color: ${colors.backgroundAccent};
          border-radius: 4px !important;
        }
        .pip :global(.btn.active) {
          background-color: ${colors.accent};
        }
      `}</style>
    </div>
  );
};
