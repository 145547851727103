import { useUniqueId } from 'hooks/useUniqueId';
import { SVGProps } from 'react';

export function ModeratorIcon(props: SVGProps<SVGSVGElement>) {
  const idA = useUniqueId();
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" {...props}>
      <g clipPath={`url(#${idA})`}>
        <path
          fill="#FF6F61"
          d="m14.26 21.576 9.01-9.01-1.66-1.62-7.23 7.231-3.992-3.99-1.738 1.738 5.61 5.65ZM16 32.44c-3.689-.922-6.718-3.062-9.089-6.42-2.37-3.359-3.556-7.04-3.556-11.044V5.573L16 .832l12.644 4.741v9.404c0 4.004-1.186 7.685-3.556 11.043-2.371 3.359-5.4 5.5-9.088 6.421Z"
        />
      </g>
      <defs>
        <clipPath id={idA}>
          <path fill="#fff" d="M0 .672h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
