import { ScreenshareIcon } from '@daily/shared/components/Icons';
import { Tooltip } from '@daily/shared/components/Tooltip';
import { useSnackbar } from '@daily/shared/contexts/Snackbar';
import { useTheme } from '@daily/shared/contexts/Theme';
import { useMediaQuery } from '@daily/shared/hooks/useMediaQuery';
import { useAppMessage, useScreenShare } from '@daily-co/daily-react-hooks';
import classnames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCallConfig } from '../../hooks/useCallConfig';
import { robotsClassName } from '../../lib/robots';
import { TrayButton } from './TrayButton';

const MAX_SCREEN_SHARES = 2;

interface Props extends React.ComponentProps<typeof TrayButton> {}

export const ScreenShareControls: React.FC<Props> = ({
  children,
  className,
  onClick,
  ...props
}) => {
  const { t } = useTranslation();
  const { colors, mediaQueries } = useTheme();
  const { enableScreenShare } = useCallConfig();
  const { isSharingScreen, screens } = useScreenShare();
  const { addMessage } = useSnackbar();

  const isDesktop = useMediaQuery(mediaQueries.large);

  const { startScreenShare, stopScreenShare } = useScreenShare();

  const toggleSharingScreen = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      isSharingScreen ? stopScreenShare() : startScreenShare();
      onClick?.(ev);
    },
    [isSharingScreen, onClick, startScreenShare, stopScreenShare]
  );

  // const disabled = screens.length >= MAX_SCREEN_SHARES && !isSharingScreen;
  // Remove screenshare limit: https://github.com/PlusPeter/learning-tools-project/issues/1937
  const disabled = false;

  const tooltipTitle = useMemo(() => {
    // No tooltips on mobile or when not screen sharing
    if (!isDesktop) return null;
    if (isSharingScreen) return t('screenShare.stopSharing');
    if (screens.length >= MAX_SCREEN_SHARES) return t('screenShare.limited');
    return null;
  }, [isDesktop, isSharingScreen, screens.length, t]);

  useAppMessage<'stop-screen-share'>({
    onAppMessage: useCallback(
      (ev) => {
        if (ev.data !== 'stop-screen-share') return;
        stopScreenShare();
        addMessage({
          content: t('notification.screenShareStoppedByHost'),
        });
      },
      [addMessage, stopScreenShare, t]
    ),
  });

  if (!enableScreenShare) return null;

  return (
    <div
      className={classnames('screen-share-controls', {
        isSharing: isSharingScreen,
      })}
    >
      <Tooltip
        title={isSharingScreen ? 'Stop sharing' : 'Share screen'}
        id="tt-screenshare-btn"
        tabIndex={-1}
      >
        <TrayButton
          className={classnames(className, {
            [robotsClassName('btn-screenshare-start')]: !isSharingScreen,
            [robotsClassName('btn-screenshare-stop')]: isSharingScreen,
          })}
          onClick={toggleSharingScreen}
          disabled={disabled}
          {...props}
        >
          <ScreenshareIcon
            className="icon"
            active={isSharingScreen}
            color={
              disabled
                ? 'var(--text-muted)'
                : isSharingScreen
                ? colors.system.white
                : 'currentColor'
            }
          />
        </TrayButton>
      </Tooltip>
    </div>
  );
};
