import { useUniqueId } from 'hooks/useUniqueId';
import { SVGProps } from 'react';

export function Emerald(props: SVGProps<SVGSVGElement>) {
  // Gradient ID's
  const a = useUniqueId();
  const b = useUniqueId();
  const c = useUniqueId();
  const d = useUniqueId();
  const e = useUniqueId();
  const f = useUniqueId();
  const g = useUniqueId();
  const h = useUniqueId();
  const i = useUniqueId();
  const j = useUniqueId();
  const k = useUniqueId();

  return (
    <svg width={120} height={120} viewBox="0 0 120 120" fill="none" {...props}>
      <g clipPath={`url(#${a})`}>
        <path
          d="M16.558 51.65s0-.103 0 0l13.288-23.335-10.324-12.55L.715 48.747l15.843 2.904Z"
          fill={`url(#${b})`}
        />
        <path
          d="M32.913 25.618c.103 0 .103 0 0 0l24.94-9.23h.103V0L22.59 13.068l10.323 12.55Z"
          fill={`url(#${c})`}
        />
        <path
          d="m103.44 51.65 15.844-2.8-18.808-32.982-10.324 12.446 13.288 23.337c0-.104 0 0 0 0Z"
          fill={`url(#${d})`}
        />
        <path
          d="m62.043 16.387 24.94 9.23h.103l10.323-12.549L62.043 0v16.387Z"
          fill={`url(#${e})`}
        />
        <path
          d="M33.377 30.264 20.244 53.372c-.204.413-.306.928-.204 1.341L24.62 81.02c.102.515.306.928.713 1.238l20.158 17.227c.407.31.815.516 1.324.516h26.368c.51 0 .917-.206 1.324-.516l20.158-17.227c.407-.31.61-.723.713-1.238l4.581-26.306c.102-.516 0-.928-.204-1.341L86.521 30.264c-.203-.412-.61-.722-1.018-.928l-24.74-9.181c-.407-.207-.916-.207-1.425 0l-24.841 9.18c-.51.207-.815.517-1.12.93Z"
          fill={`url(#${f})`}
        />
        <path
          d="m42.828 103.197-20.34-17.32L8.585 94.07l28.722 24.477 5.52-15.35Z"
          fill={`url(#${g})`}
        />
        <path
          d="m97.513 85.877-20.34 17.32h-.103l5.52 15.35 28.824-24.477-13.9-8.193Z"
          fill={`url(#${h})`}
        />
        <path
          d="M104.156 55.696v.103l-4.6 26.448v.104l13.902 8.09L120 52.895l-15.844 2.8Z"
          fill={`url(#${i})`}
        />
        <path
          d="m20.443 82.247-4.6-26.448v-.103L0 52.896 6.542 90.44l13.9-8.193Z"
          fill={`url(#${j})`}
        />
        <path
          d="M73.287 104.65H46.61L41.09 120h37.616l-5.418-15.35Z"
          fill={`url(#${k})`}
        />
      </g>
      <defs>
        <linearGradient
          id={b}
          x1={9.416}
          y1={15.765}
          x2={27.32}
          y2={77.371}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.031} stopColor="#CCFFB5" />
          <stop offset={0.415} stopColor="#A1D38A" />
          <stop offset={1} stopColor="#3F9616" />
        </linearGradient>
        <linearGradient
          id={c}
          x1={33.153}
          y1={0}
          x2={33.995}
          y2={77.064}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.031} stopColor="#CCFFB5" />
          <stop offset={0.415} stopColor="#A1D38A" />
          <stop offset={1} stopColor="#3F9616" />
        </linearGradient>
        <linearGradient
          id={d}
          x1={98.853}
          y1={15.868}
          x2={118.214}
          y2={42.717}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.031} stopColor="#CCFFB5" />
          <stop offset={0.415} stopColor="#A1D38A" />
          <stop offset={1} stopColor="#3F9616" />
        </linearGradient>
        <linearGradient
          id={e}
          x1={72.606}
          y1={0}
          x2={82.194}
          y2={34.724}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.031} stopColor="#CCFFB5" />
          <stop offset={0.415} stopColor="#A1D38A" />
          <stop offset={1} stopColor="#3F9616" />
        </linearGradient>
        <linearGradient
          id={f}
          x1={43.895}
          y1={20}
          x2={83.731}
          y2={87.854}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.031} stopColor="#CCFFB5" />
          <stop offset={0.415} stopColor="#A1D38A" />
          <stop offset={1} stopColor="#3F9616" />
        </linearGradient>
        <linearGradient
          id={g}
          x1={25.707}
          y1={76.703}
          x2={34.215}
          y2={113.964}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.031} stopColor="#CCFFB5" />
          <stop offset={0.415} stopColor="#A1D38A" />
          <stop offset={1} stopColor="#3F9616" />
        </linearGradient>
        <linearGradient
          id={h}
          x1={63.589}
          y1={64.646}
          x2={102.248}
          y2={115.254}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.031} stopColor="#CCFFB5" />
          <stop offset={0.415} stopColor="#A1D38A" />
          <stop offset={1} stopColor="#3F9616" />
        </linearGradient>
        <linearGradient
          id={i}
          x1={99.557}
          y1={38.422}
          x2={131.721}
          y2={66.642}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.031} stopColor="#CCFFB5" />
          <stop offset={0.415} stopColor="#A1D38A" />
          <stop offset={1} stopColor="#3F9616" />
        </linearGradient>
        <linearGradient
          id={j}
          x1={10.222}
          y1={37.579}
          x2={39.224}
          y2={78.829}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.031} stopColor="#CCFFB5" />
          <stop offset={0.415} stopColor="#A1D38A" />
          <stop offset={1} stopColor="#3F9616" />
        </linearGradient>
        <linearGradient
          id={k}
          x1={47.072}
          y1={93.047}
          x2={47.867}
          y2={118.397}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.031} stopColor="#CCFFB5" />
          <stop offset={0.415} stopColor="#A1D38A" />
          <stop offset={1} stopColor="#3F9616" />
        </linearGradient>
        <clipPath id={a}>
          <path fill="#fff" d="M0 0h120v120H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
