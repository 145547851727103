import { useEffect, useState } from 'react';

/**
 * This hook helps signaling and synchronizing animations to the lifecycle,
 * depending if components / HTML are mounted.
 * https://letsbuildui.dev/articles/how-to-animate-mounting-content-in-react
 *
 * @param isMounted Indicates if the component / markup is mounted and rendered into the DOM
 * @param unmountDelay Amount of time in milliseconds to wait for the unmount transition to finish
 * @returns
 */
export const useMountTransition = (isMounted, unmountDelay) => {
  const [hasTransitionedIn, setHasTransitionedIn] = useState(false);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (isMounted && !hasTransitionedIn) {
      // Mounting process
      setHasTransitionedIn(true);
    } else if (!isMounted && hasTransitionedIn) {
      // Unmounting process
      timeoutId = setTimeout(() => setHasTransitionedIn(false), unmountDelay);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [unmountDelay, isMounted, hasTransitionedIn]);

  return hasTransitionedIn;
};
