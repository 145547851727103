import { useUniqueId } from 'hooks/useUniqueId';
import { SVGProps } from 'react';

export function BakerIcon(props: SVGProps<SVGSVGElement>) {
  const idA = useUniqueId();
  return (
    <svg width="84" height="85" fill="none" viewBox="0 0 84 85" {...props}>
      <circle cx="41.824" cy="42.53" r="36" fill={`url(#${idA})`} />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="m40.465 32.114-.383-.106-6.99-1.937-4.404 2.646-1.746 7.73 6.761 6.175 7.218 7.477 13.54-9.326 1.833-8.392-1.104-5.365-5.717-.527h-4.575l-3.075 3.357-1.358-1.732Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M34.174 27.316c-5.737 0-9.835 3.752-9.835 10.144 0 4.372 2.82 8.832 9.627 14.772l.745.675 4.196 3.738a4.371 4.371 0 0 0 5.794.002l2.558-2.27 1.706-1.527c7.327-6.25 10.344-10.868 10.344-15.39 0-6.392-4.098-10.144-9.835-10.144-2.753 0-5.311 1.173-7.65 3.413-2.34-2.24-4.897-3.413-7.65-3.413Zm9.262 8.124c.038-.041.074-.084.109-.127 2.01-2.485 3.972-3.625 5.929-3.625 3.405 0 5.464 1.885 5.464 5.772 0 2.312-1.856 5.424-5.664 9.188l-.009-.008-.141.137-.782.736-1.821 1.658-4.716 4.201-4.377-3.901-2.001-1.817-.492-.46c-4.187-4.01-6.225-7.31-6.225-9.734 0-3.887 2.06-5.772 5.464-5.772 1.957 0 3.918 1.14 5.93 3.625a2.181 2.181 0 0 0 2.583.66c.277-.119.534-.297.75-.533Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id={idA}
          x1="35.186"
          x2="-35.323"
          y1="87.53"
          y2="52.663"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9656A1" />
          <stop offset="1" stopColor="#FF3939" />
        </linearGradient>
      </defs>
    </svg>
  );
}
