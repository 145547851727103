import { Button } from '@daily/shared/components/Button';
import { Modal } from '@daily/shared/components/Modal';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { useDaily } from '@daily-co/daily-react-hooks';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCallState } from '../../contexts/CallProvider';
import { isIOSMobile, isSafari } from '../../lib/browserConfig';
import { UnblockPermissionInstructions } from './UnblockPermissionInstructions';

export const UnblockPermissionsModal: React.FC<
  React.ComponentProps<typeof Modal>
> = ({ closeText, onClose }) => {
  const { t } = useTranslation();
  const daily = useDaily();
  const { disableAudio, mode } = useCallState();
  const [safariRetryCount, setSafariRetryCount] = useState(0);

  const handleGrantAccessClick = useCallback(
    async (close) => {
      if (mode === 'direct-link') {
        window.location.reload();
      } else {
        try {
          if (isSafari(14)) {
            await navigator.mediaDevices.getUserMedia({
              video: {
                deviceId: {
                  ideal: safariRetryCount.toString(),
                },
              },
            });
          }
          daily.setLocalVideo(true);
          if (!disableAudio) daily.setLocalAudio(true);
          close();
        } catch (e) {
          setSafariRetryCount((i) => i + 1);
        }
      }
    },
    [daily, disableAudio, mode, safariRetryCount]
  );

  const actions = useMemo(() => {
    if (isSafari() || isIOSMobile()) {
      return [
        <Button key="grant" variant="primary" onClick={handleGrantAccessClick}>
          {t('haircheck.blocked.grantAccess')}
        </Button>,
        <Button key="close" variant="secondary">
          {closeText}
        </Button>,
      ];
    }

    return [
      <Button key="dismiss" variant="primary">
        {t('general.dismiss')}
      </Button>,
    ];
  }, [closeText, handleGrantAccessClick, t]);

  return (
    <Modal
      actions={actions}
      onClose={onClose}
      title={t('haircheck.blocked.unblockModalTitle')}
    >
      <Stack>
        <Text variant="large" El="p">
          {t('haircheck.blocked.unblockModalContentText')}
        </Text>
        <UnblockPermissionInstructions hideUnblockButton />
      </Stack>
    </Modal>
  );
};
