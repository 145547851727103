import { useAppMessage, useParticipantIds } from '@daily-co/daily-react-hooks';
import { useChatState } from 'contexts/ChatProvider';
import { useIframeDriver } from 'contexts/IframeDriverProvider';
import { useAdminsList } from 'contexts/UIState';
import { useCallback, useEffect } from 'react';

export enum CALL_CUSTOM_EVENTS {
  ST_ADMIN_LIST_INIT = 'st-admin-list-init',
  ST_ADMIN_ADD_PARTICIPANT = 'st-admin-add-participant',
  ST_ADMIN_REMOVE_PARTICIPANT = 'st-admin-remove-participant',
  ST_ADMIN_ADD_SUCCESS = 'st-admin-add-success',
  ST_ADMIN_REMOVE_SUCCESS = 'st-admin-remove-success',
  ST_KICK_PARTICIPANT = 'st-kick-participant',
  ST_TRY_AGAIN = 'st-try-again',
  ST_CUSTOM_ROOM_UPDATED = 'st-custom-room-updated',
  ST_SHOW_PARTICIPANT_DETAILS = 'st-show-participant-details',
  ST_SEND_MESSAGE = 'st-send-message',
  ST_SEND_KUDOS = 'st-send-kudos',
  ST_REPORT_PARTICIPANT = 'st-report-participant',
  ST_SESSION_TIMER_UPDATED = 'st-session-timer-updated',
  ST_SESSION_TIMER_STARTED = 'st-session-timer-started',
  ST_SESSION_TIMER_STOPPED = 'st-session-timer-stopped',
  ST_SESSION_TIMER_PAUSED = 'st-session-timer-paused',
  ST_SESSION_TIMER_RESUMED = 'st-session-timer-resumed',
  ST_NEW_ROLE_ACHIEVED = 'st-new-role-achieved',
  ST_CUSTOM_ROOM_RELOAD = 'st-reload-room',
}
const SEND_EVENTS_BACK_TO_DASHBOARD = [
  CALL_CUSTOM_EVENTS.ST_ADMIN_ADD_SUCCESS,
  CALL_CUSTOM_EVENTS.ST_ADMIN_REMOVE_SUCCESS,
  CALL_CUSTOM_EVENTS.ST_CUSTOM_ROOM_UPDATED,
  CALL_CUSTOM_EVENTS.ST_CUSTOM_ROOM_RELOAD,
  CALL_CUSTOM_EVENTS.ST_SESSION_TIMER_UPDATED,
  CALL_CUSTOM_EVENTS.ST_SESSION_TIMER_STARTED,
  CALL_CUSTOM_EVENTS.ST_SESSION_TIMER_STOPPED,
  CALL_CUSTOM_EVENTS.ST_SESSION_TIMER_PAUSED,
  CALL_CUSTOM_EVENTS.ST_SESSION_TIMER_RESUMED,
];

export const IframeListener = () => {
  const [, setAdmins] = useAdminsList();
  const { sendMessageToDriver } = useIframeDriver();
  const { loading, sendMessage } = useChatState();
  const participants = useParticipantIds();

  useAppMessage({
    onAppMessage: (ev) => {
      // "dynamically" send back the same event to the parent window
      if (SEND_EVENTS_BACK_TO_DASHBOARD.includes(ev.data.action)) {
        sendMessageToDriver({
          action: ev.data.action,
          payload: ev.data.payload,
          receiverID: ev.data.receiverID,
        });
      }
    },
  });

  const handleIframeMessages = useCallback(
    async (ev: MessageEvent) => {
      if (ev.data.action === CALL_CUSTOM_EVENTS.ST_NEW_ROLE_ACHIEVED) {
        console.log('ST_NEW_ROLE_ACHIEVED', ev.data.payload);
        const chatMessage = ev?.data?.payload?.chatMessage ?? false;

        if (!chatMessage) return;

        if (!loading && participants.length <= 5) {
          sendMessage(chatMessage, {
            name: 'Study Together',
          });
        }

        return;
      }
      if (ev.data.action === CALL_CUSTOM_EVENTS.ST_ADMIN_LIST_INIT) {
        console.log('custom admins init success', ev.data.payload);
        setAdmins(ev.data.payload.admins);
      }
      // this is not fired, when the event was broadcasted from the parent window.
      // if (ev.data.action === CUSTOM_EVENTS.ST_ADMIN_ADD_SUCCESS) {
      //   console.log('should fire twice');
      //   sendMessageToDriver({
      //     action: CUSTOM_EVENTS.ST_ADMIN_ADD_SUCCESS,
      //   });
      //   return;
      // }
    },
    [setAdmins, sendMessage, loading]
  );

  useEffect(() => {
    window.addEventListener('message', handleIframeMessages);
    return () => window.removeEventListener('message', handleIframeMessages);
  }, [handleIframeMessages]);

  return <></>;
};
