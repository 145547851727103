import { Button } from '@daily/shared/components/Button';
import { Modal } from '@daily/shared/components/Modal';
import { Text } from '@daily/shared/components/Text';
import { useTranslation } from 'react-i18next';

import { useAutoplayFailedModal } from '../../contexts/UIState';
import { useSoundLoader } from '../../hooks/useSoundLoader';

interface Props extends React.ComponentProps<typeof Modal> {}

export const BroadcastJoiningModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { load: loadSounds } = useSoundLoader();
  const [, setShowAutoplayFailedModal] = useAutoplayFailedModal();

  const handleModalClose = () => {
    loadSounds();
    // brute-force audio tracks to play and avoid rendering the AutoPlayFailedModal
    setShowAutoplayFailedModal(false);
    document
      .querySelectorAll('.audioTracks audio')
      .forEach((audio: HTMLAudioElement) => {
        if (audio.paused) audio.play();
      });

    props.onClose();
  };

  return (
    <Modal
      actions={[
        <Button key="joining" variant="primary">
          {t('broadcast.joiningButton')}
        </Button>,
      ]}
      onClose={handleModalClose}
      title={t('broadcast.welcome')}
    >
      <Text El="p" variant="large">
        {t('broadcast.joiningViewer')}
      </Text>
    </Modal>
  );
};
