/* eslint-disable @next/next/no-img-element */

import { Gemstone } from './Gemstone';

type UserImageProps = {
  size: string;
  avatar: string;
  gemstone: string;
};

export function UserImage({ avatar, gemstone, size }: UserImageProps) {
  const defaultAvatar =
    gemstone === 'None'
      ? '/images/no-image.svg'
      : '/images/no-image-transparent.svg';

  const avatarUrl = avatar && avatar !== '' ? avatar : defaultAvatar;
  return (
    <div className={`user-image user-image--${size}`}>
      <img
        className={`user-image__avatar user-image__avatar--${gemstone?.toLowerCase()}`}
        src={avatarUrl}
        alt="user"
      />
      {gemstone !== 'None' && (
        <div className="user-image__gem">
          <Gemstone name={gemstone} />
        </div>
      )}
      <style jsx>{`
        .user-image {
          position: relative;
          display: inline-block;
        }
        .user-image__avatar {
          position: relative;
          z-index: 2;
        }
        .user-image__avatar--none {
          border-radius: 12px;
        }
        .user-image__avatar--sapphire {
          clip-path: polygon(50% 3%, 100% 40%, 81% 99%, 19% 99%, 0% 40%);
        }
        .user-image__avatar--moonstone {
          clip-path: polygon(
            50% 0%,
            94% 25%,
            94% 75%,
            50% 100%,
            5% 75%,
            5% 25%
          );
        }
        .user-image__avatar--topaz {
          clip-path: polygon(
            50% 0%,
            91% 19%,
            100% 64%,
            72% 100%,
            28% 100%,
            0% 64%,
            9% 19%
          );
        }
        .user-image__avatar--jade {
          clip-path: polygon(
            50% 0%,
            86% 15%,
            100% 50%,
            86% 85%,
            50% 100%,
            14% 85%,
            0% 50%,
            14% 15%
          );
        }
        .user-image__avatar--emerald {
          clip-path: polygon(
            50% 0%,
            83% 12%,
            100% 42%,
            94% 77%,
            67% 100%,
            33% 100%,
            6% 77%,
            0% 42%,
            17% 12%
          );
        }
        .user-image__avatar--sphene {
          clip-path: polygon(
            50% 0%,
            81% 10%,
            100% 34%,
            100% 65%,
            81% 91%,
            50% 100%,
            19% 91%,
            0% 65%,
            0% 34%,
            19% 10%
          );
        }
        .user-image__avatar--jasper {
          clip-path: polygon(
            50% 0%,
            77% 8%,
            96% 30%,
            100% 58%,
            88% 84%,
            64% 100%,
            36% 100%,
            12% 84%,
            0% 58%,
            4% 30%,
            23% 8%
          );
        }
        .user-image__avatar--quartz {
          clip-path: polygon(
            50% 0%,
            75% 7%,
            93% 25%,
            100% 50%,
            93% 75%,
            75% 93%,
            50% 100%,
            25% 93%,
            7% 75%,
            0% 50%,
            7% 25%,
            25% 7%
          );
        }
        .user-image__avatar--ruby {
          clip-path: polygon(
            50% 0%,
            74% 6%,
            92% 22%,
            100% 45%,
            97% 69%,
            83% 89%,
            62% 100%,
            38% 100%,
            17% 89%,
            3% 69%,
            0% 45%,
            8% 22%,
            26% 6%
          );
        }
        .user-image__avatar--kunzite {
          clip-path: polygon(
            50% 0%,
            72% 5%,
            91% 19%,
            100% 39%,
            100% 62%,
            91% 81%,
            72% 95%,
            50% 100%,
            28% 95%,
            9% 81%,
            0% 62%,
            0% 39%,
            9% 19%,
            28% 5%
          );
        }
        .user-image__avatar--diamond {
          clip-path: polygon(
            50% 0%,
            70% 4%,
            89% 17%,
            98% 35%,
            100% 56%,
            93% 77%,
            79% 92%,
            60% 100%,
            40% 100%,
            21% 92%,
            7% 77%,
            0% 56%,
            2% 35%,
            11% 17%,
            30% 4%
          );
        }
        .user-image__gem {
          position: absolute;
          display: block;
        }
        .user-image--xs {
          margin: 1px;
        }
        .user-image--xs .user-image__avatar {
          display: block;
          min-width: 12px;
          width: 12px;
          height: 12px;
          margin: 0 auto;
        }
        .user-image--xs .user-image__avatar--none {
          border-radius: 4px;
        }
        .user-image--xs .user-image__gem {
          width: 20px;
          height: 20px;
          transform: translate(-4px, -16px);
        }
        .user-image--small {
          margin: 3px;
        }
        .user-image--small .user-image__avatar {
          display: block;
          min-width: 27px;
          width: 27px;
          height: 27px;
          margin: 0 auto;
        }
        .user-image--small .user-image__avatar--none {
          border-radius: 4px;
        }
        .user-image--small .user-image__gem {
          width: 40px;
          height: 40px;
          transform: translate(-6.5px, -33.5px);
        }
        .user-image--medium {
          margin: 9px;
        }
        .user-image--medium .user-image__avatar {
          display: block;
          width: 34px;
          height: 34px;
          margin: 0 auto;
        }
        .user-image--medium .user-image__gem {
          width: 52px;
          height: 52px;
          transform: translate(-9px, -43px);
        }
        .user-image--medium .user-image__gem--amethyst {
          border-radius: 7px;
        }
        .user-image--large {
          margin: 22px;
        }
        .user-image--large .user-image__avatar {
          display: block;
          width: 84px;
          height: 84px;
          margin: 0 auto;
        }
        .user-image--large .user-image__avatar--amethyst {
          border-radius: 7px;
        }
        .user-image--large .user-image__gem {
          width: 126px;
          height: 126px;
          transform: translate(-21px, -105px);
        }
      `}</style>
    </div>
  );
}
