export const getFocusableElements = (container: HTMLElement) => {
  if (!container) return [];
  return Array.from<HTMLElement>(
    container.querySelectorAll(
      'input,select,textarea,a[href],button,[tabindex],audio[controls],video[controls],[contenteditable]:not([contenteditable="false"])'
    )
  )
    .filter((el: HTMLElement) => el.getAttribute('disabled') === null)
    .filter((el: HTMLElement) => el.getAttribute('aria-disabled') !== 'true')
    .filter((el: HTMLElement) => {
      const tabindex = el.getAttribute('tabindex');
      return tabindex === null || parseInt(tabindex, 10) >= 0;
    });
};

export const scrollToElement = (targetEl: HTMLElement) => {
  const rect = targetEl.getBoundingClientRect();
  const headerHeight =
    document.documentElement.style.getPropertyValue('--header-height');
  window.scrollTo({
    behavior: 'smooth',
    top: Math.max(
      0,
      window.pageYOffset + rect.y - parseInt(headerHeight, 10) - 16
    ),
  });
  const id = targetEl.getAttribute('id');
  if (id) {
    history.pushState({}, '', `#${id}`);
    window.dispatchEvent(new Event('hashchange'));
  }
};
