import { useUniqueId } from 'hooks/useUniqueId';
import { SVGProps } from 'react';

export function Quartz(props: SVGProps<SVGSVGElement>) {
  // Gradient ID's
  const a = useUniqueId();
  const b = useUniqueId();
  const c = useUniqueId();
  const d = useUniqueId();
  const e = useUniqueId();
  const f = useUniqueId();
  const g = useUniqueId();
  const h = useUniqueId();
  const i = useUniqueId();
  const j = useUniqueId();
  const k = useUniqueId();
  const l = useUniqueId();
  const m = useUniqueId();
  const n = useUniqueId();
  return (
    <svg width={120} height={120} viewBox="0 0 120 120" fill="none" {...props}>
      <g clipPath={`url(#${a})`}>
        <path
          d="m16.034 58.467 4.84-18.253-13.815-7.966L0 58.467h16.034Z"
          fill={`url(#${b})`}
        />
        <path
          d="m62.018 16.517 18.252 4.84 7.966-13.815L62.018.483v16.034Z"
          fill={`url(#${c})`}
        />
        <path
          d="m22.891 36.785 13.412-13.411-8.067-13.815-19.16 19.16 13.815 8.066Z"
          fill={`url(#${d})`}
        />
        <path
          d="m39.732 21.357 18.252-4.84V.483l-26.218 7.06 7.966 13.814Z"
          fill={`url(#${e})`}
        />
        <path
          d="m91.764 9.559-8.067 13.815L97.11 36.786l13.815-8.068-19.16-19.16Z"
          fill={`url(#${f})`}
        />
        <path
          d="m80.413 94.805 13.909-13.909c.2-.2.4-.6.5-.9l5.103-19.012c.1-.3.1-.7 0-1L94.822 40.97c-.1-.3-.3-.6-.5-.9l-13.91-13.91c-.2-.2-.6-.4-.9-.5L60.5 20.559c-.3-.1-.7-.1-1 0l-19.012 5.104c-.3.1-.6.3-.9.5L25.677 40.07c-.2.2-.4.6-.5.9l-5.103 19.012c-.1.3-.1.7 0 1l5.103 19.013c.1.3.3.6.5.9l13.91 13.909c.2.2.6.4.9.5l19.012 5.103c.3.1.7.1 1 0l19.012-5.103c.3-.1.6-.3.9-.5Z"
          fill={`url(#${g})`}
        />
        <path
          d="M97.109 84.18 83.697 97.593l8.067 13.816 19.16-19.16-13.815-8.067Z"
          fill={`url(#${h})`}
        />
        <path
          d="m80.27 99.61-18.252 4.84v16.033l26.218-7.058-7.966-13.816Z"
          fill={`url(#${i})`}
        />
        <path
          d="m103.967 62.5-4.84 18.252 13.815 7.967 7.059-26.219h-16.034Z"
          fill={`url(#${j})`}
        />
        <path
          d="m99.127 40.214 4.84 18.253h16.034l-7.059-26.219-13.815 7.966Z"
          fill={`url(#${k})`}
        />
        <path
          d="M20.874 80.752 16.034 62.5H0l7.059 26.219 13.815-7.967Z"
          fill={`url(#${l})`}
        />
        <path
          d="m57.984 104.45-18.252-4.84-7.966 13.815 26.218 7.058V104.45Z"
          fill={`url(#${m})`}
        />
        <path
          d="M36.303 97.593 22.891 84.18 9.076 92.248l19.16 19.16 8.067-13.816Z"
          fill={`url(#${n})`}
        />
      </g>
      <defs>
        <linearGradient
          id={b}
          x1={37.278}
          y1={40.632}
          x2={1.833}
          y2={34.226}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBCB6" />
          <stop offset={0.464} stopColor="#FF6F61" />
          <stop offset={0.844} stopColor="#CB2D1E" />
        </linearGradient>
        <linearGradient
          id={c}
          x1={84.532}
          y1={8.674}
          x2={59.621}
          y2={5.56}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBCB6" />
          <stop offset={0.438} stopColor="#FF6F61" />
          <stop offset={1} stopColor="#CB2D1E" />
        </linearGradient>
        <linearGradient
          id={d}
          x1={52.574}
          y1={7.582}
          x2={11.056}
          y2={23.172}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBCB6" />
          <stop offset={0.438} stopColor="#FF6F61" />
          <stop offset={1} stopColor="#CB2D1E" />
        </linearGradient>
        <linearGradient
          id={e}
          x1={71.421}
          y1={5.123}
          x2={33.63}
          y2={9.005}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBCB6" />
          <stop offset={0.438} stopColor="#FF6F61" />
          <stop offset={1} stopColor="#CB2D1E" />
        </linearGradient>
        <linearGradient
          id={f}
          x1={109.115}
          y1={24.79}
          x2={64.319}
          y2={12.771}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBCB6" />
          <stop offset={0.438} stopColor="#FF6F61" />
          <stop offset={1} stopColor="#CB2D1E" />
        </linearGradient>
        <linearGradient
          id={g}
          x1={86.444}
          y1={22.878}
          x2={24.44}
          y2={37.901}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBCB6" />
          <stop offset={0.438} stopColor="#FF6F61" />
          <stop offset={1} stopColor="#CB2D1E" />
        </linearGradient>
        <linearGradient
          id={h}
          x1={106.311}
          y1={84.996}
          x2={75.518}
          y2={93.622}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBCB6" />
          <stop offset={0.438} stopColor="#FF6F61" />
          <stop offset={1} stopColor="#CB2D1E" />
        </linearGradient>
        <linearGradient
          id={i}
          x1={83.793}
          y1={100.234}
          x2={64.103}
          y2={106.226}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBCB6" />
          <stop offset={0.438} stopColor="#FF6F61" />
          <stop offset={1} stopColor="#CB2D1E" />
        </linearGradient>
        <linearGradient
          id={j}
          x1={116.464}
          y1={63.285}
          x2={88.762}
          y2={77.595}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBCB6" />
          <stop offset={0.438} stopColor="#FF6F61" />
          <stop offset={1} stopColor="#CB2D1E" />
        </linearGradient>
        <linearGradient
          id={k}
          x1={116.464}
          y1={33.033}
          x2={85.413}
          y2={32.447}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBCB6" />
          <stop offset={0.438} stopColor="#FF6F61" />
          <stop offset={1} stopColor="#CB2D1E" />
        </linearGradient>
        <linearGradient
          id={l}
          x1={32.908}
          y1={62.5}
          x2={0.912}
          y2={64.841}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBCB6" />
          <stop offset={0.438} stopColor="#FF6F61" />
          <stop offset={1} stopColor="#CB2D1E" />
        </linearGradient>
        <linearGradient
          id={m}
          x1={74.153}
          y1={95.261}
          x2={34.595}
          y2={108.317}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBCB6" />
          <stop offset={0.438} stopColor="#FF6F61" />
          <stop offset={1} stopColor="#CB2D1E" />
        </linearGradient>
        <linearGradient
          id={n}
          x1={31.689}
          y1={84.996}
          x2={10.587}
          y2={90.108}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBCB6" />
          <stop offset={0.438} stopColor="#FF6F61" />
          <stop offset={1} stopColor="#CB2D1E" />
        </linearGradient>
        <clipPath id={a}>
          <path fill="#fff" transform="translate(0 .483)" d="M0 0h120v120H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
