import { useNetwork, useParticipant } from '@daily-co/daily-react-hooks';
import { useState } from 'react';

import { Tile } from '../Tile';

interface Props {
  height: number;
  isScreen?: boolean;
  maxWidth: number;
  ratio: number;
  sessionId: string;
  showNames: boolean;
  fullWidth?: boolean;
}

export const ScreenPinTile: React.FC<Props> = ({
  fullWidth,
  height,
  isScreen = false,
  maxWidth,
  ratio: initialRatio,
  sessionId,
  showNames,
}) => {
  const [ratio, setRatio] = useState(initialRatio);
  const handleResize = (aspectRatio: number) => {
    setRatio(aspectRatio);
  };

  const { threshold } = useNetwork();

  const participant = useParticipant(sessionId);

  if (isScreen) {
    return (
      <Tile
        aspectRatio={initialRatio}
        isScreen
        sessionId={sessionId}
        showNames={showNames}
        style={{
          height,
          maxWidth: fullWidth ? '100%' : maxWidth,
        }}
      />
    );
  }

  return (
    <Tile
      aspectRatio={ratio}
      network={participant?.local ? threshold : null}
      onVideoResize={handleResize}
      sessionId={sessionId}
      showNames={showNames}
      style={{
        maxHeight: height,
        maxWidth: fullWidth ? '100%' : height * ratio,
      }}
    />
  );
};
