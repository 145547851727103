import { Anchor } from '@daily/shared/components/Anchor';
import { Button } from '@daily/shared/components/Button';
import { CaretDownIcon } from '@daily/shared/components/Icons';
import { Text } from '@daily/shared/components/Text';
import { VisuallyHidden } from '@daily/shared/components/VisuallyHidden';
import { useTheme } from '@daily/shared/contexts/Theme';
import {
  useLocalParticipant,
  useParticipant,
} from '@daily-co/daily-react-hooks';
import classNames from 'classnames';
import { CALL_CUSTOM_EVENTS } from 'components/App/IframeListener';
import { useIframeDriver } from 'contexts/IframeDriverProvider';
import React, { Fragment, memo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Linkify from 'react-linkify';
import { atomFamily, useRecoilCallback, useRecoilValue } from 'recoil';

import {
  ChatMessage as IChatMessage,
  Reaction,
  useChatState,
} from '../../contexts/ChatProvider';
import { useResizeObserver } from '../../hooks/useResizeObserver';
import { useStyleVariants } from '../../hooks/useStyleVariants';
import { AddReaction } from './AddReaction';
import { ChatReactions } from './ChatReactions';
import { GiphyGif } from './GiphyGif';
import { GiphyIcon } from './GiphyIcon';

interface Props {
  enableAdvancedChat: boolean;
  message: IChatMessage;
  isLocal: boolean;
  onRender?(id: string, el: HTMLDivElement): void;
  showSender?: boolean;
  showTimestamp?: boolean;
}

const MsgLink = (href, text, key) => (
  <Anchor href={href} key={key} target="_blank" underline>
    {text}
  </Anchor>
);

const chatImageHeightState = atomFamily<number, string>({
  key: 'chat-image-height',
  default: undefined,
});

const chatImageVisibleState = atomFamily<boolean, string>({
  key: 'chat-image-visible',
  default: true,
});

export const ChatMessage: React.FC<Props> = memo(
  ({
    enableAdvancedChat,
    isLocal,
    message,
    onRender,
    showSender = false,
    showTimestamp = false,
  }) => {
    const { i18n, t } = useTranslation();
    const { colors, fontFamilies, fontSizes, lineHeights, mediaQueries } =
      useTheme();
    const msgRef = useRef<HTMLDivElement>(null);
    const { textVariantSmall } = useStyleVariants();
    const { reactToMessage } = useChatState();
    const { sendMessageToDriver } = useIframeDriver();

    const participant = useParticipant(message.fromId);

    const userID = participant?.user_id;

    const giphyMatch = message.message.match(
      /!\[(.+-giphy)\|(\d+)\|(.+)\]\((.*)\)/i
    );

    const reactionsCount = Object.values(message.reactions ?? {}).reduce(
      (sum, { count }) => sum + count,
      0
    );

    const handleReaction = (reaction: Reaction) => {
      reactToMessage(message, reaction);
    };

    /**
     * Call onRender to inform parent about potentially changed DOM.
     */
    useResizeObserver(
      msgRef,
      useCallback(() => {
        onRender?.(message?.id, msgRef.current);
      }, [message?.id, onRender])
    );

    const imgHeight = useRecoilValue(chatImageHeightState(message.id));
    const imgVisible = useRecoilValue(chatImageVisibleState(message.id));
    const handleGifLoad = useRecoilCallback(
      ({ set }) =>
        (ev: React.SyntheticEvent<HTMLImageElement, Event>) => {
          set(chatImageHeightState(message?.id), ev.currentTarget.clientHeight);
          onRender?.(message?.id, msgRef.current);
        },
      [message?.id, onRender]
    );

    const handleToggleGif = useRecoilCallback(
      ({ set }) =>
        () => {
          set(chatImageVisibleState(message?.id), (prev) => !prev);
        },
      [message?.id]
    );

    const handleClick = useCallback(
      (event) => {
        sendMessageToDriver({
          action: CALL_CUSTOM_EVENTS.ST_SHOW_PARTICIPANT_DETAILS,
          payload: {
            from: 'chat-list',
            userID: userID,
            x: event.clientX,
            y: event.clientY,
          },
        });
      },
      [userID, sendMessageToDriver]
    );

    return (
      <div
        className={classNames('chat-message', {
          local: isLocal,
          subsequent: !showSender && !showTimestamp,
          'with-sender': showSender,
        })}
        ref={msgRef}
      >
        {showTimestamp && (
          <Text
            className="time"
            color="muted"
            textAlign="center"
            variant={textVariantSmall}
          >
            {message.received.toLocaleTimeString(i18n.language, {
              timeStyle: 'short',
            })}
          </Text>
        )}
        <div
          className={classNames('msg', {
            isLocal,
            gif: enableAdvancedChat && !!giphyMatch,
          })}
        >
          {showSender && (
            <Text
              El="strong"
              color="inherit"
              className="sender"
              variant={textVariantSmall}
              onClick={userID && handleClick}
              style={
                userID && {
                  cursor: 'pointer',
                }
              }
            >
              {message.local ? (
                <VisuallyHidden>
                  {message.name !== t('people.guest')
                    ? t('people.you')
                    : t('people.youGuest')}
                </VisuallyHidden>
              ) : (
                message.name || t('people.guest')
              )}{' '}
            </Text>
          )}
          {enableAdvancedChat && giphyMatch ? (
            <Button
              className={classNames('gif-toggle', {
                visible: imgVisible,
              })}
              onClick={handleToggleGif}
              variant="ghost"
            >
              <Text El="span" color="inherit" variant={textVariantSmall}>
                {giphyMatch[1]} (
                {Math.round(parseInt(giphyMatch[2], 10) / 1024)}kb)
              </Text>
              <CaretDownIcon size={16} variant="thick" />
              <VisuallyHidden>
                {imgVisible ? t('chat.hideGif') : t('chat.showGif')}
              </VisuallyHidden>
            </Button>
          ) : null}
          {enableAdvancedChat && giphyMatch ? (
            imgVisible ? (
              <>
                <GiphyGif
                  className="giphy-img"
                  height={imgHeight}
                  onProgress={handleGifLoad}
                  onLoad={handleGifLoad}
                  title={giphyMatch[3]}
                  src={giphyMatch[4]}
                />
                <Text
                  className="powered-by-giphy"
                  color={message.local ? 'inherit' : 'muted'}
                  variant="small"
                >
                  <GiphyIcon /> Powered by GIPHY
                </Text>
              </>
            ) : null
          ) : (
            <Linkify componentDecorator={MsgLink}>
              {message.message.split('\n').map((line, i) => (
                <Fragment key={`line-${line}`}>
                  {i > 0 && <br />}
                  {line}
                </Fragment>
              ))}
            </Linkify>
          )}
          {enableAdvancedChat && (
            <AddReaction message={message} onClickReaction={handleReaction} />
          )}
        </div>
        {enableAdvancedChat && reactionsCount > 0 && (
          <div className="reactions">
            <ChatReactions message={message} onReact={handleReaction} />
          </div>
        )}
        <style jsx>{`
          .chat-message {
            --padding-h: 12px;

            background: var(--card-bg);
            padding-top: 4px;
          }
          .chat-message.with-sender {
            padding-top: 12px;
          }
          .chat-message.local {
            padding-left: var(--padding-h);
          }
          .chat-message:not(.local) {
            padding-right: var(--padding-h);
          }
          .chat-message :global(.time) {
            font-variant-numeric: tabular-nums;
            padding: var(--padding-h) 0;
          }
          .chat-message:not(.local) :global(.time) {
            margin-right: calc(-1 * var(--padding-h));
          }
          .chat-message.local :global(.time) {
            margin-left: calc(-1 * var(--padding-h));
          }
          .msg {
            background: ${colors.backgroundAccent};
            border-radius: 8px;
            color: ${colors.baseText};
            /* Reduce vertical padding visually because of bigger line-height. */
            padding: calc(var(--padding-h) - 5px) var(--padding-h)
              calc(var(--padding-h) - 3px);
            position: relative;
            word-break: break-word;
          }
          @media ${mediaQueries.coarse} {
            .msg {
              font-size: ${fontSizes.large};
              padding: 12px;
              line-height: ${lineHeights.large};
            }
          }
          .msg.isLocal {
            background: ${colors.accent};
            border-bottom-right-radius: 0;
            color: ${colors.accentText};
          }
          .msg:not(.isLocal) {
            border-bottom-left-radius: 0;
          }
          .chat-message.subsequent:not(.local) .msg {
            border-top-left-radius: 0;
          }
          .chat-message.subsequent.local .msg {
            border-top-right-radius: 0;
          }
          .msg :global(.sender) {
            display: block;
            font-family: ${fontFamilies.medium};
          }
          .msg :global(.gif-toggle) {
            color: ${colors.baseText};
          }
          .msg.isLocal :global(.gif-toggle) {
            color: ${colors.accentText};
          }
          .msg :global(.gif-toggle svg) {
            transform: rotate(180deg);
            transition: transform 250ms ease;
          }
          .msg :global(.gif-toggle.visible svg) {
            transform: rotate(0deg);
          }
          .msg.gif :global(.giphy-img) {
            border-radius: 4px;
            height: auto;
            margin: 0 -4px;
            width: calc(100% + 8px);
          }
          .msg.gif :global(.giphy-img) {
            margin-top: 4px;
          }
          .chat-message :global(.powered-by-giphy) {
            align-items: center;
            display: flex;
            gap: 4px;
            justify-content: flex-start;
            margin-top: 2px;
            margin-left: -4px;
          }
          .chat-message :global(.powered-by-giphy picture),
          .chat-message :global(.powered-by-giphy img) {
            display: block;
          }
          .reactions {
            display: block;
            margin-top: -4px;
            pointer-events: none;
            position: relative;
            transition: opacity 200ms ease;
          }
        `}</style>
      </div>
    );
  }
);
ChatMessage.displayName = 'ChatMessage';
