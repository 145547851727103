import { Button } from '@daily/shared/components/Button';
import {
  EllipsisVerticalIcon,
  HiddenIcon,
  PinIcon,
  UnpinIcon,
} from '@daily/shared/components/Icons';
import { Menu, MenuButton } from '@daily/shared/components/Menu';
import { Stack } from '@daily/shared/components/Stack';
import { Tooltip } from '@daily/shared/components/Tooltip';
import { VisuallyHidden } from '@daily/shared/components/VisuallyHidden';
import { useTheme } from '@daily/shared/contexts/Theme';
import { useParticipant } from '@daily-co/daily-react-hooks';
import {
  useHiddenIds,
  useHiddenParticipantModal,
  usePinnedId,
} from 'contexts/UIState';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TileMenu } from './TileMenu';
import { TileRaportUser } from './TileRaportUser';

type Props = {
  sessionId: string;
  videoEl: any;
};

export const TileButtons: React.FC<Props> = ({ sessionId, videoEl }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const [pinnedId, setPinnedId] = usePinnedId();
  const [hiddenIds, setHiddenIds] = useHiddenIds();
  const [, setHiddenParticipantModal] = useHiddenParticipantModal();
  const participant = useParticipant(sessionId);
  const [showMenu, setShowMenu] = useState(false);

  const btnId = `${sessionId}-video-tile-actions-btn`;
  const menuId = `${sessionId}-video-tile-actions`;

  const handleHideClick = useCallback(() => {
    if (!hiddenIds.includes(sessionId)) {
      setHiddenParticipantModal(true);
    }

    setHiddenIds((ids) => {
      if (ids.includes(sessionId)) {
        return ids.filter((id) => id !== sessionId);
      }
      return [...ids, sessionId];
    });
  }, [hiddenIds, sessionId, setHiddenIds, setHiddenParticipantModal]);

  const handlePinClick = useCallback(() => {
    if (pinnedId === sessionId) {
      setPinnedId(null);
    } else {
      setPinnedId(sessionId);
    }
  }, [pinnedId, sessionId, setPinnedId]);

  const pinLabel = useMemo(() => {
    if (pinnedId === sessionId) {
      return t('people.unpinParticipant');
    }
    return t('people.pinParticipant');
  }, [pinnedId, sessionId, t]);

  const handleMenuClick = () => setShowMenu(true);

  return (
    <>
      <div className={`tile-actions ${showMenu ? 'tile-show' : ' '}`}>
        <Stack horizontal gap={4}>
          <Tooltip id="tt-pin-participant" placement="top" title={pinLabel}>
            <Button onClick={handlePinClick} variant="ghost">
              {pinnedId === sessionId ? (
                <UnpinIcon color={colors.baseText} size={20} />
              ) : (
                <PinIcon color={colors.baseText} size={20} />
              )}
              <VisuallyHidden>{pinLabel}</VisuallyHidden>
            </Button>
          </Tooltip>
          {!participant?.local && (
            <Tooltip
              placement="top"
              id="tt-hide-participant"
              title={t('people.hideParticipant')}
            >
              <Button onClick={handleHideClick} variant="ghost">
                <HiddenIcon color={colors.baseText} size={20} />
                <VisuallyHidden>{t('people.hideParticipant')}</VisuallyHidden>
              </Button>
            </Tooltip>
          )}

          {!participant?.local && (
            <TileRaportUser sessionId={sessionId} videoEl={videoEl} />
          )}
          {!participant?.local && (
            <Tooltip placement="top" id="tt-more" title={t('general.more')}>
              <MenuButton
                aria-controls={menuId}
                focusable
                id={btnId}
                onClick={handleMenuClick}
                open={showMenu}
                variant="ghost"
              >
                {' '}
                <EllipsisVerticalIcon />
              </MenuButton>
            </Tooltip>
          )}
          {showMenu && (
            <TileMenu
              align="right"
              sessionId={sessionId}
              aria-labelledby={btnId}
              id={menuId}
              onClose={() => setShowMenu(false)}
            />
          )}
        </Stack>
      </div>
      <style global jsx>{`
        .tile-actions {
          opacity: 0;
          position: absolute;
          right: 8px;
          top: 8px;
          transition: opacity 150ms ease;
        }
        .tile-actions .button {
          background: green;
          background: ${colors.background};
          padding: 4px;
        }
      `}</style>
    </>
  );
};
