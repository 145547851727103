import { Button } from '@daily/shared/components/Button';
import { Card, CardContent, CardFooter } from '@daily/shared/components/Card';
import { FocusTrap } from '@daily/shared/components/FocusTrap';
import { CloseIcon, ReverseIcon } from '@daily/shared/components/Icons';
import { Stack } from '@daily/shared/components/Stack';
import { VisuallyHidden } from '@daily/shared/components/VisuallyHidden';
import { useTranslation } from 'react-i18next';

import { GiphyGif } from './GiphyGif';

interface Props {
  onCancel(): void;
  onLoad?(): void;
  onRefresh(): void;
  onSubmit(): void;
  title: string;
  url: string;
}

export const GiphyPreview: React.FC<Props> = ({
  onCancel,
  onLoad,
  onRefresh,
  onSubmit,
  title,
  url,
}) => {
  const { t } = useTranslation();
  const handleLoad = () => {
    onLoad?.();
  };
  return (
    <div className="gifPreview">
      <Card spacing={8}>
        <CardContent style={{ padding: 0 }}>
          <GiphyGif
            style={{ borderRadius: 0 }}
            title={title}
            src={url}
            onLoad={handleLoad}
          />
        </CardContent>
        <CardFooter>
          <FocusTrap arrowKeys="leftright">
            <Stack horizontal>
              <Button onClick={onSubmit} variant="primary">
                {t('chat.submit')}
              </Button>
              <Button
                className="btn-refresh"
                onClick={onRefresh}
                variant="secondary"
              >
                <VisuallyHidden>{t('giphy.refresh')}</VisuallyHidden>
                <ReverseIcon size={16} />
              </Button>
              <Button
                className="btn-cancel"
                onClick={onCancel}
                variant="secondary"
              >
                <VisuallyHidden>{t('general.cancel')}</VisuallyHidden>
                <CloseIcon size={16} variant="thick" />
              </Button>
            </Stack>
          </FocusTrap>
        </CardFooter>
      </Card>
      <style jsx>{`
        .gifPreview :global(img) {
          display: block;
          width: 100%;
        }
        .gifPreview :global(.btn-refresh),
        .gifPreview :global(.btn-cancel) {
          padding-inline: 7px;
        }
      `}</style>
    </div>
  );
};
