import { useTheme } from '@daily/shared/contexts/Theme';
import {
  useDaily,
  useScreenShare,
  useVideoTrack,
} from '@daily-co/daily-react-hooks';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Portal } from 'react-portal';
import { useDeepCompareEffect } from 'use-deep-compare';
import {
  clearInterval as workerClearInterval,
  setInterval as workerSetInterval,
} from 'worker-timers';

import { useParticipants } from '../../contexts/ParticipantsProvider';
import { useViewMode } from '../../contexts/UIState';
import { useActiveSpeaker } from '../../hooks/useActiveSpeaker';
import { isSafari } from '../../lib/browserConfig';

const PIP_FPS = 15;

interface Props {
  enabled?: boolean;
  onEnterPIP?(): void;
  onLeavePIP?(): void;
}

/**
 * captureStream is still working draft and not ported to official types, yet.
 * https://stackoverflow.com/questions/50651091/unresolved-method-capturestream-on-htmlcanvaselement
 */
interface CanvasElement extends HTMLCanvasElement {
  captureStream(frameRate?: number): MediaStream;
}

/**
 * Renders videos in current viewMode in a hidden video element.
 * Compositing is done on the client using an invisible HTMLCanvasElement.
 */
export const PictureInPicture = forwardRef<HTMLVideoElement, Props>(
  (
    { enabled = false, onEnterPIP, onLeavePIP },
    pipRef: React.MutableRefObject<HTMLVideoElement>
  ) => {
    const { t } = useTranslation();
    const { colors } = useTheme();
    const daily = useDaily();
    const canvasRef = useRef<CanvasElement>(null);
    const viewMode = useViewMode();
    const { currentSpeakerId } = useParticipants();
    const activeSpeakerId = useActiveSpeaker();
    const { isSharingScreen } = useScreenShare();
    const speakerVideo = useVideoTrack(currentSpeakerId);
    const [shouldDraw, setShouldDraw] = useState(enabled);

    useEffect(() => {
      setShouldDraw(enabled);
    }, [enabled]);

    /**
     * Setup enter/leave event listeners.
     */
    useEffect(() => {
      const pipVideo = pipRef.current;
      if (!pipVideo) return;
      const handleEnterPIP = () => {
        onEnterPIP?.();
      };
      const handleLeavePIP = () => {
        onLeavePIP?.();
      };
      pipVideo.addEventListener('enterpictureinpicture', handleEnterPIP);
      pipVideo.addEventListener('leavepictureinpicture', handleLeavePIP);
      return () => {
        pipVideo.removeEventListener('enterpictureinpicture', handleEnterPIP);
        pipVideo.removeEventListener('leavepictureinpicture', handleLeavePIP);
      };
    }, [onEnterPIP, onLeavePIP, pipRef]);

    /**
     * Create Canvas.
     */
    useEffect(() => {
      canvasRef.current = document.createElement('canvas') as CanvasElement;
      canvasRef.current.width = 1280;
      canvasRef.current.height = 720;
    }, []);

    /**
     * Safari workaround: Canvas.captureStream results in red box.
     * We'll apply the current speaker's video track directly instead.
     * In case current speaker is muted, we'll fallback to a black canvas stream.
     */
    useDeepCompareEffect(() => {
      const canvas = canvasRef.current;
      const pipVideo = pipRef.current;
      if (!canvas || !pipVideo || !isSafari()) return;
      const canvasContext = canvas.getContext('2d');

      canvasContext.rect(0, 0, canvas.width, canvas.height);
      canvasContext.fillStyle = '#000';
      canvasContext.fill();

      const canvasStream = canvas.captureStream();

      pipVideo.srcObject =
        ['playable', 'sendable'].includes(speakerVideo.state) &&
        speakerVideo?.persistentTrack instanceof MediaStreamTrack
          ? new MediaStream([speakerVideo?.persistentTrack])
          : canvasStream;
    }, [pipRef, speakerVideo]);

    /**
     * Initiate the draw loop.
     * NOTE: Getting the Canvas stream doesn't work in Safari.
     * TODO: Move rendering to [OffscreenCanvas](https://developer.mozilla.org/en-US/docs/Web/API/OffscreenCanvas).
     */
    useEffect(() => {
      const canvas = canvasRef.current;
      const pipVideo = pipRef.current;
      if (!canvas || !pipVideo || isSafari()) return;
      pipVideo.srcObject = canvas?.captureStream?.(PIP_FPS);
      const canvasContext = canvas.getContext('2d');

      if (!shouldDraw) {
        const cWidth = canvas.width;
        const cHeight = canvas.height;
        canvasContext.clearRect(0, 0, cWidth, cHeight);
        return;
      }

      const draw = () => {
        const cWidth = canvas.width;
        const cHeight = canvas.height;
        canvasContext.clearRect(0, 0, cWidth, cHeight);

        /**
         * Returns truncated text, based on passed maxWidth.
         */
        const fittingString = (
          ctx: CanvasRenderingContext2D,
          text: string,
          maxWidth: number
        ) => {
          var width = ctx.measureText(text).width;
          var ellipsis = '…';
          var ellipsisWidth = ctx.measureText(ellipsis).width;
          if (width <= maxWidth || width <= ellipsisWidth) {
            return text;
          } else {
            var len = text.length;
            while (width >= maxWidth - ellipsisWidth && len-- > 0) {
              text = text.substring(0, len);
              width = ctx.measureText(text).width;
            }
            return text + ellipsis;
          }
        };

        const drawVideo = (
          video: HTMLVideoElement,
          x: number,
          y: number,
          w: number,
          h: number,
          name: string
        ) => {
          if (!video) return;
          const stream = video.srcObject as MediaStream;
          if (
            video.paused ||
            (stream?.getVideoTracks?.()?.[0]?.muted ?? true)
          ) {
            // Participant is muted
            canvasContext.fillStyle = colors.custom.mainAreaBgAccent;
            canvasContext.fillRect(x, y, w, h);
            canvasContext.font = `bold ${w / 16}px sans-serif`;
            canvasContext.textBaseline = 'middle';
            canvasContext.textAlign = 'center';
            canvasContext.fillStyle = colors.custom.mainAreaText;
            const maxWidth = 0.8 * w;
            canvasContext.fillText(
              fittingString(canvasContext, name, maxWidth),
              x + w / 2,
              y + h / 2,
              maxWidth
            );
            return;
          }
          const videoAR = video.videoWidth / video.videoHeight;
          // Determine centered position, in case video doesn't match 16:9 aspect ratio
          const centeredW = videoAR < 16 / 9 ? h * videoAR : w;
          const centeredX = videoAR < 16 / 9 ? x + (w - centeredW) / 2 : x;
          const centeredH = videoAR > 16 / 9 ? w / videoAR : h;
          const centeredY = videoAR > 16 / 9 ? y + (h - centeredH) / 2 : y;
          // Apply mirroring on Canvas
          const isMirrored = video.classList.contains('isMirrored');
          // Correct X position when potentially flipped
          const rx = isMirrored ? cWidth - (centeredX + centeredW) : centeredX;
          /**
           * We can't easily render a flipped image, but we can flip the canvas,
           * render the image and flip the canvas back again!
           * It's like painting on a transparent sheet of paper 🧑‍🎨
           */
          if (isMirrored) {
            /**
             * Flip the canvas.
             * .scale() happens at x=0,y=0.
             * .translate() shifts the canvas back in view.
             */
            canvasContext.translate(cWidth, 0);
            canvasContext.scale(-1, 1);
          }
          canvasContext.drawImage(video, rx, centeredY, centeredW, centeredH);
          if (isMirrored) {
            /**
             * Flip the canvas again.
             */
            canvasContext.scale(-1, 1);
            canvasContext.translate(-cWidth, 0);
          }
        };

        const drawActiveBorder = (
          x: number,
          y: number,
          w: number,
          h: number
        ) => {
          canvasContext.beginPath();
          canvasContext.moveTo(x, y);
          canvasContext.lineTo(x + w, y);
          canvasContext.lineTo(x + w, y + h);
          canvasContext.lineTo(x, y + h);
          canvasContext.lineTo(x, y);
          canvasContext.lineWidth = 4;
          canvasContext.strokeStyle = colors.system.yellow;
          canvasContext.stroke();
        };

        const participants = daily.participants();
        const getName = (video: HTMLVideoElement) => {
          if (!video) return '';
          const tile = video.closest('[id]');
          const id = tile.getAttribute('id');
          const name =
            participants?.local?.session_id === id
              ? participants?.local?.user_name
                ? t('people.you')
                : t('people.youGuest')
              : participants[id]?.user_name || t('people.guest');
          return name;
        };

        const shouldDrawGrid = viewMode === 'grid' || isSharingScreen;

        if (shouldDrawGrid) {
          const selector =
            viewMode === 'grid'
              ? '.grid video'
              : '.speaker .sidebar .tile:not(.local) video';
          const videos = Array.from(
            document.querySelectorAll(selector)
          ) as HTMLVideoElement[];
          const localVideo = document.querySelector(
            '.local video'
          ) as HTMLVideoElement;
          if (!videos.length && localVideo) {
            videos.push(localVideo);
          }
          const n = videos.length;
          const cols = Math.ceil(Math.sqrt(n));
          const rows = Math.ceil(n / cols);
          const width = cWidth / cols;
          const height = width * (9 / 16);
          const dy = (cHeight - rows * height) / 2;
          let active: number[];
          videos.forEach((video, i) => {
            const tile = video.closest('[id]');
            const id = tile.getAttribute('id');
            const col = i % cols;
            const row = Math.floor(i / cols);
            const dx =
              row === rows - 1 && n % cols > 0
                ? (cWidth - (n % cols) * width) / 2
                : 0;
            const x = dx + col * width;
            const y = dy + row * height;
            drawVideo(video, x, y, width, height, getName(video));
            if (id === activeSpeakerId) {
              active = [x, y, width, height];
            }
          });
          if (active) drawActiveBorder.call(this, ...active);
        } else {
          const speakerVideo = document.querySelector(
            `[id="${currentSpeakerId}"] video`
          ) as HTMLVideoElement;
          drawVideo(speakerVideo, 0, 0, cWidth, cHeight, getName(speakerVideo));
        }
      };

      const interval = workerSetInterval(draw, 1000 / PIP_FPS);

      return () => {
        workerClearInterval(interval);
      };
    }, [
      activeSpeakerId,
      colors.custom.mainAreaBgAccent,
      colors.custom.mainAreaText,
      colors.system.yellow,
      currentSpeakerId,
      daily,
      isSharingScreen,
      pipRef,
      shouldDraw,
      t,
      viewMode,
    ]);

    return (
      <>
        <Portal>
          <video id="pip-video" autoPlay playsInline ref={pipRef} />
        </Portal>
        <style jsx>{`
          #pip-video {
            height: 24px;
            right: 0;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            top: calc(var(--banner-height) + 4px);
          }
        `}</style>
      </>
    );
  }
);
PictureInPicture.displayName = 'PictureInPicture';
