import { Button } from '@daily/shared/components/Button';
import { Modal } from '@daily/shared/components/Modal';
import { Text } from '@daily/shared/components/Text';
import { useDevices } from '@daily-co/daily-react-hooks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends React.ComponentProps<typeof Modal> {}

export const DeviceInUseModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { cameras, camState } = useDevices();

  const camInUse = camState === 'in-use';

  const modalTitle = useMemo(
    () =>
      // i18next-extract-mark-context-next-line ["camera", "other"]
      t('haircheck.deviceInUse.title', {
        context: camInUse ? 'camera' : 'other',
      }),
    [camInUse, t]
  );

  const modalContent = useMemo(() => {
    if (camInUse) {
      return t('haircheck.deviceInUse.desc_camera', {
        count: cameras.length,
      });
    }
    return t('haircheck.deviceInUse.desc_other');
  }, [camInUse, cameras.length, t]);

  return (
    <Modal
      actions={[
        <Button key="close" variant="primary">
          {props.closeText}
        </Button>,
      ]}
      title={modalTitle}
      {...props}
    >
      <Text El="p" variant="large">
        {modalContent}
      </Text>
    </Modal>
  );
};
