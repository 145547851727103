import { Button } from '@daily/shared/components/Button';
import { Modal } from '@daily/shared/components/Modal';
import { Text } from '@daily/shared/components/Text';
import { useDaily } from '@daily-co/daily-react-hooks';
import { useTranslation } from 'react-i18next';

import { useParticipants } from '../../contexts/ParticipantsProvider';

interface Props {
  onClose(): void;
}

export const RemoveParticipantModal: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const daily = useDaily();
  const { participantMarkedForRemoval, setParticipantMarkedForRemoval } =
    useParticipants();

  const handleRemoveClick = (close) => {
    daily.updateParticipant(participantMarkedForRemoval.session_id, {
      eject: true,
    });
    setParticipantMarkedForRemoval(null);
    close();
  };

  return (
    <Modal
      actions={[
        <Button key="cancel" variant="secondary">
          {t('general.cancel')}
        </Button>,
        <Button key="remove" onClick={handleRemoveClick} variant="danger">
          {t('people.removeParticipant')}
        </Button>,
      ]}
      onClose={onClose}
      title={t('people.removeParticipant')}
    >
      <Text variant="large">
        {t('people.confirmRemovalText', {
          name: participantMarkedForRemoval.user_name || t('people.guest'),
        })}
      </Text>
    </Modal>
  );
};
