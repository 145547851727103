import { EffectsIcon, EllipsisIcon } from '@daily/shared/components/Icons';
import { Menu } from '@daily/shared/components/Menu';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { Tooltip } from '@daily/shared/components/Tooltip';
import DailyIframe from '@daily-co/daily-js';
import { StatefulDevice } from '@daily-co/daily-react-hooks';
import classnames from 'classnames';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCallState } from '../../contexts/CallProvider';
import {
  useIsMobile,
  useNameModal,
  useSettingsView,
} from '../../contexts/UIState';
import { useCallConfig } from '../../hooks/useCallConfig';
import { useStyleVariants } from '../../hooks/useStyleVariants';
import { robotsClassName } from '../../lib/robots';
import { NameModal } from '../NameModal';
import { SettingsModal } from '../SettingsModal';
import { AudioControls } from '../Tray/AudioControls';
import { TrayButton } from '../Tray/TrayButton';
import { VideoControls } from '../Tray/VideoControls';
import { GeneralState } from './Setup';

interface Props {
  micState: GeneralState;
  camState: GeneralState;
  hasMicError: boolean;
  cameras: StatefulDevice[];
}

export const SetupTray: React.FC<Props> = ({
  cameras,
  camState,
  hasMicError,
  micState,
}) => {
  const { t } = useTranslation();
  const { disableAudio } = useCallState();
  const { enableVideoProcessingUI } = useCallConfig();
  const [isMobile] = useIsMobile();
  const [showMenu, setShowMenu] = useState(false);
  const [settingsView, setSettingsView] = useSettingsView();
  const [showNameModal, setShowNameModal] = useNameModal();
  const { textVariant } = useStyleVariants();

  const menuId = 'trayMoreMenu';
  const btnId = 'trayMoreButton';

  const handleSettingsModalClose = () => {
    setSettingsView(null);
  };

  const handleSettingsButtonClick = () => {
    setSettingsView('devices');
    setShowMenu(false);
  };
  const handleMenuClick = () => {
    setShowMenu(true);
  };
  const handleMenuClose = () => {
    setShowMenu(false);
  };
  const handleChangeNameClick = () => {
    setShowNameModal(true);
    setShowMenu(false);
  };
  const handleEffectsClick = () => {
    setSettingsView('effects');
  };
  const handleNameModalClose = () => {
    setShowNameModal(false);
  };

  const supportsBackgroundEffects = useMemo(
    () =>
      DailyIframe.supportedBrowser().supportsVideoProcessing &&
      !isMobile &&
      enableVideoProcessingUI,
    [enableVideoProcessingUI, isMobile]
  );

  return (
    <>
      <Stack
        align="center"
        justify="space-between"
        horizontal
        padding={'8'}
        gap={0}
      >
        <Stack align="center" gap={4} horizontal>
          <VideoControls shouldEnableVideoMenu={false} />
          {!disableAudio && (
            <Tooltip
              /**
               * In case a cam is available, but no mic, we want to render
               * the video tile anyway, because we'll request cam & mic access.
               * In that case the tooltip helps directing the user's attention
               * to their mic not working.
               */
              id="tt-no-mic"
              // i18next-extract-mark-context-next-line ["camera", "mic", "both"]
              title={t('haircheck.noDevices.title', {
                context: 'mic',
              })}
              disabled={micState !== 'not-found'}
              placement="top"
              open={!!hasMicError}
            >
              <AudioControls shouldEnableAudioMenu={false} />
            </Tooltip>
          )}
          {supportsBackgroundEffects && (
            <div className="effects-btn-container">
              <Tooltip id="tt-btn-effects" title={t('general.effects')}>
                <TrayButton
                  id="btn-effects"
                  className={classnames('btn', robotsClassName('effects-btn'))}
                  disabled={camState === 'pending' || cameras.length === 0}
                  onClick={handleEffectsClick}
                >
                  <EffectsIcon size={24} />
                  {/* <Text>{t('general.effects')}</Text> */}
                </TrayButton>
              </Tooltip>
            </div>
          )}
        </Stack>
        <Tooltip id="tt-more-btn" title={t('general.more')}>
          <TrayButton
            aria-controls={menuId}
            id={btnId}
            className={classnames('btn', robotsClassName('more-btn'))}
            onClick={handleMenuClick}
          >
            <EllipsisIcon />
            {/* <Text>{t('general.more')}</Text> */}
          </TrayButton>
        </Tooltip>
      </Stack>
      {showMenu && (
        <Menu
          align="right"
          placement="top"
          offsetTop={5}
          aria-labelledby={btnId}
          id={menuId}
          className={classnames(robotsClassName('more-menu'))}
          cardProps={isMobile ? { style: { borderRadius: 8 } } : null}
          items={[
            {
              className: robotsClassName('btn-change-name'),
              label: <Text variant={textVariant}>{t('people.rename')}</Text>,
              onClick: handleChangeNameClick,
            },
            {
              className: robotsClassName('btn-settings'),
              label: (
                <Text variant={textVariant}>
                  {t('general.settingsEllipsis')}
                </Text>
              ),
              onClick: handleSettingsButtonClick,
            },
          ]}
          onClose={handleMenuClose}
        />
      )}
      {showNameModal && <NameModal onClose={handleNameModalClose} />}
      {settingsView && <SettingsModal onClose={handleSettingsModalClose} />}
      <style jsx>{`
        .effects-btn-container :global(#btn-effects[disabled]) {
          opacity: 0.4;
        }
      `}</style>
    </>
  );
};
