import { Button } from '@daily/shared/components/Button';
import { Modal } from '@daily/shared/components/Modal';
import { Text } from '@daily/shared/components/Text';
import { useTranslation } from 'react-i18next';

import { useCallState } from '../../contexts/CallProvider';

interface Props extends React.ComponentProps<typeof Modal> {}

export const LeaveWhileRecordingModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { leaveCall } = useCallState();

  const handleLeave = () => {
    leaveCall();
  };

  return (
    <Modal
      actions={[
        <Button key="stay">{t('recording.leaveModal.stayInMeeting')}</Button>,
        <Button key="leave" onClick={handleLeave} variant="secondary">
          {t('recording.leaveModal.leaveWithoutSaving')}
        </Button>,
      ]}
      title={t('recording.leaveModal.title')}
      {...props}
    >
      <Text variant="large">{t('recording.leaveModal.content')}</Text>
    </Modal>
  );
};
