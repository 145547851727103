import { SVGProps } from 'react';

export function StaffIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="32" height="31" viewBox="0 0 32 31" fill="none" {...props}>
      <path
        fill="#8A98FF"
        d="m11.127 30.605-2.763-4.622-5.491-1.102.618-5.227L0 15.672l3.49-3.947-.617-5.227 5.49-1.102L11.128.738 16 2.943 20.873.738l2.8 4.658 5.454 1.102-.618 5.227L32 15.672l-3.49 3.982.617 5.227-5.454 1.102-2.8 4.622L16 28.4l-4.873 2.204Zm3.31-10.204 8.254-8-1.636-1.458-6.619 6.4-3.454-3.52-1.673 1.6 5.127 4.978Z"
      />
    </svg>
  );
}
