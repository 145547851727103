import React, { createContext, useEffect, useState } from 'react';

interface Props {
  id: string;
  initialIndex?: number;
  vertical?: boolean;
}

interface TabsContextValue {
  activeTab: number;
  id: string;
  setTab(index: number): void;
  vertical: boolean;
}

export const TabsContext = createContext<TabsContextValue>({
  activeTab: 0,
  id: null,
  setTab: null,
  vertical: false,
});

export const Tabs: React.FC<Props> = ({
  children,
  id,
  initialIndex = 0,
  vertical = false,
}) => {
  const [activeTab, setActiveTab] = useState(null);

  const setTab = (index: number) => {
    setActiveTab(index);
  };

  useEffect(() => {
    setActiveTab(initialIndex);
  }, [initialIndex]);

  return (
    <TabsContext.Provider
      value={{
        activeTab,
        id,
        setTab,
        vertical,
      }}
    >
      {children}
    </TabsContext.Provider>
  );
};
