import { Button } from '@daily/shared/components/Button';
import { Card, CardContent } from '@daily/shared/components/Card';
import { FocusTrap } from '@daily/shared/components/FocusTrap';
import { AddEmojiIcon, CloseIcon } from '@daily/shared/components/Icons';
import { Text } from '@daily/shared/components/Text';
import { VisuallyHidden } from '@daily/shared/components/VisuallyHidden';
import { useTheme } from '@daily/shared/contexts/Theme';
import { useClosable } from '@daily/shared/hooks/useClosable';
import classNames from 'classnames';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChatMessage, Reaction } from '../../contexts/ChatProvider';
import { defaultReactions } from './ChatReactions';

interface Props {
  message: ChatMessage;
  onClickReaction?(reaction: string): void;
  reactions?: Reaction[];
}

export const AddReaction: React.FC<Props> = ({
  message,
  onClickReaction,
  reactions = defaultReactions,
}) => {
  const { t } = useTranslation();
  const { zIndex } = useTheme();
  const [showMenu, setShowMenu] = useState(false);

  const menuRef = useRef(null);

  const handleClickReaction = (reaction) => {
    onClickReaction?.(reaction);
    setShowMenu(false);
  };
  const handleAddReactionClick = () => {
    setShowMenu((show) => !show);
  };

  useClosable(
    {
      onClose: useCallback(() => setShowMenu(false), []),
      ref: menuRef,
    },
    [showMenu]
  );

  return (
    <div className="add-reaction-wrapper">
      <FocusTrap active={showMenu}>
        {showMenu ? (
          <div ref={menuRef} className="reactions-menu">
            <Card noBorder spacing={8} shadow="strong">
              <CardContent>
                {reactions.map((reaction) => (
                  <Button
                    key={reaction}
                    className={classNames('reaction', {
                      reacted: message.reactions?.[reaction]?.hasReacted,
                    })}
                    onClick={() => handleClickReaction(reaction)}
                    variant="ghost"
                  >
                    <Text El="span" variant="large">
                      {reaction}
                    </Text>
                  </Button>
                ))}
              </CardContent>
            </Card>
          </div>
        ) : null}
        <Button
          className={classNames('add-reaction', {
            visible: showMenu,
          })}
          type="button"
          onClick={handleAddReactionClick}
          title={t('chat.addReaction')}
          variant="secondary"
        >
          {showMenu ? (
            <CloseIcon size={16} variant="thick" />
          ) : (
            <AddEmojiIcon size={16} />
          )}
          <VisuallyHidden>{t('chat.addReaction')}</VisuallyHidden>
        </Button>
      </FocusTrap>
      <style jsx>{`
        .add-reaction-wrapper {
          position: absolute;
          right: -8px;
          top: min(calc(50% - 12px), 8px);
        }
        .add-reaction-wrapper :global(.add-reaction) {
          border: none;
          border-radius: 100%;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04),
            0px 0px 4px rgba(0, 0, 0, 0.04);
          opacity: 0;
          padding: 4px;
          transition: opacity 150ms ease;
          z-index: 1;
        }
        :global(.msg:hover) .add-reaction-wrapper :global(.add-reaction),
        :global(.msg)
          .add-reaction-wrapper
          :global(.add-reaction:focus-visible),
        :global(.msg) .add-reaction-wrapper :global(.add-reaction.visible) {
          opacity: 1;
        }
        .reactions-menu {
          bottom: 16px;
          position: absolute;
          right: 16px;
          z-index: ${zIndex.menu};
        }
        .reactions-menu :global(.card) {
          border-radius: 8px;
          height: auto;
          width: auto;
        }
        .reactions-menu :global(.card-content) {
          display: grid;
          gap: 4px;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        }
        .add-reaction-wrapper :global(.reaction) {
          display: inline-flex;
          justify-content: center;
          padding: 0 8px;
        }
      `}</style>
    </div>
  );
};
