import { Button } from '@daily/shared/components/Button';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import {
  useLocalParticipant,
  useWaitingParticipants,
} from '@daily-co/daily-react-hooks';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from '../../contexts/UIState';
import { useWaitingRoom } from '../../contexts/WaitingRoomProvider';
import { WaitingParticipantRow } from '../WaitingRoom';

/**
 * Renders a list of all [waiting participants](https://docs.daily.co/reference/daily-js/instance-methods/waiting-participants) and actions for each.
 * Does not render, when the local participant is not an [owner](https://docs.daily.co/guides/how-daily-works/controlling-who-joins-a-meeting#meeting-owner-privileges).
 */
export const WaitingList: React.FC = () => {
  const { t } = useTranslation();
  const { setShowModal } = useWaitingRoom();
  const { waitingParticipants } = useWaitingParticipants();
  const [isMobile] = useIsMobile();

  const localParticipant = useLocalParticipant();

  const handleManageAllClick = () => setShowModal(true);

  const hasMultiplePeopleWaiting = waitingParticipants.length > 1;

  if (!localParticipant?.owner || waitingParticipants.length === 0) return null;

  return (
    <Stack gap={isMobile ? 16 : 4}>
      <Stack align="center" justify="space-between" horizontal>
        <Text color="muted" variant="small">
          {hasMultiplePeopleWaiting
            ? t('waitingRoom.peopleWouldLikeToJoin_plural', {
                amount: waitingParticipants.length,
              })
            : t('waitingRoom.peopleWouldLikeToJoin', {
                amount: waitingParticipants.length,
              })}
        </Text>
        {waitingParticipants.length > 1 && (
          <Button onClick={handleManageAllClick} variant="ghost">
            <Text color="success" variant="small">
              {t('waitingRoom.manageAll')}
            </Text>
          </Button>
        )}
      </Stack>
      {waitingParticipants.map((p) => (
        <WaitingParticipantRow key={p.id} participant={p} />
      ))}
    </Stack>
  );
};
