import { Button } from '@daily/shared/components/Button';
import { Modal } from '@daily/shared/components/Modal';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { useTheme } from '@daily/shared/contexts/Theme';
import Bowser from 'bowser';
import classNames from 'classnames';
import getConfig from 'next/config';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { isSafari } from '../../lib/browserConfig';

interface Props extends React.ComponentProps<typeof Modal> {
  error: 'blocked-by-os' | 'blocked-by-browser';
}

export const ScreenShareErrorModal: React.FC<Props> = ({ error, ...props }) => {
  const { t } = useTranslation();
  const { isDarkMode } = useTheme();
  const { assetPrefix } = getConfig().publicRuntimeConfig;

  const FALLBACK_BROWSER = 'Chrome';
  const SUPPORTED_BROWSERS = [
    'Chrome',
    'Firefox',
    'Microsoft Edge',
    'Opera',
    'Safari',
  ];

  const [browser, setBrowser] = useState<Bowser.Parser.ParsedResult>(null);
  const browserName = SUPPORTED_BROWSERS.includes(browser?.browser?.name)
    ? browser?.browser?.name
    : FALLBACK_BROWSER;

  useEffect(() => {
    setBrowser(Bowser.parse(navigator.userAgent));
  }, []);

  return (
    <Modal
      actions={[
        <Button key="dismiss" variant="secondary">
          {t('general.dismiss')}
        </Button>,
      ]}
      title={t('screenShare.errorTitle')}
      {...props}
    >
      {error === 'blocked-by-os' && (
        <Stack>
          <Text variant="large" El="p">
            {t('screenShare.errorBlockedByOsSub', { browserName })}
          </Text>
          <ol>
            <Text variant="large" El="li">
              {
                <Trans i18nKey="screenShare.errorBlockedByOsStep1">
                  On your Mac, choose Apple menu{' '}
                  <img
                    alt=""
                    className="blocked-icon"
                    src={`${assetPrefix}/unblock/apple-logo-${
                      isDarkMode ? 'dark' : 'light'
                    }.png`}
                    height="24"
                  />{' '}
                  &gt; System Preferences, then click Sharing
                  <img
                    alt=""
                    className="blocked-icon"
                    src={`${assetPrefix}/unblock/apple-security-${
                      isDarkMode ? 'dark' : 'light'
                    }.png`}
                    height="24"
                  />
                </Trans>
              }
            </Text>
            <Text variant="large" El="li">
              {t('screenShare.errorBlockedByOsStep2')}
            </Text>
            <Text variant="large" El="li">
              {t('screenShare.errorBlockedByOsStep3', {
                browserName,
              })}
            </Text>
            <Text variant="large" El="li">
              {t('screenShare.errorBlockedByOsStep4', {
                browserName,
              })}
            </Text>
          </ol>
        </Stack>
      )}
      {error === 'blocked-by-browser' && (
        <>
          {isSafari() && (
            <Stack style={{ marginBottom: '8px' }}>
              <Text variant="large" El="p">
                <Trans i18nKey="screenShare.errorBlockedByBrowserSafari" />
              </Text>
            </Stack>
          )}

          <ol className={classNames({ safari: isSafari() })}>
            <Text variant="large" El="li">
              {
                // i18next-extract-mark-context-next-line ["Firefox", "Safari"]
                <Trans
                  i18nKey="screenShare.errorBlockedByBrowserStep1"
                  tOptions={{
                    context: isSafari(15) ? 'Safari15' : browserName,
                  }}
                  values={{ url: window.location.hostname }}
                >
                  Click the screen share icon{' '}
                  <img
                    alt=""
                    className="blocked-icon"
                    src={`${assetPrefix}/unblock/firefox-screenshare-${
                      isDarkMode ? 'dark' : 'light'
                    }.png`}
                    height="24"
                  />{' '}
                  in your browser&apos;s address bar
                </Trans>
              }
            </Text>
            <Text variant="large" El="li">
              {
                // i18next-extract-mark-context-next-line ["Firefox", "Safari"]
                t('screenShare.errorBlockedByBrowserStep2', {
                  context: browserName,
                })
              }
            </Text>
            <Text variant="large" El="li">
              {
                // i18next-extract-mark-context-next-line ["Firefox", "Safari"]
                t('screenShare.errorBlockedByBrowserStep3', {
                  context: browserName,
                })
              }
            </Text>
          </ol>
        </>
      )}
      <style jsx>{`
        .blocked-icon {
          vertical-align: bottom;
        }
        ol {
          padding: 0 0 0 24px;
          text-align: left;
        }
        ol.safari {
          margin-left: 16px;
        }
      `}</style>
    </Modal>
  );
};
