import { useTheme } from '@daily/shared/contexts/Theme';
import {
  useLocalParticipant,
  useNetwork,
  useParticipantIds,
} from '@daily-co/daily-react-hooks';
import React, { CSSProperties, useCallback, useMemo, useState } from 'react';

import { useActiveSpeaker } from '../../hooks/useActiveSpeaker';
import { useResizeObserver } from '../../hooks/useResizeObserver';
import { DEFAULT_ASPECT_RATIO } from '../../lib/constants';
import { Tile } from '../Tile';

interface Props {
  isScreen?: boolean;
  screenRef: React.MutableRefObject<HTMLElement>;
  sessionId: string;
  showNames: boolean;
}

const MAX_RATIO = DEFAULT_ASPECT_RATIO;
const MIN_RATIO = 4 / 3;

export const SpeakerTile: React.FC<Props> = ({
  isScreen,
  screenRef,
  sessionId,
  showNames,
}) => {
  const { colors } = useTheme();
  const [ratio, setRatio] = useState(MAX_RATIO);
  const [nativeAspectRatio, setNativeAspectRatio] = useState(null);
  const [screenHeight, setScreenHeight] = useState(1);
  const activeSpeakerId = useActiveSpeaker();

  const participantIds = useParticipantIds();
  const localParticipant = useLocalParticipant();

  const { threshold } = useNetwork();

  useResizeObserver(
    screenRef,
    useCallback(() => {
      if (!screenRef.current) return;
      const { height, width } = screenRef.current.getBoundingClientRect();
      setRatio(width / height);
      setScreenHeight(height);
    }, [screenRef])
  );

  /**
   * Only use the video's native aspect ratio if it's in portrait mode
   * (e.g. mobile) to update how we crop videos. Otherwise, use landscape
   * defaults.
   */
  const handleNativeAspectRatio = (ratio: number) => {
    const isPortrait = ratio < 1;
    setNativeAspectRatio(isPortrait ? ratio : null);
  };

  const { finalRatio, height, videoFit } = useMemo<{
    height: CSSProperties['height'];
    finalRatio: number;
    videoFit: React.ComponentProps<typeof Tile>['videoFit'];
  }>(() => {
    const height = (nativeAspectRatio ?? ratio) >= MIN_RATIO ? '100%' : null;
    // Avoid cropping mobile videos, which have the nativeAspectRatio set
    const finalRatio =
      nativeAspectRatio || (ratio <= MIN_RATIO ? MIN_RATIO : MAX_RATIO);
    const videoFit =
      ratio >= MAX_RATIO || nativeAspectRatio ? 'contain' : 'cover';
    return {
      height,
      finalRatio,
      videoFit,
    };
  }, [nativeAspectRatio, ratio]);

  const style = {
    height,
    maxWidth: screenHeight * finalRatio,
    outline: `1px solid ${colors.custom.mainAreaBg}`,
    overflow: 'hidden',
  };

  return (
    <Tile
      aspectRatio={finalRatio}
      hideActions={participantIds.length === 1}
      isScreen={isScreen}
      isSpeaking={sessionId === activeSpeakerId}
      network={sessionId === localParticipant?.session_id ? threshold : null}
      sessionId={sessionId}
      showNames={showNames}
      style={style}
      videoFit={videoFit}
      onVideoResize={handleNativeAspectRatio}
    />
  );
};
