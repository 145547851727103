import classnames from 'classnames';
import React from 'react';

import { useTheme } from '../../contexts/Theme';
import { Anchor } from '../Anchor';
import { Text } from '../Text';

interface Props extends React.HTMLAttributes<HTMLElement> {
  href?: string;
  HrefComponent?: React.FC<{ href: string }>;
  variant?: 'default' | 'success' | 'warning' | 'error' | 'info';
  textProps?: React.ComponentProps<typeof Text>;
}

export const Badge: React.FC<Props> = ({
  children,
  className,
  href,
  HrefComponent,
  variant = 'default',
  textProps = {},
  ...props
}) => {
  const { isDarkMode } = useTheme();
  const classNames = classnames(className, variant);
  const textColor = isDarkMode && variant !== 'default' ? 'inverse' : 'default';

  const HrefC = HrefComponent ?? Anchor;

  return (
    <>
      {href ? (
        <HrefC href={href}>
          <span className={classNames} {...props}>
            <Text color={textColor} variant="xsmall" {...textProps}>
              {children}
            </Text>
          </span>
        </HrefC>
      ) : (
        <span className={classNames} {...props}>
          <Text color={textColor} variant="xsmall" {...textProps}>
            {children}
          </Text>
        </span>
      )}
      <style jsx>{`
        span {
          align-items: center;
          border-radius: 4px;
          display: flex;
          padding: 0 4px;
          width: auto;
        }
        span :global(svg) {
          margin-right: 2px;
        }
        .default {
          background-color: var(--badge-default-bg);
          border: 1px solid var(--badge-default-border);
        }
        .success {
          background-color: var(--badge-success-bg);
          border: 1px solid var(--badge-success-border);
        }
        .warning {
          background-color: var(--badge-warning-bg);
          border: 1px solid var(--badge-warning-border);
        }
        .error {
          background-color: var(--badge-error-bg);
          border: 1px solid var(--badge-error-border);
        }
        .info {
          background-color: var(--badge-info-bg);
          border: 1px solid var(--badge-info-border);
        }
      `}</style>
    </>
  );
};
