import { Anchor } from '@daily/shared/components/Anchor';
import { LoadingSpinner } from '@daily/shared/components/LoadingSpinner';
import { Stack, StackItem } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { useTheme } from '@daily/shared/contexts/Theme';
import { useMediaQuery } from '@daily/shared/hooks/useMediaQuery';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCallConfig } from '../../hooks/useCallConfig';
import { robotsClassName } from '../../lib/robots';
import { CTAButton } from './CTAButton';
import { LockedMessage } from './LockedMessage';
import { AccessState, GeneralState, RequestState } from './Setup';

interface Props {
  accessState: AccessState;
  requestState: RequestState;
  onCancelClick(): void;
  onComplete(): void;
  hasCamError: boolean;
  hasMicError: boolean;
  camState: GeneralState;
  micState: GeneralState;
  shouldUseLandscapeLayout: boolean;
}
export const CTAContent: React.FC<Props> = ({
  accessState,
  camState,
  hasCamError,
  hasMicError,
  micState,
  onCancelClick,
  onComplete,
  requestState,
  shouldUseLandscapeLayout,
}) => {
  const { t } = useTranslation();
  const { mediaQueries } = useTheme();
  const { broadcastRole } = useCallConfig();
  const isLargeScreen = useMediaQuery(mediaQueries.large);
  const isSmallScreen = useMediaQuery(mediaQueries.small);

  // When camera is mandatory for the rooms, we should look for the query params in url.
  // For instance, if ?video=forced, that it means that camera is required for the room.
  const router = useRouter();
  const isCameraRequired = router.query['video'] === 'forced';

  const hasNoErrors = !hasCamError && !hasMicError;

  const joinWithoutText = useMemo(() => {
    if (accessState === 'lobby') {
      let requestJoinWithoutX = t('haircheck.setup.requestJoinWithoutDevices');
      if (hasCamError && !hasMicError) {
        requestJoinWithoutX = t('haircheck.setup.requestJoinWithoutCam');
      }
      if (hasMicError && !hasCamError) {
        requestJoinWithoutX = t('haircheck.setup.requestJoinWithoutMic');
      }
      return requestJoinWithoutX;
    }

    let joinWithoutX = t('haircheck.setup.joinWithoutDevices');
    if (hasCamError && !hasMicError) {
      joinWithoutX = t('haircheck.setup.joinWithoutCam');
    }
    if (hasMicError && !hasCamError) {
      joinWithoutX = t('haircheck.setup.joinWithoutMic');
    }
    return joinWithoutX;
  }, [accessState, hasCamError, hasMicError, t]);

  return (
    <>
      {requestState === 'waiting' ? (
        <Stack align="center">
          <Stack
            horizontal={!shouldUseLandscapeLayout && !isSmallScreen}
            style={{ textAlign: 'center' }}
          >
            <StackItem style={{ display: 'flex', justifyContent: 'center' }}>
              <LoadingSpinner />
            </StackItem>
            <Text variant="largestrong">
              {t('haircheck.setup.titleWaiting')}
            </Text>
          </Stack>
          <Anchor
            onClick={onCancelClick}
            underline
            className={robotsClassName('btn-cancel-request')}
          >
            {t('haircheck.setup.cancelRequest')}
          </Anchor>
        </Stack>
      ) : hasNoErrors ? (
        <div
          className={classnames('no-errors-content', {
            horizontal: !shouldUseLandscapeLayout && !isSmallScreen,
            landscape: shouldUseLandscapeLayout,
          })}
        >
          <StackItem style={{ width: isSmallScreen && '100%' }}>
            <CTAButton
              className={robotsClassName('btn-join')}
              disabled={camState !== 'granted' && micState !== 'granted'}
              onClick={onComplete}
              fullWidth={isSmallScreen}
              size={'large'}
            >
              <strong style={{ fontSize: '1.3rem' }}>
                {broadcastRole === 'owner'
                  ? t('haircheck.setup.joinBroadcast')
                  : accessState === 'lobby'
                  ? t('haircheck.setup.requestToJoin')
                  : t('general.joinMeeting')}
              </strong>
            </CTAButton>
          </StackItem>
          {(shouldUseLandscapeLayout || isSmallScreen) && (
            <div style={!isLargeScreen ? { flex: '1 1 100%' } : {}}>
              {accessState === 'lobby' && <LockedMessage />}
              {broadcastRole === 'owner' && (
                <Stack>
                  <Text textAlign="center" color="muted">
                    {t('haircheck.setup.joinAsPresenter')}
                  </Text>
                </Stack>
              )}
            </div>
          )}
          <style jsx>{`
            .no-errors-content {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              gap: 8px;
              width: 100%;
              padding: 0;
            }
            .no-errors-content.horizontal {
              flex-direction: row;
              padding: 0;
            }
            .no-errors-content.landscape {
              padding: 0;
            }
          `}</style>
        </div>
      ) : (
        <Stack
          align="center"
          justify="center"
          gap={shouldUseLandscapeLayout ? 24 : 16}
        >
          {!isCameraRequired && (
            <Text color="muted" style={{}}>
              <Anchor
                onClick={onComplete}
                underline
                className={robotsClassName('btn-join')}
              >
                {joinWithoutText}
              </Anchor>
            </Text>
          )}
          {accessState === 'lobby' && <LockedMessage />}
          {broadcastRole === 'owner' && (
            <Stack>
              <Text textAlign="center" color="muted">
                {t('haircheck.setup.joinAsPresenter')}
              </Text>
            </Stack>
          )}
        </Stack>
      )}
    </>
  );
};
