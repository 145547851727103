import React from 'react';

import { CustomSelect } from './CustomSelect';
import { NativeSelect } from './NativeSelect';

type NativeProps = React.ComponentProps<typeof NativeSelect>;
type CustomProps = React.ComponentProps<typeof CustomSelect>;
type Props = NativeProps | CustomProps;

export const Select: React.FC<Props> = ({ native = false, ...props }) =>
  native ? (
    <NativeSelect {...(props as NativeProps)} />
  ) : (
    <CustomSelect {...(props as CustomProps)} />
  );
