import { Button } from '@daily/shared/components/Button';
import { CloseIcon } from '@daily/shared/components/Icons';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { VisuallyHidden } from '@daily/shared/components/VisuallyHidden';
import { useTheme } from '@daily/shared/contexts/Theme';
import {
  useLocalParticipant,
  useScreenShare,
} from '@daily-co/daily-react-hooks';
import { robotsClassName } from 'lib/robots';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { usePinnedId } from '../../contexts/UIState';

export const Unpin: React.FC = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const [pinnedId, setPinnedId] = usePinnedId();
  const { screens } = useScreenShare();
  const localParticipant = useLocalParticipant();

  const isPinnedScreen = useMemo(
    () => screens.some((s) => s.screenId === pinnedId),
    [pinnedId, screens]
  );

  const handleClick = () => {
    setPinnedId(null);
  };

  const label = useMemo(() => {
    if (isPinnedScreen) return t('screenShare.unpin');
    if (pinnedId === localParticipant?.session_id)
      return t('people.unpinYourself');
    return t('people.unpinParticipant');
  }, [localParticipant?.session_id, isPinnedScreen, pinnedId, t]);

  return (
    <div className="unpin">
      <Stack align="center" horizontal>
        <Text>{label}</Text>
        <Button
          className={robotsClassName('btn-unpin')}
          onClick={handleClick}
          variant="ghost"
        >
          <CloseIcon className="close" color={colors.accentText} size={24} />
          <VisuallyHidden>{label}</VisuallyHidden>
        </Button>
      </Stack>
      <style jsx>{`
        .unpin :global(button) {
          border-radius: 4px !important;
        }
        .unpin :global(.close) {
          background: ${colors.accent};
          border-radius: 4px;
        }
      `}</style>
    </div>
  );
};
