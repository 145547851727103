import { DailyParticipant, DailyParticipantsObject } from '@daily-co/daily-js';

// Note: doesn't actually delete the tracks fields, just marks them undefined,
// for code simplicity.
export function cloneParticipantWithoutTracks(
  participant: DailyParticipant
): DailyParticipant {
  return {
    ...participant,
    audioTrack: undefined,
    videoTrack: undefined,
    screenVideoTrack: undefined,
    screenAudioTrack: undefined,
    tracks: {
      audio: {
        ...participant.tracks.audio,
        track: undefined,
        persistentTrack: undefined,
      },
      video: {
        ...participant.tracks.video,
        track: undefined,
        persistentTrack: undefined,
      },
      screenAudio: {
        ...participant.tracks.screenAudio,
        track: undefined,
        persistentTrack: undefined,
      },
      screenVideo: {
        ...participant.tracks.screenVideo,
        track: undefined,
        persistentTrack: undefined,
      },
    },
  };
}

export function cloneParticipantsWithoutTracks(
  participants: DailyParticipantsObject
): DailyParticipantsObject {
  const newParticipants = {};
  for (const id in participants) {
    newParticipants[id] = cloneParticipantWithoutTracks(participants[id]);
  }
  return newParticipants as DailyParticipantsObject;
}
