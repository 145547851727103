import { useTheme } from '@daily/shared/contexts/Theme';
import React from 'react';

interface Props {
  size?: number;
}

export const FullscreenIcon: React.FC<Props> = ({ size = 24, ...props }) => {
  const { colors } = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888 0 17 0C7.61116 0 0 7.61116 0 17C0 26.3888 7.61116 34 17 34Z"
        fill={colors.background}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5076 14.2451C23.497 14.7428 23.9032 15.149 24.4009 15.1385L24.4558 15.1374C24.9245 15.1274 25.3021 14.7499 25.3121 14.2812L25.4107 9.66057C25.4228 9.09171 24.9585 8.62738 24.3896 8.63948L19.7689 8.73782C19.3001 8.7478 18.9225 9.12549 18.9127 9.59425L18.9115 9.64943C18.9011 10.1471 19.3073 10.5532 19.805 10.5426L22.1429 10.4928L18.3617 14.274C17.9712 14.6645 17.9712 15.2977 18.3617 15.6882C18.7522 16.0787 19.3854 16.0787 19.7759 15.6882L23.5574 11.9068L23.5076 14.2451Z"
        fill={colors.baseText}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5413 19.8038C10.5519 19.3061 10.1457 18.8998 9.64798 18.9104L9.59302 18.9115C9.12432 18.9214 8.74671 19.299 8.73672 19.7677L8.6382 24.3883C8.62607 24.9572 9.09038 25.4215 9.65925 25.4094L14.28 25.3111C14.7487 25.3011 15.1263 24.9234 15.1362 24.4546L15.1373 24.3995C15.1478 23.9018 14.7415 23.4957 14.2439 23.5063L11.906 23.556L15.6871 19.7749C16.0777 19.3844 16.0777 18.7512 15.6871 18.3607C15.2966 17.9701 14.6635 17.9701 14.2729 18.3607L10.4915 22.1421L10.5413 19.8038Z"
        fill={colors.baseText}
      />
      <style jsx>{`
        svg {
          display: block;
          height: ${size}px;
          width: ${size}px;
        }
      `}</style>
    </svg>
  );
};
