import { ExitIcon } from '@daily/shared/components/Icons';
// import { Text } from '@daily/shared/components/Text';
import { useTheme } from '@daily/shared/contexts/Theme';
import { useRecording } from '@daily-co/daily-react-hooks';
import { useCallback, useEffect } from 'react';

// import { useTranslation } from 'react-i18next';
import { useCallState } from '../../contexts/CallProvider';
import { useLeaveWhileRecordingModal } from '../../contexts/UIState';
import { robotsClassName } from '../../lib/robots';
import { TrayButton } from './TrayButton';

export const LeaveButton: React.FC = () => {
  // const { t } = useTranslation();
  const { colors } = useTheme();
  const { enableCallButtons, leaveCall } = useCallState();
  const [, setShowLeaveWhileRecordingModal] = useLeaveWhileRecordingModal();
  const { isRecording, local } = useRecording();

  const handleClick = useCallback(() => {
    if (isRecording && local) {
      setShowLeaveWhileRecordingModal(true);
      return;
    }
    leaveCall();
  }, [isRecording, leaveCall, local, setShowLeaveWhileRecordingModal]);

  useEffect(() => {
    if (typeof navigator.mediaSession?.setActionHandler !== 'function') return;
    try {
      navigator.mediaSession.setActionHandler('hangup', handleClick);
    } catch (e) {}
  }, [handleClick]);

  return (
    <div className="leave">
      <TrayButton
        className={robotsClassName('btn-leave')}
        disabled={!enableCallButtons}
        onClick={handleClick}
      >
        <ExitIcon color={colors.system.red} />
        {/* <Text>{t('general.leave')}</Text> */}
      </TrayButton>
    </div>
  );
};
