import { Button } from '@daily/shared/components/Button';
import { Text } from '@daily/shared/components/Text';
import { useTheme } from '@daily/shared/contexts/Theme';
import { pxToRem } from '@daily/shared/lib/pxToRem';
import classNames from 'classnames';
import { ChatMessage, Reaction } from 'contexts/ChatProvider';
import { useRef } from 'react';

interface Props {
  message: ChatMessage;
  onReact(reaction: Reaction): void;
}

export const defaultReactions: Reaction[] = [
  '👏',
  '👍',
  '👎',
  '👋',
  '❤️',
  '😂',
  '🤯',
  '➕',
  '🔥',
  '🎉',
];

export const ChatReactions: React.FC<Props> = ({ message, onReact }) => {
  const { colors } = useTheme();
  const reactionsRef = useRef<HTMLDivElement>(null);

  const handleClick = (reaction: Reaction) => {
    onReact(reaction);
  };

  const emptyReactions = defaultReactions.filter(
    (r) =>
      !(r in (message?.reactions ?? {})) ||
      (message?.reactions?.[r]?.count ?? 0) === 0
  );

  return (
    <div className="chat-reactions" ref={reactionsRef}>
      {defaultReactions
        .filter((r) => message.reactions?.[r]?.count > 0)
        .sort((a, b) => {
          if (emptyReactions.includes(a) && !emptyReactions.includes(b))
            return 1;
          if (!emptyReactions.includes(a) && emptyReactions.includes(b))
            return -1;
          return 0;
        })
        .map((reaction) => {
          const hasReacted = message.reactions?.[reaction]?.hasReacted;
          const count = message.reactions?.[reaction]?.count;
          return (
            <Button
              key={reaction}
              className={classNames({
                hasReacted,
                local: message.local,
              })}
              onClick={() => handleClick(reaction)}
              variant="secondary"
              size="small"
            >
              <span className="emoji">{reaction}</span>
              {count > 0 ? (
                <Text className="count" color="inherit" El="span">
                  {count >= 100 ? '99+' : count}
                </Text>
              ) : null}
            </Button>
          );
        })}
      <style jsx>{`
        .chat-reactions {
          display: inline-flex;
          flex-wrap: wrap;
          gap: 4px;
        }
        .chat-reactions :global(button) {
          background: ${colors.background};
          display: flex;
          gap: 6px;
          justify-content: center;
          min-width: 24px;
          padding: 6px 8px;
          position: relative;
          z-index: 1;
        }
        .chat-reactions :global(button.hasReacted) {
          background: ${colors.backgroundAccent};
        }
        .chat-reactions .emoji {
          font-size: ${pxToRem(14)};
        }
        .chat-reactions :global(.count) {
          font-variant-numeric: tabular-nums;
        }
      `}</style>
    </div>
  );
};
