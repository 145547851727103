import { FocusTrap } from '@daily/shared/components/FocusTrap';
import { useTheme } from '@daily/shared/contexts/Theme';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import tinykeys from 'tinykeys';

import { useIsMobile } from '../../contexts/UIState';
import { useCallConfig } from '../../hooks/useCallConfig';
import { localChatMessageState } from './Chat';
import { ChatCommandMenu } from './ChatCommandMenu';

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

export const ChatInput: React.FC<Props> = (props) => {
  const { enableAdvancedChat } = useCallConfig();
  const { fontFamilies, fontSizes, lineHeights, mediaQueries } = useTheme();
  const [localMessage, setLocalMessage] = useRecoilState(localChatMessageState);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [isMobile] = useIsMobile();
  const [textAreaHeight, setTextAreaHeight] = useState('auto');
  const [parentHeight, setParentHeight] = useState('auto');
  const [showCommands, setShowCommands] = useState(false);

  /**
   * Initially moves the cursor to end of input.
   */
  useEffect(() => {
    if (!textAreaRef.current) return;
    const textArea = textAreaRef.current;
    textArea.setSelectionRange(textArea.value.length, textArea.value.length);
  }, [textAreaRef]);

  useEffect(() => {
    if (!textAreaRef.current) return;
    if (!localMessage) {
      setTextAreaHeight('auto');
    } else {
      setParentHeight(`${textAreaRef.current.scrollHeight + 2}px`);
      setTextAreaHeight(`${textAreaRef.current.scrollHeight + 2}px`);
    }
  }, [localMessage, textAreaRef]);

  /**
   * Reset parent height when textarea height is set to 'auto'.
   */
  useEffect(() => {
    if (!textAreaRef.current || textAreaHeight !== 'auto') return;
    setParentHeight(`${textAreaRef.current.scrollHeight + 2}px`);
  }, [textAreaHeight, textAreaRef]);

  const handleChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLocalMessage(ev.target.value);
    props?.onChange?.(ev);
  };

  /**
   * Fill textarea with selected commands value.
   */
  const handlePick = (value: string) => {
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLTextAreaElement.prototype,
      'value'
    ).set;
    nativeInputValueSetter.call(textAreaRef.current, value);
    textAreaRef.current.dispatchEvent(new Event('input', { bubbles: true }));
    setShowCommands(false);
  };

  /**
   * Show/hide command menu based on current input value.
   */
  useEffect(() => {
    if (!enableAdvancedChat) return;
    setShowCommands(
      localMessage.startsWith('/') && !localMessage.includes(' ')
    );
  }, [enableAdvancedChat, localMessage]);

  /**
   * Register ESC key listener to close command menu.
   */
  useEffect(() => {
    if (!showCommands) return;
    const unsubscribe = tinykeys(textAreaRef.current, {
      Escape: (ev) => {
        ev.preventDefault();
        ev.stopImmediatePropagation();
        setShowCommands(false);
      },
    });
    return () => {
      unsubscribe();
    };
  }, [showCommands]);

  return (
    <div
      className="chat-input-wrapper"
      style={{
        minHeight: parentHeight,
      }}
    >
      <FocusTrap active={showCommands} arrowKeys="updown">
        <textarea
          {...props}
          autoFocus={!isMobile}
          ref={textAreaRef}
          rows={1}
          style={{
            height: textAreaHeight,
          }}
          onChange={handleChange}
          value={localMessage}
        />
        {enableAdvancedChat && showCommands && (
          <div className="cmds">
            <ChatCommandMenu
              message={localMessage}
              onPick={handlePick}
              textAreaRef={textAreaRef}
            />
          </div>
        )}
      </FocusTrap>
      <style jsx>{`
        .chat-input-wrapper {
          position: relative;
        }
        .cmds {
          bottom: calc(100% + 4px);
          position: absolute;
          width: 100%;
        }
        textarea {
          background: var(--input-bg);
          border: 1px solid var(--input-border);
          border-radius: 8px;
          box-shadow: 0 0 0 0 var(--focus-shadow);
          caret-color: var(--input-color);
          color: var(--input-color);
          display: block;
          font-family: ${fontFamilies.regular};
          font-size: ${fontSizes.base};
          font-weight: normal;
          line-height: ${lineHeights.base};
          margin: 0;
          overflow: hidden;
          padding: 12px 52px 12px 12px;
          position: relative;
          resize: none;
          transition: border 200ms ease, box-shadow 200ms ease;
          width: 100%;
          z-index: 2;
        }
        @media ${mediaQueries.coarse} {
          textarea {
            font-size: ${fontSizes.large};
            line-height: ${lineHeights.large};
            padding-right: 72px;
          }
        }
        textarea:not([disabled]):focus,
        textarea:not([disabled]):hover {
          border: 1px solid var(--focus-border);
          box-shadow: 0 0 0 2px var(--focus-shadow);
        }
        textarea:not([disabled]):focus {
          outline: none;
        }
        textarea::placeholder {
          color: var(--input-placeholder-color);
        }
        textarea[disabled] {
          background-color: var(--input-disabled-bg);
          color: var(--input-disabled-color);
        }
        textarea[disabled]::placeholder {
          color: var(--input-disabled-placeholder-color);
        }
      `}</style>
    </div>
  );
};
