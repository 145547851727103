interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  title: string;
  src: string;
}

export const GiphyGif: React.FC<Props> = ({ title, ...props }) => (
  <>
    <img {...props} alt={`Giphy Gif with title "${title}"`} />
    <style jsx>{`
      img {
        border-radius: 8px;
        display: block;
        width: 100%;
      }
    `}</style>
  </>
);
