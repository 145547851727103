import { Anchor } from '@daily/shared/components/Anchor';
import { Card, CardContent } from '@daily/shared/components/Card';
import { LoadingSpinner } from '@daily/shared/components/LoadingSpinner';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { robotsClassName } from 'lib/robots';
import { useTranslation } from 'react-i18next';

import { LockedMessage } from './LockedMessage';

interface Props {
  onCancelJoinRequest(): void;
}

export const OwnerOnlyBroadcastWaiting: React.FC<Props> = ({
  onCancelJoinRequest,
}) => {
  const { t } = useTranslation();

  return (
    <Stack className="card-container">
      <Card>
        <CardContent>
          <Stack align="center" gap={16}>
            <LoadingSpinner />
            <Text variant="largestrong">
              {t('haircheck.setup.titleWaiting')}
            </Text>
            <Text color="muted">
              <Anchor
                onClick={onCancelJoinRequest}
                underline
                className={robotsClassName('btn-cancel-request')}
              >
                {t('haircheck.setup.cancelRequest')}
              </Anchor>
            </Text>
            <LockedMessage />
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};
